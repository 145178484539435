import React, { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { createTheme, FormControl, MenuItem, Select, SelectChangeEvent, ThemeProvider } from '@mui/material';

interface DateTimePickerComponentProps {
	style?: any;
	error?: any;
	value: Dayjs | 'current';
	onChange: (newValue: Dayjs | '' | null) => void;
	onBlur?: (e: any) => void;
}

// Define a custom theme for the DateTimePicker
const customTheme = createTheme({
	components: {
		MuiPickersDay: {
			styleOverrides: {
				root: {
					color: 'white',
					backgroundColor: '#000' // Default day color
				},
				today: {
					backgroundColor: '#00B9FF' // Border for today's date
				}
			}
		},
		MuiClockNumber: {
			styleOverrides: {
				root: {
					color: 'white'
				}
			}
		},
		MuiTypography: {
			styleOverrides: {
				root: {
					'&.MuiDayPicker-weekDayLabel': {
						color: 'white' // Day label (S, M, T, etc.) color
					}
				}
			}
		}
	}
});

const CustomDateTimePicker: React.FC<DateTimePickerComponentProps> = ({ style, value, onChange, onBlur, error }) => {
	return (
		<ThemeProvider theme={customTheme}>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<DateTimePicker
					value={value}
					onChange={onChange}
					renderInput={(params) => (
						<TextField
							{...params}
							sx={{ ...style }}
							onBlur={onBlur}
							error={error}
							onKeyDown={(e) => {
								if (e.key === 'Enter' && onBlur) {
									onBlur(e);
								}
							}}
						/>
					)}
				/>
			</LocalizationProvider>
		</ThemeProvider>
	);
};

export default CustomDateTimePicker;
