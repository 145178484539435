import { Box, BoxProps, FormControl, MenuItem, Popover, Select, Stack } from '@mui/material';
import { DateRange as ReactDateRange } from 'react-date-range';
import { enUS } from 'date-fns/locale';
import { ReactComponent as CalendarIcon } from '../../assets/calendar-outline_white.svg';
import { useRef, useState } from 'react';
import moment from 'moment';
import { useParams, useSearchParams } from 'react-router-dom';

type ReactDateRangeFilterProps = {
	startingDate?:string,
	setStartDate: React.Dispatch<React.SetStateAction<string>>;
	setEndDate: React.Dispatch<React.SetStateAction<string>>;
	handleDateSelect: (stDt: string, edDt: string, selectedDate?: string, is24HrsTime?: boolean) => void;
	endingDate?:string,
	boxProps?: BoxProps;
	isDisabled?: boolean;
};

const timeZone = 'America/Chicago';

const ReactDateRangeFilter = ({ startingDate, setStartDate, setEndDate, handleDateSelect, isDisabled, boxProps }: ReactDateRangeFilterProps) => {
	const { sx, ...props } = boxProps || {};
	const [searchParams, setSearchParams] = useSearchParams();
	const { projDate } = useParams();
	let dateParam = {
		start: projDate || searchParams.get('startingDate') || startingDate,
		end: projDate || searchParams.get('endingDate') || startingDate
	}
	const [locale, setLocale] = useState(enUS);
	const [selectionRange, setSelectionRange] = useState<any>({
		startDate: dateParam.start ? new Date(dateParam.start) :  new Date(),
		endDate: dateParam.start ? new Date(dateParam.start) :  new Date(),
		key: 'selection'
	});
	const [customMonth, setCustomMonth] = useState('');
	const [isDateOpen, setIsDateOpen] = useState(false);
	// const [filterMonth, setFilterMonth] = useState('Today');
	const [filterMonth, setFilterMonth] = useState(moment(dateParam.start).format("YYYY-MM-DD") == moment().tz("America/Chicago").format("YYYY-MM-DD") ? 'Today' : 'custom');

	if (dateParam.start) {
		setStartDate(moment(dateParam.start).format("YYYY-MM-DD"));
			setEndDate(moment(dateParam.start).format("YYYY-MM-DD"));
	}

	// dateParam = {
	// 	start: '',
	// 	end: ''
	// }
	const [filterDefaultMonth, setFilterDefaultMonth] = useState('Today');
	const [dateRangeClickCount, setDateRangeClickCount] = useState(0);
	const isCustomMonth = useRef(false);
	const [dateFilterMenu, setDateFilterMenu] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);
	const dateFilterMenuClick = (event: any) => {
		setDateFilterMenu(
			dateFilterMenu === null
				? {
						mouseX: parseInt(event.clientX) - 400,
						mouseY: parseInt(event.clientY) - 110
				  }
				: null
		);
	};
	const handleCustomOnclick = (e: any) => {
		if (e.target.textContent == 'Custom Date Range') {
			setDateRangeClickCount(0);
			isCustomMonth.current = true;
			setTimeout(() => {
				dateFilterMenuClick(e);
			}, 200);
		}
		if (isCustomMonth.current) setIsDateOpen(true);
		else setIsDateOpen(false);

		if (e.type == 'click' && e.target.className.includes('MuiModal-backdrop')) {
			isCustomMonth.current = false;
			setDateFilterMenu(null);
			setIsDateOpen(false);
			if (customMonth == '') {
				setFilterMonth(filterDefaultMonth);
			}
		}
	};
	const handleDateFilter = (field: any, selectedDate: any) => {
		// dateParam = {start: '', end: ''}
		selectedDate = selectedDate.target.value;
		let stDt = '',
			edDt = '';
		if (field === 'dateFilter') {
			isCustomMonth.current = false;
			let is24HrsTime = false;
			setCustomMonth('');
			// edDt = moment.tz(timeZone).format('YYYY-MM-DD HH:mm');

			// if (selectedDate == 'Today') {
			// 	stDt = moment.tz(timeZone).format('YYYY-MM-DD HH:mm');
			// } else if (selectedDate == 'Hrs') {
			// 	stDt = moment.tz(timeZone).subtract(24, 'hours').format('YYYY-MM-DD HH:mm');
			// 	is24HrsTime = true;
			// } else if (selectedDate == '7d') {
			// 	stDt = moment.tz(timeZone).subtract(6, 'd').format('YYYY-MM-DD HH:mm');
			// } else if (selectedDate == '30d') {
			// 	stDt = moment.tz(timeZone).subtract(29, 'd').format('YYYY-MM-DD HH:mm');
			// } else if (selectedDate == '3M') {
			// 	stDt = moment.tz(timeZone).subtract(3, 'M').format('YYYY-MM-DD HH:mm');
			// }
			edDt = moment().endOf('day').format('YYYY-MM-DD HH:mm');	

			if (selectedDate == 'Today') {
				stDt = moment().format('YYYY-MM-DD');
			} else if (selectedDate == 'Hrs') {
				stDt = moment().subtract(24, 'hours').format('YYYY-MM-DD HH:mm');
				is24HrsTime = true;
			} else if (selectedDate == '7d') {
				stDt = moment().subtract(6, 'd').format('YYYY-MM-DD HH:mm');
			} else if (selectedDate == '30d') {
				stDt = moment().subtract(29, 'd').format('YYYY-MM-DD HH:mm');
			} else if (selectedDate == '3M') {
				stDt = moment().subtract(3, 'M').format('YYYY-MM-DD HH:mm');
			}
			setStartDate(stDt);
			setEndDate(edDt);
			setFilterMonth(selectedDate);
			if (selectedDate === 'custom') {
				setDateRangeClickCount(0);
				isCustomMonth.current = true;
				setIsDateOpen(true);
				return;
			} else {
				handleDateSelect(stDt, edDt, selectedDate, is24HrsTime);
			}
			setFilterDefaultMonth(selectedDate);
		}
		setFilterMonth(selectedDate);
	};
	const handleSelectDateRange = (e: any) => {
		let clickCount = dateRangeClickCount + 1;
		setSelectionRange(e.selection);
		setDateRangeClickCount(clickCount);
		if (clickCount == 2) {
			setCustomMonth(e.selection.key);
			handleDateFilterMenuClose(e);
			setIsDateOpen(false);
		
			const stDt = moment(e.selection.startDate).add(1, 'days').startOf('day').format('YYYY-MM-DD HH:mm');
			const edDt = moment(e.selection.endDate).add(1, 'days').endOf('day').format('YYYY-MM-DD HH:mm');
			// const stDt = moment.tz(moment(e.selection.startDate), 'America/Chicago').format('YYYY-MM-DD HH:mm');
			// const edDt = moment.tz(moment(e.selection.endDate), 'America/Chicago').format('YYYY-MM-DD HH:mm');
			handleDateSelect(stDt, edDt);
		}
	};
	const handleDateFilterMenuClose = (event: any) => {
		setDateFilterMenu(null);
	};

	return (
		<Box
			sx={{
				backgroundColor: 'blue.dark',
				borderRadius: '4px',
				fontSize: '15px',
				zIndex: 999,
				width: 'fit-content',
				minWidth: '230px',
				textAlign: 'left',
				height: 'fit-content',
				...sx,
				'& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
					borderColor: 'transparent !important'
				},
				'& .Mui-disabled.MuiSelect-icon': {
					top: 'auto'
				},
				'& .MuiSelect-icon': {
					top: 'calc(50% - 12px)'
				}
			}}
			{...props}
		>
			<FormControl fullWidth>
				<Select
					name="date-select"
					labelId="demo-simple-select-label"
					id="single-date-select"
					value={filterMonth}
					label="Date"
					onChange={(e) => {
						handleDateFilter('dateFilter', e);
					}}
					disabled={isDisabled}
					IconComponent={CalendarIcon}
					open={isDateOpen}
					onClick={(e) => handleCustomOnclick(e)}
					onOpen={() => setIsDateOpen(true)}
					onClose={() => setIsDateOpen(false)}
				>
					<MenuItem value={'Today'}>Today</MenuItem>
					{
						filterMonth.includes('-') && <MenuItem value={moment(filterMonth).format("YYYY-MM-DD")}>Custom Date Range</MenuItem>
					}
					
					<MenuItem value={'Hrs'}>Yesterday</MenuItem>
					<MenuItem value={'7d'}>Last 7 days</MenuItem>
					<MenuItem value={'30d'}>Last 30 days</MenuItem>
					<MenuItem value={'3M'}>Last 3 Months</MenuItem>
					<MenuItem value={'custom'}>Custom Date Range</MenuItem>
				</Select>
			</FormControl>
			{isCustomMonth.current && (
				<Popover
					open={dateFilterMenu !== null}
					onClose={handleDateFilterMenuClose}
					anchorReference="anchorPosition"
					anchorPosition={
						dateFilterMenu !== null ? { top: dateFilterMenu.mouseY, left: dateFilterMenu.mouseX } : undefined
					}
				>
					<Box
						sx={{
							'.rdrCalendarWrapper': {
								backgroundColor: '#003771',
								borderRadius: '4px',
								padding: '20px'
							},
							'.rdrDateDisplayWrapper': {
								display: 'none'
							},
							'.rdrMonthAndYearWrapper': {
								position: 'relative',
								display: 'block',
								marginBottom: '10px'
							},
							'.rdrMonthAndYearPickers': {
								display: 'block',
								height: '30px',
								'& select': {
									background: 'none',
									border: 'none',
									color: '#fff',
									fontSize: '15px',
									fontWeight: 700,
									padding: '3px 0',
									'& option': {
										background: '#001e3e',
										color: '#fff',
										fontSize: '15px',
										fontWeight: 500,
										padding: '4px 0',
										'&:focus, &:active': {
											outline: 'none',
											border: 'none'
										}
									}
								}
							},
							'.rdrNextPrevButton': {
								border: '1px solid #71758F',
								background: 'none',
								borderRadius: '4px',
								position: 'absolute',
								right: 0,
								top: 0,
								padding: '4px 7px',
								'&.rdrPprevButton': {
									right: '35px',
									'& i': {
										border: 'solid #e5e5e5',
										borderWidth: '0 2px 2px 0',
										display: 'inline-block',
										padding: '3px',
										transform: 'rotate(-135deg)',
										verticalAlign: 'middle'
									}
								},
								'&.rdrNextButton': {
									'& i': {
										border: 'solid #e5e5e5',
										borderWidth: '0 2px 2px 0',
										display: 'inline-block',
										padding: '3px',
										transform: 'rotate(45deg)',
										verticalAlign: 'text-top',
										marginTop: '2px'
									}
								},
								'&:hover': {
									backgroundColor: '#002349'
								}
							},
							'.rdrMonths': {
								'.rdrMonth': {
									width: '16em'
								},
								'.rdrWeekDays': {
									marginBottom: '10px',
									'.rdrWeekDay': {
										fontFamily: 'Montserrat',
										color: '#71758F',
										fontSize: '14px',
										fontWeight: 600
									}
								},
								'.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge': {
									background: '#009BD8',
									position: 'absolute',
									top: '5px',
									left: 0,
									right: 0,
									bottom: '5px'
								},
								'.rdrInRange': {
									backgroundColor: 'rgba(113, 117, 143, 0.4)'
								},
								'.rdrStartEdge': {
									borderTopLeftRadius: '1.042em',
									borderBottomLeftRadius: '1.042em',
									left: '2px'
								},
								'.rdrEndEdge': {
									borderTopRightRadius: '1.042em',
									borderBottomRightRadius: '1.042em',
									right: '2px'
								},
								'.rdrDayNumber': {
									'& span': {
										color: '#fff'
									}
								},
								'.rdrDayToday': {
									'&:after': {
										content: "''",
										position: 'absolute',
										bottom: '4px',
										left: '50%',
										transform: 'translate(-50%, 0)',
										width: '18px',
										height: '2px',
										borderRadius: '2px',
										background: '#3d91ff'
									}
								},
								'.rdrDays': {
									'.rdrDay': {
										backgroundColor: '#003771',
										border: 'none',
										fontFamily: 'Montserrat',
										fontSize: '12px',
										fontWeight: 600,
										padding: '13px 0'
									},
									'.rdrDayPassive': {
										'.rdrDayNumber': {
											'& span': {
												color: 'rgba(113, 117, 143, 1)'
											}
										}
									}
								}
							}
						}}
					>
						<ReactDateRange
							showPreview={false}
							editableDateInputs={true}
							moveRangeOnFirstSelection={false}
							locale={locale}
							ranges={[selectionRange]}
							onChange={(date) => handleSelectDateRange(date)}
						/>
					</Box>
				</Popover>
			)}
		</Box>
	);
};

export default ReactDateRangeFilter;
