import { IconButton, Stack, Typography } from '@mui/material';
import colors from 'assets/theme/base/colors';
import RowTypography from 'components/Table/RowTypography';
import React, { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import HistoryIcon from '@mui/icons-material/History';
import CloseIcon from '@mui/icons-material/Close';
import { CustomizedDataGrid } from 'components/customized_data_grid';
import LoaderComponent from 'components/Loader';
import { getAllocation } from 'service/service';
import moment from 'moment';

interface UpdateHistoryProps {
	curRow: any;
    setIsUpdateHistoryOpen: any;
}

interface UpdateHistory {
	sector: string;
	environment: string;
	program: string;
	project: string;
	allocationPercentage: string;
	updatedBy: {
		name: string;
		email: string;
	};
	updatedAt: string;
}

const UpdateHistory: React.FC<UpdateHistoryProps> = ({ curRow, setIsUpdateHistoryOpen }: any) => {
	const defaultColumnNames = [
		'Sector',
		'Environment',
		'Program',
		'Project',
		'Allocation %',
		'Start Date',
		'End Date',
		'Updated By',
		'Updated At'
	];

	const columnNames = [
		{ name: 'pepwiseSector', label: 'Sector' },
		{ name: 'pepwiseEnvironment', label: 'Environment' },
		{ name: 'pepwiseProgram', label: 'Program' },
		{ name: 'pepwiseProject', label: 'Project' },
		{ name: 'allocation', label: 'Allocation %' },
		{ name: 'startDate', label: 'Start Date' },
		{ name: 'endDate', label: 'End Date' },
		{ name: 'updatedBy', label: 'Updated By' },
		{ name: 'updatedDate', label: 'Updated At' }
	];

	const [updateHistory, setUpdateHistory] = useState<any[]>([]);
	const [loading, setLoading] = useState<boolean>(false);


	async function historyDetailsFunc() {
		setLoading(true);
		const res = await getAllocation(curRow.groupId, true);
		if (res.success) {
			setLoading(false);
			setUpdateHistory(res.data);
		}
	}

	//fetching data
	useEffect(() => {
		if (curRow) {
			setLoading(true);
			setTimeout(() => {
				historyDetailsFunc();
				setLoading(false);
			}, 2000);
		}
	}, [curRow]);

	function getColumns(): Array<any> {
		return columnNames.map((col: any, idx: number) => ({
			key: col.name,
			name: col.label,
			formatter({ row, column }: any) {
				return (
					<Stack direction="row" justifyContent="space-between" alignItems="center">
						<RowTypography isSuccessor={!row[column.key]}>
							{(column.key === 'startDate') || (column.key === 'endDate') ? (
								row[column.key] ? moment(row[column.key]).format('YYYY-MM-DD') : ''
							) : typeof row[column.key] === 'object' ? (
								<Stack direction="row" gap={2}>
									<Typography sx={{ fontSize: '12px' }}>{row[column.key].name}</Typography>
									<Typography sx={{ fontSize: '12px', fontStyle: 'italic', color: 'light.diff' }}>
										({row[column.key].email})
									</Typography>
								</Stack>
							) : column.key === 'allocation' ? (
								<>{`${row[column.key]}%`}</>
							) : column.key === 'updatedDate' ? (
								moment(row[column.key]).format('YYYY-MM-DD HH:mm')
							) : (
								row[column.key]
							)}
						</RowTypography>
					</Stack>
				);
			},
			width: col.name === 'updatedBy' ? 530 : 221
		}));
	}

	return (
		<Stack direction="column" p={4} position='relative'>
			<IconButton
				onClick={() => setIsUpdateHistoryOpen(false)} // Close drawer
				sx={{
					position: 'absolute',
					top: 8,
					right: 8,
					zIndex: 1
				}}
			>
				<CloseIcon />
			</IconButton>
			<Stack
				direction="row"
				spacing={12}
				pt={1}
				pb={4}
				marginBottom={1}
				borderBottom={`0.5px solid ${colors.dark.dark}`}
			>
				<Stack>
					<Typography sx={{ color: 'text.subtle' }}>
						Resource Name&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						<Typography component="span" sx={{ fontWeight: 'bold' }}>
							{curRow.Resource}
						</Typography>
					</Typography>
				</Stack>

				<Stack>
					<Typography sx={{ color: 'text.subtle' }}>
						Resource Group&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						<Typography component="span" sx={{ fontWeight: 'bold' }}>
							{curRow.ResourceGroup}
						</Typography>
					</Typography>
				</Stack>
			</Stack>
			{loading ? (
				<LoaderComponent />
			) : (
				<CustomizedDataGrid
					title={
						<Stack direction="row" alignItems="center" spacing={1} mt="1rem">
							<HistoryIcon />
							<Typography fontSize="16px">Recent update history</Typography>
						</Stack>
					}
					tableColumns={getColumns()}
					tableData={updateHistory}
					tableType="history-list"
					defaultColumnNames={defaultColumnNames}
					autoSetDraggedColumns={false}
					listLength={updateHistory.length}
					showExport={false}
					isDateRangeFilter={false}
					isDisabled={false}
					isRenderPagination={false}
					isShowMoreButton={false}
					style={{ maxHeight: '250px' }}
					rowHeight={40}
				/>
			)}
		</Stack>
	);
};

export default UpdateHistory;
