import { Box, Chip, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import serviceRequestIcon from '../../assets/service-request.svg';
import React, { useEffect, useRef, useState } from 'react';
import PageHeader from 'components/Header/Page';
import DateFilterDropdown from 'components/Dropdown/DateFilter';
import { useCacheDataController } from 'Context/contexts/CacheDataContext';
import ReactECharts from 'echarts-for-react';
import { CustomizedDataGrid } from 'components/customized_data_grid';
import LoaderComponent from 'components/Loader';
import { fetchDEIncidentDetails, fetchServiceRequestSummary } from 'service/service';
import moment from 'moment';
import { Column } from 'react-data-grid';
import { convertShortDateToActualDateFormat, formatNumberWithCommas } from 'utils/common-util';
import DoughnutChartWidget from 'components/Widgets/DoughnutWidget';

const majorIncident = () => {
	const [controller] = useCacheDataController();
	const { deMetricsLastSyncDate } = controller;
	const [dateFilter, setDateFilter] = useState<any>('Apr 2024');
	const [customMonth, setCustomMonth] = useState('');
	const [filterMonth, setFilterMonth] = useState<any>(localStorage.getItem('deMajorIncident') == 'true' ? localStorage.getItem('deIncidentDetailSelectedDate') : '0Y');
	const [filterDefaultMonth, setFilterDefaultMonth] = useState<any>(localStorage.getItem('deMajorIncident') == 'true' ? localStorage.getItem('deIncidentDetailSelectedDate') : '0Y');
	const [chartType, setChartType] = useState('');
	const [dimension, setDimension] = useState('');

	const applyDateFilter = (selectedDate: any) => {
		 getMajorIncidentsFunc(selectedDate);
		setDateFilter(selectedDate);
	};

    const formatNumberByUnits = (number: number) => {
		if (number >= 1000000) {
			return `${new Intl.NumberFormat(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(
				number / 1000000
			)}MM`;
		}
		if (number >= 1000) {
			return `${formatNumberWithCommas((number / 1000).toFixed(2), true)}K`;
		}
		return new Intl.NumberFormat(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(number);
	};   

    interface Row {
		incidentId: string,
		relatedProblem: string,
		shortDescription: string,
		configurationItem: string,
		classDescription: string,
		openedDate: string,
		closedDate: string,
		resolvedDate: string,
		priority: string,
		businessCriticality: string,
		assignmentGroup: string,
		assignedTo: string,
		vendorName: string,
		status: string,
		outageType: string
	}

    const renderStatus = (status: any) => {
		switch (status) {
			case 'closed':
				return <Chip label={status} color="success" />;
			case 'open':
				return <Chip label={status} style={{ backgroundColor: '#299BFF26', color: '#299BFF' }} />;
			default:
				return <Chip label={status} style={{ backgroundColor: '#299BFF26', color: '#299BFF' }} />;
		}
	};

	const setdateFilter = () =>{
		localStorage.setItem('deIncidentDetailSelectedDate',filterMonth);
		localStorage.setItem('deMajorIncident','false');
	}
    function getColumns(): Array<Column<Row>> {
		return [
			{
				key: 'incidentId',
				name: 'Incident ID',
				minWidth: 120,
				width: 120,
				formatter(props) {
					if (props.row.assignmentGroup.includes('EDW PLATFORM TERADATA') || // teradata
						(props.row.assignmentGroup.includes('INFRA AZURE CLOUD') && props.row.incidentId == 'INC10974136') ||
						(props.row.assignmentGroup.includes('ENTERPRISE DATA WAREHOUSE') || // databrick
						props.row.assignmentGroup.includes('ADVANCED ANALYTICS PLATFORM SUPPORT') && 
						props.row.incidentId == 'INC09974786' || props.row.incidentId == 'INC10192559' || props.row.incidentId == 'INC11223786') // databrick
					) {
						return (
							<Link to={`/dataestate/incident-impact-analysis/${props.row.incidentId}`} state={props.row} className="jobsNameList" onClick={setdateFilter}>
								{props.row.incidentId}
							</Link>
						);
					} else
						return props.row.incidentId
				},
			},
			// {
			// 	key: 'relatedProblem',
			// 	name: 'Related Problem',
			// 	width: 140,
			// 	minWidth: 140
			// },
			{
				key: 'shortDescription',
				name: 'Short Description',
				width: 230,
				minWidth: 200,
				formatter(props) {
					const data = props.row.shortDescription ? props.row.shortDescription : '-';
					return <div title={props.row.shortDescription} className='has-ellipsis'>{data}</div>;
				}
			},
			{
				key: 'configurationItem',
				name: 'Configuration Item Name',
				width: 230,
				minWidth: 200
			},
			{
				key: 'classDescription',
				name: 'Class Description',
				width: 230,
				minWidth: 200
			},
			{
				key: 'openedDate',
				name: 'Open Date',
				minWidth: 150,
				formatter(props: any) {
					const openedDate = props.row.openedDate;
					if (openedDate !== 'undefined' && openedDate !== null) {
						return moment(openedDate).format('MM-DD-YY HH:mm:ss');
					}
					return '';
				}
			},
			{
				key: 'outageStartTime',
				name: 'Outage Start Time',
				minWidth: 150,
				formatter(props: any) {
					const outageStartTime = props.row.outageStartTime;
					if (outageStartTime !== 'undefined' && outageStartTime !== null) {
						return moment(outageStartTime).format('MM-DD-YY HH:mm:ss');
					}
					return '';
				}
			},
			{
				key: 'outageEndTime',
				name: 'Outage End Time',
				minWidth: 150,
				formatter(props: any) {
					const outageEndTime = props.row.outageEndTime;
					if (outageEndTime !== 'undefined' && outageEndTime !== null) {
						return moment(outageEndTime).format('MM-DD-YY HH:mm:ss');
					}
					return '';
				}
			},
			{
				key: 'outageDuration',
				name: 'Outage Duration',
				minWidth: 150,
				formatter(props: any) {
					const outageDuration = props.row.outageDuration;
					if (outageDuration !== 'undefined' && outageDuration !== null) {
						return decimalHoursToHMS(outageDuration);
					}
					return '';
				}
			},
			{
				key: 'outageType',
				name: 'Outage Type',
				width: 150,
				minWidth: 150
			},
			{
				key: 'closedDate',
				name: 'Close Date',
				minWidth: 150,
				formatter(props: any) {
					const closedDate = props.row.closedDate;
					if (closedDate !== 'undefined' && closedDate !== null) {
						return moment(closedDate).format('MM-DD-YY HH:mm:ss');
					}
					return '';
				}
			},
			{
				key: 'resolvedDate',
				name: 'Resolve Date',
				minWidth: 150,
				formatter(props: any) {
					const resolvedDate = props.row.resolvedDate;
					if (resolvedDate !== 'undefined' && resolvedDate !== null) {
						return moment(resolvedDate).format('MM-DD-YY HH:mm:ss');
					}
					return '';
				}
			},
			{
				key: 'priority',
				name: 'Priority',
				minWidth: 130,
				width: 130,
			},
			{
				key: 'businessCriticality',
				name: 'Business Criticality',
				width: 190,
				minWidth: 190
			},
			{
				key: 'assignmentGroup',
				name: 'Assignment Group',
				width: 230,
				minWidth: 200,
				formatter(props) {
					const data = props.row.assignmentGroup ? props.row.assignmentGroup : '-';
					return <div title={props.row.assignmentGroup} className='has-ellipsis'>{data}</div>;
				}
			},
			{
				key: 'assignedTo',
				name: 'Assigned To',
				minWidth: 125,
				width: 125
			},
			// {
			// 	key: 'vendorName',
			// 	name: 'Vendor Name',
			// 	minWidth: 130,
			// 	width: 130,
			// },
		];
	}
    const [checkedList, setCheckedList] = useState<any[]>([]);
    const [MTTRData, SetMTTRData] = useState([]);
    const [IncidentsData, SetIncidentsData] = useState([]);
    const [OutagesData, SetOutagesData] = useState<any>({});
    const [MTTRDataObject, SetMTTRDataObject] = useState<any>({});

    const [doughnutMTTRTotal, setDoughnutMTTRTotal] = useState<any>();
    const [doughnutSeriesData, setDoughnutSeriesData] = useState<any>([]);
    const [MTTRLoading, setMTTRLoading] = useState(true);

    const [doughnutIncidentTotal, setDoughnutIncidentTotal] = useState<any>();
    const [doughnutIncSeriesData, setDoughnutIncSeriesData] = useState<any>([]);
    const [incidentLoading, setIncidentLoading] = useState(true);

    const [doughnutOutagTotal, setDoughnutOutageTotal] = useState<any>();
    const [doughnutOutageSeriesData, setDoughnutOutageSeriesData] = useState<any>([]);
    const [OutageLoading, setOutageLoading] = useState(true);

    const [incidentDetails, setIncidentDetails] = useState<Row[]>([]);
	const [loading, setLoading] = useState(false);
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const totalElements = paginationData?.totalElements || 0;
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);

	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [filterData, setFilterData] = useState<Row[]>([]);
	const [filterInfo, setFilterInfo] = useState([]);
	const [searchDetails, setFilterSearchInfo] = useState([]);

    const defaultColumnNames = [
		'Incident ID',
		// 'Related Problem',
		'Short Description',
		'Configuration Item Name',
		'Open Date',
		'Outage Start Time',
		'Outage End Time',
		'Outage Duration',
		'Resolve Date',
		'Close Date',
		'Priority',
		'Business Criticality',
		'Assignment Group',
		'Assigned To',
		// 'Vendor Name',
		'Status'
	];

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		incidentsDetailFunc(value, pageSize, sortingInfo, parseFilter(filterInfo), filterMonth, chartType, dimension);
	};

	const handlepageSizeChange = (event: any) => {
		incidentsDetailFunc('1', event.target.value, sortingInfo, parseFilter(filterInfo), filterMonth, chartType, dimension);
	};
	const handleSorting = (sortingInfo: any) => {
		incidentsDetailFunc(page, pageSize, sortingInfo, parseFilter(filterInfo), filterMonth, chartType, dimension);
	};

    function decimalHoursToHMS(decimalHours: any) {
		// Calculate hours
		const hours = Math.floor(decimalHours);
		// Calculate remaining minutes from the decimal part
		const minutes = Math.floor((decimalHours - hours) * 60);
		// Calculate seconds from the remaining decimal part of minutes
		const seconds = Math.round(((decimalHours - hours) * 60 - minutes) * 60);
	 
		// Format the output
		if (hours === 0 && minutes === 0) {
			return `${seconds ? seconds : 1} Seconds`;
		} else if (hours === 0) {
			return `${minutes} Minutes and ${seconds} Seconds`;
		} else {
			return `${hours} Hours, ${minutes} Minutes, and ${seconds} Seconds`;
		}
	}

    const handleFilter = (filterInfo: any) => {
		setFilterInfo(filterInfo);
		incidentsDetailFunc(1, pageSize, sortingInfo, parseFilter(filterInfo), filterMonth, chartType, dimension);
	};
	const handleSearch = (searchDetails: any, columnKey: any) => {
		incidentSearchFunc(searchDetails, parseFilter(filterInfo, columnKey), columnKey);
	};

	const parseFilter = (filterInfo: any, name?: string) => {
		// Call only filterable columns and reset the filtering in case of wrong/empty column
		const parsedFilter: any = {};
		Object.keys(filterInfo).forEach((key: any) => {
			if (key !== name && filterInfo[key] && filterInfo[key].length) {
				parsedFilter[key] = filterInfo[key];
			}
		});
		return parsedFilter;
	};

    function splitHrsToDays(numberOfHours: number) {
        const Days = Math.floor(numberOfHours / 24); 
        const Remainder = numberOfHours % 24;        
        const Hours = Math.floor(Remainder);         
        const Minutes = Math.round(60 * (Remainder - Hours)); 
    
        return { Days, Hours, Minutes }; 
    }

    async function getMajorIncidentsFunc(selectedDate?: string) {
		setLoading(true);
		const formattedDate = convertShortDateToActualDateFormat(selectedDate);
        fetchServiceRequestSummary('MTTR', selectedDate, checkedList, formattedDate).then(res => {
			SetMTTRData(res?.data?.result);
			const data: any = {'Legacy EDW': null, 'Azure': null, 'Databricks': null, 'Presto': null, 'Others':null};
            let totalSum = 0;
			let count = 0;            
			res?.data?.result.forEach((mttr: any) => {
                totalSum = 0; count = 0;
				if (mttr.EDW != 0 && mttr.EDW != null) {
					data['Legacy EDW'] = mttr.EDW;
					totalSum += mttr.EDW; count++;
				}
				if (mttr.Azure != 0 && mttr.Azure != null) {					
					data['Azure'] = mttr.Azure;
					totalSum += mttr.Azure; count++;
				}
				if (mttr.Databricks != 0 && mttr.Databricks != null) {
					data['Databricks'] = mttr.Databricks;
					totalSum += mttr.Databricks; count++;
				}
				if (mttr.Presto != 0 && mttr.Presto != null) {
					data['Presto'] = mttr.Presto;
					totalSum += mttr.Presto; count++;
				}
				if (mttr.Others != 0 && mttr.Others != null) {
					data['Others'] = mttr.Others;
					totalSum += mttr.Others; count++;
				}
			})  			
				const average = count > 0 ? parseFloat((totalSum / count).toFixed(2)) : 0;  
				let seriesData = Object.keys(data).map((team: string) => ({
				name: team,
				value: data[team],
				percentage: ((data[team] / totalSum) * 100).toFixed(2),
				formattedName: `${((data[team] / totalSum) * 100).toFixed(2)}%`
				}));				

				setDoughnutMTTRTotal(formatNumberByUnits(average || 0));
				setDoughnutSeriesData(seriesData);
				setMTTRLoading(false);			
			    setLoading(false);
		}).catch(error => { 
			console.error(error);
		})
		
        fetchServiceRequestSummary('incidents', selectedDate, checkedList, formattedDate).then(res => {
			SetIncidentsData(res?.data?.result);
			const data: any = {'Legacy EDW': null, 'Azure': null, 'Databricks': null, 'Presto': null, 'Others': null};
            let totalSum = 0;
			res?.data?.result.forEach((incident: any) => {
				totalSum = 0;
				if (incident.EDW != 0) {
					data['Legacy EDW'] = incident.EDW;
					totalSum += incident.EDW;
				}
				if (incident.Azure != 0) {
					data['Azure'] = incident.Azure;
					totalSum += incident.Azure;
				}
				if (incident.Databricks != 0) {
					data['Databricks'] = incident.Databricks;
					totalSum += incident.Databricks;
				}
				if (incident.Presto != 0) {
					data['Presto'] = incident.Presto;
					totalSum += incident.Presto;
				}
				if (incident.Others != 0) {
					data['Others'] = incident.Others;
					totalSum += incident.Others;
				}
			})
                let seriesData = Object.keys(data).map((team: string) => ({
                name: team,
                value: data[team],
                percentage: ((data[team] / totalSum) * 100).toFixed(2),
                formattedName: `${((data[team] / totalSum) * 100).toFixed(2)}%`
                }));				
    
                setDoughnutIncidentTotal(formatNumberByUnits(totalSum));
                setDoughnutIncSeriesData(seriesData);
                setIncidentLoading(false);			
                setLoading(false);
			
		}).catch(error => { 
			console.error(error);
		})

        
            fetchServiceRequestSummary('outage', selectedDate, checkedList, '').then(res => {
                SetOutagesData(res?.data?.result);
                const data: any = {'Legacy EDW': null, 'Azure': null, 'Databricks': null, 'Presto': null, 'Others':null};
                let totalSum = 0;
                res?.data?.result.forEach((request: any) => {
					totalSum = 0;
					if (request.EDW != 0) {
						data['Legacy EDW'] = request.EDW;
						totalSum += request.EDW;
					}
					if (request.Azure != 0) {
						data['Azure'] = request.Azure;
						totalSum += request.Azure;
					}
					if (request.Databricks != 0) {
						data['Databricks'] = request.Databricks;
						totalSum += request.Databricks;
					}
					if (request.Presto != 0) {
						data['Presto'] = request.Presto;
						totalSum += request.Presto;
					}
					if (request.Others != 0) {
						data['Others'] = request.Others;
						totalSum += request.Others;
					}
                });                
                let seriesData = Object.keys(data).map((team: string) => ({
                name: team,
                value: data[team],
                percentage: ((data[team] / totalSum) * 100).toFixed(2),
                formattedName: `${((data[team] / totalSum) * 100).toFixed(2)}%`
                }));				
    
                setDoughnutOutageTotal(formatNumberByUnits(totalSum));
                setDoughnutOutageSeriesData(seriesData);
                setOutageLoading(false);			
                setLoading(false);
			
		}).catch(error => { 
			console.error(error);
		})
	}

    async function incidentsDetailFunc(pageNumber: any, size: any, sortingInfo: any, jobFilterInfo: any, selectedDate : any, type: any, dimension: any) {
	    setLoading(true);
		if (pageSize !== size) {
			pageNumber = 1;
		}
		const sector:any =[];
		const res = await fetchDEIncidentDetails(sector,pageNumber.toString(),size.toString(), selectedDate, type, dimension);
		if (res.success) {
			if(res.data) {
				setIncidentDetails(res.data.data);
				setPaginationData(res.data.page); 
				setPage(pageNumber);
				setPageSize(size);
				setSortingInfo(sortingInfo);
				setLoading(false);
			}
		}
	}

    useEffect(() => {
		localStorage.setItem('deIncidentDetailSelectedDate',filterMonth);
		localStorage.setItem('deMajorIncident','false');
		incidentsDetailFunc(page, pageSize, sortingInfo, parseFilter(filterInfo), filterMonth, chartType, dimension);
		const activeId = document.getElementById('root');
		if (window.location.pathname === '/dataestate/majorIncident') {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
				activeId.classList.add('jobs-list-table');
				activeId.classList.remove('jobs-list');
				activeId.classList.remove('root');
				document.body.classList.add('jobs-exe-datas');
				document.body.classList.remove('jobs-run-table');
				document.body.classList.add('jobs-list-datas');
				document.body.classList.add('datefilter-align-sector');
				document.body.classList.add('sector-label');
			}
		} else {
			if (activeId) {
				document.body.classList.remove('jobs-list-datas');
				activeId.classList.add('root');
				document.body.classList.add('dashboard-panel-dataestate');
				document.body.classList.add('datefilter-align-sector');
			}
		}
        getMajorIncidentsFunc(filterMonth)
		
	}, []);

    async function incidentSearchFunc(searchDetails: any, jobFilterInfo: any, columnKey: any) {
		// const res = await jobSearch(searchDetails, jobFilterInfo, columnKey);
		// if (res.success) {
		// 	setFilterSearchInfo(res.data);
		// }
	}
	const handleDateChange = (selectedDate: string) => {
		incidentsDetailFunc(1, pageSize, sortingInfo, parseFilter(filterInfo),  selectedDate, chartType, dimension);
		getMajorIncidentsFunc(selectedDate);
		setFilterDefaultMonth(selectedDate)
	};
	
	const handleINCReportOnclick = (param:any, title?: string) => {
		if(param && param.name){ 
			if(chartType == title && dimension == param.name) {
				incidentsDetailFunc(page, pageSize, sortingInfo, param.name, filterMonth ,'', '');
				setChartType('');
				setDimension('');
			} else {
				incidentsDetailFunc(page, pageSize, sortingInfo, param.name, filterMonth ,title, param.name);
				setChartType(title != null ? title: '');
				setDimension(param.name)
			}
		}
	}; 
	

    function formatMTTR(totalHours: number): string {
        const { Days, Hours, Minutes } = splitHrsToDays(totalHours);
        return `${Days} Days\n ${Hours} Hrs ${Minutes} Mins`;
    }
    

	return (
		<Box mt="100px">
			<PageHeader title="Major INC Summary" icon={serviceRequestIcon} lastSync={deMetricsLastSyncDate}>
				<DateFilterDropdown
					customMonth={customMonth}
					filterMonth={filterMonth}
					setCustomMonth={setCustomMonth}
					setFilterMonth={setFilterMonth}
					handleChange={handleDateChange}
				/>
			</PageHeader>
			<Grid container spacing={2} p={3}>
				<Grid item xs={12} md={4}>					
                    <DoughnutChartWidget
							title="Major INC Details"
							isLoading={incidentLoading}
							seriesData={doughnutIncSeriesData}
							totalText={`{small|Total}\n{large|${doughnutIncidentTotal}}`}
                            dataLabel = {true}
                            legendLabel = {true}
							handleEvent={handleINCReportOnclick}
						/>
				</Grid>
				<Grid item xs={12} md={4}>                    
                    <DoughnutChartWidget
							title="Outages"
							isLoading={OutageLoading}
							seriesData={doughnutOutageSeriesData}
							totalText={`{small|Total}\n{large|${doughnutOutagTotal}}`}
                            dataLabel = {true}
                            legendLabel = {true}
							handleEvent={handleINCReportOnclick}
						/>
                </Grid>
				<Grid item xs={12} md={4}>                    
                    <DoughnutChartWidget
							title="Mean Time To Resolve (MTTR)"
							isLoading={MTTRLoading}
							seriesData={doughnutSeriesData}
							totalText={`{small|Avg Mean Time}\n{large|${formatMTTR(doughnutMTTRTotal)}}`}
                            dataLabel = {true}
                            legendLabel = {true}
                            hoursFormat={true}
							handleEvent={handleINCReportOnclick}
						/>
                </Grid>
			</Grid>
            <Grid p={2} mt={0.5}>
				{!loading ? (
					<CustomizedDataGrid
                        title={`${chartType != '' && dimension != '' ? `${dimension} ${chartType}` : 'INC Details'}`}
						tableColumns={getColumns()}
						tableData={ incidentDetails && incidentDetails.length !== 0 ? incidentDetails : 0 }
						tableType="incident-details-ver"
						defaultColumnNames={defaultColumnNames}
						onSorting={handleSorting}
						currSorting={sortingInfo}
						filterDataOptions={filterData}
						searchDataValues={searchDetails}
						onFilterCheck={handleFilter}
						currFilter={filterInfo}
						onFilterSearch={handleSearch}
						page={page}
						pageSize={pageSize}
						paginationData={{ totalElements }}
						listLength={ incidentDetails && incidentDetails.length !== 0 ? incidentDetails.length : 0 }
						handlepageSizeChange={handlepageSizeChange}
						handlePageChange={handlePageChange}
						isFetching={loading}
						showExport={false}
						fileName={`Jobs.xlsx`}
						isDateRangeFilter={false}
						isDisabled={true}
					/>
				) : (
					<LoaderComponent />
				)}
			</Grid>
		</Box>
	);
};

export default majorIncident;



