import { useLocation, useNavigate } from 'react-router-dom';

export const useRedirectLogin = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const redirectToLogin = (redirectTo = '/') => {
		if (!['/', '/userLogin', '/me'].includes(location.pathname)) {
			localStorage.setItem('redirectAfterLogin', window.location.href);
		}
		if (process.env.REACT_APP_IS_LOCAL === 'true') {
			redirectTo = '/userLogin';
		}
		if (location.pathname !== '/userLogin') {
			navigate(redirectTo, { replace: true, state: { from: location } });
		}
	};

	const redirectAfterLogin = () => {
		const storedUrl = localStorage.getItem('redirectAfterLogin');
		if (storedUrl && storedUrl !== 'null' && storedUrl !== '') {
			localStorage.removeItem('redirectAfterLogin');
			console.log(`Redirecting to: ${storedUrl}`);
			window.location.replace(storedUrl);
		} else {
			console.log('No redirect URL found, proceeding without redirect');
		}
	};

	return { redirectToLogin, redirectAfterLogin };
};
