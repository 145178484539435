import React from 'react';

interface RagBarProps {
  colors: string[]; // Array of colors
}

const RagBar: React.FC<RagBarProps> = ({ colors }) => {
  // Ensure the input has exactly 30 colors
  // if (colors.length !== 30) {
  //   return <p>Please provide exactly 30 colors.</p>;
  // }

  return (
    <div
      style={{
        border: '0px',
        display: 'flex'
      }}
    >
      {colors.map((color, index) => (
        <div
          key={index}
          style={{
            display: 'flex',
            width: '5px',
            borderRadius: '1px',
            height: '30px',
            margin: '0px 1px',
            background: `${color}`,
          }}
        ></div>
      ))}

      {!colors.length && <>--</>}
    </div>
  );
};

export default RagBar