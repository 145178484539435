import { Button, ButtonGroup, FormControlLabel, Input, MenuItem, Select, Switch, TextareaAutosize, TextField } from "@mui/material"
import { useEffect } from "react"
import { useState } from "react"
import { createKPIConfig, updateKPIConfig } from "service/service"

interface KPIConfig {
    _id: string,
    name: string,
    description: string,
    update_frequency_unit: string,
    update_frequency_value: number,
    isActive: boolean,
    source_config: any,
    target_config: any
}

export const KPIForm = ({onCreateKPI, kpiConfig = {}}: any) => {
    const [kpi, setKpi] = useState<KPIConfig>(kpiConfig)
    const [selectedFile, setSelectedFile] = useState<any>(null)
    
    const parseFile = (file: any) => {
        const reader = new FileReader()
        reader.readAsText(file)
        reader.onload = () => {
            const text = reader.result as string
            if(text){
                const jsonData : any = JSON.parse(text)
                if(jsonData.source_config){
                    setKpi({...kpi, source_config: jsonData.source_config})
                }
                if(jsonData.target_config){
                    setKpi({...kpi, target_config: jsonData.target_config})
                }
            }
        }
    }

    const handleFile = (e: any) => {
        if(e.target?.files?.length){
            setSelectedFile(e.target.files[0])
            parseFile(e.target?.files?.[0])
        }
    }

    const saveKPIConfig = async () => {
        const result = kpi._id ? await updateKPIConfig(kpi) : await createKPIConfig(kpi)
        if(result.success){
            onCreateKPI()
        }
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <div style={{display: 'flex', flexDirection: 'row', gap: 16}}>
                <TextField variant='filled' required label='KPI Name' style={{flex: 8}} type="text" value={kpi.name} onChange={(e) => setKpi({...kpi, name: e.target.value})}/>
                <FormControlLabel style={{flex: 4}}  label='Active' control={<Switch checked={kpi.isActive} onChange={(e) => setKpi({...kpi, isActive: e.target.checked})}/>}/>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', flex: 6, gap: 2}}>
                <TextField variant='filled' required label='Frequency' style={{flex: 4}} type='number' 
                    value={kpi.update_frequency_value} onChange={(e) => setKpi({...kpi, update_frequency_value: parseInt(e.target.value)})}/>
                <Select variant="filled" style={{flex: 8, textAlign: 'left'}} 
                    value={kpi.update_frequency_unit} onChange={(e) => setKpi({...kpi, update_frequency_unit: e.target.value})}>
                    <MenuItem value='days'>Days</MenuItem>
                    <MenuItem value='weeks'>Weeks</MenuItem>
                    <MenuItem value='months'>Months</MenuItem>
                </Select>
            </div>
            <div style={{marginTop: 16, display: 'flex', flexDirection: 'row',}}>
                <TextField style={{flex: 12}} variant='filled' label="Description" value={kpi.description} onChange={(e) => setKpi({...kpi, description: e.target.value})}/>
            </div>
            <div style={{marginTop: 32, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <input type='file' style={{display: 'none'}} id='upload-file' onChange={handleFile}/>
                <label htmlFor="upload-file">
                    <Button variant="contained" component='span'>
                        Upload JSON
                    </Button>
                </label>                
                <ButtonGroup>
                    <Button>Cancel</Button>
                    <Button variant='contained' onClick={saveKPIConfig}>Save</Button>
                </ButtonGroup>
            </div>
            {!!selectedFile && <p style={{textAlign: 'left', marginLeft: 16}}>{selectedFile?.name}</p>}
        </div>
    )
}