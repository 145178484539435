/**
 * Checks if a role or roles match valid roles, with optional negation.
 * @param role - A single role (string) or an array of roles (string[])
 * @param validRoles - An array of valid roles
 * @param negate - Whether to negate the result
 * @returns boolean - True if the role(s) match the validRoles, false otherwise
 */
export const checkRole = (role: string | string[], validRoles: string[], negate = false): boolean => {
    const roleCheck = Array.isArray(role)
        ? role.some((r) => validRoles.includes(r))
        : validRoles.includes(role);

    return negate ? !roleCheck : roleCheck;
};
