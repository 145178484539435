import React, { useEffect, useState, useRef } from 'react';
import {
	Button,
	ButtonGroup,
	Checkbox,
	FormControl,
	FormControlLabel,
	Grid,
	MenuItem,
	Popover,
	Select,
	Stack,
	Typography
} from '@mui/material';
import { powerBICapacityMetricsData } from '../../service/service';
import { powerBICapacityDetailData } from '../../service/service';
import { fetchSectors } from '../../service/service';
import { powerBICapacityStatus } from '../../service/service';
import { powerBICUUtil } from '../../service/service';
import AnalyticsVisualization from '../framework/analyticsVisualization';
import { dashboardUrl } from '../../service/config';
import UnderDevelopment from '../../assets/under-construction.svg';
import { ReactComponent as FilterIcon } from '../../assets/ml_summary_filter.svg';
import '../PowerBIObservability/powerBIObs.scss';
import { CustomizedDataGrid } from '../customized_data_grid';
import { Column } from 'react-data-grid';
import { useSearchParams } from 'react-router-dom';
import { powerBICapacitySearch } from '../../service/service';
import ReactECharts from 'echarts-for-react';
import Loader from 'components/Loader';
import NumberWidget from 'components/Widgets/NumberWidget';
import LoaderComponent from 'components/Loader';
import RowTypography from 'components/Table/RowTypography';
import StatusChip from 'components/Table/StatusChip';
import BarChartWidget from 'components/Widgets/BarChartWidget';
import SectorCheckFilter from 'components/Dropdown/SectorCheckFilter';
import PageHeader from 'components/Header/Page';
import CapacityObservabilityLogo from 'assets/powerbi_capacity_obs.svg';
import CustomDropdown from 'components/Dropdown';
import DateFilterDropdown from 'components/Dropdown/DateFilter';

const CapacityObservability = () => {
	interface Row {
		capacityName: string;
		capacityOwner: string;
		capacityStatus: string;
		workspaceName: string;
		sector: string;
		workspaceOwner: string;
		itemKind: string;
		itemName: string;
		itemStatus: string;
		itemOwner: string;
		operation: string;
		CU_Percentage: string;
		duration: string;
		throttled: string;
		frozen: string;
	}

	function getColumns(): Array<Column<Row>> {
		return [
			{
				key: 'capacityName',
				name: 'Capacity',
				formatter(props) {
					return <RowTypography>{props.row.capacityName ? props.row.capacityName : '--'}</RowTypography>;
				},
				width: 230,
				minWidth: 230
			},
			{
				key: 'capacityOwner',
				name: 'Capacity Owner',
				width: 190,
				minWidth: 190
			},
			{
				key: 'capacityStatus',
				name: 'Capacity Status',
				width: 150,
				minWidth: 150,
				cellClass: 'center-text',
				formatter: ({ row }) => <StatusChip status={row.capacityStatus} />
			},
			{
				key: 'workspaceName',
				name: 'Workspace',
				formatter(props) {
					return <RowTypography>{props.row.workspaceName ? props.row.workspaceName : '--'}</RowTypography>;
				},
				width: 230,
				minWidth: 230
			},
			{
				key: 'sector',
				name: 'Sector',
				minWidth: 230
			},
			{
				key: 'workspaceOwner',
				name: 'Workspace Owner',
				width: 200,
				minWidth: 200
			},
			{
				key: 'itemKind',
				name: 'Item Kind',
				formatter(props) {
					return <RowTypography>{props.row.itemKind ? props.row.itemKind : '--'}</RowTypography>;
				},
				minWidth: 230
			},
			{
				key: 'itemName',
				name: 'Item Name',
				formatter(props) {
					return <RowTypography>{props.row.itemName ? props.row.itemName : '--'}</RowTypography>;
				},
				minWidth: 230
			},
			{
				key: 'itemStatus',
				name: 'Item Status',
				minWidth: 130,
				width: 130
			},
			{
				key: 'itemOwner',
				name: 'Item Owner',
				width: 190,
				minWidth: 190
			},
			{
				key: 'operation',
				name: 'Operation',
				width: 180,
				minWidth: 180
			},
			{
				key: 'CU_Percentage',
				name: 'CU %',
				minWidth: 125,
				width: 125
			},
			{
				key: 'duration',
				name: 'Duration',
				minWidth: 130,
				width: 130
			},
			{
				key: 'throttled',
				name: 'Throttled',
				minWidth: 100,
				width: 100
			},
			{
				key: 'frozen',
				name: 'Frozen',
				minWidth: 100,
				width: 100
			}
		];
	}

	const [incidentDetails, setIncidentDetails] = useState<Row[]>([]);
	const [loading, setLoading] = useState(false);
	const [tableLoading, setTableLoading] = useState(false);
	const [chartLoading, setChartLoading] = useState(false);
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	const [drillDownStatusfilterInfo, setDrilldownStatusFilterInfo] = useState([]);
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [filterData, setFilterData] = useState<Row[]>([]);
	const [filterInfo, setFilterInfo] = useState<{ [key: string]: any }>({});
	const [searchDetails, setFilterSearchInfo] = useState([]);
	const [searchParams, setSearchParams] = useSearchParams();
	const [currSectorFilter, setCurrSectorFilter] = useState<any[]>([]);
	const dataFetchedRef = useRef(false);
	const hasDrillDown = searchParams.has('isDrillDowned');
	const [metricsData, setMetricsData] = useState<any>({
		healthyCount: 0,
		throttledCount: 0,
		frozenCount: 0,
		riskCount: 0,
		frozenRecoveredCount: 0,
		totalCount: 0
	});
	const [chartOption, setChartOption] = useState<any>();
	const [capacityStatusFilter, setCapacityStatusFilter] = useState<string | null>(null);
	const checkedListRef = useRef<any>([]);
	const [timePeriod, setTimePeriod] = useState('Weekly');
	const [customMonth, setCustomMonth] = useState('');
	const [filterMonth, setFilterMonth] = useState('0M');
	const [selectedMonth, setselectedMonth] = useState('This Month');

	const defaultColumnNames = [
		'Capacity',
		'Capacity Owner',
		'Capacity Status',
		'Workspace',
		'Sector',
		'Workspace Owner',
		'Item Kind',
		'Item Name',
		'Item Status',
		'Item Owner',
		'Operation',
		'CU %',
		'Duration',
		'Throttled',
		'Frozen'
	];

	const handleOkClick = () => {
		const updatedCheckedList = [...checkedListRef.current]; // Just copying the checkedList

		if (updatedCheckedList.length > 0) {
			setCurrSectorFilter(updatedCheckedList);
			filterInfo['sector']=updatedCheckedList;
		} else {
			setCurrSectorFilter([]);
			filterInfo['sector']='';
		}
		Object.keys(filterInfo).forEach((key: any) => {
			if(key !== 'sector'){
				delete filterInfo[key];
			}
		});
		setFilterInfo(filterInfo);
		// updateSharedValue({ sectorFilter: updatedCheckedList });
		fetchData(selectedMonth);
		powerBICUUtil(updatedCheckedList,'Monthly',selectedMonth);
		sortingInfo.sortBy = '';
		sortingInfo.sortDir = '';
		capacityDetailFunc(1, pageSize, sortingInfo, null,selectedMonth);
		//fetchCapacityData();
		fetchChartData(timePeriod,selectedMonth);
	};

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		capacityDetailFunc(value, pageSize, sortingInfo, parseFilter(filterInfo),selectedMonth);
	};

	const handlepageSizeChange = (event: any) => {
		capacityDetailFunc('1', event.target.value, sortingInfo, parseFilter(filterInfo),selectedMonth);
	};
	const handleSorting = (sortingInfo: any) => {
		capacityDetailFunc(page, pageSize, sortingInfo, parseFilter(filterInfo),selectedMonth);
	};

	const handleFilter = (filterInfo: any) => {
		setFilterInfo(filterInfo);
		console.log(filterInfo, 'filterinfo');
		capacityDetailFunc(1, pageSize, sortingInfo, parseFilter(filterInfo),selectedMonth);
	};
	// const handleSearch = (searchDetails: any, columnKey: any) => {
	// 	// incidentSearchFunc(searchDetails, parseFilter(filterInfo, columnKey), columnKey);
	// };

	const parseFilter = (filterInfo: any, name?: string) => {
		// Call only filterable columns and reset the filtering in case of wrong/empty column
		const parsedFilter: any = {};
		console.log(filterInfo,name,"parse")
		Object.keys(filterInfo).forEach((key: any) => {
			if (key !== name && filterInfo[key] && filterInfo[key].length) {
				parsedFilter[key] = filterInfo[key];
			}
		});
		console.log(parsedFilter,"parsefilter")
		return parsedFilter;
	};

async function capacityDetailFunc(pageNumber: any, size: any, sortingInfo: any, jobFilterInfo: any, dateLabel: string) {
	// Reset page number if page size has changed
    if (pageSize !== size) {
        pageNumber = 1;
    }

		try {
			// Retrieve stored sector filter from localStorage
			let storedSectorFilter = localStorage.getItem('ml-summary-sector-filter')
				? JSON.parse(localStorage.getItem('ml-summary-sector-filter') ?? '')
				: null;

			setTableLoading(true);

			let response;
            let selectedDate = dateLabel ? dateLabel : null
			// Check if a status is selected in jobFilterInfo
			if (jobFilterInfo?.capacityStatus) {
				// Call the powerBICapacityStatus API to get filtered data by status
				response = await powerBICapacityDetailData(
					pageNumber.toString(),
					size.toString(),
					storedSectorFilter,
					sortingInfo,
					jobFilterInfo ? jobFilterInfo : null,
					selectedDate
				);
			} else {
				// Otherwise, call the general capacity detail API
				response = await powerBICapacityDetailData(
					pageNumber.toString(),
					size.toString(),
					storedSectorFilter,
					sortingInfo,
					jobFilterInfo ? jobFilterInfo : null,
					selectedDate
				);
			}

			// Check if response contains the necessary data
			const capacityData = response?.data?.result || []; // Assuming 'result' contains the relevant data

			// Transform the fetched data into the desired structure
			const incidentData = capacityData.map((item: any) => ({
				incidentId: item.CapacityId, // Using CapacityId as incidentId for uniqueness
				capacityName: item.capacityName,
				capacityOwner: item.capacityOwner, // Update as needed
				capacityStatus: item.CapacityStatus,
				workspaceName: item.workspaceName,
				sector: item.sector,
				workspaceOwner: item.capacityOwner, // Update as needed
				itemKind: item.itemKind,
				itemName: item.itemName,
				itemStatus: item.ItemStatus,
				itemOwner: item.capacityOwner, // Update as needed
				operation: item.Operation,
				CU_Percentage: item.CU_Percentage,
				duration: item.duration, // Update as needed
				throttled: item.Throttled,
				frozen: item.Frozen
			}));

			setIncidentDetails(incidentData);

			// Update pagination data based on the response
			const paginationData = response.data.page;
			setPaginationData(paginationData); // Set pagination data directly from response

			// Update state for current page and size
			if (pageNumber > response.data.page.totalPages) {
				setPage(response.data.page.currentPage);
			} else {
				setPage(pageNumber);
			}
			setPageSize(size);
			setSortingInfo({ sortBy: sortingInfo.sortBy, sortDir: sortingInfo.sortDir });
			setFilterData(response?.data?.filterOptions);
			console.log(filterData)
		} catch (error) {
			console.error('Error fetching capacity data:', error);
		} finally {
			setTableLoading(false);
		}
	}

	async function powerBICapacitySearchFunc(searchDetails: any, columnKey: any) {
		const res = await powerBICapacitySearch(
			searchDetails,
			columnKey,
			parseFilter(filterInfo),
			checkedListRef.current,
			drillDownStatusfilterInfo
		);
		if (res.success) {
			setFilterSearchInfo(res.data);
		}
	}
	const handleSearch = (searchDetails: any, columnKey: any) => {
		console.log(searchDetails, columnKey);
		if (searchDetails?.length >= 3) powerBICapacitySearchFunc(searchDetails, columnKey);
	};
	const handleCapacityClick = (status: string) => {
		let storedSectorFilter = localStorage.getItem('ml-summary-sector-filter')
				? JSON.parse(localStorage.getItem('ml-summary-sector-filter') ?? '')
				: null;
		setCapacityStatusFilter(status); 
		const statusArray:any =[];
		statusArray.push(status)
		if(filterInfo.sector)
		// setFilterInfo([]);
		setFilterInfo(prevFilterInfo => {
			return Object.keys(prevFilterInfo).reduce((newFilterInfo, key) => {
				newFilterInfo[key] = (key === 'sector' && storedSectorFilter) ? prevFilterInfo[key] : [];
				return newFilterInfo;
			}, {} as { [key: string]: any });
		});
		sortingInfo.sortBy = '';
		sortingInfo.sortDir = '';

        // Update the state with the new JSON array
		updateFilterInfo('capacityStatus', statusArray);
		// Fetch data and filter by status
		capacityDetailFunc('1', pageSize, sortingInfo, { capacityStatus: statusArray },selectedMonth);
	};
	const updateFilterInfo = (key: string, value: any) => {
		setFilterInfo(prev => ({ ...prev, [key]: value }));
	};
	
	async function fetchData(dateLabel: string) {
		try {
			let storedSectorFilter = localStorage.getItem('ml-summary-sector-filter')
				? JSON.parse(localStorage.getItem('ml-summary-sector-filter') ?? '')
				: null;
			setLoading(true);
		    let selectedDate = dateLabel ? dateLabel : null
			const response = await powerBICapacityMetricsData(storedSectorFilter,selectedDate);

			// Transform the data to match the expected structure
			if (response && response.data) {
				const transformedData = {
					healthyCount: response.data.data.CapacitiesHealthy || 0,
					throttledCount: response.data.data.CapacitiesThrottled || 0,
					frozenCount: response.data.data.CapacitiesFrozen || 0,
					riskCount: response.data.data.CapacitiesAtRiskOfGettingThrottled || 0,
					frozenRecoveredCount: response.data.data.CapacitiesOutOfFrozen || 0,
					totalCount: response.data.data.latestRecords.length || 0
				};
				setMetricsData(transformedData);
			} else {
				console.error('No data returned from API');
			}
		} catch (error) {
			console.error('Error fetching data:', error);
		} finally {
			setLoading(false);
		}
	}

	const fetchChartData = async (timePeriod: string, dateLabel: string) => {
		try {
			let storedSectorFilter = localStorage.getItem('ml-summary-sector-filter')
			? JSON.parse(localStorage.getItem('ml-summary-sector-filter') ?? '') : null;
			setChartLoading(true);	
			
			// Simulate fetching chart data
			// const response = await powerBICUUtil(storedSectorFilter);
			let selectedDate = dateLabel ? dateLabel : null
			const response = await powerBICUUtil(storedSectorFilter,timePeriod,selectedDate);
	
			if (response && response.data && response.data.monthlyCU && timePeriod == 'Monthly') {
				const fetchedData = response.data.monthlyCU;
				// Convert the data into a format suitable for the chart
				const reportData = fetchedData.reduce((acc: { [key: string]: { [key: string]: number; }; }, item: { Year: any; Month: number; Month_CU_Percentage: any; }) => {
					const month = `${item.Year}-${item.Month.toString().padStart(2, '0')}`;
					const percentage = item.Month_CU_Percentage;
					let level = categorizeCULevel(percentage); // Categorize CU% level
					// Initialize the month object if it doesn't exist
					if (!acc[month]) {
						acc[month] = { '00-35%': 0, '36-60%': 0, '61-80%': 0, '81-90%': 0, '91%+': 0, 'Unknown': 0 };
					}
					acc[month][level] += 1; // Increment the count for the level
					return acc;
				}, {});
				const formattedData = Object.keys(reportData).map(month => {
					const [year, monthNum] = month.split('-');
					// Format as "Jan 24"
			        const date = new Date(parseInt(year), parseInt(monthNum) - 1);
			        const formattedMonth = date.toLocaleString('en-US', { month: 'short' });
			        const formattedYear = year.slice(-2);
			        const newKey = `${formattedMonth} ${formattedYear}`;
					return { [newKey]: reportData[month] };
				});
				setChartOption(formattedData);
			}
			else if (response && response.data && response.data.cu15Min && timePeriod === '15 Minutes') {
				const fetched15MinData = response.data.cu15Min;
				// Convert the data into a format suitable for the chart
				const formattedData = fetched15MinData.reduce(
					(acc: Record<string, { '00-35%': number; '36-60%': number; '61-80%': number; '81-90%': number; '91%+': number; Unknown: number }>, item: { TimePoint: string; CU_Percentage: number }) => {
						const time = new Date(item.TimePoint);
						const hour = time.getUTCHours().toString().padStart(2, '0');
						let minutes = time.getUTCMinutes(); minutes = minutes >= 15 ? 15 : 0;
						const timeKey = `${hour}:${minutes.toString().padStart(2, '0')}`;
						if (!acc[timeKey]) {
							acc[timeKey] = {
								'00-35%': 0,'36-60%': 0,'61-80%': 0,'81-90%': 0,'91%+': 0,Unknown: 0,
							};
						}
						const category = categorizeCULevel(item.CU_Percentage);
						if (category) {
							acc[timeKey][category]++;
						}
						return acc;
					},{}
				);
				// Convert to the desired array format
				const formattedArray = Object.entries(formattedData).map(([time, data]) => ({
				  [time]: data,
				})).sort((a, b) => {
					const timeA = Object.keys(a)[0];const timeB = Object.keys(b)[0];
					const [hourA, minuteA] = timeA.split(':').map(Number);
					const [hourB, minuteB] = timeB.split(':').map(Number);
					return hourA === hourB ? minuteA - minuteB : hourA - hourB;
				  });
				  setChartOption(formattedArray);
			}
			else if (response && response.data && response.data.cu30Min && timePeriod === '30 Minutes') {
				const fetched30MinData = response.data.cu30Min;
				// Convert the data into a format suitable for the chart
				const formattedData = fetched30MinData.reduce(
				  (acc: Record<string, { '00-35%': number; '36-60%': number; '61-80%': number; '81-90%': number; '91%+': number; Unknown: number }>, item: { TimePoint: string; CU_Percentage: number }) => {
					const time = new Date(item.TimePoint);
					const hour = time.getUTCHours().toString().padStart(2, '0');
					let minutes = time.getUTCMinutes();minutes = minutes >= 30 ? 30 : 0;
					const timeKey = `${hour}:${minutes.toString().padStart(2, '0')}`;
					if (!acc[timeKey]) {
					  acc[timeKey] = {
						'00-35%': 0,'36-60%': 0,'61-80%': 0,'81-90%': 0,'91%+': 0,Unknown: 0,
					  };
					}
					const category = categorizeCULevel(item.CU_Percentage);
					if (category) {
					  acc[timeKey][category]++;
					}
					return acc;
				  },{}
				);
				// Convert to the desired array format
				const formattedArray = Object.entries(formattedData).map(([time, data]) => ({
				  [time]: data,
				})).sort((a, b) => {
					const timeA = Object.keys(a)[0];const timeB = Object.keys(b)[0];
					const [hourA, minuteA] = timeA.split(':').map(Number);
					const [hourB, minuteB] = timeB.split(':').map(Number);
					return hourA === hourB ? minuteA - minuteB : hourA - hourB;
				  });
				  setChartOption(formattedArray);
			}
			else if (response && response.data && response.data.hourlyCU && timePeriod === 'Hourly') {
				const fetchedHourlyData = response.data.hourlyCU;
				// Convert the data into a format suitable for the chart
				const hourlyReportData = fetchedHourlyData.reduce((
					acc: { [hour: string]: { [x: string]: number } }, item: { Hour: number, Hour_CU_Percentage: number | null }) => {
					const hourKey = `${item.Hour}:00`;
					const level = item.Hour_CU_Percentage !== null ? categorizeCULevel(item.Hour_CU_Percentage) : 'Unknown';
					if (!acc[hourKey]) { 
						acc[hourKey] = { '00-35%': 0, '36-60%': 0, '61-80%': 0, '81-90%': 0, '91%+': 0, 'Unknown': 0 }; 
					}
					acc[hourKey][level] += 1;
					return acc;
				}, {});
				const formattedHourlyData = Object.entries(hourlyReportData).map(([hour, data]) => ({
					[hour]: data
				}));
				setChartOption(formattedHourlyData);
			}
			else if (response && response.data && response.data.dailyCU && timePeriod === 'Daily') {
				const fetchedData = response.data.dailyCU;
				// Convert the data into a format suitable for the chart
				const reportData = fetchedData.reduce((
					acc: { [x: string]: { [x: string]: number } }, item: { Date: string, Day_CU_Percentage: number }) => {
						const { Date:dateString, Day_CU_Percentage } = item;
						const itemDate =  new Date(dateString);
						const formattedDate = itemDate.toLocaleString('en-US', { day: 'numeric', month: 'short' }); 
						const level = categorizeCULevel(Day_CU_Percentage);
						if (!acc[formattedDate]) {
							acc[formattedDate] = { '00-35%': 0, '36-60%': 0, '61-80%': 0, '81-90%': 0, '91%+': 0, 'Unknown': 0 };
						}
						acc[formattedDate][level] += 1;
						return acc;
					}, {});
					const formattedData = Object.entries(reportData).map(([date, data]) => ({
					[date]: data,
					}))
					const sortedData = formattedData.sort((a, b) => {
						const dateA = Object.keys(a)[0];  // Get the date string from the first key
						const dateB = Object.keys(b)[0];  // Get the date string from the second key
						const [monthA, dayA] = dateA.split(' ');  // Split the date into month and day
						const [monthB, dayB] = dateB.split(' ');
						// Convert month names to their respective numeric values
						const monthMap: { [key: string]: number } = {
							Jan: 1, Feb: 2, Mar: 3, Apr: 4, May: 5, Jun: 6,
							Jul: 7, Aug: 8, Sep: 9, Oct: 10, Nov: 11, Dec: 12
						};
						const monthComparison = monthMap[monthA] - monthMap[monthB];
						if (monthComparison !== 0) return monthComparison;  // If months are different, sort by month
						return parseInt(dayA) - parseInt(dayB);  // If months are the same, sort by day
					});
					setChartOption(formattedData);
			}
			else if (response && response.data && response.data.weeklyCU && timePeriod == 'Weekly') {
				const fetchedData = response.data.weeklyCU;
				// Convert the data into a format suitable for the chart
				const reportData = fetchedData.reduce((
					acc: { [x: string]: { [x: string]: number; }; }, item: { Year: any; Week: any; Week_CU_Percentage: any; }) => {
						const { Year, Week, Week_CU_Percentage } = item;
						// Calculate the start of the week
						const firstDayOfYear = new Date(Year, 0, 1);
		                const daysOffset = (Week - 1) * 7;
		                let startDate = new Date(firstDayOfYear.getTime() + daysOffset * 24 * 60 * 60 * 1000);
						// Adjust to the nearest Monday
		                const dayOfWeek = startDate.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
		                const daysToMonday = (dayOfWeek === 0 ? -6 : 1) - dayOfWeek; // Calculate difference to Monday
		                startDate.setDate(startDate.getDate() + daysToMonday);
						const formattedStartDate = startDate.toLocaleString('en-GB', { day: 'numeric', month: 'short' });
						const level = categorizeCULevel(Week_CU_Percentage); 
						if (!acc[formattedStartDate]) {
							acc[formattedStartDate] = { '00-35%': 0, '36-60%': 0, '61-80%': 0, '81-90%': 0, '91%+': 0, 'Unknown': 0 };
						}
						acc[formattedStartDate][level] += 1;
						return acc;
						}, {});
						const formattedData = Object.entries(reportData).map(([date, data]) => ({
							[date]: data
						}));
					    setChartOption(formattedData);
			}
			else{
				console.error('No data returned from API');
			}
		} catch (error) {
			console.error('Error fetching chart data:', error);
		}finally {
			setChartLoading(false);
		}
	};

	function categorizeCULevel(percentage: number | null) {
		if (percentage === null) return 'Unknown';
		if (percentage <= 35) return '00-35%';
		if (percentage <= 60) return '36-60%';
		if (percentage <= 80) return '61-80%';
		if (percentage <= 90) return '81-90%';
		return '91%+';
	}

		const handleTimePeriodChange = (event: { target: { value: string } }) => {
			const newTimePeriod: string = event.target.value;
			setTimePeriod(newTimePeriod);
			// Update chart data based on time period
			fetchChartData(newTimePeriod,selectedMonth);
		};

	async function incidentSearchFunc(searchDetails: any, jobFilterInfo: any, columnKey: any) {
	}

	const handleDateChange = (selectedDate: string) => {
		let dateLabel = '';
		switch(selectedDate){
			case '0M': dateLabel = 'This Month';
				break;
			case '1M': dateLabel = 'Last Month';
				break;
			case '3M': dateLabel = 'Last 3 Months';
				break;
			case '6M': dateLabel = 'Last 6 Months';
				break;	
			case '9M': dateLabel = 'Last 9 Months';
				break;
			case '0Y': dateLabel = 'This Year';
				break;
			case '1Y': dateLabel = 'Last Year';
				break;
			case '2Y': dateLabel = 'Last 2 Years';
				break;	
			default: dateLabel = selectedDate;
				break;	
		}
		setselectedMonth(dateLabel);
		fetchData(dateLabel);
		capacityDetailFunc(page, pageSize, sortingInfo, parseFilter(filterInfo),dateLabel);
		fetchChartData(timePeriod,dateLabel);
	};

	useEffect(() => {
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		localStorage.removeItem('ml-summary-sector-filter');
		// let storedSectorFilter = localStorage.getItem('ml-summary-sector-filter')
		// 	? JSON.parse(localStorage.getItem('ml-summary-sector-filter') ?? '')
		// 	: null;
		// 	console.log("Fetched Data1:", storedSectorFilter );
		// if (storedSectorFilter) {
		// 	setCheckedList(storedSectorFilter);
		// 	setCurrSectorFilter(storedSectorFilter);
		// }
		capacityDetailFunc(page, pageSize, sortingInfo, parseFilter(filterInfo),selectedMonth);
		const activeId = document.getElementById('root');
		if (window.location.pathname === '/powerBI/capacityObservability/dashboard') {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
				document.body.classList.add('filter-bg');
				activeId.classList.remove('root');
			}
		}
		fetchData(selectedMonth);
		fetchChartData(timePeriod,selectedMonth);
	}, [timePeriod]);

	const infoCardData = [
		{
			title: 'Capacities Healthy',
			handleEvent: () => handleCapacityClick('HEALTHY'),
			number: metricsData.healthyCount,
			totalNumber: metricsData.totalCount
		},
		{
			title: 'Capacities Throttled',
			handleEvent: () => handleCapacityClick('THROTTLED'),
			number: metricsData.throttledCount,
			totalNumber: metricsData.totalCount
		},
		{
			title: 'Capacities Frozen',
			handleEvent: () => handleCapacityClick('FROZEN'),
			number: metricsData.frozenCount,
			totalNumber: metricsData.totalCount
		},
		{
			title: 'Capacities at risk of Getting Throttled',
			handleEvent: () => handleCapacityClick('RISK'),
			number: metricsData.riskCount,
			totalNumber: metricsData.totalCount
		},
		{
			title: 'Capacities out of Frozen (In Last 24 hours)',
			handleEvent: () => handleCapacityClick('FROZEN 24'),
			number: metricsData.frozenRecoveredCount,
			totalNumber: metricsData.totalCount
		}
	];

	const seriesData = [
		{ name: '00-35%', dataKey: '00-35%', color: '#3498db' },
		{ name: '36-60%', dataKey: '36-60%', color: '#1abc9c' },
		{ name: '61-80%', dataKey: '61-80%', color: '#f1c40f' },
		{ name: '81-90%', dataKey: '81-90%', color: '#e67e22' },
		{ name: '91%+', dataKey: '91%+', color: '#e74c3c' }
	];

	return (
		<Stack mt="80px" width="100%" className="sector-filter-drop">
			<PageHeader title="Capacity Observability" icon={CapacityObservabilityLogo} lastSync={''} iconSize={30}>
			<DateFilterDropdown
					customMonth={customMonth}
					filterMonth={filterMonth}
					setCustomMonth={setCustomMonth}
					setFilterMonth={setFilterMonth}
					handleChange={handleDateChange}
					boxProps={{ sx: { backgroundColor: 'primary.main',
						minHeight: '50px', 
					 } }}
				/>
				<SectorCheckFilter
					sectorStorageName="ml-summary-sector-filter"
					handleOkClick={handleOkClick}
					checkedListRef={checkedListRef}
				/>
			</PageHeader>
			<Stack p={2} spacing={2} className='capacity-metrics'>
				<Grid container spacing={1}>
					<Grid item container xs={12} md={6} spacing={1}>
						{infoCardData.map((info, idx) => (
							<Grid key={info.title} item xs={12} md={idx > 2 ? 6 : 4}>
								<NumberWidget
									isLoading={loading}
									title={info.title}
									tooltipLabel=""
									handleEvent={info.handleEvent}
									ratioNumber={{
										number: info.number,
										totalNumber: info.totalNumber
									}}
									containerProps={{
										minHeight: '50px'
									}}
								/>
							</Grid>
						))}
					</Grid>
					<Grid item container xs={12} md={6}>
						<BarChartWidget
							title="Capacities CU% Utilization Over Time"
							isLoading={chartLoading}
							seriesData={seriesData}
							data={chartOption}
							yAxisOptions={{ name: 'Capacities', nameLocation: 'middle', nameGap: 40, splitLine: { show: false } }}
							heightDifferencePercent={-40}
							containerProps={{
								pt: 0.5
							}}
						>
							<CustomDropdown
								handleChange={handleTimePeriodChange}
								value={timePeriod}
								options={[
									{label: 'Monthly',value: 'Monthly'},
									{label: '15 Minutes',value: '15 Minutes'},
									{label: '30 Minutes',value: '30 Minutes'},
									{label: 'Hourly',value: 'Hourly'},
									{label: 'Daily',value: 'Daily'},
									{label: 'Weekly',value: 'Weekly'},
								]}
							/>
						</BarChartWidget>
					</Grid>
				</Grid>
				{!tableLoading ? (
					<CustomizedDataGrid
						title="Details"
						tableColumns={getColumns()}
						tableData={incidentDetails}
						tableType="incident-details-ver"
						defaultColumnNames={defaultColumnNames}
						onSorting={handleSorting}
						currSorting={sortingInfo}
						filterDataOptions={filterData}
						onFilterCheck={handleFilter}
						currFilter={filterInfo}
						searchDataValues={searchDetails}
						onFilterSearch={handleSearch}
						page={page}
						pageSize={pageSize}
						paginationData={paginationData}
						listLength={incidentDetails.length}
						handlepageSizeChange={handlepageSizeChange}
						handlePageChange={handlePageChange}
						isDateRangeFilter={false}
						isDisabled={false}
						isFetching={tableLoading}
						sectorsList={checkedListRef.current}
					/>
				) : (
					<Stack justifyContent="center" alignItems="center" minHeight="100px">
						<LoaderComponent />
					</Stack>
				)}
			</Stack>
		</Stack>
	);
};

export default CapacityObservability;
