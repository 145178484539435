//tablesection

import { useEffect, useRef, useState } from 'react';
import { CustomizedDataGrid } from 'components/customized_data_grid';
import { getFinopsResourceMappingList } from 'service/service';
import { CellExpanderFormatter } from 'components/cell_expander_formatter';
import RowTypography from 'components/Table/RowTypography';
import LoaderComponent from 'components/Loader';
import { Box, Button, Drawer, Stack } from '@mui/material';
import SearchFilterDropdown from 'components/Inputs/ResourceMapping/SearchFilterDropdown';
import DialogueModal from 'components/Modal/DialogModal';
import BulkEditModalConfirm from './BulkEditModalConfirm';
import ResourceAllocationModal from './ResourceAllocationModal';
import HistoryIcon from '@mui/icons-material/History';
import UpdateHistory from './UpdateHistory';
import ResolveAllocationConflictsModal from './ResolveAllocationConflictsModal';
import AllocationConflictModal from './AllocationConflictModal';
import { ReactComponent as AllocationIcon } from '../../../assets/allocation_big_button.svg';
import { useSearchParams } from 'react-router-dom';

interface Row {
	id: string;
	[key: string]: any;
	Resource: string;
	ResourceGroup: string;
	DFProjectcode: string;
	DFSectorname: string;
	pepwiseEnvironment: string;
	ProjectName: string;
	BillingInformation: string;
	Subscription: string;
	MeterCategory: string;
	MeterName: string;
	selected: boolean;
	DAI: String;
	ClusterName: string;
	SqlEndpointId: string;
	ConsumedService: string;
	BusinessSector: string;
	OrgGrpCodeProjectCode: string;
	projectno: string;
	ClusterJobSource: string;
	DatabricksInstancePoolId: string;
	DFDataassetname: string;
	kubernetespvcnamespace: string;
	DNAI_Value: string;
	allocationId: string;
	AllocationCount: string;
	Allocpercent: string;
	pepwisesector: string;
	pepwiseproject: string;
	pepwiseprogram: string;
	actions: string;
}

interface Option {
	value: string;
	label: string;
}

type ModalWidth = 'sm' | 'md' | 'lg' | 'xl';

const defaultColumnNames = [
	'HeaderCheckbox',
	'Resource',
	'Resource Group',
	'Sector',
	'Environment',
	'Project Name',
	'Project Number',
	'Billing Information',
	'Subscription',
	'Meter Category',
	'Meter Name',
	'D & AI',
	'Actions'
];

const columnNames = [
	{ name: 'Resource', label: 'Resource', isEditable: false },
	{ name: 'ResourceGroup', label: 'Resource Group', isEditable: false },
	{ name: 'AllocPercent', label: 'Allocation %', isEditable: false },
	{ name: 'PepwiseSector', label: 'Pepwise Sector', isEditable: true },
	{ name: 'PepwiseEnvironment', label: 'Pepwise Environment', isEditable: true },
	{ name: 'PepwiseProject', label: 'Pepwise Project', isEditable: true },
	{ name: 'PepwiseProgram', label: 'Pepwise Program', isEditable: true },
	{ name: 'BusinessSector', label: 'Business Sector', isEditable: false },
	{ name: 'ProjectName', label: 'Project Name', isEditable: true },
	{ name: 'Program', label: 'Program', isEditable: true },
	{ name: 'Environment', label: 'Environment', isEditable: true },
	{ name: 'Subscription', label: 'Subscription', isEditable: false },
	{ name: 'ConsumedService', label: 'Consumed Service', isEditable: false },
	{ name: 'MeterCategory', label: 'Meter Category', isEditable: false },
	{ name: 'location', label: 'Location', isEditable: false },
	{ name: 'OrgGrpCodeProjectCode', label: 'Org Grp Code Project Code', isEditable: false },
	{ name: 'projectno', label: 'Project No', isEditable: false },
	{ name: 'BillingInformation', label: 'Billing Information', isEditable: false },
	{ name: 'ClusterName', label: 'Cluster Name', isEditable: false },
	{ name: 'ClusterJobSource', label: 'Cluster Job Source', isEditable: false },
	{ name: 'DatabricksInstancePoolId', label: 'Databricks Instance Pool Id', isEditable: false },
	{ name: 'DFSectorname', label: 'DF Sectorname', isEditable: false },
	{ name: 'DFProjectcode', label: 'DF Projectcode', isEditable: false },
	{ name: 'DFDataassetname', label: 'DF Data Assetname', isEditable: false },
	{ name: 'SqlEndpointId', label: 'Sql Endpoint Id', isEditable: false },
	{ name: 'kubernetespvcnamespace', label: 'Kubernetes pvc Namespace', isEditable: false },
	// { name: 'DNAI_Value', label: 'DNAI Value', isEditable: true },
	// { name: 'allocationId', label: 'Allocation Id', isEditable: false },
	// { name: 'AllocationCount', label: 'Allocation Count', isEditable: false },
	// { name: 'actions', label: 'Actions', isEditable: false },
];

const parseFilter = (filterInfo: any, name?: string) => {
	// Call only filterable columns and reset the filtering in case of wrong/empty column
	const parsedFilter: any = {};
	Object.keys(filterInfo).forEach((key: any) => {
		if (key !== name && filterInfo[key] && filterInfo[key].length) {
			parsedFilter[key] = filterInfo[key];
		}
	});
	return parsedFilter;
};

const getInputOptions = async (input: string, field: string) => {
	const fieldsRes = await getFinopsResourceMappingList({ searchField: field, searchText: input });
	const options: Option[] = fieldsRes.data.result.filter(Boolean).map((item: string) => ({ value: item, label: item }));
	return options;
};

const initialEditRowState = {
	rowsToUpdate: {}
};

const tableTitles: Record<string, string> = {
	ResourceGroup_tree: 'Resource Groups',
	SqlEndpointId_tree: 'DBSQL Clusters',
	ClusterName_inttree: 'Interactive Clusters',
	ClusterJobSource_jobtree: 'Job Clusters',
};

const TableSection = ({ queryPreference, queryProps }: any) => {
	const [searchParams] = useSearchParams();
	  // Extract query parameters
	//   const programId = searchParams.get('programId');
	//   const projectId = searchParams.get('projectId');
	  const programName = decodeURIComponent(searchParams.get('programName') || '');
	  const projectName = decodeURIComponent(searchParams.get('projectName') || '');
	const [tableTitle, setTableTitle] = useState('Resources');
	const isTreeView = queryPreference.split('_')[1] === 'tree';

	const collapseChildren = (childrenToCollapse: Row[], newRows: Row[]) => {
		for (const child of childrenToCollapse) {
			const childIndex = newRows.findIndex((r) => r.id === child.id);
			if (child.isExpanded && child.values) {
				collapseChildren(child.values, newRows);
				newRows.splice(childIndex + 1, child.values.length);
			}
		}
	};
	function toggleSubRow(id: string, resourceTypes: any) {
		setTableDataList((prev) => {
			const rows = [...prev];
			const rowIndex = rows.findIndex((r: any) => r.id === id);
			const row = rows[rowIndex];
			const newRows = [...rows];
			let children: Row[] = [];
			let childRows: Row[] = [];
			if (!row.isExpanded) {
				if (resourceTypes?.length > 0) {
					childRows = resourceTypes;
				}
				children = childRows.map((x: any) => ({
					...x,
					isChildren: true
				}));
				newRows.splice(Number(rowIndex) + 1, 0, ...children);
				newRows[rowIndex] = { ...row, isExpanded: true, children };
				localStorage.setItem('boxRows', JSON.stringify(newRows));
				row.isExpanded = true;
			} else {
				children = row.children ?? [];
				collapseChildren(children, newRows);
				newRows.splice(Number(rowIndex) + 1, children.length);
				newRows[rowIndex] = { ...row, isExpanded: false, children: [] };
				localStorage.setItem('boxRows', JSON.stringify(newRows));
				row.isExpanded = false;
			}
			return newRows;
		});
	}

	const handleTreeViewCheck = (treeRow: any, updatedSelectedRows: any) => {
		if (treeRow.values && Array.isArray(treeRow.values)) {
			treeRow.values.forEach((childRow: any) => {
				updatedSelectedRows[childRow.id] = childRow;
				if (childRow.values && Array.isArray(childRow.values)) {
					childRow.values.forEach((nestedChildRow: any) => {
						updatedSelectedRows[nestedChildRow.id] = nestedChildRow;
					});
				}
			});
		}
	};
	function getColumns(editedRows: Record<string, any>): Array<any> {
		// For checkBox
		const checkboxColumn = {
			key: 'selected',
			name: 'HeaderCheckbox',
			frozen: true,
			formatter: ({ row }: any) => {
				const isParentRow = Boolean(row.values && Array.isArray(row.values));
				return isParentRow ? (
					<input
						type="checkbox"
						className="transparent-checkbox row-checkbox"
						checked={!!bulkSelectedRows[row.id]}
						onChange={(e) => {
							const checked = e.target.checked;
							const updatedRow = { ...row };
							const headerCheckboxInput = document.getElementById('HeaderCheckbox') as HTMLInputElement;

							setBulkSelectedRows((prevSelectedRows) => {
								const updatedSelectedRows = { ...prevSelectedRows };

								if (checked) {
									// **Flat View**: Add the row directly
									updatedSelectedRows[row.id] = row;

									// **Tree View**: If row has children (values), add each child row
									handleTreeViewCheck(row, updatedSelectedRows);
								} else {
									// Deselect the row (Flat or Tree)
									let removeRowIds = [row.id];

									// **Tree View**: If row has children, add their IDs for removal
									if (row.values && Array.isArray(row.values)) {
										removeRowIds = removeRowIds.concat(
											row.values
												.map((childRow: any) => {
													let nestedRemove = [];
													if (childRow.values && Array.isArray(childRow.values)) {
														nestedRemove = childRow.values.map((nestedChildRow: any) => nestedChildRow.id);
													}

													return [childRow.id, ...nestedRemove];
												})
												.flat()
										);
									}

									// **Flat View**: Remove the row itself (if not a tree view)
									for (let key in updatedSelectedRows) {
										if (removeRowIds.includes(key)) {
											delete updatedSelectedRows[key];
										}
									}
								}

								// Update header checkbox state
								const allRowsSelected =
									Object.keys(updatedSelectedRows).length ===
									tableDataList.reduce((count, row) => {
										// Count all rows (including parent rows and child rows in the tree)
										return count + (row.values ? row.values.length + 1 : 1); // +1 for the parent row, +children if available
									}, 0);

								if (headerCheckboxInput) {
									headerCheckboxInput.checked = allRowsSelected;
								}

								return updatedSelectedRows;
							});

							setTableDataList((prevRows) =>
								prevRows.map((prevRow) => (prevRow.id === updatedRow.id ? updatedRow : prevRow))
							);
						}}
					/>
				) : null;
			},
			minWidth: 40
		};

		const [groupBy] = queryPreference.split('_');
		const columns = [];
		for (const value of columnNames) {
			const field = value.name;
			if (field.toLowerCase() !== groupBy.toLowerCase()) {
				columns.push(value);
			} else {
				columns.unshift(value);
			}
		}
		const columnArray: any = [
			// Add the "Actions" column first
			{
				key: 'actions',
				name: 'Actions',
				editable: false,
				frozen: true,
				formatter: ({ row }: any) => {
					const isRootParent = !!(Array.isArray(row.values) && !row.values[0]?.values);
					return isRootParent ? (
						<Stack direction="row" alignItems="center" height="100%">
							<Box lineHeight={0} sx={{ display: 'flex', alignItems: 'center' }}>
								<AllocationIcon
									title="Allocation"
									style={{ width: '24px', height: '24px', cursor: 'pointer' }}
									onClick={() => handleResourceColumnClick(row)}
								/>
							</Box>
						</Stack>
					) : null;
				},
				width: 20,
			},
			// Add the "Checkbox" column second
			checkboxColumn,
			// Add all other columns
			...columns.map((col: any, idx: number) => ({
				key: col.name,
				name: col.label,
				editable: () => col.isEditable,
				resizable: col.name === groupBy || ['Resource'].includes(col.name),
				frozen: col.name === groupBy || ['Resource'].includes(col.name),
				formatter({ row, isCellSelected, onRowChange, column }: any) {
					const isGroupBy = column.key.toLowerCase() === groupBy.toLowerCase();
					const hasChildren = !!row.values;
					const isCellEdited =
						(isTreeView ? nonEditedUnGroupList.current : nonEditedList.current)[row.id]?.[column.key] !==
						row[column.key];
					if (column.key === 'PepwiseSector')
						return renderRowTypography(row.SectorCount, row.PepwiseSector, row.BusinessSector, idx, 'SECTORS', row.groupId);
					if (column.key === 'PepwiseProject')
						return renderRowTypography(row.ProjectCount, row.PepwiseProject, row.ProjectName, idx, 'PROJECTS', row.groupId);
					if (column.key === 'PepwiseProgram')
						return renderRowTypography(row.ProgramCount, row.PepwiseProgram, row.Program, idx, 'PROGRAMS', row.groupId);
					if (column.key === 'AllocPercent') 
						return formatAllocPercent(row.AllocPercent);
					if (column.key === 'PepwiseEnvironment'){
						// return !row.PepwiseEnvironment ? '' : row.PepwiseEnvironment.split(',')[0];
						const isEdited = editedRows[row.groupId]?.[column.key];
						const color = row.PepwiseEnvironment !== row.Environment ? '#FFA500' : '#fff';

						return (
							<RowTypography
								isSuccessor={!row.values}
								style={{ color }}
								isEditing={isEditing}
								sx={{
									width: !hasChildren && idx === 0 ? 'calc(100% - 20px)' : 'auto'
								}}
							>
								{row.PepwiseEnvironment ? row.PepwiseEnvironment.split(',')[0] : ''}
							</RowTypography>
						);
					}
					// if (col.name === 'actions') {
					// 	const isRootParent = !!( Array.isArray(row.values) && !row.values[0]?.values);
					// 	return isRootParent ? (
					// 		<Stack direction="row" alignItems="center" height="100%">
					// 			<Box lineHeight={0} sx={{ direction:"row", display: 'flex', alignItems: 'center' }}>
					// 				<AllocationIcon
					// 					title="Allocation"
					// 					style={{ width: '24px', height: '24px',cursor:'pointer'}}
					// 					onClick={ () => handleResourceColumnClick(row) }
					// 				/>
					// 			</Box>
					// 		</Stack>
					// 	) : null;
					// }
					if (!hasChildren && isEditing && col.isEditable) {
						return (
							<SearchFilterDropdown
								mappingType="finopsResourceMapping"
								columnName={column.key}
								isCellSelected={isCellSelected}
								value={row[column.key]}
								onChange={(newValue: string) => {
									onRowChange(
										{
											...row,
											[column.key]: newValue
										},
										false
									);
								}}
								options={[]}
								fieldValueOptionsCallback={async (input: string) => getInputOptions(input, column.key)}
								{...(isCellEdited && {
									cellInputStyle: {
										color: `#FFA500`
									}
								})}
							/>
						);
					}
					return (
						<Box display="flex" gap={1}>
							{idx === 0 && isGroupBy && hasChildren && row.isResource === false && row.values.length > 0 && (
								<CellExpanderFormatter
									isCellSelected={isCellSelected}
									expanded={row.isExpanded === true}
									onCellExpand={() => {
										toggleSubRow(row.id, row.values);
									}}
								/>
							)}
							{idx === 1 && column.key === 'Resource' && row.isResource && hasChildren && row.values.length > 0 && (
								<CellExpanderFormatter
									isCellSelected={isCellSelected}
									expanded={row.isExpanded === true}
									onCellExpand={() => {
										toggleSubRow(row.id, row.values);
									}}
								/>
							)}
							<Stack direction="row" justifyContent="space-between" alignItems="center" flexGrow={1}  sx={{width: '100%'}}>
								<RowTypography
									isSuccessor={!row.values}
									{...(row.groupId && editedRows[row.groupId]?.[column.key] && { color: `#FFA500 !important` })}
									isEditing={isEditing}
									sx={{
										width: (!hasChildren && idx === 0) ? 'calc(100% - 20px)' : 'auto',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										whiteSpace: 'nowrap',
										maxWidth: 'calc(100% - 40px)'
									}}
								>
									{(idx === 0 && isGroupBy && hasChildren) ? row.name 
									: ((column.key === 'Resource' && !hasChildren) && (groupBy.toLowerCase() === 'resourcegroup' 
									|| groupBy.toLowerCase() === 'resource')) ? '' : row[column.key]}
								</RowTypography>
								{((hasChildren && idx === 0 && isGroupBy && row.isResource === false && groupBy.toLowerCase() !== 'resourcegroup')
								|| (idx === 1 && column.key === 'Resource' && row.isResource && hasChildren && groupBy.toLowerCase() === 'resourcegroup')) && (
									<HistoryIcon  sx={{ marginLeft: 'auto' }} cursor="pointer" fontSize="medium" onClick={() => fetchUpdateHistory(row)} />
								)}
							</Stack>
						</Box>
					);
				},
				...(tableDataList && tableDataList.length > 0
					? { minWidth: col.name === 'actions' ? 20 : 200 }
					: { minWidth: col.name === 'actions' ? 20 : 180 })
			}))
		];
		// if (!isEditing && tableDataList.length !== 0) {
		// 	columnArray.unshift(checkboxColumn);
		// }

		return columnArray;
	}

	const renderRowTypography = (count: any, value: any, DBValue: any, idx: any, label: any, groupId?: any) => {
		const labelVal = count > 1 ? `${count} ${label}` : value?.split(',')[0];
		return count > 1 || value ? (
			<RowTypography
				sx={{
					backgroundColor: 'rgba(41, 155, 255, 0.15)',
					// color: groupId && editedRows[groupId] ? `#FFA500 !important` : '#fff',
					color: value !== DBValue ? `#FFA500 !important` : '#fff',
					padding: '4px 10px',
					marginRight: '5px',
					borderRadius: '20px'
				}}
				key={idx}
				title={labelVal}
			>
				{labelVal}
			</RowTypography>
		) : (
			''
		);
	};

	const formatAllocPercent = (allocPercent: string) => {
		if (!allocPercent) return null;
		const percentages = allocPercent.split(',').map((val) => parseInt(val.trim(), 10));
		let groups: string[] = [];
		let currentGroup: number[] = [];
		let currentSum = 0;
		percentages.forEach((value) => {
			if (currentSum + value <= 100) {
				currentGroup.push(value);
				currentSum += value;
			} else {
				groups.push(`(${currentGroup.map((v) => `${v}%`).join(', ')})`);
				currentGroup = [value];
				currentSum = value;
			}
		});
		if (currentGroup.length > 0) {
			groups.push(`(${currentGroup.map((v) => `${v}%`).join(', ')})`);
		}
		return groups.join(' ');
	};

	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [bulkSelectedRows, setBulkSelectedRows] = useState<Record<string, Row>>({});

	const [tableDataList, setTableDataList] = useState<any[]>([]);
	const nonEditedList = useRef<Record<string, Row>>({});
	const nonEditedUnGroupList = useRef<Record<string, Row>>({});
	const [loading, setLoading] = useState(false);
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);

	const [filterData, setFilterData] = useState<Row[]>([]);
	const [filterInfo, setFilterInfo] = useState({} as any);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isProjectAllocationModalOpen, setIsProjectAllocationModalOpen] = useState<boolean>(false);
	const [isUpdateHistoryOpen, setIsUpdateHistoryOpen] = useState<boolean>(false);
	const [isResourceConflictModalOpen, setIsResourceConflictModalOpen] = useState<boolean>(false);
	const [modalTitle, setModalTitle] = useState<string>('Allocation Conflicts in Demo Project');
	const [modalWidth, setModalWidth] = useState<ModalWidth>('sm');
	const [currentRow, setCurrentRow] = useState({} as any);
	const [curRow, setCurRow] = useState({} as any);
	const [isConflicts, setIsConflicts] = useState<boolean>(true);

	const [editedRows, setEditedRows] = useState<any>(initialEditRowState);
	const nonEditedRows = useRef<any>(initialEditRowState);

	//resolve conflict modal title change
	const handleTitleChange = (newTitle: string) => {
		setModalTitle(newTitle);
	};

	//modal width change
	const handleWidthChange = (newWidth: ModalWidth) => {
		setModalWidth(newWidth);
	};

	// fetch resource update history
	const fetchUpdateHistory = (row: any) => {
		setCurRow({ ...row });
		setIsUpdateHistoryOpen(true);
	};

	async function listTableData({ pageNumber, size, sortingInfo, resourceGroupFilterInfo, queryProps }: any) {
		if (queryProps.query === null) {
			return;
		}
		try {
			setLoading(true);
			Object.keys(resourceGroupFilterInfo).forEach((key) => {
				resourceGroupFilterInfo[key] = resourceGroupFilterInfo[key].filter((value: any) => value !== null);
			});

			const [groupBy, viewType] = queryPreference.split('_');
			let res;
			if (projectName && programName) {
				res = await getFinopsResourceMappingList({
					page: pageNumber,
					size,
					sortingInfo: sortingInfo.sortBy,
					orderBy: sortingInfo.sortDir,
					// isProgram: true,
					params: [programName, projectName],
					sql: '(programName = ? and projectName = ?)',
					...(queryProps.isSearch
						? {
								searchField: 'quicksearch',
								searchText: queryProps.query
						  }
						: {
								searchField: 'detail',
								...queryProps.query
						  }),
					...((viewType === 'tree' || viewType === 'inttree' || viewType === 'jobtree') && {
						groupBy
					}),
					viewType
				});
			} else {
				res = await getFinopsResourceMappingList({
					page: pageNumber,
					size,
					sortingInfo: sortingInfo.sortBy,
					orderBy: sortingInfo.sortDir,
					...(queryProps.isSearch
						? {
								searchField: 'quicksearch',
								searchText: queryProps.query
						  }
						: {
								searchField: 'detail',
								...queryProps.query
						  }),
					...((viewType === 'tree' || viewType === 'inttree' || viewType === 'jobtree') && {
						groupBy
					}),
					viewType
				});
			}

			// const res = await getFinopsResourceMappingList({
			// 	page: pageNumber,
			// 	size,
			// 	sortingInfo: sortingInfo.sortBy,
			// 	orderBy: sortingInfo.sortDir,
			// 	...(queryProps.isSearch
			// 		? {
			// 				searchField: 'quicksearch',
			// 				searchText: queryProps.query
			// 		  }
			// 		: {
			// 				searchField: 'detail',
			// 				...queryProps.query
			// 		  }),
			// 	...((viewType === 'tree' || viewType === 'inttree' || viewType === 'jobtree') && {
			// 		groupBy
			// 	}),
			// 	viewType
			// });

			if (res.success) {
				// const listData = isTreeView
				// 	? res.data.result.map((row: any, index: number) => ({
				// 			id: row.name,
				// 			...row,

				// 	  }))
				// 	: res.data.result;

				//Adding external id
				const listData = res.data.result.map((row: any) => ({
					// id: crypto.randomUUID(),
					...row,
					values: row.values
						? row.values.map((secondRow: any) => ({
								id: crypto.randomUUID(),
								...secondRow,
								values: secondRow.values
									? secondRow.values.map((thirdRow: any) => ({
											id: crypto.randomUUID(),
											...thirdRow
									  }))
									: undefined
						  }))
						: undefined
				}));
				setTableDataList(listData);
				setPaginationData(res.data.page);
				setPage(res.data.page.currentPage);
				setPageSize(res.data.page.size);
				setSortingInfo(sortingInfo);
				setFilterData(res.data.filterOptions);
				setFilterInfo(resourceGroupFilterInfo);
				setLoading(false);
				setBulkSelectedRows({});
				const resourceGroupsState = {
					page: res.data.page,
					filterOptions: res.data.filterOptions,
					pageSize: size,
					pageNumber,
					sortingInfo,
					filterInfo: resourceGroupFilterInfo
				};
				localStorage.setItem('resourceGroupsState ', JSON.stringify(resourceGroupsState));
			}
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	}

	const handleResourceColumnClick = (row: any) => {
		setCurrentRow({ ...row });
		handleTitleChange('Create/Modify Allocation');
		handleWidthChange('lg');
		setIsProjectAllocationModalOpen(true);
	};

	const handleEditCell = (rows: any, editData: any) => {
		const editedColumnkey = editData.column.key;
		const editedRow = rows[editData.indexes[0]];
		setEditedRows((prevEditedRows: any) => ({
			...prevEditedRows,
			rowsToUpdate: {
				...prevEditedRows.rowsToUpdate,
				[editedRow.id]: editedRow
			},
			[editedRow.id]: {
				...prevEditedRows[editedRow.id],
				[editedColumnkey]: true
			}
		}));
		if (isTreeView) {
			let parentRow: any = {};
			for (let i = editData.indexes[0]; i >= 0; i--) {
				if (rows[i]?.name) {
					parentRow = rows[i];
					break;
				}
			}
			const index = parentRow.values.findIndex((row: any) => row.id === editedRow.id);
			parentRow.values[index][editedColumnkey] = editedRow[editedColumnkey];
			if (parentRow.children?.[index]?.[editedColumnkey]) {
				parentRow.children[index][editedColumnkey] = editedRow[editedColumnkey];
			}
		}
		setTableDataList(rows);
	};

	const setNonEditedTableList = () => {
		if (isTreeView) {
			const defaultList: Record<string, Row> = {};
			const unGroupedList: Record<string, Row> = {};
			tableDataList.forEach((row) => {
				defaultList[row.id] = row;
				if (row.values) {
					row.values.forEach((value: Row) => {
						unGroupedList[value.id] = value;
					});
				}
			});
			nonEditedList.current = structuredClone(defaultList);
			nonEditedUnGroupList.current = structuredClone(unGroupedList);
		} else {
			nonEditedList.current = structuredClone(
				tableDataList.reduce((acc, row) => {
					acc[row.id] = row;
					return acc;
				}, {})
			);
		}
	};

	// save edited rows
	const handleSave = async () => {
		console.log('save', editedRows);
		// const { rowsToUpdate, ...changedCells } = editedRows;
		// try {
		//   const response = await getFinopsResourceMappingList(rowsToUpdate);
		//   if (response.status === 200) {
		// 	console.log('Data saved successfully:', response.data);
		//   }
		// } catch (error) {
		//   console.error('Error saving data:', error);
		// }

		// deepCopy with structuredClone
		nonEditedRows.current = structuredClone(editedRows);
		setNonEditedTableList();
		setIsEditing(false);
	};

	const handleCancel = async () => {
		setIsEditing(false);
		setTableDataList(structuredClone(Object.values(nonEditedList.current)));
		setEditedRows(structuredClone(nonEditedRows.current));
	};

	const handleEditMode = async () => {
		setNonEditedTableList();
		setIsEditing(true);
	};

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		listTableData({
			pageNumber: value,
			size: pageSize,
			sortingInfo,
			resourceGroupFilterInfo: parseFilter(filterInfo),
			queryProps
		});
	};

	const handlepageSizeChange = (event: any) => {
		listTableData({
			pageNumber: 1,
			size: event.target.value,
			sortingInfo,
			resourceGroupFilterInfo: parseFilter(filterInfo),
			queryProps
		});
	};
	const handleSorting = (sortingInfo: any) => {
		listTableData({
			pageNumber: page,
			size: pageSize,
			sortingInfo,
			resourceGroupFilterInfo: parseFilter(filterInfo),
			queryProps
		});
	};
	const handleFilter = (filterInfo: any) => {
		setFilterInfo(filterInfo);
		listTableData({
			pageNumber: 1,
			size: pageSize,
			sortingInfo,
			resourceGroupFilterInfo: parseFilter(filterInfo),
			queryProps
		});
	};

	//Handle for select all rows
	const handleHeaderSelectAll = (event: any) => {
		const isChecked = event.target.checked;

		const updatedBulkSelectedRows = isChecked
			? tableDataList.reduce((acc: Record<string, any>, row: any) => {
					acc[row.id] = row;
					if (row.values && Array.isArray(row.values)) {
						// For tree view, add each nested row as seperate row
						row.values.forEach((nestedRow: any) => {
							acc[nestedRow.id] = nestedRow;
							if (nestedRow.values && Array.isArray(nestedRow.values)) {
								// For tree view, add each nested nestedRow as seperate nestedRow
								nestedRow.values.forEach((childNested: any) => {
									acc[childNested.id] = childNested;
								});
							}
						});
					}
					return acc;
			  }, {})
			: {};

		setBulkSelectedRows(updatedBulkSelectedRows);
	};

	const handleModalSave = (formData: any, changedCells: any, rowsToUpdate: any) => {
		setEditedRows((prev: any) => {
			const changedCellsClone = structuredClone(changedCells);
			const value = Object.keys(changedCellsClone).reduce(
				(acc: any, key) => {
					acc[key] = {
						...acc[key],
						...changedCellsClone[key]
					};
					return acc;
				},
				{ ...prev }
			);

			nonEditedRows.current = structuredClone(value);
			return value;
		});
		setTableDataList((prev: any) => {
			const clonedValue = structuredClone(prev);
			if (isTreeView) {
				const defaultList: Record<string, Row> = {};
				const unGroupedList: Record<string, Row> = {};
				prev.forEach((row: any, idx: number) => {
					if (rowsToUpdate[row.id]) {
						defaultList[row.id] = rowsToUpdate[row.id];
						clonedValue.splice(idx, 1, rowsToUpdate[row.id]);
					} else {
						defaultList[row.id] = row;
					}
					if (row.values) {
						row.values.forEach((value: Row, valIdx: number) => {
							if (rowsToUpdate[value.id]) {
								unGroupedList[value.id] = rowsToUpdate[value.id];
								clonedValue[idx].values.splice(valIdx, 1, rowsToUpdate[value.id]);
							} else {
								unGroupedList[value.id] = value;
							}
						});
					}
				});
				nonEditedList.current = structuredClone(defaultList);
				nonEditedUnGroupList.current = structuredClone(unGroupedList);
			} else {
				nonEditedList.current = structuredClone(
					prev.reduce((acc: any, row: any, idx: number) => {
						if (rowsToUpdate[row.id]) {
							acc[row.id] = rowsToUpdate[row.id];
							clonedValue.splice(idx, 1, rowsToUpdate[row.id]);
						} else {
							acc[row.id] = row;
						}
						return acc;
					}, {})
				);
			}
			return clonedValue;
		});
	};

	useEffect(() => {}, [bulkSelectedRows]);

	useEffect(() => {
		setTableTitle(tableTitles[queryPreference] ?? 'Resources');
		listTableData({
			page,
			pageSize,
			sortingInfo,
			resourceGroupFilterInfo: parseFilter(filterInfo),
			queryProps
		});
	}, [queryProps]);

	useEffect(() => {
		setTableTitle(tableTitles[queryPreference] ?? 'Resources');
		listTableData({
			page,
			pageSize,
			sortingInfo,
			resourceGroupFilterInfo: parseFilter(filterInfo),
			queryProps
		});
	}, [searchParams]);

	const handleModalClose = () => {
		setBulkSelectedRows({});
		setIsModalOpen(false);
		const headerCheckboxInput = document.getElementById('HeaderCheckbox') as HTMLInputElement;
		if (headerCheckboxInput) {
			headerCheckboxInput.checked = false;
		}
	};

	const handleResourceConflictsModalClose = () => {
		setIsConflicts(true);
		setBulkSelectedRows({});
		setIsResourceConflictModalOpen(false);
		const headerCheckboxInput = document.getElementById('HeaderCheckbox') as HTMLInputElement;
		if (headerCheckboxInput) {
			headerCheckboxInput.checked = false;
		}
	};

	const handleProjectAllocationModalClose = () => {
		setIsProjectAllocationModalOpen(false);
		setCurrentRow({});
	};

	const refetchTableList = () => {
		listTableData({
			page,
			pageSize,
			sortingInfo,
			resourceGroupFilterInfo: parseFilter(filterInfo),
			queryProps
		});
	}

	return loading ? (
		<LoaderComponent pt={10} />
	) : (
		<>
			<Stack justifyContent="flex-end">
				{isEditing ? (
					<Box sx={{ display: 'flex', justifyContent: 'flex-end', width: 'auto', gap: 2 }}>
						<Button variant="pepwiseSecondaryOutline2" sx={{ width: '8rem' }} onClick={handleCancel}>
							Cancel
						</Button>
						<Button variant="pepwiseSecondary" sx={{ width: '8rem' }} onClick={handleSave}>
							Save
						</Button>
					</Box>
				) : tableDataList.length > 0 ? (
					<Box sx={{ display: 'flex', justifyContent: 'flex-end', width: 'auto', gap: 2 }}>
						{/* <Button
							variant="pepwiseSecondaryOutline2"
							sx={{ width: '8rem', alignSelf: 'flex-end' }}
							onClick={handleEditMode}
						>
							Edit
						</Button> */}
						<Button
							disabled={Object.keys(bulkSelectedRows).length === 0}
							variant="pepwiseSecondary"
							sx={{ width: '8rem', alignSelf: 'flex-end' }}
							onClick={() => {
								setIsModalOpen(true);
								handleTitleChange('Resources Bulk Update');
								handleWidthChange('sm');
							}}
						>
							EDIT
						</Button>
						{/* <Button
							disabled={Object.keys(bulkSelectedRows).length === 0}
							variant="pepwiseSecondary"
							onClick={() => setIsResourceConflictModalOpen(true)}
						>
							ADD ALL SELECTED
						</Button> */}
					</Box>
				) : (
					<></>
				)}
			</Stack>
			<CustomizedDataGrid
				title={tableTitle}
				tableType="resource-list"
				tableColumns={getColumns(nonEditedRows.current)}
				tableData={tableDataList}
				defaultColumnNames={defaultColumnNames}
				onCellEdit={handleEditCell}
				onSorting={handleSorting}
				currSorting={sortingInfo}
				filterDataOptions={filterData}
				onHeaderSelectAll={handleHeaderSelectAll}
				onFilterCheck={handleFilter}
				currFilter={filterInfo}
				page={page}
				pageSize={pageSize}
				paginationData={paginationData}
				listLength={tableDataList?.length}
				handlepageSizeChange={handlepageSizeChange}
				handlePageChange={handlePageChange}
				isDateRangeFilter={false}
				isDisabled={false}
				isFetching={loading}
				autoSetDraggedColumns={false}
				isEditing={isEditing}
				childRowClass={true}
				isShowMoreButton = {false}
			/>
			{/* Bulk edit modal */}
			<DialogueModal
				isOpen={isModalOpen}
				title={modalTitle}
				handleClose={() => {
					if (modalTitle === 'Allocation Modified') {
						refetchTableList();
					}
					handleModalClose();
				}}
				width={modalWidth}
			>
				<BulkEditModalConfirm
					onClose={handleModalClose}
					onSave={handleModalSave}
					onCancel={() => setIsModalOpen(false)}
					bulkSelectedRows={bulkSelectedRows}
					setBulkSelectedRows={setBulkSelectedRows}
					queryPreference={queryPreference}
					columnNames={columnNames}
					onTitleChange={handleTitleChange}
					onWidthChange={handleWidthChange}
					onConfirm={() => {
						refetchTableList();
					}}
					allocationType={{
						key: queryPreference?.split?.('_')?.[0],
						label: tableTitle
					}}
				/>
			</DialogueModal>

			{/* Resource Conflict Modal */}
			<DialogueModal
				isOpen={isResourceConflictModalOpen}
				title={modalTitle}
				handleClose={handleResourceConflictsModalClose}
				width={modalWidth}
				dialogContentProps={{
					sx: { p: 0 }
				}}
			>
				{isConflicts ? (
					<AllocationConflictModal onTitleChange={handleTitleChange} setIsConflicts={setIsConflicts} />
				) : (
					<ResolveAllocationConflictsModal
						bulkSelectedRows={bulkSelectedRows}
						onTitleChange={handleTitleChange}
						onWidthChange={handleWidthChange}
						modalTitle={modalTitle}
					/>
				)}
			</DialogueModal>

			{/* Resource Allocation Modal */}
			<DialogueModal
				isOpen={isProjectAllocationModalOpen}
				title={modalTitle}
				handleClose={() => {
					if (modalTitle === 'Allocation Modified') {
						refetchTableList();
					}
					handleProjectAllocationModalClose();
				}}
				width={modalWidth}
				dialogContentProps={{
					sx: { p: 0 }
				}}
			>
				<ResourceAllocationModal
					allocationType={{
						key: queryPreference?.split?.('_')?.[0],
						label: tableTitle
					}}
					currentRow={currentRow}
					onCancel={() => setIsProjectAllocationModalOpen(false)}
					onTitleChange={handleTitleChange}
					onWidthChange={handleWidthChange}
					modalTitle={modalTitle}
					//Re-fetch the updated table data inside tableSection once project allocation completed
					onConfirm={() => {
						refetchTableList();
					}}
				/>
			</DialogueModal>

			{/* Resource update history */}
			<Drawer anchor="bottom" open={isUpdateHistoryOpen} onClose={() => setIsUpdateHistoryOpen(false)}>
				<UpdateHistory curRow={curRow} setIsUpdateHistoryOpen={setIsUpdateHistoryOpen} />
			</Drawer>
		</>
	);
};

export default TableSection;
