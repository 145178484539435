import {
	Button,
	Stack,
	Typography,
	Box,
	Link
} from '@mui/material';
import { CustomizedDataGrid } from 'components/customized_data_grid';
import RowTypography from 'components/Table/RowTypography';
import React, { useEffect} from 'react';

import { ReactComponent as ResourceMapping } from '../../../../src/assets/resourceMappingNew.svg';
import styled from '@emotion/styled';
import {ReactComponent as WarningImage} from '../../../assets/warning.svg';

const StyledConfirmationIcon = styled(ResourceMapping)({
	width: '5rem',
	height: '5rem'
});

type ModalWidth = "sm" | "md" | "lg" | "xl";

interface AddResourceSuccessModalProps {
    bulkSelectedRows: any;
    modalTitle: string;
    onTitleChange: (newtitle: string) => void;
    onWidthChange: (newWidth: ModalWidth) => void;
}

const AddResourceSuccessModal: React.FC<AddResourceSuccessModalProps> = ({bulkSelectedRows, onTitleChange, onWidthChange, modalTitle}) => {


	const defaultColumnNames = ['Resource', 'Resource Group', 'Allocation%', 'SubScription'];

	const columnNames = [
		{ name: 'Resource', label: 'Resource' },
        { name: 'ResourceGroup', label: 'Resource Group' },
		{ name: 'allocationPercentage', label: 'Allocation%' },
		{ name: 'subScription', label: 'SubScription' }
	];

	// Set a new title when the component is rendered
	useEffect(() => {
		const newTitle = 'Resources Added Successfully';
		onTitleChange(newTitle);
        onWidthChange("sm");
	}, [onTitleChange, onWidthChange]);

	const handleConfirm = () => {
		// onConfirm();
		// Save the updated allocations to the server
	};

	const handleSave = () => {
		// Save the updated allocations to the state
	};

	const handleCancel = () => {
		// onCancel();
	};
	
	function getColumns(): Array<any> {
		return columnNames.map((col: any, idx: number) => ({
			key: col.name,
			name: col.label,
			editable: () => col.isEditable,
			formatter({ row, column }: any) {
				return (
					<Stack direction="row" justifyContent="space-between" alignItems="center">
						<RowTypography isSuccessor={!row[column.key]}>
							{column.key === 'allocationPercentage' ? `${row[column.key]?? 0}%` : row[column.key]}
						</RowTypography>
					</Stack>
				);
			},
			width: 200
		}));
	}

	return (
		<>
			<Stack
				direction="row"
				justifyContent="space-between"
                pl='1rem'
                pr='1rem'
                py='0.5rem'
				sx={{ backgroundColor: 'boxColor.orange.bgColor', display: 'flex', alignItems: 'center' }}
			>
				<Stack direction="row">
					<WarningImage className="warning-image" />
					<Typography variant="tableColumnFont" sx={{ color: 'boxColor.orange.color', ml: '0.5rem' }}>
						We are unable to add{' '}<Link sx={{textDecoration: 'underline', color: 'inherit', cursor: 'pointer'}}>3 Resources</Link>{' '}due to some conflicts.
					</Typography>
				</Stack>
				<Stack>
					<Typography variant="tableColumnFont" sx={{ color: 'text.focus' }}>RESOLVE CONFLICT</Typography>
				</Stack>
			</Stack>
			<Stack sx={{ padding: '15px' }}>
				<Stack sx={{ flexDirection: 'row', gap: 2 }}>
					<Box sx={{ alignItems: 'center', justifyContent: 'flex-start', width: '5rem' }}>
						<StyledConfirmationIcon />
					</Box>
					<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
						<Typography variant="h6" sx={{ fontWeight: 500 }}>
							2 Resources added successfully in the Project
						</Typography>
						<Typography sx={{ alignSelf: 'flex-start', color: 'light.diff' }}>Would you like to add more?</Typography>
					</Box>
				</Stack>
				<Stack sx={{ flexDirection: 'column', gap: 3, textAlign: 'left' }}>
					<CustomizedDataGrid
						tableColumns={getColumns()}
						tableData={Object.values(bulkSelectedRows)}
						defaultColumnNames={defaultColumnNames}
						autoSetDraggedColumns={false}
						isRenderPagination={false}
						isShowMoreButton={false}
						style={{ maxHeight: '250px' }}
					/>
					<Stack direction="row" gap={2} sx={{ alignSelf: 'flex-end' }}>
						<Button variant="pepwiseOutline">CONFIRM & CLOSE</Button>
						<Button variant="pepwiseSecondary">ADD MORE</Button>
					</Stack>
				</Stack>
			</Stack>
		</>
	);
};

export default AddResourceSuccessModal;
