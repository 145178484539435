import React, { useEffect, useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { ReactComponent as WarningIcon } from '../../../assets/warning.svg';

type AllocationConflictModalProps = {
	onTitleChange: (newTitle: string) => void;
	setIsConflicts: any;
};

const AllocationConflictModal: React.FC<AllocationConflictModalProps> = ({onTitleChange, setIsConflicts}) => {
	const dummyCount = 3;
	const [resourceCount, setResourceCount] = useState(dummyCount);
	const handleCancel = () => {
		// onCancel();
	};

	const handleResolve = () => {
		// onResolve();
		setIsConflicts(false);
	};

	// Set a new title when the component is rendered
	useEffect(() => {
		const newTitle = 'Allocation Conflicts in Demo Project';
		onTitleChange(newTitle);
	}, [onTitleChange]);
	return (
		<Stack
			spacing={2}
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: 2,
				p: 2
			}}
		>
			<Stack direction="row" alignItems="center" spacing={2}>
				<WarningIcon style={{ width: '5rem', height: '5rem' }} />

				<Stack>
					<Typography variant="pageTitle" sx={{ display: 'flex', justifyContent: 'flex-start', pb: '0.5rem' }}>
						<Box component="span" sx={{ mr: 1 }}>
							{resourceCount}
						</Box>
						Conflicts Found
					</Typography>
					<Typography>
						Please adjust allocation of{' '}
						<Box component="span" sx={{ color: 'info.main' }}>
							{resourceCount} Resources
						</Box>{' '}
						individually.
					</Typography>
				</Stack>
			</Stack>
			<Stack direction="row" sx={{ justifyContent: 'flex-end', gap: 2 }}>
				<Button variant="pepwiseOutline" sx={{ width: '8rem' }} onClick={handleCancel}>
					CANCEL
				</Button>
				<Button variant="pepwiseSecondary" sx={{ width: '11.5rem' }} onClick={handleResolve}>
					RESOLVE CONFLICT
				</Button>
			</Stack>
		</Stack>
	);
};

export default AllocationConflictModal;
