import { ReactNode } from 'react';
import { Box, BoxProps, FormControl, MenuItem, MenuProps, Select, SelectProps } from '@mui/material';

type DropdownOption = {
	label: string;
	value: string;
	icon?: string;
	isDisabled?: boolean;
};

type CustomDropdownProps = {
	dropDownPlaceholder?: string;
	value: string;
	handleChange: (event: any) => void;
	boxProps?: BoxProps;
	menuProps?: Partial<MenuProps>;
	selectComponentProps?: SelectProps;
	noSelectionLabel?: string;
	dropdownIcon?: React.ElementType;
} & ({ options: DropdownOption[]; children?: never } | { children: ReactNode; options?: never });

const CustomDropdown = ({
	value,
	options,
	handleChange,
	dropDownPlaceholder,
	boxProps,
	menuProps,
	selectComponentProps,
	noSelectionLabel,
	children,
	dropdownIcon,
}: CustomDropdownProps) => {
	const { sx, ...props } = boxProps || {};

	return (
		<Box
			sx={{
				backgroundColor: 'blue.dark',
				borderRadius: '4px',
				fontSize: '14px',
				fontWeight: '500',
				zIndex: 9,
				minWidth: '90px',
				textAlign: 'left',
				height: 'fit-content',
				...sx
			}}
			className='custom-dropdown-box'
			{...props}
		>
			<FormControl fullWidth>
				<Select
					value={value}
					onChange={handleChange}
					placeholder={dropDownPlaceholder}
					IconComponent={dropdownIcon ? dropdownIcon : undefined} 
					MenuProps={menuProps}
					{...(!!options && {
						renderValue: (selected) => {
							if (!selected) {
								return <em>{noSelectionLabel}</em>;
							}
							const selectedOption = options.find((option) => option.value === selected);
							return (
								<Box sx={{ display: 'flex', alignItems: 'center' }}>
									{!!selectedOption?.icon && <Box component="img" src={selectedOption.icon} alt="" sx={{ mr: 1 }} />}
									{selectedOption?.label}
								</Box>
							);
						}
					})}
					{...selectComponentProps}
					sx={{
						...selectComponentProps?.sx
					}}
				>
					{!!options
						? options.map(({ label, value, icon, isDisabled = false }) => (
								<MenuItem key={`${value}-${label}`} value={value} disabled={isDisabled}>
									<Box sx={{ display: 'flex', alignItems: 'center', color: isDisabled ? 'white.main' : 'inherit' }}>
										{!!icon && <Box component="img" src={icon} alt="" sx={{ mr: 1 }} />}
										{label}
									</Box>
								</MenuItem>
						  ))
						: children}
				</Select>
			</FormControl>
		</Box>
	);
};

export default CustomDropdown;
