import React from 'react';
import { Box, Chip, Typography } from '@mui/material';

type RowChipProps = {
    value: string[] | string;
    rowId: number;
    // isExpanded: boolean;
    // onToggle: (id: number) => void;
};

const RowChip: React.FC<RowChipProps> = ({ value, rowId }) => {
    const commonChipStyles = {
        color: 'white',
        whiteSpace: 'normal',
        overflow: 'visible',
        maxWidth: 'none',
    };

    const renderChips = (sectorsToRender: RowChipProps['value']) => {
			if (typeof sectorsToRender === 'string') {
				return <Chip label={sectorsToRender} variant="filled" color="primary" sx={commonChipStyles} />;
			}
			if (Array.isArray(sectorsToRender)) {
				return sectorsToRender.map((sector, index) => (
					<Chip key={index} label={sector} variant="filled" color="primary" sx={commonChipStyles} />
				));
			}
		};

    return (
        <Box
            sx={{
                maxWidth: '100%',
                overflowX: 'auto',
                overflowY: 'hidden',
                display: 'flex',  
                gap: 1,
                paddingBottom: '4px',
                '::-webkit-scrollbar': {
                    height: '7px',
                },
            }}
        >
             {renderChips(value)}

            {/* {isExpanded ? (
                <>
                    {renderChips(value)}
                    <Typography
                        variant="subtitle1"
                        sx={{ color: 'info.main', cursor: 'pointer' }}
                        onClick={() => onToggle(rowId)}
                    >
                        Show less
                    </Typography>
                </>
            ) : (
                <>
                    {renderChips(value.slice(0, 2))}
                    {value.length > 2 && (
                        <Typography
                            variant="subtitle1"
                            sx={{ color: 'info.main', cursor: 'pointer' }}
                            onClick={() => onToggle(rowId)}
                        >
                            +{value.length - 2} more
                        </Typography>
                    )}
                </>
            )} */}
        </Box>
    );
};

export default RowChip;