import {
	Box,
	Button,
	Dialog,
	Grid,
	Menu,
	MenuProps,
	Stack,
	Typography,
	Drawer
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DataPipelineModal } from './modal-component';
import { ReactComponent as EditIcon } from '../../assets/edit-img.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete-img.svg';
import { ReactComponent as AddIcon } from '../../assets/plus-icon.svg';
import { ReactComponent as ClockIcon } from '../../assets/time-history.svg';
import useStyles from './data-pipeline-styles';
import '../DataPipeline/dataPipeline.scss';
import CyclicExecutions from './CyclicExecutions';
import {
	getDependentJobExeData,
	getDpJobKpidata,
	getJobPerformanceChartData,
	getMappedJobScheduleList,
	getProjectById,
	getSlaCountDetail
} from '../../service/service';
import { CustomizedDataGrid } from '../customized_data_grid';
import { Column } from 'react-data-grid';
import Loader from '../Loader/loader';
import moment from 'moment';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import 'react-date-range/dist/styles.css'; // main style file
import { CellExpanderFormatter } from 'components/cell_expander_formatter';
import DataInfoSecondary from 'components/Widgets/DataInfoSecondary';
import ShowInfoWidget from 'components/Widgets/ShowInfoWidget';
import PageHeader from 'components/Header/Page';
import DashboardIcon from '../../assets/material-symbols_dashboard.svg';
import DataInfoColorWidget from 'components/Widgets/DataInfoColorWidget';
import ReactDateRangeFilter from 'components/Dropdown/ReactDateRangeFilter';
import StatusChip from 'components/Table/StatusChip';
import RowTypography from 'components/Table/RowTypography';
import eventEmitter from 'SnackBarEventEmitter';
import { dependInfoTransformData } from 'utils/common-util';
import { usePepwiseAuthController } from 'Context/contexts/AuthContext';
import RagBar from 'components/Table/RagBar';
import { checkRole } from 'utils/roleUtils';
import { useNavigate } from 'react-router-dom';
import { start } from 'repl';


const StyledMenu = styled((props: MenuProps) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right'
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right'
		}}
		{...props}
	/>
))(({ theme }) => ({
	'& .MuiPaper-root': {
		borderRadius: 6,
		marginTop: theme.spacing(1),
		minWidth: 180,
		color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
		boxShadow:
			'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
		'& .MuiMenu-list': {
			padding: '4px 0'
		},
		'& .MuiMenuItem-root': {
			'& .MuiSvgIcon-root': {
				fontSize: 18,
				color: theme.palette.text.secondary,
				marginRight: theme.spacing(1.5)
			},
			'&:active': {
				backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
			}
		}
	}
}));

const ProjectDetails = () => {
	interface Row {
		id: number,
		orderDate: any,
		jobId: number,
		jobName: string,
		dependentData: any,
		scheduler?: string,
		jobStatus: string,
		oDate: string,
		actualStartTime: string,
		actualEndTime: string,
		slaStatus: string,
		slaTime: number,
		isExpanded: boolean,
		ischildren?: boolean;
		children: Row[],
		platformId: number,
		slaDate: string,
		hasDependent: boolean,
		className: string;
		eleLevel: string;
		isChildren?: boolean;
		exeId?: number;
		jobCondition: string;
		scheduleInfo: string;
		isCyclic: string;
		depCyclic: string;
		avgRunTime: any
		totalExecutions: number,
	}
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	let jobStatusParam = searchParams.get('jobStatus');
	// console.log(searchParams.get('startingDate'))
	let dateParam = {
		start: searchParams.get('startingDate'),
		end: searchParams.get('endingDate')
	}
	const { programId, projectId, program, project, projDate } = useParams();

	const [controller] = usePepwiseAuthController();
	const { role } = controller;
	const isAllowed = checkRole(role, ['Data Operations Admin', 'PepWise System User']);

	const classes = useStyles();
	const [hoveredItem, setHoveredItem] = useState<string | null>(null);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		event.preventDefault();
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};
	const handleMenuClose = (event: any) => {
		event.preventDefault();
		event.stopPropagation();
		setAnchorEl(null);
	};

	const [projectList, setProjectList] = useState<any>([]);
	const [openModal, setOpenModal] = useState<any>({ isOpen: false });
	const [projectDetail, setProjectDetail] = useState<any>({});
	const [slaStatus, setSlaStatus] = useState<any>('');
	const [loading, setLoading] = useState(false);
	const [slaLoading, setSlaLoading] = useState(false);
	const [tableLoading, setTableLoading] = useState(false);
	const [kpiLoading, setKpiLoading] = useState(false);
	const [dependentInfoLoading, setDependentInfoLoading] = React.useState<boolean>();
	const [depExeLoading, setDepExeLoading] = useState(false);
	const [jobSchedulesList, setjobSchedulesList] = useState<Row[]>([]);
	const [jobExeList, setJobExeList] = useState<Row[]>([]);
	const [slaData, setSlaData] = useState<any>({});
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [filterInfo, setFilterInfo] = useState([]);
	const [filterOptions, setFilterOptions] = useState<any>({});
	const [slaReport, setSlaReport] = useState<any>({});
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState('1');
	const [isCyclicExeData, setIsCyclicExeData] = useState<boolean>(false);
	const [curRow, setCurRow] = useState({} as any);


	const [startDate, setStartDate] = useState<string>(dateParam?.start ? moment(dateParam.start).startOf('day').format('YYYY-MM-DD HH:mm') : projDate ? moment(projDate).startOf('day').format('YYYY-MM-DD HH:mm') : moment().startOf('day').format('YYYY-MM-DD HH:mm'));
	const [endDate, setEndDate] = useState<string>(dateParam?.end ? moment(dateParam.end).startOf('day').format('YYYY-MM-DD HH:mm') : projDate ? moment(projDate).startOf('day').format('YYYY-MM-DD HH:mm') : moment().startOf('day').format('YYYY-MM-DD HH:mm'));

	const [grafanaChartLoading, setGrafanaChartLoading] = useState(true);
	const [grafanaChartOptions, setGrafanaChartOptions] = useState<any>();
	const [performanceByDay, setPerformanceByDay] = useState<any>(false);

	// const [is24Hrs, setIs24Hrs] = useState<any>(false);
	let is24Hrs: boolean = false;

	const handleDateSelect = (stDt: string, edDt: string, selectedDate?: string, is24HrsTime?: boolean) => {
		dateParam = {
			start: '',
			end: ''
		}
		projectMappedJobScheduleFunc(page, pageSize, stDt, edDt, parseFilter(filterInfo), sortingInfo);
		// getKpiCount(stDt, edDt)
		// jobPerformanceBarChartData(stDt, edDt);
		// getSlaCountData(stDt, edDt);
		if (is24HrsTime !== undefined) {
			is24Hrs = is24HrsTime;
		}
		// getServiceRequestSummary(selectedDate);
	};

	eventEmitter.on('showSnackbar', (message: string) => {
		setTableLoading(false);
		setSlaLoading(false);
		setGrafanaChartLoading(false)
	});

	const handleTableAction = (props: any, type: string) => {
		if (type == 'delete') {
			setOpenModal({
				isOpen: true,
				type: 'Schedule',
				action: 'DELETE',
				from: 'delete',
				data: props
			});
		}
	};

	const fetchCyclicExecutions = (row: any) => {
		setCurRow({ ...row });
		setIsCyclicExeData(true);
	};

	const getColorData = (data: string) => {
		let colorStr = data;
		let colorArr: any = []
		let arr = colorStr.split(',').map(Number)
		arr.map((data: any) => {
			switch (Number(data)) {
				case 1:
					colorArr.push('rgba(1, 192, 8, 1)') // Success Color
					break;
				case 2:
					colorArr.push('rgba(216, 43, 81, 1)') // Failed color
					break;
				case 3:
					colorArr.push('rgba(236, 185, 20, 1)') // Inprogress Color
					break;
				case 4:
					colorArr.push('rgba(253, 149, 50, 1)') // LongRunning Color			    
					break;
				case 5:
					colorArr.push('rgba(255, 56, 176, 1)') // Delayed Color
					break;
				// case 6:	
				// 	colorArr.push('rgba(160, 129, 255, 1)')
				// 	break;
				case 7:
					colorArr.push('rgba(255, 118, 118, 1)') // Scheduled Color
					break;
				default:
					break;
			}
		});


		return colorArr
	}

	const parseFilter = (filterInfo: any, name?: string) => {
		// Call only filterable columns and reset the filtering in case of wrong/empty column
		const parsedFilter: any = {};
		Object.keys(filterInfo).forEach((key: any) => {
			if (key !== name && filterInfo[key] && filterInfo[key].length) {
				parsedFilter[key] = filterInfo[key];
			}
		});
		return parsedFilter;
	};

	function convertToTimeString(num: any) {
		const hours = Math.floor(num / 100)
			.toString()
			.padStart(2, '0');
		const minutes = (num % 100).toString().padStart(2, '0');
		return `${hours}:${minutes}`;
	}

	const handleAndGetFilterOptions = async (column: any) => {
		console.log("COLLLL", column)
		let filterSch: any = {};
		let colObj: any = {
			Job_Name: 'Job_Name',
			platform: 'Platform_Id',
			Scheduler_Name: 'Scheduler_Name',
			Start_Time: 'Start_Time'
		};
		if (jobSchedulesList.length) {
			filterSch[column] = [];

			jobSchedulesList.map((data: any) => {
				// Check if the value is already in the array before pushing

				if (column == 'platformId') {
					switch (data[column]) {
						case 1:
							if (!filterSch[column].includes('Teradata')) {
								filterSch[column].push('Teradata');
							}
							break;

						default:
							break;
					}
				} else if(column == 'jobStatus'){
                   if (data.jobStatus == 'Successful' && !filterSch[column].includes("Success")) {
					filterSch[column].push('Success')
				   } else  if (data.jobStatus == 'Failure' && !filterSch[column].includes("Failed")) {
					filterSch[column].push('Failed')
				   } else if(!filterSch[column].includes(data.jobStatus) && data.jobStatus != 'Successful' && data.jobStatus != 'Failure') {
					filterSch[column].push(data.jobStatus)
				   }
				}
				 else if (
					column == 'oDate' &&
					!filterSch[column].includes(`${moment(data.oDate).tz('America/Chicago').format("MM-DD-YY")}`)
				) {
					filterSch[column].push(`${moment(data.oDate).tz('America/Chicago').format("MM-DD-YY")}`);
				} else if (
					column == 'actualStartTime' || column == 'actualEndTime'

				) {
					if (!filterSch['actualStartTime'].includes(`${moment(data.actualStartTime).tz('America/Chicago').format("MM-DD-YY HH:mm")}`)) {
						filterSch['actualStartTime'].push(`${moment(data.actualStartTime).tz('America/Chicago').format("MM-DD-YY HH:mm")}`);
					} else if (!filterSch['actualEndTime'].includes(`${moment(data.actualEndTime).tz('America/Chicago').format("MM-DD-YY HH:mm")}`)) {
						filterSch['actualEndTime'].push(`${moment(data.actualEndTime).tz('America/Chicago').format("MM-DD-YY HH:mm")}`);
					}

				} else if (
					column == 'slaTime' &&
					!filterSch[column].includes(`${convertToTimeString(data.slaTime)}`)
				) {
					filterSch[column].push(`${convertToTimeString(data.slaTime)}`);
				} else if (!filterSch[column].includes(data[column])) {
					filterSch[column].push(data[column]);
				}
			});
			if (column == 'slaTime') {
				let filteredData = [];
				filteredData = filterSch[column].filter((item: any) => typeof item === 'string');
				filterSch[column] = filteredData;
			}
			console.log(filterSch)
			setFilterOptions(filterSch);
		}
	};

	const handleFilter = (filterData: any) => {
		setFilterInfo(filterData);
		let objData: any = parseFilter(filterData);
		if (Object.keys(objData).includes('slaTime')) {
			objData['slaTime'].map((data: any, index: number) => {
				objData['slaTime'][index] = Number(data.replace(':', ''));
			});
			projectMappedJobScheduleFunc(page, pageSize, startDate, endDate, objData, sortingInfo);
		} else if (Object.keys(objData).includes('platformId')) {
			objData['platformId'].map((data: any, index: number) => {
				if (data == 'Teradata') {
					objData['platformId'][index] = 1;
				}
			});
			projectMappedJobScheduleFunc(page, pageSize, startDate, endDate, objData, sortingInfo);
		} else if (Object.keys(objData).includes('oDate')) {

			objData['oDate'].map((data: any, index: number) => {
				objData['oDate'][index] = moment(data, "MM-DD-YY").format("YYYY-MM-DD")
			});
			projectMappedJobScheduleFunc(page, pageSize, startDate, endDate, objData, sortingInfo);
		} else if (Object.keys(objData).includes('jobStatus')) {

			objData['jobStatus'].map((data: any, index: number) => {
				if (objData['jobStatus'][index] == 'Success') {
					objData['jobStatus'][index] = 'Successful'
				} else if (objData['jobStatus'][index] == 'Failed'){
					objData['jobStatus'][index] = 'Failure'
				} else {
					objData['jobStatus'][index] = objData['jobStatus'][index]
				}
				
			});
			projectMappedJobScheduleFunc(page, pageSize, startDate, endDate, objData, sortingInfo);
		}
		else if (Object.keys(objData).includes('jobName')) {
            console.log("OBJJJJJJJ", objData)
			objData['jobName'].map((data: any, index: number) => {
				let jobInfo: any = jobSchedulesList.find((obj)=>obj.jobName == data) 
				objData['jobName'][index] = jobInfo.jobId
				
			});
			projectMappedJobScheduleFunc(page, pageSize, startDate, endDate, objData, sortingInfo);
		} else {
			projectMappedJobScheduleFunc(page, pageSize, startDate, endDate, parseFilter(filterData), sortingInfo);
		}
	};

	let constRows: any = []

	async function toggleSubRow(name: number, resourceTypes: any, eleLevel: string) {

		const rows = constRows.length ? constRows : jobSchedulesList

		const rowIndex = rows.findIndex((r: any) => r.id === name);
		const row = rows[rowIndex];
		const newRows = [...rows];
		let children: Row[] = [];
		let childRows: Row[] = [];
		if (!row.isExpanded) {

			const boxRows: any = localStorage.getItem('depJobExeData');
			const storageRows = boxRows ? JSON.parse(boxRows) : [];
			let hasStrRow = false;
			storageRows.filter((strRow: any) => {
				if (strRow.jobId == row.jobId && (row.exeId ? strRow.exeId == row.exeId : strRow.oDate == row.oDate) && strRow.projectId == Number(projectId)) {
					hasStrRow = true;
					newRows.splice(Number(rowIndex) + 1, 0, ...strRow.children);
					newRows[rowIndex] = { ...row, isExpanded: !row.isExpanded, children: strRow.children };
					const newUpdatedRows = [...newRows];
					constRows = newUpdatedRows
					setjobSchedulesList(newUpdatedRows);
					// addSchData = newUpdatedRows
				}
			});


			if (!hasStrRow) {
				let payload = jobSchedulesList.filter((data) => data.id === name)
				setDependentInfoLoading(true)
				// setDepExeLoading(true)
				const res = await getDependentJobExeData(payload[0]);
				if (res.success) {
					//    setDepExeLoading(false)
					setDependentInfoLoading(false)

					Object.entries(res.data).forEach(([key, value]: any) => {
						childRows = dependInfoTransformData(value);
					});
					children = childRows.map((x: any) => {
						return { ...x, isChildren: true };
					});
					newRows.splice(Number(rowIndex) + 1, 0, ...children);
					newRows[rowIndex] = { ...row, isExpanded: !row.isExpanded, children };
					const newUpdatedRows = [...newRows];

					constRows = newUpdatedRows
					setjobSchedulesList(newUpdatedRows);
					// addSchData = newUpdatedRows
					const stBoxRows: any = localStorage.getItem('depJobExeData');
					const tempRows = [{
						jobId: row.jobId,
						oDate: row.oDate,
						exeId: row.exeId || '',
						projectId: Number(projectId),
						children: children
					}]
					if (stBoxRows) {
						let tempStorageBoxRows = JSON.parse(stBoxRows)
						tempStorageBoxRows = tempStorageBoxRows.concat(tempRows);
						localStorage.setItem('depJobExeData', JSON.stringify(tempStorageBoxRows))
					} else {
						localStorage.setItem('depJobExeData', JSON.stringify(tempRows))
					}
				}
			}

			if (resourceTypes?.length > 0) {
				childRows = resourceTypes;
			}
			children = childRows.map((x: any) => {
				return { ...x, isChildren: true };
			});
			newRows.splice(Number(rowIndex) + 1, 0, ...children);
			newRows[rowIndex] = { ...row, isExpanded: true, children };
			row.isExpanded = true;
		} else {
			children = row.children ?? [];
			newRows.splice(Number(rowIndex) + 1, children.length);
			newRows[rowIndex] = { ...row, isExpanded: false };
			const newUpdatedRows = [...newRows];

			constRows = newUpdatedRows
			setjobSchedulesList(newUpdatedRows);
			row.isExpanded = false;
		}
	}

	const [pageSize, setPageSize] = useState('100');
	const defaultColumnNames = [
		'Platform',
		'Jobs',
		'Total Exec.',
		'Cyclic',
		'Order Date',
		
		'Act. Start Time (CST)',
		'Act. End Time (CST)',
		'Avg Exec. Time',
		'Job Status',
		'Last 30 Execution Trend'
	];


	function getColumns(): Array<Column<Row>> {
		return [
			{
				key: 'platformId',
				name: 'Platform',
				formatter({ row }) {
					return (
						<RowTypography isSuccessor={!row.dependentData}>{row.platformId == 1 ? 'Teradata' : ''}</RowTypography>
					);
				},
				minWidth: 120
			},
			{
				key: 'jobName',
				name: 'Jobs',
				formatter({ row, isCellSelected }) {
					const hasChildren = row.hasDependent ? true : false;
					return (
						<div className={[row.className, row.eleLevel, row.jobStatus, row.isChildren ? 'child-view' : 'parent-view', hasChildren ? 'has-children' : 'single'].join(' ')}>
							{hasChildren && (
								<CellExpanderFormatter
									isCellSelected={isCellSelected}
									expanded={row.isExpanded === true}
									onCellExpand={() => {
										toggleSubRow(row.id, row.dependentData, row.eleLevel);
									}}
								/>
							)}
							{'   '}
							{/* <Link to="" state={row}>
								<RowTypography isSuccessor={!row.dependentData} title={row.jobName}>{row.jobName}</RowTypography>
							</Link>{' '} */}
							<RowTypography isSuccessor={!row.dependentData} title={row.jobName}>{row.jobName}</RowTypography>
						</div>
					);
				},
				minWidth: 300
			},
			{
				key: 'totalExecutions',
				name: 'Total Exec.',
				formatter({row}) {
					if (row.totalExecutions) {
						return (
							<Grid container spacing={1}>
								<Grid item xs={6}>
								   <StatusChip displayNumber={true} columnName='totalExecutions' status='IP_Delayed_Start' value={row.totalExecutions ? row.totalExecutions : '--'} />
								</Grid>
								<Grid item xs={4}>
								  
								</Grid>
								{
									row.totalExecutions > 1 && <Grid item xs={2} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', cursor:'pointer'}}>
									<ClockIcon onClick={()=> fetchCyclicExecutions(row)}/>
								  </Grid>
								}
							</Grid>)
						
					} else {
						return <div style={{textAlign: 'center'}}>--</div>
					}
				},
				minWidth: 160
			},
			{
				key: 'isCyclic',
				name: 'Cyclic',
				formatter({ row }) {
					return (
						<RowTypography isSuccessor={!row.dependentData}>{ row.isCyclic == '0' ? 'No' : row.isCyclic == '1' ? 'Yes' : row.isCyclic }</RowTypography>
					);
				},
				minWidth: 130
			},
			{
				key: 'oDate',
				name: 'Order Date',
				formatter(props) {
					const startDate = props.row.orderDate;
					if (startDate) {
						return (
							<RowTypography isSuccessor={!props.row.dependentData}>
								{' '}
								{startDate ? moment(startDate).utc().format('MM-DD-YY') : '--'}{' '}
							</RowTypography>
						);
					}
					return '';
				},
				minWidth: 130
			},
			{
				key: 'actualStartTime',
				name: 'Act. Start Time (CST)',
				formatter(props) {
					const startDate = props.row.actualStartTime;
					return (
						<RowTypography isSuccessor={!props.row.dependentData}>
							{' '}
							{startDate && startDate != null
								? moment(props.row.actualStartTime).tz('America/Chicago').format('MM-DD-YY HH:mm:ss')
								: '--'}{' '}
						</RowTypography>
					);
				},
				minWidth: 200
			},
			{
				key: 'actualEndTime',
				name: 'Act. End Time (CST)',
				formatter(props) {
					const startDate = props.row.actualEndTime;
					return (
						<RowTypography isSuccessor={!props.row.dependentData}>
							{' '}
							{startDate
								? moment(props.row.actualEndTime).tz('America/Chicago').format('MM-DD-YY HH:mm:ss')
								: '--'}{' '}
						</RowTypography>
					);
				},
				minWidth: 200
			},
			{
				key: 'avgRunTime',
				name: 'Avg Exec. Time',
				formatter({ row }) {
					return (
						<RowTypography isSuccessor={!row.dependentData}>{row.avgRunTime ? getHrsMin(Number(row.avgRunTime)) : '--'}</RowTypography>
					);
				},
				minWidth: 130
			},
			{
				key: 'jobStatus',
				name: 'Job Status',
				formatter(props) {
					return <StatusChip status={props.row.jobStatus == 'Successful' ? 'Success' : props.row.jobStatus == 'Failure' ? 'Failed' : props.row.jobStatus} columnName='jobStatus' />;
				},
				minWidth: 200
			},
			{
				key: 'execStatusTrend',
				name: 'Last 30 Execution Trend',
				formatter(props: any) {
					return <RagBar colors={getColorData(props.row.execStatusTrend ? props.row.execStatusTrend : '')} />;
				},
				minWidth: 200
			},
			{
				key: 'scheduleInfo',
				name: 'Schedule Info',
				formatter({ row }) {
					return (
						<RowTypography isSuccessor={!row.dependentData}>{row.scheduleInfo || '--'}</RowTypography>
					);
				},
				minWidth: 200,
				maxWidth: 500
			},
			{
				key: 'jobCondition',
				name: 'Job Condition',
				formatter({ row }) {
					return (
						<RowTypography isSuccessor={!row.dependentData}>{row.jobCondition === 'A' ? 'AND' : row.jobCondition === 'O' ? 'OR' : '--'}</RowTypography>
					);
				},
				minWidth: 150
			},
			{
				key: 'slaStatus',
				name: 'SLA Status',
				formatter(props) {
					const startDate = props.row.oDate;
					if (startDate) {
						return (
							<RowTypography isSuccessor={!props.row.dependentData}>
								{' '}
								{startDate ? moment(startDate).utc().format('MM-DD-YY') : '--'}{' '}
							</RowTypography>
						);
					}
					return '';
				},
				minWidth: 130
			},
			{
				key: 'scheduleInfo',
				name: 'Schedule Info',
				formatter({ row }) {
					return (
						<RowTypography isSuccessor={!row.dependentData}>{row.scheduleInfo || '--'}</RowTypography>
					);
				},
				minWidth: 200,
				maxWidth: 500
			},
			{
				key: 'jobCondition',
				name: 'Job Condition',
				formatter({ row }) {
					return (
						<RowTypography isSuccessor={!row.dependentData}>{row.jobCondition === 'A' ? 'AND' : row.jobCondition === 'O' ? 'OR' : '--'}</RowTypography>
					);
				},
				minWidth: 150
			},
			{
				key: 'slaStatus',
				name: 'SLA Status',
				formatter(props) {
					return <StatusChip status={props.row.slaStatus} />;
				},
				minWidth: 150
			},
		];
	}

	const getDays = (day: number) => {
		let days;
		if (day == 0) {
			days = 'Sun';
		} else if (day == 1) {
			days = 'Mon';
		} else if (day == 2) {
			days = 'Tue';
		} else if (day == 3) {
			days = 'Wed';
		} else if (day == 4) {
			days = 'Thur';
		} else if (day == 5) {
			days = 'Fri';
		} else if (day == 6) {
			days = 'Sat';
		}
		return days;
	};

	const jobPerformanceBarChartData = async (stdt: any = '', eddt: any = '') => {
		let params = {
			projectId,
			programId,
			startDate: stdt ? stdt : startDate,
			endDate: eddt ? eddt : endDate,
			hours: is24Hrs ? 24 : ''
		};

		setGrafanaChartLoading(true);
		let result = await getJobPerformanceChartData(params);
		if (result) {
			setGrafanaChartOptions(result.data.slaMetrics);
			setGrafanaChartLoading(false);
		}
	};
	let slaTimeObj = {
		slaTime: '',
		slaDate: 0
	}
	useEffect(() => {
		const activeId = document.getElementById('root');
		if (window.location.pathname.includes('/data-pipeline/projects')) {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
			}
		}



		getProjectDetails();
	}, []);

	const getProjectDetails = async () => {
		setLoading(true);
		let result = await getProjectById({ projectId, programId });
		setLoading(false);

		const projectDetails = result.data?.programData

		console.log("HEREEE", result.data?.programData)
		slaTimeObj = {
			slaTime: projectDetails[0].SLA_Time || '',
			slaDate: projectDetails[0].SLA_Date || 0
		}
		setProjectDetail(projectDetails[0]);
		setSlaStatus(projectDetails[0].SLA_Status)

		if (projectDetails[0].JobCount) {
			projectMappedJobScheduleFunc('1', '100', startDate, endDate, parseFilter(filterInfo), sortingInfo);
			// getKpiCount(startDate, endDate)
		}
	};

	const passInfoToParent = (data: any) => {
		getProjectDetails();
	};

	const handleEditClose = (closeType: string) => {
		setOpenModal({
			isOpen: false
		});
		if (closeType == 'updated') {
			getProjectDetails();
		}
	};

	const onCreateProgramClick = (event: any) => {
		setOpenModal({
			isOpen: true,
			type: 'Project',
			action: 'CREATE'
		});
	};

	function convertToTimeFormat(num: number) {
		// Ensure the number is a string with leading zeros if necessary
		const numStr = num.toString().padStart(4, '0');

		// Extract hours and minutes
		const hours = numStr.slice(0, 2);
		const minutes = numStr.slice(2);

		// Format as 'HH:MM'
		return `${hours}:${minutes}`;
	}

	const handleSorting = (sortingInfo: any) => {
		projectMappedJobScheduleFunc(page, pageSize, startDate, endDate, parseFilter(filterInfo), sortingInfo);
		setSortingInfo(sortingInfo)

	};
  
	const handlePageChange = (event: React.ChangeEvent<unknown>, value: string) => {
		console.log("HEREEEE", value, (Number(pageSize) * Number(value)) - Number(pageSize), Number(pageSize), jobExeList.length)
		
		// if (jobExeList.length == paginationData.totalElements) {
		// 	reqData = jobExeList
		// } else {
		// 	setJobExeList(reqData)
		// }
		setPage(value)
		let data = jobExeList.slice((Number(pageSize) * Number(value)) - Number(pageSize), ((Number(pageSize) * Number(value)) - Number(pageSize) + Number(pageSize)))
		setjobSchedulesList(data)
		// let data = jobExeList.splice(1, value)
		// setjobSchedulesList(data)
		// projectMappedJobScheduleFunc(value, pageSize, startDate, endDate, parseFilter(filterInfo), sortingInfo);
	};
	const handlepageSizeChange = (event: any) => {
		
		let data = jobExeList.slice(0, event.target.value)
		setjobSchedulesList(data)

		setPaginationData({
			...paginationData,
			totalPages: Math.ceil(jobExeList.length / Number(pageSize)),
			
		} )
		setPageSize(event.target.value)
		
		// projectMappedJobScheduleFunc(page, event.target.value, startDate, endDate, parseFilter(filterInfo), sortingInfo);
	};

	async function getSlaCountData(start: string, end: string) {
		setSlaLoading(true);
		const res: any = await getSlaCountDetail(projectId, start, end, is24Hrs);
		let objData = {
			slaMet: 0,
			slaMissed: 0,
			slaPending: 0,
			completed: 0,
			failed: 0
		};
		res.data.map((obj: any) => {
			objData.slaMet += obj.slaMet;
			objData.slaMissed += obj.slaMissed;
			(objData.slaPending += obj.slaPending), (objData.completed += obj.completed), (objData.failed += obj.failed);
		});
		// setSlaReport({
		// 	...objData
		// });
		setSlaLoading(false);
	}

	const removeDuplicates = (arr: any) => {
		const seenIds = new Set();

		return arr.filter((item: any) => {
			const duplicate = seenIds.has(item.id);
			seenIds.add(item.id);
			return !duplicate; // Only return items with unique ids
		});
	};

	// async function projectMappedJobScheduleFunc(
	// 	pageNumber: string,
	// 	pageSize: string,
	// 	startDate: string,
	// 	endDate: string,
	// 	filterData: any,
	// 	sortingInfo: any
	// ) {
	// 	setTableLoading(true);
	// 	const res = await getMappedJobScheduleList(projectId, pageNumber, pageSize, startDate, endDate, filterData, is24Hrs, sortingInfo);
	// 	if (res.success) {
	// 		setTableLoading(false);
	// 		let jobArr = res.data.scheduleList.filter((obj: any) => !obj.successor || obj.successor === null);
	// 		let depJobArr = res.data.scheduleList.filter((obj: any) => obj.successor && obj.successor !== null);
	// 		let reqData: any = [];
	// 		setSlaData({
	// 			...slaData,
	// 			...res.data.slaData
	// 		});
	// 		const filters = res.data.filterOptions;
	// 		let formatedDates: any = [];
	// 		Object.entries(filters).filter((d: any) => {
	// 			if (d[0] == 'date') {
	// 				formatedDates = d[1].map((s: any) => moment(s).utc().format('YYYY-MM-DD'));
	// 			}
	// 		});
	// 		filters['date'] = formatedDates;

	// 		Object.keys(filters).map((key: any, index: number) => {
	// 			if (key === 'slaStatus') {
	// 				filters['sla'] = [];
	// 				filters[key].map((val: any) => {
	// 					switch (val) {
	// 						case 1:
	// 							filters['sla'].push('SLA Met');
	// 							break;
	// 						case 0:
	// 							filters['sla'].push('SLA Missed');
	// 							break;
	// 						case 2:
	// 							filters['sla'].push('Scheduled');
	// 							break;
	// 						default:
	// 							break;
	// 					}
	// 				});
	// 				filters['slaStatus'] = filters['sla'];
	// 				delete filters['sla'];
	// 			} else if (key === 'status') {
	// 				filters['jobStatus'] = [];
	// 				filters[key].map((val: any) => {
	// 					if (val === 'Blank') {
	// 						filters['jobStatus'].push('Scheduled');
	// 					} else {
	// 						filters['jobStatus'].push(val);
	// 					}
	// 				});
	// 				delete filters['status'];
	// 			} else if (key === 'Platform_Id') {
	// 				filters[key].map((val: any, index: number) => {
	// 					if (val === 1) {
	// 						filters['Platform_Id'][index] = 'Teradata';
	// 					}
	// 				});
	// 			} else if (key === 'startAt') {
	// 				filters['actualStartTime'] = filters['startAt'];
	// 			} else if (key === 'endAt') {
	// 				filters['actualEndTime'] = filters['endAt'];
	// 			} else if (key === 'Start_Time') {
	// 				filters['scheduleStartTime'] = filters['Start_Time'];

	// 				filters['scheduleStartTime'].map((data: any, i: number) => {
	// 					typeof data == 'number'
	// 						? (filters['scheduleStartTime'][i] = convertToTimeFormat(data))
	// 						: (filters['scheduleStartTime'][i] = data);
	// 				});
	// 			} else if (key === 'Normal_Completion_Time') {
	// 				filters['Normal_Completion_Time'].map((data: any, i: number) => {
	// 					typeof data == 'number'
	// 						? (filters['Normal_Completion_Time'][i] = convertToTimeFormat(data))
	// 						: (filters['Normal_Completion_Time'][i] = data);
	// 				});
	// 			}
	// 		});
	// 		setFilterOptions(filters);
	// 		if (is24Hrs) {
	// 			jobArr.map((data: any) => {
	// 				data['dependentData'] = [];
	// 				data['isExpanded'] = false;
	// 				depJobArr.map((dep: any) => {
	// 					if (dep.successor === data.projectScheduleId) {
	// 						// dep['Dep_Job_Name'] = dep['Job_Name']
	// 						// delete dep['Job_Name']
	// 						data.dependentData.push(dep);
	// 					}
	// 				});
	// 				data['dependentData'] = data.dependentData.length ? data.dependentData : false;
	// 				reqData.push(data);
	// 			});
	// 		} else {
	// 			jobArr.map((data: any) => {
	// 				data['dependentData'] = [];
	// 				data['isExpanded'] = false;
	// 				depJobArr.map((dep: any) => {
	// 					if (
	// 						dep.successor === data.projectScheduleId &&
	// 						moment(dep.Date).format('YYYY-MM-DD') === moment(data.Date).format('YYYY-MM-DD')
	// 					) {
	// 						// reqData.push(dep)
	// 						// dep['Dep_Job_Name'] = dep['Job_Name']
	// 						// delete dep['Job_Name']
	// 						data.dependentData.push(dep);
	// 					}
	// 				});
	// 				data['dependentData'] = data.dependentData.length ? data.dependentData : false;
	// 				reqData.push(data);
	// 			});
	// 		}

	// 		reqData = removeDuplicates(reqData);

	// 		// if (sortingInfo.sortBy != 'Date') {
	// 		//     reqData.sort(function (a: any, b: any) {
	// 		//         // First, compare by date
	// 		//         const dateDiff = new Date(b.Date).getTime() - new Date(a.Date).getTime();

	// 		//         // If dates are equal, compare by Days
	// 		//         if (dateDiff === 0) {
	// 		//           const daysDiff = a.Days - b.Days;

	// 		//           // If Days are also equal, compare by Start_Time
	// 		//           if (daysDiff === 0) {
	// 		//             return a.Start_Time - b.Start_Time; // Assuming Start_Time is a comparable number or timestamp
	// 		//           }

	// 		//           return daysDiff;
	// 		//         }

	// 		//         return dateDiff;
	// 		//       });
	// 		// }

	// 		setjobSchedulesList(reqData);
	// 		setPaginationData(res.data.page);
	// 		setPage(res.data.page.currentPage);
	// 		setPageSize(res.data.page.size);
	// 		// const pagination = {"size":100,"totalElements": reqData.length || res.data.scheduleList.length,"totalPages": 1,"currentPage":1};
	// 		// setPaginationData(pagination);
	// 		// setPage('1');
	// 		// setPageSize('100');
	// 	}
	// }

	const onClickWidget = (type: string) => {
		jobStatusParam = type;
		if (type == 'Total Jobs') {
			jobStatusParam = '';
		} else if (type == 'Delayed') {
			jobStatusParam = 'Delay Start';
		} else if (type == 'Long Running') {
			jobStatusParam = 'Long Running';
		} else if (type == 'Success') {
			jobStatusParam = 'Successful'
		} else if (type == 'Failed') {
			jobStatusParam = 'Failure'
		}
		else if (type == 'Failed To Start') {
			jobStatusParam = 'Failed to Start'
		}
		projectMappedJobScheduleFunc('1', pageSize, startDate, endDate, parseFilter(filterInfo), sortingInfo);
	}

	async function getKpiCount(
		
		startDate: string,
		endDate: string,
	
	){
		setKpiLoading(true)
		const res = await getDpJobKpidata(projectId, startDate, endDate);
        if(res.success){
         let objData = {
				slaMet: res.data.kpiData.sla_met,
				slaMissed: res.data.kpiData.sla_missed,
				slaPending: res.data.kpiData.sla_pending,
				completed: res.data.kpiData.success,
				failed: res.data.kpiData.failed,
				longRunning: res.data.kpiData.longRunning,
				scheduled: res.data.kpiData.scheduled,
				delayStart: res.data.kpiData.delayStart,
				failedToStart: res.data.kpiData.failedToStart,

			};

			setSlaReport({
				...objData
			});
		}
		setKpiLoading(false)
	}

	async function projectMappedJobScheduleFunc(
		pageNumber: string,
		pageSize: string,
		startDate: string,
		endDate: string,
		filterData: any,
		sortingInfo: any
	) {
		setTableLoading(true);
		if (!Object.keys(filterOptions).length || !Object.keys(filterOptions).includes('jobName')) {
			setKpiLoading(true)
		}
		let slaTime = projectDetail?.SLA_Time || slaTimeObj.slaTime || ''
		let slaDate = projectDetail?.SLA_Date || slaTimeObj.slaDate || 0
		const res = await getMappedJobScheduleList(projectId, pageNumber, pageSize, startDate, endDate, filterData, is24Hrs, sortingInfo, slaTime, slaDate, jobStatusParam);
		if (res.success) {
			setjobSchedulesList(res.data.reqResult);
			setTableLoading(false);
			setKpiLoading(false)
			setGrafanaChartLoading(false)
			res.data.reqResult.map((data: any, index: number) => {
				res.data.reqResult[index] = { ...data, id: index + 1 }
			})
			setJobExeList(res.data.reqResult)
			// setjobSchedulesList(res.data.reqResult.slice(0, res.data.page.size));
			
			console.log("PROJJJJJ", projectDetail)
			// setSlaStatus(res.data.slaStatus)
			// setProjectDetail({
			// 	...projectDetail,
			// 	slaStatus: res.data.slaStatus
			// })
			// setGrafanaChartOptions(res.data.chartData);
		if (!Object.keys(filterOptions).length || !Object.keys(filterOptions).includes('jobName')) {
			let objData = {
				slaMet: res.data.kpiData.sla_met,
				slaMissed: res.data.kpiData.sla_missed,
				slaPending: res.data.kpiData.sla_pending,
				completed: res.data.kpiData.success,
				failed: res.data.kpiData.failed,
				longRunning: res.data.kpiData.longRunning,
				scheduled: res.data.kpiData.scheduled,
				delayStart: res.data.kpiData.delayStart,
				failedToStart: res.data.kpiData.failedToStart,

			};

			setSlaReport({
				...objData
			});
		}
			setPaginationData(res.data.page);
			setPage(res.data.page.currentPage);
			setPageSize(res.data.page.size);
			setFilterOptions(filterData)

		}
	}


	const formatDate = (isoString: string) => {
		const date = new Date(isoString);

		const padTo2Digits = (num: any) => String(num).padStart(2, '0');

		const month = padTo2Digits(date.getUTCMonth() + 1); // getUTCMonth() returns months from 0-11
		const day = padTo2Digits(date.getUTCDate());
		const year = String(date.getUTCFullYear()).slice(-2); // Get last two digits of year
		const hours = padTo2Digits(date.getUTCHours());
		const minutes = padTo2Digits(date.getUTCMinutes());
		const seconds = padTo2Digits(date.getUTCSeconds());

		return `${month}-${day}-${year}`;
	};

	const handleAction = (module: string, type: string, data: any, e: any) => {
		e.preventDefault();
		e.stopPropagation();
		handleMenuClose(e);
		data = {
			...data,
			ProgramName: projectDetail?.program_Name || window.location.pathname.split('/')[5].replace(/%20/g, ' ')
		};
		switch (type) {
			case 'edit':
				setOpenModal({
					isOpen: true,
					type: module,
					action: 'EDIT',
					from: 'edit',
					data: module === 'Project' ? projectDetail : data
				});
				break;
			case 'delete':
				setOpenModal({
					isOpen: true,
					type: module,
					action: 'DELETE',
					from: 'delete',
					data: data
				});
				break;
			case 'view':
				window.location.href = `/data-pipeline/search-jobs/${programId}/${projectId}/${encodeURIComponent(
					program || ''
				)}/${encodeURIComponent(project || '')}`;
				break;
			default:
				break;
		}
	};

	const seriesData = [
		{ name: 'SLA MET', dataKey: 'sla_met', color: '#43CC14' },
		{ name: 'SLA MISSED', dataKey: 'sla_missed', color: '#ff7f00' }
	];

	const getHrsMin = (totalMinutes: number) => {
		totalMinutes = totalMinutes / 60;
		const days = Math.floor(totalMinutes / (24 * 60));
		const hours = Math.floor((totalMinutes % (24 * 60)) / 60);
		const minutes = totalMinutes % 60;

		// Format the result
		let result = '';
		if (days > 0) result += `${days} day${days > 1 ? 's' : ''} `;
		if (hours > 0) result += `${hours} hr${hours > 1 ? 's' : ''} `;
		if (minutes > 0) result += `${minutes.toFixed(2)} min`;

		let time = result.trim()
		return time
	}

	return (
		<Box bgcolor="blue.darker" mt="60px">
			<PageHeader
				icon={DashboardIcon}
				title={[
					{
						text: 'Programs',
						link: '/data-pipeline/programs'
					},
					{
						text: decodeURIComponent(window.location.pathname.split('/')[5]),
						link: `/data-pipeline/programs/${window.location.pathname
							.split('/')[3]
							.replace(/%20/g, ' ')}/${window.location.pathname.split('/')[5].replace(/%20/g, ' ')}`
					},
					{
						text: decodeURIComponent(window.location.pathname.split('/')[6])
					}
				]}
				childrenContainerProps={{
					minWidth: isAllowed ? '395px' : '230px'
				}}
			>
				<ReactDateRangeFilter startingDate={projDate} setStartDate={setStartDate} setEndDate={setEndDate} handleDateSelect={handleDateSelect} />
				{isAllowed && <Link
					to={`/data-pipeline/search-jobs/${programId}/${projectId}/${encodeURIComponent(
						program || ''
					)}/${encodeURIComponent(project || '')}`}
				>
					<Button variant="pepwiseSecondary" startIcon={<AddIcon />}>
						ADD JOBS
					</Button>
				</Link>}
			</PageHeader>
			<Dialog open={openModal.isOpen} onClose={handleEditClose} fullWidth={true} maxWidth="md">
				<DataPipelineModal
					handleEditClose={handleEditClose}
					programData={openModal}
					passInfoToParent={passInfoToParent}
				/>
			</Dialog>

			<Drawer anchor="bottom" open={isCyclicExeData} onClose={() => setIsCyclicExeData(false)}>
				<CyclicExecutions curRow={curRow} setIsCyclicExeData={setIsCyclicExeData} />
			</Drawer>

			<Stack spacing={2} p={2} mt={2}>
				<Grid container spacing={1}>
					<Grid item xs={12} lg={5}>
						<DataInfoSecondary
							infoCards={[
								{
									label: 'Project Name',
									value: projectDetail?.Project_Name,
									isLoading: loading,
									valueProps: {
										lineHeight: '24px'
									}
								},
								// `${projectDetail?.SLA_Date ? moment().tz("America/Chicago").add(1, 'days').format("YYYY-MM-DD") : moment().tz("America/Chicago").format("YYYY-MM-DD")}`+ '  ' + 
								{
									label: 'SLA Time (CST)',
									value: `${projectDetail?.SLA_Time
										? projectDetail?.SLA_Time < 10
											? `000${projectDetail?.SLA_Time}`.replace(/(.{2})$/, ':$1')
											: projectDetail?.SLA_Time < 100
												? `00${projectDetail?.SLA_Time}`.replace(/(.{2})$/, ':$1')
												: projectDetail?.SLA_Time.toString().replace(/(.{2})$/, ':$1')
										: projectDetail?.SLA_Time == 0 ? '00:00' : 'NA'}`,
									isLoading: loading,
									valueProps: {
										lineHeight: '24px'
									},
									minWidth: '150px'
								},
								{
									label: 'SLA Status',
									value: slaStatus === 'In Progress' ? '' : slaStatus,
									isLoading: tableLoading,
									valueProps: {
										lineHeight: '24px'
									},
									minWidth: '130px'
								},

							]}
							infoTooltip={{
								titleValues: [
									{
										label: 'Alias',
										value: projectDetail?.Friendly_Name ? projectDetail.Friendly_Name : loading ? 'Loading...' : 'NA'
									},
									{
										label: 'Project Owner',
										value: projectDetail?.Project_Owner ? projectDetail.Project_Owner : loading ? 'Loading...' : 'NA'
									},
									{
										label: 'Description',
										value: projectDetail?.Project_Description ? projectDetail.Project_Description : loading ? 'Loading...' : 'NA'
									},
									{
										label: 'Sectors',
										value: projectDetail?.Sectors ? projectDetail.Sectors : loading ? 'Loading...' : 'NA'
									}
								],
								dateValues: [
									{ label: 'Date Created', value: projectDetail?.Created_Dt ?? 'NA' },
									{ label: 'Last Updated', value: projectDetail?.Updated_Dt ?? 'NA' }
								]
							}}
							infoOptionMenu={{
								onClick: handleClick,
								onClose: handleMenuClose,
								menuItems: [
									{
										key: 'edit',
										label: 'Edit',
										icon: <EditIcon />,
										onMenuClick: (e) => handleAction('Project', 'edit', projectDetail, e)
									},
									{
										key: 'delete',
										label: 'Delete',
										icon: <DeleteIcon />,
										onMenuClick: (e) => handleAction('Project', 'delete', projectDetail, e)
									}
								]
							}}
							isAdmin={isAllowed ? true : false}
						/>
					</Grid>
					<Grid item container xs={12} lg={7} spacing={1}>
						{[
							{
								title: 'Total Jobs',
								value: projectDetail?.JobCount ? projectDetail?.JobCount : 0,
								isLoading: loading,
								color: ''
							},


							{
								title: 'Scheduled',
								value: slaReport.scheduled || 0,
								isLoading: kpiLoading,
								color: 'paleGray'
							},
							{
								title: 'Delayed',
								value: slaReport.delayStart || 0,
								isLoading: kpiLoading,
								color: 'paleRed'
							},
							{
								title: 'Long Running',
								value: slaReport.longRunning || 0,
								isLoading: kpiLoading,
								color: 'paleYellow'
							},
							{
								title: 'Failed',
								value: slaReport.failed || 0,
								isLoading: kpiLoading,
								color: 'red'
							},
							{
								title: 'Success',
								value: slaReport.completed || 0,
								isLoading: kpiLoading,
								color: 'blue'
							},
							{
								title: 'Failed To Start',  
								value: slaReport.failedToStart || 0,  
								isLoading: kpiLoading,
								color: 'purple'  
							  }
						].map(({ title, value, color, isLoading }) => (
							<Grid key={title} item xs={3} lg={1.7} sm={1.7} display="flex" onMouseEnter={() => value && setHoveredItem(title)} onMouseLeave={() => setHoveredItem(null)}>
								<DataInfoColorWidget title={title} style={{ cursor: value ? 'pointer' : 'default', border: hoveredItem === title ? '2px solid #009BD8' : 'none', borderRadius: '4px', transition: 'border 0.2s ease-in-out'}} onClick={(e) => value ? onClickWidget(title) : false} value={value} boxColor={color} isLoading={isLoading} />
							</Grid>	
						))}
					</Grid>
				</Grid>

				<Box pl={2}>
					{dependentInfoLoading && <Loader />}
					{!tableLoading && jobSchedulesList.length > 0 ? (
						// <CustomizedDataGrid
						// 	tableColumns={getColumns()}
						// 	tableData={jobSchedulesList}
						// 	tableType="job-schedules-1"
						// 	defaultColumnNames={defaultColumnNames}
						// 	filterDataOptions={filterOptions}
						// 	onSorting={handleSorting}
						// 	currSorting={sortingInfo}
						// 	onFilterCheck={handleFilter}
						// 	currFilter={filterInfo}
						// 	page={page}
						// 	pageSize={pageSize}
						// 	paginationData={paginationData}
						// 	listLength={jobSchedulesList.length}
						// 	handlepageSizeChange={handlepageSizeChange}
						// 	handlePageChange={handlePageChange}
						// 	isDateRangeFilter={false}
						// 	isDisabled={false}
						// 	isFetching={true}
						// 	showExport={false}
						// 	isShowMoreButton
						// />
						<Stack>
							<Typography variant="pageTitle" style={{ fontSize: '16px', fontWeight: '700', position: 'absolute', marginTop: '10px' }}>Job Executions</Typography>
							<Box>
								<CustomizedDataGrid
									tableColumns={getColumns()}
									tableData={jobSchedulesList}
									tableType="job-schedules-1"
									defaultColumnNames={defaultColumnNames}
									filterDataOptions={filterOptions}
									onSorting={handleSorting}
									currSorting={sortingInfo}
									onFilterCheck={handleFilter}
									currFilter={filterInfo}
									page={page}
									pageSize={pageSize}
									paginationData={paginationData}
									listLength={jobSchedulesList.length}
									handlepageSizeChange={handlepageSizeChange}
									handlePageChange={handlePageChange}
									isDateRangeFilter={false}
									isDisabled={false}
									isFetching={tableLoading}
									showExport={false}
									isShowMoreButton
									onFilterClick={handleAndGetFilterOptions}
								/>
							</Box>
							{/* <Box marginTop={'10px'}>
					  { !grafanaChartLoading && Object.keys(grafanaChartOptions).length ? (
					<BarChartWidget
					title={'Job Performance'}
					isLoading={grafanaChartLoading}
					data={grafanaChartOptions}
					seriesData={seriesData}
					/>
					) : (
						<Loader />
					)
				}
					  </Box> */}
						</Stack>

					) : !tableLoading && jobSchedulesList.length == 0 && projectDetail.JobCount == 0 ? (
						<ShowInfoWidget 
							title="No Jobs has been added yet" 
							height="50vh" 
							button="+ ADD Job" 
							onClick={() => {
								if (isAllowed) {
								  navigate(
									`/data-pipeline/search-jobs/${programId}/${projectId}/${encodeURIComponent(
									  program || ''
									)}/${encodeURIComponent(project || '')}`
								  );
								}
							  }}
							  />
					) : !tableLoading && jobSchedulesList.length == 0 ? (
						<><br /> <br /> <Typography textAlign='center'>Data will be displayed based on selected filters.</Typography></>
					) : tableLoading && (
						<Loader />
					)}
				</Box>
			</Stack>
		</Box>
	);
};

export default ProjectDetails;
