import { Box, Typography, Tooltip, Stack, debounce } from '@mui/material';
import ReactECharts from 'echarts-for-react';
import ToolTipIcon from '../../assets/info-icon.svg';
import LoaderComponent from 'components/Loader';
import { useEffect, useRef, useState } from 'react';
import { ProgressChartWidgetProps } from './type';
import { ECElementEvent } from 'echarts';
import { getPieChartOptions } from './chartWidgetUtility';

const defaultOptions = {
	graphic: [
		{
			type: 'circle',
			left: 'center',
			top: 'center',
			shape: {
				r: 100
			},
			style: {
				fill: '#71758f66'
			}
		}
	],
	title: {
		show: true,
		textStyle: {
			color: '#bcbcbc',
			fontWeight: 'normal',
			fontSize: 16
		},
		text: 'No data',
		left: 'center',
		top: 'center'
	},
	backgroundColor: '#053367'
};

const PieChartWidget = ({
	isLoading = false,
	isOverlayWidget = false,
	title = '',
	tooltipLabel = null,
	seriesData,
	minTitleHeight = '50.5px',
	handleEvent
}: Readonly<ProgressChartWidgetProps>) => {
	const chartContainerRef = useRef<HTMLDivElement>(null);
	const [windowSize, setWindowSize] = useState(window.innerWidth);
	const [gridLeft, setGridLeft] = useState('5%');

	useEffect(() => {

		// re-render chart to fix alignment when resizing the browser
		const handleResize = debounce(() => {
			if (chartContainerRef.current) {
				const containerWidth = chartContainerRef.current.offsetWidth;
				const calculatedLeft =
					containerWidth < 420
						? '18%'
						: containerWidth < 600
						? '15%'
						: containerWidth < 820
						? '12%'
						: containerWidth < 920
						? '9%'
						: containerWidth < 1260
						? '7%'
						: '5%';
				setGridLeft(calculatedLeft);
				setWindowSize(window.innerWidth);
			}
		}, 200);

		handleResize();
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const result = getPieChartOptions(seriesData, chartContainerRef?.current?.offsetWidth);
	
	const adjustedOption = !seriesData.length
			? defaultOptions
			: {
					...result,
					grid: {
						...result,
						left: gridLeft
					}
			  };

	return (
		<Box display="flex" flexGrow={1} height="100%" position="relative">
			<Stack
				minHeight="100px"
				p={2}
				bgcolor="primary.main"
				fontFamily="Montserrat"
				borderRadius="5px"
				flexGrow={1}
				// {...(handleEvent && { onClick: handleEvent, sx: { cursor: 'pointer' } })}
			>
				<Stack direction="row" gap={2} justifyContent="space-between" alignItems="start" minHeight={minTitleHeight}>
					<Stack direction="row" gap={1.5} alignItems="start">
						<Typography variant={isOverlayWidget ? 'overlayWidgetTitle' : 'widgetTitle'}>{title}</Typography>
						{!!tooltipLabel && (
							<Tooltip title={tooltipLabel} arrow>
								<img src={ToolTipIcon} />
							</Tooltip>
						)}
					</Stack>
				</Stack>
				<Stack flexGrow={1} justifyContent="center" key={windowSize} ref={chartContainerRef} minHeight={"450px"}>
					{!isLoading ? (
						<ReactECharts
							style={{ width: '100%', height: '100%' }}
							option={adjustedOption}
							onEvents={{
								click: (params: ECElementEvent) => {
									if (handleEvent && seriesData.length) {
										handleEvent(params);
									}
								}
							}}
							opts={{ renderer: 'canvas', width: 'auto' }}
							notMerge={true}
							lazyUpdate={true}							
						/>
					) : (
						<LoaderComponent />
					)}
				</Stack>
			</Stack>
		</Box>
	);
};

export default PieChartWidget;
