import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';

// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import PickersDay, { PickersDayProps, pickersDayClasses } from '@mui/lab/PickersDay';
import { Box, Stack } from '@mui/material';
import './header.scss';
import useStyles from './headerStyles';
import JobsIcon from '../../assets/ri_file-list-fill.svg';
import SectorsIcon from '../../assets/mingcute_building-2-fill.svg';
import SystemIcon from '../../assets/ri_computer-fill.svg';
import ProcessIcon from '../../assets/ion_sync-circle.svg';
// // import UsersIcon from '../../assets/fa-solid_users-cog.svg';
import Jobschedule from '../../assets/material-symbols_schedules_white.svg';
import JobExecutions from '../../assets/material-symbols_jobschedule.svg';
import AdministrativeIcon from '../../assets/material-symbols_Admindashboard_unselected.svg';
import OperationalIcon from '../../assets/material-symbols_Operationdashboard_unselected.svg';
import NotificationBar from './notificationBar';
import { ReactComponent as ReloadIcon } from '../../assets/reload.svg';
import { HeaderContext } from '../../Context/HeaderContext';
import Loader from '../Loader/loader';
import { platformCostObsDashboard, DataGrowthDashboard } from '../../service/config';
import TeradataLogo from '../../assets/simple-icons_teradata.svg';
import DataGrowthPowerBI from '../../assets/material-symbols_chart-data_growth.svg';
import PlatformCostLogoPowerBI from '../../assets/ri_money-dollar-circle-fill.svg';
import DatabricksIcon from '../../assets/databricks.svg';
import DashboardIcon from '../../assets/material-symbols_dashboard.svg';
import CustomDashboardIcon from '../../assets/Custom_Dashboard.svg'
import ResourceOptIcon from '../../assets/Resource Optimization.svg';
import DataPipelineIcon from '../../assets/data-pipeline-logo.svg'
import CostSummaryIcon from '../../assets/Cost Summary.svg';
import GovernanceIcon from '../../assets/Governance.svg';
import PerformanceIcon from '../../assets/Performance.svg';
import TableauLogo from '../../assets/tableau-filled.svg';
import PowerBILogo from '../../assets/simple-icon_powerbi.svg';
import CapacityLogo from '../../assets/material-symbols_capacities.svg';
import DatasetLogo from '../../assets/powerbi_dataset.svg';
import UsageLogo from '../../assets/powerbi_usage.svg';
import DataGrowthLogo from '../../assets/powerbi_datagrowth.svg';
import PowerBI from '../../assets/PowerBI.svg';
import InfrastructureLogo from '../../assets/powerbi_infrastructure.svg';
import CapacityObservabilityLogo from '../../assets/powerbi_capacity_obs.svg';
import CatalogueLogo from '../../assets/Catalogue&Quality.svg';
import DMFLogo from '../../assets/simple-icons_DMF.svg';
import VVLogo from '../../assets/vv.svg';
import MLLogo from '../../assets/ml.svg';
import IBPMonitoring from '../../assets/eos-icons_monitoring.svg';
import IBPDataQuality from '../../assets/DQ.svg';
import IBPExecutiveDashboardIcon from '../../assets/material-symbols_table-chart-view-sharp.svg';

import VVPowerBILogo from '../../assets/PowerBI.svg';
import VVTableauLogo from '../../assets/Tableau.svg';
import PrestoLogo from '../../assets/simple-icons_presto.svg';
import CCLogo from '../../assets/simple-icons_cc.svg';
import IBPLogo from '../../assets/ibp.svg';
import DollarIcon from '../../assets/dollar-circle.svg';
import ControlTestIcon from '../../assets/Control Dashboard_Unselected.svg';
import AlteryxIcon from '../../assets/Alteryx.svg';
import TrifactaIcon from '../../assets/Trifacta.svg';
import DataGrowthDatabricksIcon from '../../assets/datagrowth-databricks-symbols.svg';
import DataGrowthPrestoIcon from '../../assets/datagrowth-presto-symbols.svg';
import DataGrowthTableauIcon from '../../assets/datagrowth-tableau-symbols.svg';
import IBPPlatform from '../../assets/ibp_platform.svg';
import IBPDQ from '../../assets/ibp_DQ.svg';
import DataEstateLogo from '../../assets/dataestate-logo.svg'
import StorageIcon from '../../assets/storage-summary.svg';
import ProcessingIcon from '../../assets/data-processing.svg';
import BIApplicationIcon from '../../assets/biApplication.svg';
import CostIcon from '../../assets/dataestate-cost.svg';
import serviceRequestIcon from '../../assets/service-request.svg';
import QueryIcon from '../../assets/query.svg';
import FinOpsLogo from '../../assets/FinOps_small.svg'
import ProgramIcon from '../../assets/program.svg';
import DetailsIcon from '../../assets/finop-detail.svg';
import NextArrowIcon from '../../assets/page_next.svg';
import moment from 'moment';
import { FetchFinOpsOverviewCloudCost, fetchDataEstateLastSyncData } from '../../service/service';
import IncidentIcon from '../../assets/Incident Impact Analysis.svg';
import { setTeradataStorageLastSyncDate, setAzureCostSummaryLastSyncDate, setDemetricsLastSyncDate } from 'Context/contexts/cacheData/DataEstate';
import { useCacheDataController } from 'Context/contexts/CacheDataContext';
import ResourceMappingIcon from '../../assets/Resource Metadata Update.svg';

const Header = (notificationData: any) => {
	var todaysDate = moment([new Date().getFullYear(), new Date().getMonth()+1, new Date().getDate()]);

	// [context Api]
	const { lastSync } = useContext(HeaderContext);
	const [controller, dispatch] = useCacheDataController();
	const { teradataStorageLastSyncDate, azureCostSummaryLastSyncDate, deMetricsLastSyncDate } = controller;
	const classes = useStyles();
	const location = useLocation();

	if (location.pathname === '/' || location.pathname === '/userLogin' || location.pathname === '/signup') {
		return null;
	}
	const lastMonthName = moment().subtract(1, 'month').format('MMMM');
	// [Detect route change]
	const [loading, setLoading] = React.useState(false);
	const [azureCostSummaryLastSyncDate1, setAzureCostSummaryLastSyncDate1] = React.useState<string | null>('');
	//const [deMetricsLastSyncDate, setDeMetricsLastSyncDate] = React.useState<string | null>('');
	const [teradataDailyLastSyncDate, setTeradataDailyLastSyncDate] = React.useState<string | null>('');

	React.useEffect(() => {
		let lastSyncDate = 0;
		// set 2 local storage and update the last sync date
		if (localStorage.getItem('fetchAzureCoSumLastSyncDate')) {
			const lastSyncDate: any = localStorage.getItem('fetchAzureCoSumLastSyncDate');
			// check if stored data is more than 24 hrs
			const hrs = (new Date().getTime() - new Date(lastSyncDate).getTime()) / 60000;
			if ((hrs/60) >= 24) {
				localStorage.removeItem('azureCoSumLastSyncDate')
			}
		}
		if (localStorage.getItem('fetchdeMetricsLastSyncDate')) {
			const lastSyncDate: any = localStorage.getItem('fetchdeMetricsLastSyncDate');
			// check if stored data is more than 24 hrs
			const hrs = (new Date().getTime() - new Date(lastSyncDate).getTime()) / 60000;
			if ((hrs/60) >= 24) {
				localStorage.removeItem('deMetricsLastSyncDate')
			}
		}
		if (localStorage.getItem('fetchteradataDailyLastSyncDate')) {
			const lastSyncDate: any = localStorage.getItem('fetchteradataDailyLastSyncDate');
			// check if stored data is more than 24 hrs
			const hrs = (new Date().getTime() - new Date(lastSyncDate).getTime()) / 60000;
			if ((hrs/60) >= 24) {
				localStorage.removeItem('teradataDailyLastSyncDate')
			}
		}
		if (localStorage.getItem('fetchteradataStorageLastSyncDate')) {
			const lastSyncDate: any = localStorage.getItem('fetchteradataStorageLastSyncDate');
			// check if stored data is more than 24 hrs
			const hrs = (new Date().getTime() - new Date(lastSyncDate).getTime()) / 60000;
			if ((hrs/60) >= 24) {
				localStorage.removeItem('teradataStorageLastSyncDate')
			}
		}
		
		if (!localStorage.getItem('azureCoSumLastSyncDate') && window.location.pathname.includes('/dataestate/')) {
			FetchFinOpsOverviewCloudCost('lastSync')
			.then((res) => {
				lastSyncDate = res?.data?.result[0]?.lastSyncTime;
				const lastDays = moment([new Date(lastSyncDate).getFullYear(), new Date(lastSyncDate).getMonth()+1, new Date(lastSyncDate).getDate()]);
				const azureCoSumLastSyncDate = todaysDate.diff(lastDays, 'days') == 0 ? 'Today' : todaysDate.diff(lastDays, 'days') > 1 ? (todaysDate.diff(lastDays, 'days') + ' days ago') : (todaysDate.diff(lastDays, 'days') + ' day ago');
				localStorage.setItem('azureCoSumLastSyncDate', azureCoSumLastSyncDate);
				localStorage.setItem('fetchAzureCoSumLastSyncDate', new Date().toISOString());
				setAzureCostSummaryLastSyncDate(dispatch, azureCoSumLastSyncDate)
			})
			.catch((error) => {
				console.error(error);
			});
		} else {
			setAzureCostSummaryLastSyncDate(dispatch, localStorage.getItem('azureCoSumLastSyncDate'));
		}

		if (!localStorage.getItem('deMetricsLastSyncDate') && window.location.pathname.includes('/dataestate/')) {
			fetchDataEstateLastSyncData('de-metrics')
			.then((res) => {
				lastSyncDate = res?.data?.result[0]?.lastSyncTime;
				const lastDays = moment([new Date(lastSyncDate).getFullYear(), new Date(lastSyncDate).getMonth()+1, new Date(lastSyncDate).getDate()]);
				const resLastSyncDate = todaysDate.diff(lastDays, 'days') == 0 ? 'Today' : todaysDate.diff(lastDays, 'days') > 1 ? (todaysDate.diff(lastDays, 'days') + ' days ago') : (todaysDate.diff(lastDays, 'days') + ' day ago');
				localStorage.setItem('deMetricsLastSyncDate', resLastSyncDate);
				localStorage.setItem('fetchdeMetricsLastSyncDate', new Date().toISOString());
				setDemetricsLastSyncDate(dispatch, resLastSyncDate)
			})
			.catch((error) => {
				console.error(error);
			});
		} else {
			setDemetricsLastSyncDate(dispatch, localStorage.getItem('deMetricsLastSyncDate'))
		}

		if (!localStorage.getItem('teradataDailyLastSyncDate') && window.location.pathname.includes('/dataestate/')) {
			fetchDataEstateLastSyncData('teradata-daily')
			.then((res) => {
				lastSyncDate = res?.data?.result[0]?.lastSyncTime;
				const lastDays = moment([new Date(lastSyncDate).getFullYear(), new Date(lastSyncDate).getMonth()+1, new Date(lastSyncDate).getDate()]);
				const resLastSyncDate = todaysDate.diff(lastDays, 'days') == 0 ? 'Today' : todaysDate.diff(lastDays, 'days') > 1 ? (todaysDate.diff(lastDays, 'days') + ' days ago') : (todaysDate.diff(lastDays, 'days') + ' day ago');
				localStorage.setItem('teradataDailyLastSyncDate', resLastSyncDate);
				localStorage.setItem('fetchteradataDailyLastSyncDate', new Date().toISOString());
				setTeradataDailyLastSyncDate(resLastSyncDate)
			})
			.catch((error) => {
				console.error(error);
			});
		} else {
			setTeradataDailyLastSyncDate(localStorage.getItem('teradataDailyLastSyncDate'));
		}

		if (!localStorage.getItem('teradataStorageLastSyncDate') && window.location.pathname.includes('/dataestate/')) {
			fetchDataEstateLastSyncData('teradata-storage')
			.then((res) => {
				lastSyncDate = res?.data?.result[0]?.lastSyncTime;
				const lastDays = moment([new Date(lastSyncDate).getFullYear(), new Date(lastSyncDate).getMonth()+1, new Date(lastSyncDate).getDate()]);
				const resLastSyncDate = todaysDate.diff(lastDays, 'days') ? 'Today' : todaysDate.diff(lastDays, 'days') > 1 ? (todaysDate.diff(lastDays, 'days') + ' days ago') : (todaysDate.diff(lastDays, 'days') + ' day ago');
				localStorage.setItem('teradataStorageLastSyncDate', resLastSyncDate);
				localStorage.setItem('fetchteradataStorageLastSyncDate', new Date().toISOString());
				setTeradataStorageLastSyncDate(dispatch, resLastSyncDate)
			})
			.catch((error) => {
				console.error(error);
			});
		} else {
			setTeradataStorageLastSyncDate(dispatch, localStorage.getItem('teradataStorageLastSyncDate'));
		}
		
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
		}, 1000);
		if (
			window.location.pathname === '/verificationValidation/dashboard' ||
			window.location.pathname === '/powerBI/capacity/dashboard' ||
			window.location.pathname === '/powerBI/dataset/dashboard' ||
			window.location.pathname === '/verificationValidation/powerbi/dashboard' ||
			window.location.pathname === '/powerBI/usage/dashboard' ||
			window.location.pathname === '/powerBI/datagrowth/dashboard' ||
			window.location.pathname === '/dataGrowthDataGrowth/dashboard'
		) {
			const element = document.getElementsByClassName('head')[0];
			element.classList.add('header1');
			element.classList.add('databricks_title1');
			document.body.classList.add('iframe-parent-container');
		}
		if(window.location.pathname === '/powerBI/capacityObservability/dashboard' ||
			window.location.pathname === '/powerBI/gatewayObservability/dashboard' ||
			window.location.pathname === '/powerBI/adoptionMetrics/dashboard'
		){
			const element = document.getElementsByClassName('head')[0];
			element.classList.remove('header1');
			element.classList.remove('databricks_title1');
			document.body.classList.remove('iframe-parent-container');
		}
	}, [location.pathname]);

	return (
		// for dashboard only display lastSync message
		<div className="head">
			{!loading ? (
				<aside className="header">
					{location.pathname === '/dashboard/business' && (
						<div className="lastSync">
							<ReloadIcon
								onClick={(event) => {
									window.location.reload();
								}}
								style={{ cursor: 'pointer' }}
							/>{' '}
							Last Sync: {lastSync}
						</div>
					)}

					{/*  databricks obs header */}

					{/* 1) Summary */}
					{location.pathname === '/databricks/summary/dashboard' && (
						<div>
							<div className="databricks_title">
								<img src={DatabricksIcon} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">DATABRICKS OBSERVABILITY</h4>
							</div>
							<Stack className="page_title">
								<span className="job-menu">
									<img src={DashboardIcon} className={classes.jobIcon} />
									<span className="header-name">Summary</span>
								</span>
							</Stack>
						</div>
					)}
					{/* 2) Resource optimization  */}
					{location.pathname === '/databricks/resource/dashboard' && (
						<div>
							<div className="databricks_title">
								<img src={DatabricksIcon} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">DATABRICKS OBSERVABILITY</h4>
							</div>
							<Stack className="page_title">
								<span className="job-menu">
									<img src={ResourceOptIcon} className={classes.jobIcon} />
									<span className="header-name">Resource Optimization</span>
								</span>
							</Stack>
						</div>
					)}

					{/* 3) Databricks jobs  */}
					{location.pathname === '/databricks/jobs/dashboard' && (
						<div>
							<div className="databricks_title">
								<img src={DatabricksIcon} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">DATABRICKS OBSERVABILITY</h4>
							</div>
							<Stack className="page_title">
								<span className="job-menu">
									<img src={JobsIcon} className={classes.jobIcon} />
									<span className="header-name">Jobs</span>
								</span>
							</Stack>
						</div>
					)}

					{/* 4) Cost summary */}
					{location.pathname === '/databricks/costSummary/dashboard' && (
						<div>
							<div className="databricks_title">
								<img src={DatabricksIcon} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">DATABRICKS OBSERVABILITY</h4>
							</div>
							<Stack className="page_title">
								<span className="job-menu">
									<img src={CostSummaryIcon} className={classes.jobIcon} />
									<span className="header-name">Cost Summary</span>
								</span>
							</Stack>
						</div>
					)}

					{/* 5) Governance */}
					{location.pathname === '/databricks/governance/dashboard' && (
						<div>
							<div className="databricks_title">
								<img src={DatabricksIcon} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">DATABRICKS OBSERVABILITY</h4>
							</div>
							<Stack className="page_title">
								<span className="job-menu">
									<img src={GovernanceIcon} className={classes.jobIcon} />
									<span className="header-name">Governance</span>
								</span>
							</Stack>
						</div>
					)}

					{/* 6) infrastructure */}
					{location.pathname === '/databricks/infrastructure/dashboard' && (
						<div>
							<div className="databricks_title">
								<img src={DatabricksIcon} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">DATABRICKS OBSERVABILITY</h4>
							</div>
							<Stack className="page_title">
								<span className="job-menu">
									<img src={InfrastructureLogo} className={classes.jobIcon} />
									<span className="header-name">Infrastructure View</span>
								</span>
							</Stack>
						</div>
					)}

					{/* DATABRICKS alert  */}
					{location.pathname.includes('/databricks/observation') && (
						<div>
							<div className="databricks_title">
								<img src={TableauLogo} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">DATABRICKS OBSERVABILITY</h4>
							</div>
						</div>
					)}
					{/* End of Databricks obs header */}

					{/* Tableau obs header */}
					{/* 1) Summary */}
					{location.pathname === '/tableau/summary/dashboard' && (
						<div>
							<div className="databricks_title">
								<img src={TableauLogo} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">TABLEAU OBSERVABILITY</h4>
							</div>
							<Stack>
								<span className="job-menu">
									<img src={DashboardIcon} className={classes.jobIcon} />
									<span className="header-name">Summary</span>
								</span>
							</Stack>
						</div>
					)}

					{/* 2) Performance */}
					{location.pathname.includes('tableau/performance/dashboard') && (
						<div>
							<div className="databricks_title">
								<img src={TableauLogo} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">TABLEAU OBSERVABILITY</h4>
							</div>
							<Stack>
								<span className="job-menu">
									<img src={PerformanceIcon} className={classes.jobIcon} />
									<span className="header-name">Performance</span>
								</span>
							</Stack>
						</div>
					)}

					{/* 3) Governance */}
					{location.pathname === '/tableau/governance/dashboard' && (
						<div>
							<div className="databricks_title">
								<img src={TableauLogo} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">TABLEAU OBSERVABILITY</h4>
							</div>
							<Stack>
								<span className="job-menu">
									<img src={GovernanceIcon} className={classes.jobIcon} />
									<span className="header-name">Governance</span>
								</span>
							</Stack>
						</div>
					)}

					{/* 4) infrastructure */}
					{location.pathname === '/tableau/infrastructure/dashboard' && (
						<div>
							<div className="databricks_title">
								<img src={TableauLogo} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">TABLEAU OBSERVABILITY</h4>
							</div>
							<Stack>
								<span className="job-menu">
									<img src={InfrastructureLogo} className={classes.jobIcon} />
									<span className="header-name">Infrastructure View</span>
								</span>
							</Stack>
						</div>
					)}
					{/*  job details */}
					{location.pathname.includes('/tableau/jobdetails') && (
						<div>
							<div className="databricks_title">
								<img src={TableauLogo} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">TABLEAU OBSERVABILITY</h4>
							</div>
						</div>
					)}

					{/* Extract failure alert drilldown */}
					{location.pathname.includes('/tableau/observation') && (
						<div>
							<div className="databricks_title">
								<img src={TableauLogo} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">TABLEAU OBSERVABILITY</h4>
							</div>
						</div>
					)}
					{/* End Tableau obs header */}

					{/* PowerBI obs header */}
					{/* 1) capacity */}
					{location.pathname === '/powerBI/capacity/dashboard' && (
						<div>
							<div className="databricks_title">
								<img src={PowerBILogo} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">POWER BI OBSERVABILITY</h4>
							</div>
							<Stack>
								<span className="job-menu">
									<img src={CapacityLogo} className={classes.jobIcon} />
									<span className="header-name">Capacity Performance</span>
								</span>
							</Stack>
						</div>
					)}
					{/* 2) dataset */}
					{location.pathname === '/powerBI/dataset/dashboard' && (
						<div>
							<div className="databricks_title">
								<img src={PowerBILogo} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">POWER BI OBSERVABILITY</h4>
							</div>
							<Stack>
								<span className="job-menu">
									<img src={DatasetLogo} className={classes.jobIcon} />
									<span className="header-name">Dataset Performance</span>
								</span>
							</Stack>
						</div>
					)}
					{/* 3) usage */}
					{location.pathname === '/powerBI/usage/dashboard' && (
						<div>
							<div className="databricks_title">
								<img src={PowerBILogo} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">POWER BI OBSERVABILITY</h4>
							</div>
							<Stack>
								<span className="job-menu">
									<img src={UsageLogo} className={classes.jobIcon} />
									<span className="header-name">Usage & Governance</span>
								</span>
							</Stack>
						</div>
					)}
					{/* 4) datagrowth */}
					{location.pathname === '/powerBI/datagrowth/dashboard' && (
						<div>
							<div className="databricks_title">
								<img src={PowerBILogo} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">POWER BI OBSERVABILITY</h4>
							</div>
							<Stack>
								<span className="job-menu">
									<img src={DataGrowthLogo} className={classes.jobIcon} />
									<span className="header-name">Data Growth</span>
								</span>
							</Stack>
						</div>
					)}
					{/* 5) infrastructure */}
					{location.pathname === '/powerBI/infrastructure/dashboard' && (
						<div>
							<div className="databricks_title">
								<img src={PowerBILogo} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">POWER BI OBSERVABILITY</h4>
							</div>
							<Stack>
								<span className="job-menu">
									<img src={InfrastructureLogo} className={classes.jobIcon} />
									<span className="header-name">Infrastructure View</span>
								</span>
							</Stack>
						</div>
					)}

					{/* 6) capacity observabiltiy */}
					{location.pathname === '/powerBI/capacityObservability/dashboard' ? (
						<div>
							<div className="databricks_title ibp--title">
								<img src={PowerBILogo} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">POWER BI OBSERVABILITY</h4>
							</div>
						</div>
					) : (
						''
					)}

					{/* 7) gateway observabiltiy */}
					{location.pathname === '/powerBI/gatewayObservability/dashboard' ? (
						<div>
							<div className="databricks_title ibp--title">
								<img src={PowerBILogo} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">POWER BI OBSERVABILITY</h4>
							</div>
						</div>
					) : (
						''
					)}

					{/* 7) adoption metrics */}
					{location.pathname === '/powerBI/adoptionMetrics/dashboard' ? (
						<div>
							<div className="databricks_title ibp--title">
								<img src={PowerBILogo} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">POWER BI OBSERVABILITY</h4>
							</div>
						</div>
					) : (
						''
					)}

					{/* End PowerBI obs header */}

					{/* DMF  header */}

					{/* 1) HealthSummary */}
					{location.pathname === '/dataManagementFramework/healthSummary/dashboard' && (
						<div>
							<div className="databricks_title">
								<img src={DMFLogo} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">DATA MANAGEMENT FRAMEWORK</h4>
							</div>
							<Stack>
								<span className="job-menu">
									<img src={DashboardIcon} className={classes.jobIcon} />
									<span className="header-name">Data Health Summary</span>
								</span>
							</Stack>
						</div>
					)}

					{/* 2) Catalogues */}
					{location.pathname === '/dataManagementFramework/Catalogues/dashboard' && (
						<div>
							<div className="databricks_title">
								<img src={DMFLogo} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">DATA MANAGEMENT FRAMEWORK</h4>
							</div>
							<Stack>
								<span className="job-menu">
									<img src={CatalogueLogo} className={classes.jobIcon} />
									<span className="header-name">Catalogues & Quality</span>
								</span>
							</Stack>
						</div>
					)}

					{/* End DMF obs header */}

					{/* VV  header */}

					{/* 1) Tableau */}
					{location.pathname === '/verificationValidation/dashboard' && (
						<div>
							<div className="databricks_title">
								<img src={VVLogo} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">VERIFICATION & VALIDATION</h4>
							</div>
							<Stack>
								<span className="job-menu">
									<img src={VVTableauLogo} className={classes.jobIcon} />
									<span className="header-name">Tableau</span>
								</span>
							</Stack>
						</div>
					)}

					{/* 2) Power BI */}
					{location.pathname === '/verificationValidation/powerbi/dashboard' && (
						<div>
							<div className="databricks_title">
								<img src={VVLogo} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">VERIFICATION & VALIDATION</h4>
							</div>
							<Stack>
								<span className="job-menu">
									<img src={VVPowerBILogo} className={classes.jobIcon} />
									<span className="header-name">Power BI</span>
								</span>
							</Stack>
						</div>
					)}
					{/* End VV obs header */}

					{/* ML  header */}

					{/* 1) Summary */}
					{location.pathname.includes('/machineLearning') && (
						<div>
							<div className="machineLearning_title_div">
								<img src={MLLogo} alt="logo" className="databricks_logo" />
								<h4 className="machineLearning_title">MACHINE LEARNING OBSERVABILITY</h4>
							</div>
						</div>
					)}
					{/* End ML obs header */}

					{/* Presto  header */}

					{/* 1) Summary */}
					{location.pathname === '/presto/dashboard' && (
						<div>
							<div className="presto_title_div">
								<img src={PrestoLogo} alt="logo" className="databricks_logo" />
								<h4 className="presto_title">PRESTO OBSERVABILITY</h4>
							</div>
							<Stack>
								<span className="job-menu">
									<img src={DashboardIcon} className={classes.jobIcon} />
									<span className="header-name">Query Performance Summary</span>
								</span>
							</Stack>
						</div>
					)}
					{/* End Presto obs header */}

					{/* Control & Compliance obs header */}
					{/* 1) Controls Testing Dashboard */}
					{location.pathname === '/controlsCompliance/dashboard' && (
						<div>
							<div className="databricks_title">
								<img src={CCLogo} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">CONTROLS & COMPLIANCE</h4>
							</div>
							<Stack>
								<span className="job-menu">
									<img src={ControlTestIcon} className={classes.jobIcon} />
									<span className="header-name">Controls Testing Dashboard</span>
								</span>
							</Stack>
						</div>
					)}

					{/* IBP Dashboard */}
					{location.pathname === '/ibp/dashboard' && (
						<div>
							<div className="databricks_title ibp--title">
								<img src={IBPLogo} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">IBP Jobs Monitoring</h4>
							</div>
							<Stack>
								<span className="job-menu">
									<img src={DashboardIcon} className={classes.jobIcon} />
									<span className="header-name">
										Dashboard
										<span className="date-sync">
											<ReloadIcon /> Last Sync: {new Date().toLocaleString()}
										</span>
									</span>
								</span>
							</Stack>
						</div>
					)}

					{location.pathname === '/ibp/executive' && (
						<div>
							<div className="databricks_title ibp--title">
								<img src={IBPExecutiveDashboardIcon} alt="logo" className="databricks_logo ibp-exe-dsbd" />
								<h4 className="databricksObs_title">
									IBP Executive Dashboard
									<span className="date-sync">
										<ReloadIcon /> Last Sync: {new Date().toLocaleString()}
									</span>
								</h4>
							</div>
						</div>
					)}

					{location.pathname === '/ibp/bdr-pbi' && (
						<div>
							<div className="databricks_title ibp--title">
								<img src={IBPDataQuality} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">BDR Dashboard</h4>
							</div>
						</div>
					)}
                    	{location.pathname === '/mdip/dashboard' && (
						<div>
							<div className="databricks_title ibp--title">
								<img src={IBPLogo} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">MDIP EXECUTIVE DASHBOARD</h4>
							</div>
							<Stack>
								<span className="job-menu">
								<img src={IBPExecutiveDashboardIcon} className={classes.jobIcon} />
									<span className="header-name">
										MDIP EXECUTIVE DASHBOARD
										<span className="date-sync">
											<ReloadIcon /> Last Sync: {new Date().toLocaleString()}
										</span>
									</span>
								</span>
							</Stack>
						</div>
					)}

                    {location.pathname === '/mdip/job' && (
						<div>
							<div className="databricks_title ibp--title">
								<img src={IBPLogo} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">MDIP EXECUTIVE DASHBOARD</h4>
							</div>
							<Stack>
								<span className="job-menu">
									<img src={DashboardIcon} className={classes.jobIcon} />
									<span className="header-name">
										MDIP JOB DASHBOARD
										<span className="date-sync">
											<ReloadIcon /> Last Sync: {new Date().toLocaleString()}
										</span>
									</span>
								</span>
							</Stack>
						</div>
					)}
					{/* DataGrowth  header */}

					{/* 1) dashboard */}
					{location.pathname === '/dataGrowth/dashboard' && (
						<div>
							<div className="databricks_title">
								<img src={DataGrowthPowerBI} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title"> DATA GROWTH OBSERVABILITY</h4>
							</div>
						</div>
					)}
					{/* 2) databricks */}
					{location.pathname === '/dataGrowthDatabricks/dashboard' && (
						<div>
							<div className="databricks_title">
								<img src={DataGrowthPowerBI} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title"> DATA GROWTH OBSERVABILITY</h4>
							</div>
							<Stack>
								<span className="job-menu">
									<img src={DataGrowthDatabricksIcon} className={classes.jobIcon} />
									<span className="header-name">Databricks</span>
								</span>
							</Stack>
						</div>
					)}

					{/* 3) presto */}
					{location.pathname === '/dataGrowthPresto/dashboard' && (
						<div>
							<div className="databricks_title">
								<img src={DataGrowthPowerBI} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title"> DATA GROWTH OBSERVABILITY</h4>
							</div>
							<Stack>
								<span className="job-menu">
									<img src={DataGrowthPrestoIcon} className={classes.jobIcon} />
									<span className="header-name">Presto</span>
								</span>
							</Stack>
						</div>
					)}

					{/* Extract failure alert drilldown */}
					{location.pathname.includes('/presto/observation') && (
						<div>
							<div className="databricks_title">
								<img src={PrestoLogo} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">PRESTO OBSERVABILITY</h4>
							</div>
						</div>
					)}
					{/* End Prestp obs header */}

					{/* 4) tableau */}
					{location.pathname === '/dataGrowthTableau/dashboard' && (
						<div>
							<div className="databricks_title">
								<img src={DataGrowthPowerBI} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title"> DATA GROWTH OBSERVABILITY</h4>
							</div>
							<Stack>
								<span className="job-menu">
									<img src={DataGrowthTableauIcon} className={classes.jobIcon} />
									<span className="header-name">Tableau</span>
								</span>
							</Stack>
						</div>
					)}
					{/* 4) datagrowth */}
					{location.pathname === '/dataGrowthDataGrowth/dashboard' && (
						<div>
							<div className="databricks_title">
								<img src={DataGrowthPowerBI} alt="logo" className="databricks_logo" />

								<h4 className="databricksObs_title"> DATA GROWTH OBSERVABILITY</h4>
							</div>
							<Stack>
								<span className="job-menu">
									<img src={PowerBI} className={classes.jobIcon} />
									<span className="header-name">Power BI</span>
								</span>
							</Stack>
						</div>
					)}
					{/*datagrowth alert drilldown */}
					{location.pathname.includes('/dataGrowth/observation') && (
						<div>
							<div className="databricks_title">
								<img src={PrestoLogo} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">DATA GROWTH OBSERVABILITY</h4>
							</div>
						</div>
					)}
					{/* End datagrowth obs header */}

					{/* Data Estate EXECUTIVE Dashboard */}
					{location.pathname === '/dataestate/executive-summary' && (
						<div>
							<div className="databricks_title ibp--title">
								<img src={DataEstateLogo} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">DATA ESTATE EXECUTIVE DASHBOARD</h4>
							</div>
							<Stack>
								<span className="job-menu">
									<img src={DashboardIcon} className={classes.jobIcon} />
									<span className="header-name">
										Executive Summary
										<span className="date-sync">
											{/* <ReloadIcon /> Last Sync: {deMetricsLastSyncDate} */}
											<ReloadIcon /> Last Sync: Today
										</span>
									</span>
								</span>
							</Stack>
						</div>
					)}

					{location.pathname === '/dataestate/storagesummary' && (
						<div>
							<div className="databricks_title ibp--title">
								<img src={DataEstateLogo} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">DATA ESTATE EXECUTIVE DASHBOARD</h4>
							</div>
						</div>
					)}
					{location.pathname === '/dataestate/queriessummary' && (
						<div>
							<div className="databricks_title ibp--title">
								<img src={DataEstateLogo} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">DATA ESTATE EXECUTIVE DASHBOARD</h4>
							</div>
							{/* <Stack>
								<span className="job-menu">
									<img src={QueryIcon} className={classes.jobIcon} />
									<span className="header-name">
									Quereis Summary
										<span className="date-sync">
											<ReloadIcon /> Last Sync: {teradataDailyLastSyncDate}
										</span>
									</span>
								</span>
							</Stack> */}
						</div>
					)}
					{location.pathname === '/dataestate/biApplicationSummary' && (
						<div>
							<div className="databricks_title ibp--title">
								<img src={DataEstateLogo} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">DATA ESTATE EXECUTIVE DASHBOARD</h4>
							</div>
							<Stack>
								<span className="job-menu">
									<img src={BIApplicationIcon} className="biApplicationIcon" />
									<span className="header-name">
										BI Application Summary
										<span className="date-sync">
											<ReloadIcon /> Last Sync: {deMetricsLastSyncDate}
										</span>
									</span>
								</span>
							</Stack>
						</div>
					)}

					{location.pathname === '/dataestate/costSummary' && (
						<div>
							<div className="databricks_title ibp--title">
								<img src={DataEstateLogo} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">DATA ESTATE EXECUTIVE DASHBOARD</h4>
							</div>
						</div>
					)}

					{location.pathname === '/dataestate/data-processing' && (
						<div>
							<div className="databricks_title ibp--title">
								<img src={DataEstateLogo} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">DATA ESTATE EXECUTIVE DASHBOARD</h4>
							</div>
							<Stack>
								<span className="job-menu">
									<img src={ProcessingIcon} className={classes.jobIcon} />
									<span className="header-name">
										Data Processing Summary
										<span className="date-sync">
											<ReloadIcon /> Last Sync: {teradataStorageLastSyncDate}
										</span>
									</span>
								</span>
							</Stack>
						</div>
					)}

					{location.pathname === '/dataestate/service-request' && (
						<div>
							<div className="databricks_title ibp--title">
								<img src={DataEstateLogo} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">DATA ESTATE EXECUTIVE DASHBOARD</h4>
							</div>
							<Stack>
								<span className="job-menu">
									<img src={serviceRequestIcon} className={classes.serviceRequestIcon} />
									<span className="header-name">
										Major INC Summary
										<span className="date-sync">
											<ReloadIcon /> Last Sync: {deMetricsLastSyncDate}
										</span>
									</span>
								</span>
							</Stack>
						</div>
					)}
					{location.pathname === '/dataestate/incident-details' && (
						<div>
							<div className="databricks_title ibp--title">
								<img src={DataEstateLogo} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">DATA ESTATE EXECUTIVE DASHBOARD</h4>
							</div>
							{/* <Stack>
								<span className="job-menu">
									<img src={IncidentIcon} className={classes.jobIcon} />
									<span className="header-name incident-header">
										Incident Details
										<span className="date-sync">
											Major Incidents In {lastMonthName} '24
										</span>
									</span>
								</span>
							</Stack> */}
						</div>
					)}
					{location.pathname.includes('/dataestate/incident-impact-analysis/') && (
						<div>
							<div className="databricks_title ibp--title">
								<img src={DataEstateLogo} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">DATA ESTATE EXECUTIVE DASHBOARD</h4>
							</div>
							{/* <Stack>
								<span className="job-menu">
									<img src={IncidentIcon} className={classes.jobIcon} />
									<span className="header-name incident-header">
										<Link to={`/dataestate/incident-details`} className="titleLink">
											Incident Details
										</Link>
										<span className="symbol titleLink">
											<img src={NextArrowIcon} className={classes.nextArrowIcon} />
										</span>
										<span className="page-title">
											Incident Impact Analysis - {window.location.pathname.split('/')[3]}
										</span>
									</span>
								</span>
							</Stack> */}
							
						</div>
					)}
					{location.pathname === '/dataestate/majorIncident' && (
						<div>
							<div className="databricks_title ibp--title">
								<img src={DataEstateLogo} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">DATA ESTATE EXECUTIVE DASHBOARD</h4>
							</div>							
						</div>
					)}
					{/* End of Data Estate EXECUTIVE Dashboard */}

					{/* Start of FinOps Dashboard */}

					{location.pathname === '/finops/overview' || location.pathname === '/dataestate/financialOverview' ? (
						<div>
							<div className="databricks_title ibp--title">
								<img src={FinOpsLogo} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">FinOps</h4>
							</div>
							<Stack>
								<span className="job-menu">
									<img src={DashboardIcon} className={classes.jobIcon} />
									<span className="header-name">
										Overview
										<span className="date-sync">
											<ReloadIcon /> Last Sync: {azureCostSummaryLastSyncDate}
										</span>
									</span>
								</span>
							</Stack>
						</div>
					) : (
						''
					)}
					{location.pathname === '/finops/executive-dashboard' ? (
						<div>
							<div className="databricks_title ibp--title">
								<img src={FinOpsLogo} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">FinOps</h4>
							</div>
							{/* <Stack>
								<span className="job-menu">
									<img src={DashboardIcon} className={classes.jobIcon} />
									<span className="header-name">
										Executive Dashboard
										<span className="date-sync">
											<ReloadIcon /> Last Sync: Today
										</span>
									</span>
								</span>
							</Stack> */}
						</div>
					) : (
						''
					)}
					{location.pathname === '/finops/program' || location.pathname === '/dataestate/financialProgram' ? (
						<div>
							<div className="databricks_title ibp--title">
								<img src={FinOpsLogo} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">FinOps</h4>
							</div>
							{/* <Stack>
								<span className="job-menu">
									<img src={ProgramIcon} className={classes.jobIcon} />
									<span className="header-name">
										Program Summary
										<span className="date-sync">
											<ReloadIcon /> Last Sync: Today
										</span>
									</span>
								</span>
							</Stack> */}
						</div>
					) : (
						''
					)}

					{location.pathname === '/finops/details' || location.pathname === '/dataestate/financialDetails' ? (
						<div>
							<div className="databricks_title ibp--title">
								<img src={FinOpsLogo} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">FinOps</h4>
							</div>
							{/* <Stack>
								<span className="job-menu">
									<img src={DetailsIcon} className={classes.jobIcon} /> 
									<span className="header-name">
									Details
										<span className="date-sync">
											<ReloadIcon /> Last Sync: Today
										</span>
									</span>
								</span>
							</Stack> */}
						</div>
					) : (
						''
					)}

					{location.pathname === '/finops/resourcemapping/programs' && (
						<div>
							<div className="databricks_title ibp--title">
								<img src={ResourceMappingIcon} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">Resource Mapping</h4>
							</div>
						</div>
					)}

					{location.pathname === '/finops/resourcemapping/search' && (
						<div>
							<div className="databricks_title ibp--title">
								<img src={ResourceMappingIcon} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">Resource Mapping</h4>
							</div>
						</div>
					)}

					{location.pathname.includes('/finops/resourcemapping/programs/') && (
						<div>
							<div className="databricks_title ibp--title">
								<img src={ResourceMappingIcon} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">Resource Mapping</h4>
							</div>
						</div>
					)}

					{location.pathname.includes('/finops/resourcemapping/projects/') && (
						<div>
							<div className="databricks_title ibp--title">
								<img src={ResourceMappingIcon} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">Resource Mapping</h4>
							</div>
						</div>
					)}

					{location.pathname.includes('/finops/resourcemapping/search-resources/') && (
						<div>
							<div className="databricks_title ibp--title">
								<img src={ResourceMappingIcon} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">Resource Mapping</h4>
							</div>
						</div>
					)}
					{/* End of FinOps Dashboard */}

					{/* Data Pipeline Dashboard */}
					{location.pathname === '/data-pipeline/programs' && (
						<div>
							<div className="databricks_title ibp--title">
								<img src={DataPipelineIcon} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">DATAOPS</h4>
							</div>
							{/* <Stack>
								<span className="job-menu">
									<img src={DashboardIcon} className={classes.jobIcon} />
									<span className="header-name">Programs</span>
								</span>
							</Stack> */}
						</div>
					)}
					{location.pathname.includes('/data-pipeline/programOps') && (
						<div>
							<div className="databricks_title ibp--title">
								<img src={DataPipelineIcon} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">DATAOPS</h4>
							</div>
							{location.pathname.includes('/data-pipeline/programOpsDashboard') && (
								<Stack>
									<span className="job-menu">
										<img src={CustomDashboardIcon} className={classes.jobIcon} />
										<span className="header-name">Program Ops Dashboards</span>
									</span>
								</Stack>
							)}
						</div>
					)}
					{location.pathname.includes('/data-pipeline/programs/') && (
						<div>
							<div className="databricks_title ibp--title">
								<img src={DataPipelineIcon} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">DATAOPS</h4>
							</div>
							{/* <Stack>
								<span className="job-menu">
									<img src={DashboardIcon} className={classes.jobIcon} />
									<span className="header-name incident-header">
										<Link to={`/data-pipeline/programs`} className="titleLink">
											Programs
										</Link>
										<span className="symbol titleLink">
											<img src={NextArrowIcon} className={classes.nextArrowIcon} />
										</span>
										<span className="page-title">
											{decodeURIComponent(window.location.pathname.split('/')[4].replace(/%20/g, ' '))}
										</span>
									</span>
								</span>
							</Stack> */}
						</div>
					)}

					{location.pathname.includes('/data-pipeline/projects/') && (
						<div>
							<div className="databricks_title ibp--title">
								<img src={DataPipelineIcon} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">DATAOPS</h4>
							</div>
							{/* <Stack>
								<span className="job-menu project-details-header">
									<img src={DashboardIcon} className={classes.jobIcon} />
									<span className="header-name incident-header">
										<Link to={`/data-pipeline/programs`} className="titleLink">
											Programs
										</Link>
										<span className="symbol titleLink">
											<img src={NextArrowIcon} className={classes.nextArrowIcon} />
										</span>
										<Link
											to={`/data-pipeline/programs/${window.location.pathname
												.split('/')[3]
												.replace(/%20/g, ' ')}/${window.location.pathname.split('/')[5].replace(/%20/g, ' ')}`}
											className="titleLink head-title-has-ellipsis"
											title={decodeURIComponent(window.location.pathname.split('/')[5])}
										>
											{decodeURIComponent(window.location.pathname.split('/')[5])}
										</Link>

										<span className="symbol titleLink">
											<img src={NextArrowIcon} className={classes.nextArrowIcon} />
										</span>
										<span
											className="page-title title-has-ellipsis title-ellipsis-responsive"
											title={decodeURIComponent(window.location.pathname.split('/')[6])}
										>
											{decodeURIComponent(window.location.pathname.split('/')[6])}
										</span>
									</span>
								</span>
							</Stack> */}
						</div>
					)}

					{location.pathname.includes('/data-pipeline/search-jobs/') && (
						<div>
							<div className="databricks_title ibp--title">
								<img src={DataPipelineIcon} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">DATAOPS</h4>
							</div>
						</div>
					)}

					{/* KPI Framework */}
					{location.pathname.includes('/kpiFramework') && (
						<div>
							<div className="databricks_title">
								<img src={DataPipelineIcon} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">KPI STORE</h4>
							</div>
						</div>
					)}
					
					{/* Data Pipeline Dashboard */}

					{!location.pathname.includes('platformCostObs') &&
						location.pathname !== DataGrowthDashboard &&
						location.pathname !== '/databricks/summary/dashboard' &&
						location.pathname !== '/databricks/resource/dashboard' &&
						location.pathname !== '/databricks/jobs/dashboard' &&
						location.pathname !== '/databricks/costSummary/dashboard' &&
						location.pathname !== '/databricks/governance/dashboard' &&
						location.pathname !== '/databricks/infrastructure/dashboard' &&
						location.pathname !== '/powerBI/capacity/dashboard' &&
						location.pathname !== '/powerBI/capacityObservability/dashboard' &&
						location.pathname !== '/powerBI/gatewayObservability/dashboard' &&
						location.pathname !== '/powerBI/adoptionMetrics/dashboard' &&
						location.pathname !== '/powerBI/dataset/dashboard' &&
						location.pathname !== '/powerBI/usage/dashboard' &&
						location.pathname !== '/powerBI/datagrowth/dashboard' &&
						location.pathname !== '/powerBI/infrastructure/dashboard' &&
						location.pathname !== '/dataManagementFramework/healthSummary/dashboard' &&
						location.pathname !== '/dataManagementFramework/Catalogues/dashboard' &&
						location.pathname !== '/verificationValidation/dashboard' &&
						location.pathname !== '/verificationValidation/powerbi/dashboard' &&
						!location.pathname.includes('/tableau') &&
						!location.pathname.includes('/machineLearning') &&
						!location.pathname.includes('/presto') &&
						!location.pathname.includes('/controlsCompliance') &&
						!location.pathname.includes('/ibp') &&
						!location.pathname.includes('/dataGrowth') &&
						!location.pathname.includes('/dataestate') &&
						!location.pathname.includes('/databricks') &&
						!location.pathname.includes('/finops') &&
						!location.pathname.includes('/data-pipeline') && (
							<div>
								{location.pathname === '/dashboard/business' && (
									<div className="teradata_title">
										<img src={TeradataLogo} alt="logo" className="teradata_logo" />
										<h4 className="teraObs_title">Teradata Jobs Observability</h4>
									</div>
								)}

								<Stack direction="row" className="header-title">
									{location.pathname === '/dashboard/administrative' && (
										<div style={{ display: 'flex', alignItems: 'flex-end' }}>
											<div className="dashboard_title_icon">
												<img src={AdministrativeIcon} className={classes.jobIcon} />
											</div>
											<div className="dashboard_title">Administrative Data Dashboard</div>
										</div>
									)}
									{location.pathname === '/dashboard/operational' && (
										<div style={{ display: 'flex', alignItems: 'flex-end' }}>
											<div className="dashboard_title_icon">
												<img src={OperationalIcon} className={classes.jobIcon} />
											</div>
											<div className="dashboard_title">Operational Data Dashboard</div>
										</div>
									)}
									{location.pathname === '/dashboard/business' && (
										<div style={{ display: 'flex', alignItems: 'flex-end' }}>
											<div className="dashboard_title_icon">
												<img src={OperationalIcon} className={classes.jobIcon} />
											</div>
											<div className="dashboard_title">Platform Executive Dashboard</div>
										</div>
									)}
									{location.pathname === '/dashboard/jobPerformance' && (
										<span>
											<img src={OperationalIcon} className={classes.jobIcon} />
											Jobs Performance
										</span>
									)}
									{location.pathname === '/jobs' ? (
										<span className="job-menu">
											<img src={JobsIcon} className={classes.jobIcon} />
											<span className="header-name">Jobs</span>
										</span>
									): location.pathname.includes("/jobs/")  ?
									<div className="teradata_title">
										<img src={TeradataLogo} alt="logo" className="teradata_logo" />
										<h4 className="teraObs_title">Teradata Jobs Observability</h4>
									</div> : null
									}
									{location.pathname === '/master/systems' && (
										<span className="job-menu">
											<img src={SystemIcon} className={classes.jobIcon} />
											<span className="header-name">Systems</span>
										</span>
									)}
									{location.pathname === '/master/sectors' && (
										<span className="job-menu">
											<img src={SectorsIcon} className={classes.jobIcon} />
											<span className="header-name">Sectors</span>
										</span>
									)}
									{location.pathname === '/master/processes' && (
										<span className="job-menu">
											<img src={ProcessIcon} className={classes.jobIcon} />
											<span className="header-name">Processes</span>
										</span>
									)}
									{/* {/* {location.pathname === '/user/list' && (
								<span className="job-menu">
									<img src={UsersIcon} className={classes.jobIcon} />
									<span className="header-name">Users</span>
								</span>
							)} */}
									{location.pathname === '/master/reasonsOfSLA' && (
										<span className="job-menu">
											<img src={ProcessIcon} className={classes.jobIcon} />
											<span className="header-name">Reasons Of SLA Not Met</span>
										</span>
									)}
									{location.pathname === '/jobSchedules' && (
										<Stack direction="row" spacing={80} sx={{ marginTop: '20px' }}>
											<div style={{ width: '100%', paddingTop: '2%' }}>
												<img src={Jobschedule} className={classes.jobScheduleIcon} />
												<span className="page-title">Job Schedules</span>
											</div>
										</Stack>
									)}
									{location.pathname === '/jobExecutions' && (
										<Stack direction="row" spacing={80} sx={{ marginTop: '20px' }}>
											<div style={{ width: '100%', paddingTop: '2%' }}>
												<img src={JobExecutions} className={classes.jobScheduleIcon} />
												<span className="page-title">Job Executions</span>
											</div>
										</Stack>
									)}
									{location.pathname === '/jobRunSchedules' && (
										<Stack direction="row" spacing={80} sx={{ marginBottom: '15px', marginTop: '45px' }}>
											<div style={{ width: '100%', paddingTop: '5%' }}>
												<img src={JobExecutions} className={classes.jobScheduleIcon} />
												<span className="page-title">Run Schedules</span>
											</div>
										</Stack>
									)}
									{location.pathname === '/job/Infrastructure' && (
										<Stack direction="row" spacing={80} sx={{ marginBottom: '15px', marginTop: '45px' }}>
											<div style={{ width: '100%', paddingTop: '5%', marginTop: '-30px' }}>
												<img src={InfrastructureLogo} className={classes.jobScheduleIcon} />
												<span className="page-title">Infrastructure View</span>
											</div>
										</Stack>
									)}
									{location.pathname.includes('platformCostObs') && (
										<div style={{ display: 'flex', alignItems: 'flex-end' }}>
											<div className="dashboard_title_icon">
												<img src={OperationalIcon} className={classes.jobIcon} />
											</div>
											<div className="dashboard_title">Platform Cost Observability</div>
										</div>
									)}
									{location.pathname === DataGrowthDashboard && (
										<div style={{ display: 'flex', alignItems: 'flex-end' }}>
											<div className="dashboard_title_icon">
												<img src={OperationalIcon} className={classes.jobIcon} />
											</div>
											<div className="dashboard_title">Data Growth Observability</div>
										</div>
									)}
								</Stack>
							</div>
						)}
					{location.pathname.includes('/platformCostObs') && (
						<div>
							<div className="databricks_title">
								<img src={PlatformCostLogoPowerBI} alt="logo" className="databricks_logo" />
								<h4 className="databricksObs_title">PLATFORM COST OBSERVABILITY</h4>
							</div>
						</div>
					)}

					{location.pathname !== DataGrowthDashboard &&
						location.pathname !== '/databricks/summary/dashboard' &&
						location.pathname !== '/databricks/resource/dashboard' &&
						location.pathname !== '/databricks/jobs/dashboard' &&
						location.pathname !== '/databricks/costSummary/dashboard' &&
						location.pathname !== '/databricks/governance/dashboard' &&
						location.pathname !== '/databricks/infrastructure/dashboard' &&
						location.pathname !== '/powerBI/capacity/dashboard' &&
						location.pathname !== '/powerBI/capacityObservability/dashboard' &&
						location.pathname !== '/powerBI/gatewayObservability/dashboard' && 
						location.pathname !== '/powerBI/adoptionMetrics/dashboard' &&
						location.pathname !== '/powerBI/dataset/dashboard' &&
						location.pathname !== '/powerBI/usage/dashboard' &&
						location.pathname !== '/powerBI/datagrowth/dashboard' &&
						location.pathname !== '/powerBI/infrastructure/dashboard' &&
						location.pathname !== '/dataManagementFramework/healthSummary/dashboard' &&
						location.pathname !== '/dataManagementFramework/Catalogues/dashboard' &&
						location.pathname !== '/verificationValidation/dashboard' &&
						location.pathname !== '/verificationValidation/powerbi/dashboard' &&
						!location.pathname.includes('/tableau') &&
						!location.pathname.includes('/machineLearning') &&
						!location.pathname.includes('/platformCostObs') &&
						!location.pathname.includes('/presto') &&
						!location.pathname.includes('/controlsCompliance') &&
						!location.pathname.includes('/ibp') &&
						!location.pathname.includes('/dataGrowth') &&
						!location.pathname.includes('/dataestate') &&
						!location.pathname.includes('/databricks') &&
						!location.pathname.includes('/finops') &&
						!location.pathname.includes('/data-pipeline') && (
							<NotificationBar notificationData={notificationData?.notificationData} />
						)}
				</aside>
			) : (
				<Loader />
			)}
		</div>
	);
};

export default Header;
