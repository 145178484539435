import React from "react";
import { Box, Typography } from "@mui/material";

interface SubscriptTagProps {
  label: string;
}

const SubscriptTag: React.FC<SubscriptTagProps>= ({label}) => {
  return (
    <Box display="inline-flex" alignItems="flex-start" position="relative">
      {/* Subscript Tag */}
      <Typography
        variant="caption"
        component="span"
        sx={{
          color: "white",
          backgroundColor: "info.main",
          fontWeight: "bold",
          padding: "0px 4px",
          borderRadius: "4px",
          marginLeft: "8px",
          position: "relative",
          top: "-5px", // Adjust for subscript positioning
        }}
      >
        {label}
      </Typography>
    </Box>
  );
};

export default SubscriptTag;