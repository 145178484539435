import {
	Button,
	Stack,
	TextField,
	Typography,
	Alert,
	FormControl,
	FormHelperText,
	Box,
	Chip
} from '@mui/material';
import { CustomizedDataGrid } from 'components/customized_data_grid';
import CustomDateTimePicker from 'components/Inputs/CustomDateTimePicker';
import { ReactComponent as EditIcon } from '../../../assets/material-symbols_edit-rounded.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/delete-img.svg';
import RowTypography from 'components/Table/RowTypography';
import React, { useEffect, useRef, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import AsyncAutoComplete from 'components/Inputs/ResourceMapping/AsyncAutocomplete';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import colors from 'assets/theme/base/colors';
import {ReactComponent as WarningImage} from '../../../assets/warning.svg';

import { ReactComponent as ResourceMapping } from '../../../../src/assets/resourceMappingNew.svg';
import styled from '@emotion/styled';
import AddResourceSuccessModal from './AddResourceSuccessModal';

const StyledConfirmationIcon = styled(ResourceMapping)({
	width: '5rem',
	height: '5rem'
});

type NewAllocationRecords = {
	[key: number]: {};
};

interface Option {
	value: string;
	label: string;
}

interface ProjectOwner {
	name: string;
	email: string;
}

interface TableRow {
	id: number;
	isNew: boolean;
	isEditing: boolean;
	project: string;
	projectOwner: ProjectOwner;
	sectors: string[];
	allocationPercentage: string;
	allocationStartTime: Dayjs;
	allocationEndTime: Dayjs;
}
type ModalWidth = "sm" | "md" | "lg" | "xl";

interface ResolveAllocationConflictsModal {
    bulkSelectedRows: any;
    modalTitle: string;
    onTitleChange: (newTitle: string) => void;
    onWidthChange: (newWidth: ModalWidth) => void;
}

const ResolveAllocationConflictsModal: React.FC<ResolveAllocationConflictsModal> = ({
	bulkSelectedRows,
	onTitleChange,
	onWidthChange,
	modalTitle
}) => {
	const validationInitialState = {
		resource: false,
		allocationPercentage: false,
		allocationStartTime: false,
		allocationEndTime: false
	};

	const editErrorMessages = {
		allocationPercentage: 'Allocation percentage must be between 0 and 100.',
		allocationStartTime: 'Allocation Start date is required.',
		allocationEndTime: 'Allocation End date is required and must be after start date.'
	};
	const selectedRowsInitialState = [
		{
			id: 1,
			isNew: false,
			isEditing: false,
			project: 'Demo1',
			projectOwner: {
				name: 'John Doe',
				email: 'johndoe@xyz.com'
			},
			sectors: ['ALL 7 SECTORS'],
			allocationPercentage: '100',
			allocationStartTime: dayjs('2023-11-10T19:30:00'),
			allocationEndTime: dayjs('2024-01-30T19:30:00')
		},
		{
			id: 2,
			isNew: false,
			isEditing: false,
			project: 'SPOT',
			projectOwner: {
				name: 'Jane Smith',
				email: 'janes@abc.com'
			},
			sectors: ['LATAM', 'AMESA', 'SMP', 'ABP'],
			allocationPercentage: '30',
			allocationStartTime: dayjs('2024-02-01T19:30:00'),
			allocationEndTime: dayjs('2024-08-01T19:30:00')
		},
		{
			id: 3,
			isNew: false,
			isEditing: false,
			project: 'Alpha',
			projectOwner: {
				name: 'Mike Ross',
				email: 'miker@def.com'
			},
			sectors: ['ALL 7 SECTORS'],
			allocationPercentage: '30',
			allocationStartTime: dayjs('2024-02-01T19:30:00'),
			allocationEndTime: dayjs('2024-08-01T19:30:00')
		},
		{
			id: 4,
			isNew: false,
			isEditing: false,
			project: 'Beta',
			projectOwner: {
				name: 'Emma Stone',
				email: 'emmas@ghi.com'
			},
			sectors: ['LATAM', 'EMEA', 'SMP'],
			allocationPercentage: '40',
			allocationStartTime: dayjs('2024-02-01T19:30:00'),
			allocationEndTime: dayjs('2024-08-01T19:30:00')
		}
	];
	const [validationErrors, setValidationErrors] = useState(validationInitialState);
	const [editValidationErrors, setEditValidationErrors] = useState(validationInitialState);
	const [selectedRows, setSelectedRows] = useState<TableRow[]>(selectedRowsInitialState);
	const [newAllocationRecords, setNewAllocationRecords] = useState<NewAllocationRecords>({});
	const [conflictResources, setConflictResources] = useState<Option[]>([]);

	const isNewAllocRowExists = selectedRows.some((row) => row.isNew);

	const options: Option[] = [
		{
			value: 'Demo2',
			label: 'Demo2'
		},
		{
			value: 'Demo3',
			label: 'Demo3'
		}
	];

	const dummyData = [
		{
			resource: 'system',
			project: 'Demo2',
			projectOwner: {
				name: 'john Stone',
				email: 'john@ghi.com'
			},
			sectors: ['Sector 1', 'Sector 2']
		},
		{
			resource: '0.0.14',
			project: 'Demo3',
			projectOwner: {
				name: 'jain Smith',
				email: 'jain@ghi.com'
			},
			sectors: ['Sector 3', 'Sector 4']
		},
		{
			resource: '0.0.15',
			project: 'Demo4',
			projectOwner: {
				name: 'jain Smith',
				email: 'jain@ghi.com'
			},
			sectors: ['Sector 5', 'Sector 6']
		}
	];

	const [selectedResource, setSelectedresource] = useState<string>('');
	const [resolveConflict, setResolveConflict] = useState<boolean>(false);
	const [newAllocation, setNewAllocation] = useState<TableRow>({
		id: 0,
		isNew: true,
		project: '',
		projectOwner: { name: '', email: '' },
		sectors: [],
		allocationPercentage: '',
		allocationStartTime: dayjs(),
		allocationEndTime: dayjs(),
		isEditing: false
	});

	const defaultColumnNames = [
		'Project',
		'Project Owner',
		'Allocation%',
		'Allocation Start Time',
		'Allocation End Time',
		'Sectors',
		'Actions'
	];

	const columnNames = [
		{ name: 'project', label: 'Project', isEditable: false },
		{ name: 'projectOwner', label: 'Project Owner', isEditable: false },
		{ name: 'allocationPercentage', label: 'Allocation%', isEditable: true },
		{ name: 'allocationStartTime', label: 'Allocation Start Time', isEditable: true },
		{ name: 'allocationEndTime', label: 'Allocation End Time', isEditable: true },
		{ name: 'sectors', label: 'Sectors', isEditable: false },
		{ name: 'actions', label: 'Actions', isEditable: false }
	];

	// Set a new title when the component is rendered
	useEffect(() => {
		if (modalTitle !== 'Resources Added Successfully') {
			const newTitle = 'Resolve Conflicts & Allocate Resource to Demo Projects';
			onTitleChange(newTitle);
			onWidthChange('lg');
		}
	}, [onTitleChange, onWidthChange]);

	// Setting resources in dropdown
	useEffect(() => {
		const newConflictResources = Object.values(bulkSelectedRows).reduce((acc: Option[], row: any) => {
			const isDuplicate = acc.some((item) => item.value === row.Resource);
			if (!isDuplicate) {
				acc.push({ value: row.Resource, label: row.Resource });
			}
			return acc;
		}, []);
		setConflictResources(newConflictResources);
	}, [bulkSelectedRows]);

	const handleChange = (field: string, value: any) => {
		setNewAllocation((prev) => ({
			...prev,
			[field]: value
		}));

		// validation based on the field
		switch (field) {
			case 'project':
				setValidationErrors((prev) => ({
					...prev,
					project: !value
				}));
				break;
			case 'allocationPercentage':
				setValidationErrors((prev) => ({
					...prev,
					allocationPercentage: !value || value < 0 || value > 100 || isNaN(value)
				}));
				break;
			case 'allocationStartTime':
				setValidationErrors((prev) => ({
					...prev,
					allocationStartTime: !value,
					allocationEndTime: prev.allocationEndTime && value && dayjs(value).isAfter(newAllocation.allocationEndTime)
				}));
				break;
			case 'allocationEndTime':
				setValidationErrors((prev) => ({
					...prev,
					allocationEndTime:
						!value || (newAllocation.allocationStartTime && !dayjs(value).isAfter(newAllocation.allocationStartTime))
				}));
				break;
			default:
				break;
		}
	};

	const handleEditRow = (row: TableRow) => {
		setSelectedRows((prevRows) =>
			prevRows.map((r) => (r.id === row.id ? { ...r, isEditing: true } : { ...r, isEditing: false }))
		);
	};

	const handleSaveEdit = (rowId: number, updatedFields: { [key: string]: any }) => {
		setSelectedRows((prevRows) =>
			prevRows.map((row) => (row.id === rowId ? { ...row, ...updatedFields, isEditing: false } : row))
		);
		setEditValidationErrors(validationInitialState); // Clear validation errors
	};

	const handleAllocationChange = (id: number, field: string, value: any) => {
		setSelectedRows(selectedRows.map((row) => (row.id === id ? { ...row, [field]: value } : row)));
	};

	const handleAddAllocation = () => {
		const generateRandomId = () => Math.floor(Math.random() * 1000000);
		// Remove the existing row with isNew: true, if any
		const rowsWithoutNew = selectedRows.filter((row) => !row.isNew);
		const newId = generateRandomId();

		const errors = {
			resource: !newAllocation.project, // Project is required
			allocationPercentage:
				!newAllocation.allocationPercentage ||
				Number(newAllocation.allocationPercentage) < 0 ||
				Number(newAllocation.allocationPercentage) > 100 ||
				isNaN(Number(newAllocation.allocationPercentage)), // Percentage must be between 0 and 100
			allocationStartTime: !newAllocation.allocationStartTime, // Start time is required
			allocationEndTime:
				!newAllocation.allocationEndTime ||
				(newAllocation.allocationStartTime &&
					!dayjs(newAllocation.allocationEndTime).isAfter(newAllocation.allocationStartTime)) // End time must be after start time
		};
		setValidationErrors(errors);

		// Add the new allocation to the updated list
		if (!Object.values(errors).some((error) => error)) {
			setSelectedRows([...rowsWithoutNew, { ...newAllocation, id: newId, isNew: false, isEditing: false }]);
			setNewAllocation({
				id: 0,
				isNew: false,
				isEditing: false,
				project: '',
				projectOwner: { name: '', email: '' },
				sectors: [],
				allocationPercentage: '',
				allocationStartTime: dayjs(),
				allocationEndTime: dayjs()
			});
			setValidationErrors(validationInitialState);
			setNewAllocationRecords({
				...newAllocationRecords,
				[newId]: { ...newAllocation, id: newId, isNew: false, isEditing: false }
			});
		}
	};

	useEffect(() => {
		console.log('new allocation records', newAllocationRecords);
	}, [newAllocationRecords]);

	const handleAddNewRow = () => {
		setValidationErrors(validationInitialState);
		// check there's only one new row at a time
		if (isNewAllocRowExists) return;
		setSelectedRows([
			...selectedRows,
			{
				id: 0,
				isNew: true,
				isEditing: false,
				project: '',
				projectOwner: { name: '', email: '' },
				sectors: [],
				allocationPercentage: '',
				allocationStartTime: dayjs(),
				allocationEndTime: dayjs()
			}
		]);
	};

	const handleConfirm = () => {
		// onConfirm();
		// Save the updated allocations to the server
        setResolveConflict(true);
		console.log('Saving allocations:', selectedRows);
	};

	const handleSave = () => {
		// Save the updated allocations to the state
		console.log('Saving allocations:', selectedRows);
	};

	const handleCancel = () => {

        setResolveConflict(true);
		// const newRecordIds = Object.keys(newAllocationRecords).map(Number);

		// setSelectedRows((prevRows) => prevRows.filter((row) => !newRecordIds.includes(row.id)));
		// setNewAllocationRecords({});
		// onCancel();
	};

	const handleDeleteRow = (id: number) => {
		setSelectedRows(selectedRows.filter((row) => row.id !== id));
		setNewAllocationRecords((prevRecords) => {
			const updatedRecords = { ...prevRecords };
			delete updatedRecords[id];
			return updatedRecords;
		});
	};

	// render sectors as chips
	const [expandedRows, setExpandedRows] = useState<{
		[key: number]: boolean;
	}>({});

	const toggleRowExpansion = (id: number) => {
		setExpandedRows((prev) => ({ ...prev, [id]: !prev[id] }));
	};

	const renderSectors = (sectors: string[], rowId: number) => {
		const isExpanded = expandedRows[rowId];
		const commonChipStyles = {
			color: 'white',
			whiteSpace: 'normal',
			overflow: 'visible',
			maxWidth: 'none'
		};

		const renderChips = (sectorsToRender: string[]) =>
			sectorsToRender.map((sector, index) => (
				<Chip key={index} label={sector} variant="filled" color="primary" sx={commonChipStyles} />
			));

		if (isExpanded) {
			return (
				<Box
					sx={{
						maxWidth: '100%',
						overflowX: 'auto',
						overflowY: 'hidden',
						display: 'flex',
						gap: 1,
						paddingBottom: '4px',
						'::-webkit-scrollbar': {
							height: '7px'
						}
					}}
				>
					{renderChips(sectors)}
					<Typography
						variant="subtitle1"
						sx={{ color: 'info.main', cursor: 'pointer' }}
						onClick={() => toggleRowExpansion(rowId)}
					>
						Show less
					</Typography>
				</Box>
			);
		}

		const visibleSectors = sectors.slice(0, 2);
		const remainingCount = sectors.length - visibleSectors.length;

		return (
			<>
				{renderChips(visibleSectors)}
				{remainingCount > 0 && !isExpanded && (
					<Typography
						variant="subtitle1"
						sx={{ color: 'info.main', cursor: 'pointer' }}
						onClick={() => toggleRowExpansion(rowId)}
					>
						+{remainingCount} more
					</Typography>
				)}
			</>
		);
	};

	function getColumns(): Array<any> {
		return columnNames.map((col: any, idx: number) => ({
			key: col.name,
			name: col.label,
			editable: () => col.isEditable,
			formatter({ row, column }: any) {
				// Add new row of input fields
				if (row.isNew) {
					if (col.isEditable && col.name === 'allocationPercentage') {
						return (
							<FormControl fullWidth error={validationErrors.allocationPercentage}>
								<TextField
									type="text"
									variant="outlined"
									label="Allocation %"
									value={newAllocation.allocationPercentage}
									sx={{
										'& .MuiOutlinedInput-root': {
											'& fieldset': {
												borderColor: 'text.focus'
											},
											'&:hover fieldset': {
												borderColor: 'text.focus'
											},
											'&.Mui-focused fieldset': {
												borderColor: 'text.focus'
											}
										},
										'& .MuiOutlinedInput-input': {
											fontSize: '12px',
											width: '9.5rem'
										}
									}}
									onChange={(e) => handleChange(col.name, e.target.value)}
								/>
								{validationErrors.allocationPercentage && (
									<FormHelperText sx={{ whiteSpace: 'normal', wordBreak: 'break-word', lineHeight: 1.5 }}>
										Allocation percentage must be between 0 and 100.
									</FormHelperText>
								)}
							</FormControl>
						);
					}
					if (col.name === 'allocationStartTime') {
						return (
							<FormControl fullWidth error={validationErrors.allocationStartTime}>
								<CustomDateTimePicker
									value={newAllocation.allocationStartTime}
									style={{
										'& .MuiOutlinedInput-input': {
											fontSize: '12px'
										},
										'& .MuiOutlinedInput-notchedOutline': {
											borderColor: `${colors.text.focus} !important`
										},
										'&:hover .MuiOutlinedInput-notchedOutline': {
											borderColor: `${colors.text.focus} !important`
										},
										'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
											borderColor: `${colors.text.focus} !important`
										}
									}}
									onChange={(newValue) => handleChange(col.name, dayjs(newValue))}
								/>
								{validationErrors.allocationStartTime && (
									<FormHelperText sx={{ whiteSpace: 'normal', wordBreak: 'break-word', lineHeight: 1.5 }}>
										Allocation Start date is required.
									</FormHelperText>
								)}
							</FormControl>
						);
					}

					if (col.name === 'allocationEndTime') {
						return (
							<FormControl fullWidth error={validationErrors.allocationEndTime}>
								<CustomDateTimePicker
									value={newAllocation.allocationEndTime}
									style={{
										'& .MuiOutlinedInput-input': {
											fontSize: '12px'
										},
										'& .MuiOutlinedInput-notchedOutline': {
											borderColor: `${colors.text.focus} !important`
										},
										'&:hover .MuiOutlinedInput-notchedOutline': {
											borderColor: `${colors.text.focus} !important`
										},
										'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
											borderColor: `${colors.text.focus} !important`
										}
									}}
									onChange={(newValue) => handleChange(col.name, dayjs(newValue))}
								/>
								{validationErrors.allocationEndTime && (
									<FormHelperText
										sx={{
											whiteSpace: 'normal',
											wordBreak: 'break-word',
											lineHeight: 1.5
										}}
									>
										Allocation End date is required and must be after start date.
									</FormHelperText>
								)}
							</FormControl>
						);
					}
					if (col.name === 'actions') {
						return (
							<Stack>
								<Button
									variant="pepwiseOutline"
									sx={{
										color: 'text.focus',
										width: '1.3rem',
										height: '1rem',
										padding: ' 25px 25px'
									}}
									onClick={handleAddAllocation}
								>
									SAVE
								</Button>
							</Stack>
						);
					}
				}

				// Cell editing field render
				if (col.isEditable && row.isEditing && col.name === 'allocationPercentage') {
					return (
						<FormControl fullWidth error={!!editValidationErrors.allocationPercentage}>
							<TextField
								sx={{
									'& .MuiOutlinedInput-root': {
										border: `1px solid ${colors.text.focus}`,
										'&.Mui-error': {
											'& .MuiOutlinedInput-notchedOutline': {
												borderColor: 'red'
											},
											'&:hover .MuiOutlinedInput-notchedOutline': {
												borderColor: 'red'
											},
											'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
												borderColor: 'red'
											}
										}
									},
									'& .MuiOutlinedInput-input': {
										fontSize: '12px',
										padding: '8px'
									}
								}}
								error={!!editValidationErrors.allocationPercentage}
								value={row[column.key]}
								onChange={(e) => {
									const value = e.target.value;
									handleAllocationChange(row.id, column.key, value);
									// Validate allocationPercentage
									if (!value || Number(value) < 0 || Number(value) > 100 || isNaN(Number(value))) {
										setEditValidationErrors((prev) => ({
											...prev,
											allocationPercentage: true
										}));
									} else {
										setEditValidationErrors((prev) => ({
											...prev,
											allocationPercentage: false
										}));
									}
								}}
							/>
							{/* {editValidationErrors.allocationPercentage && (
								<FormHelperText sx={{ whiteSpace: 'normal', wordBreak: 'break-word', lineHeight: 1.5 }}>
									Allocation percentage must be between 0 and 100.
								</FormHelperText>
							)} */}
						</FormControl>
					);
				}
				if (col.isEditable && row.isEditing && col.name === 'allocationStartTime') {
					return (
						<FormControl fullWidth error={!!editValidationErrors.allocationStartTime}>
							<CustomDateTimePicker
								style={{
									'& .MuiOutlinedInput-root': {
										'& .MuiOutlinedInput-notchedOutline': {
											borderColor: `${colors.text.focus}`
										},
										'&:hover .MuiOutlinedInput-notchedOutline': {
											borderColor: `${colors.text.focus}`
										},
										'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
											borderColor: `${colors.text.focus}`
										},
										'&.Mui-error': {
											'& .MuiOutlinedInput-notchedOutline': {
												borderColor: 'red'
											},
											'&:hover .MuiOutlinedInput-notchedOutline': {
												borderColor: 'red'
											},
											'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
												borderColor: 'red'
											}
										}
									},
									'& .MuiOutlinedInput-input': {
										fontSize: '12px',
										padding: '8px'
									}
								}}
								value={row[column.key]}
								error={!!editValidationErrors.allocationStartTime}
								onChange={(newValue) => {
									handleAllocationChange(row.id, column.key, newValue);
									// Validate allocationStartTime
									if (!newValue) {
										setEditValidationErrors((prev) => ({
											...prev,
											allocationStartTime: true
										}));
									} else {
										setEditValidationErrors((prev) => ({
											...prev,
											allocationStartTime: false
										}));
									}
								}}
							/>
							{/* {editValidationErrors.allocationStartTime && (
								<FormHelperText sx={{ whiteSpace: 'normal', wordBreak: 'break-word', lineHeight: 1.5 }}>
									Allocation Start date is required.
								</FormHelperText>
							)} */}
						</FormControl>
					);
				}
				if (col.isEditable && row.isEditing && col.name === 'allocationEndTime') {
					return (
						<FormControl fullWidth error={!!editValidationErrors.allocationEndTime}>
							<CustomDateTimePicker
								style={{
									'& .MuiOutlinedInput-root': {
										'& .MuiOutlinedInput-notchedOutline': {
											borderColor: `${colors.text.focus}`
										},
										'&:hover .MuiOutlinedInput-notchedOutline': {
											borderColor: `${colors.text.focus}`
										},
										'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
											borderColor: `${colors.text.focus}`
										},
										'&.Mui-error': {
											'& .MuiOutlinedInput-notchedOutline': {
												borderColor: 'red'
											},
											'&:hover .MuiOutlinedInput-notchedOutline': {
												borderColor: 'red'
											},
											'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
												borderColor: 'red'
											}
										}
									},
									'& .MuiOutlinedInput-input': {
										fontSize: '12px',
										padding: '8px'
									}
								}}
								value={row[column.key]}
								error={!!editValidationErrors.allocationEndTime}
								onChange={(newValue) => {
									handleAllocationChange(row.id, column.key, newValue);
									// Validate allocationEndTime
									if (!newValue || (row.allocationStartTime && newValue <= row.allocationStartTime)) {
										setEditValidationErrors((prev) => ({
											...prev,
											allocationEndTime: true
										}));
									} else {
										setEditValidationErrors((prev) => ({
											...prev,
											allocationEndTime: false
										}));
									}
								}}
							/>
							{/* {editValidationErrors.allocationEndTime && (
								<FormHelperText
									sx={{
										whiteSpace: 'normal',
										wordBreak: 'break-word',
										lineHeight: 1.5
									}}
								>
									Allocation End date is required and must be after start date.
								</FormHelperText>
							)} */}
						</FormControl>
					);
				}
				// Delete icon
				if (col.name === 'actions') {
					return row.isEditing ? (
						<Stack
							direction="column"
							alignItems="center"
							justifyContent="center"
							sx={{
								height: '100%',
								width: '100%'
							}}
						>
							<Button
								variant="pepwiseOutline"
								sx={{
									color: 'text.focus',
									width: '1.3rem',
									height: '1rem'
								}}
								onClick={() => {
									if (
										!editValidationErrors.allocationPercentage &&
										!editValidationErrors.allocationStartTime &&
										!editValidationErrors.allocationEndTime
									) {
										handleSaveEdit(row.id, {
											allocationPercentage: row.allocationPercentage,
											allocationStartTime: row.allocationStartTime,
											allocationEndTime: row.allocationEndTime
										});
									}
								}}
							>
								SAVE
							</Button>
						</Stack>
					) : (
						<Stack direction="row" alignItems="center" justifyContent="space-evenly">
							<EditIcon className="edit-icon" title="Click to Edit" onClick={() => handleEditRow(row)} />
							<DeleteIcon
								title="Delete Row"
								style={{ width: '30px', height: '30px' }}
								onClick={() => handleDeleteRow(row.id)}
							/>
						</Stack>
					);
				}
				return (
					<Stack direction="row" justifyContent="space-between" alignItems="center">
						<RowTypography isSuccessor={!row[column.key]}>
							{Array.isArray(row[column.key]) ? (
								<Stack direction="row" spacing={1}>
									{renderSectors(row[column.key], row.id)}
								</Stack>
							) : dayjs.isDayjs(row[column.key]) ? (
								row[column.key].format('YYYY-MM-DD HH:mm')
							) : typeof row[column.key] === 'object' ? (
								<>
									<Typography sx={{ fontSize: '12px' }}>{row[column.key].name}</Typography>
									<Typography sx={{ fontSize: '12px', fontStyle: 'italic', color: 'light.diff' }}>
										{row[column.key].email}
									</Typography>
								</>
							) : Array.isArray(row[column.key]) ? (
								<>
									{row[column.key].map((item: string, index: number) => (
										<Typography key={index}>{item}</Typography>
									))}
								</>
							) : column.key === 'allocationPercentage' ? (
								`${row[column.key]}%`
							) : (
								row[column.key]
							)}
						</RowTypography>
					</Stack>
				);
			},
			width: col.name === 'actions' ? 70 : 204
		}));
	}

	// scroll to table input field once add row clicked
	const gridRef = useRef<any>();
	useEffect(() => {
		if (isNewAllocRowExists) {
			if (gridRef.current) {
				gridRef.current.scrollToRow(selectedRows.length - 1);
			}
		}
	}, [selectedRows]);

	return !resolveConflict ? (
		<>
			<Stack
				direction="row"
				py={'0.5rem'}
				px={'1.5rem'}
				sx={{ backgroundColor: 'boxColor.orange.bgColor', display: 'flex', alignItems: 'center' }}
			>
				<WarningImage className="warning-image" />
				<Typography variant="tableColumnFont" sx={{ color: 'boxColor.orange.color', ml: '0.5rem' }}>
					Please utilize the remainining 20% of the allocated resource,ensure it is being utilized fully.
				</Typography>
			</Stack>
			<Stack
				sx={{
					flexDirection: 'column',
					pr: 3,
					pb: 3,
					pl: 3,
					gap: 2,
					textAlign: 'left',
					'& .rdg-cell': {
						'&:hover': {
							backgroundColor: 'inherit !important'
						}
					}
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						color: 'error.main',
						textAlign: 'center',
						width: '100%',
						fontSize: '12px'
					}}
				>
					{editValidationErrors.allocationPercentage && (
						<Typography>{editErrorMessages.allocationPercentage}</Typography>
					)}
					{editValidationErrors.allocationStartTime && <Typography>{editErrorMessages.allocationStartTime}</Typography>}
					{editValidationErrors.allocationEndTime && <Typography>{editErrorMessages.allocationEndTime}</Typography>}
				</Box>
				<Stack
					direction="row"
					justifyContent="space-between"
					spacing={12}
					pt={1}
					pb={4}
					marginBottom={1}
					borderBottom={`0.5px solid ${colors.dark.dark}`}
				>
					<Stack>
						<Typography sx={{ color: 'text.subtle' }}>Select Conflicting Resource</Typography>
						<FormControl fullWidth error={validationErrors.resource}>
							<AsyncAutoComplete
								textFieldLabel="Resource"
								inputValue={selectedResource}
								onChange={(e: any, value: string) => {
									setSelectedresource(value);

									// const selectedProjectValues = dummyData.find((item) => item.resource === value);

									// // Update ProjectOwner and sectors based on the selected project
									// if (selectedProjectValues) {
									// 	setNewAllocation((prevState) => ({
									// 		...prevState,
									// 		projectOwner: selectedProjectValues.projectOwner,
									// 		sectors: selectedProjectValues.sectors
									// 	}));
									// }
								}}
								mappingType="finopsResourceMapping"
								columnName="Resource"
								options={conflictResources}
								style={{ minWidth: '30rem' }}
								sx={{
									'& .MuiOutlinedInput-notchedOutline': {
										borderColor: `${colors.text.focus} !important`
									},
									'&:hover .MuiOutlinedInput-notchedOutline': {
										borderColor: `${colors.text.focus} !important`
									},
									'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
										borderColor: `${colors.text.focus} !important`
									}
								}}
							/>
							{validationErrors.resource && <FormHelperText>Select Conflicting Resource</FormHelperText>}
						</FormControl>
					</Stack>
					<Stack direction="row" gap={5}>
						{/* Red Conflict */}
						<Box display="flex" alignItems="center" gap={1}>
							<ErrorOutlineIcon style={{ color: 'red' }} />
							<Typography style={{ color: 'red', fontWeight: 'bold' }}>2 Conflicts</Typography>
						</Box>

						{/* Green Resolved */}
						<Box display="flex" alignItems="center" gap={1}>
							<CheckCircleOutlineIcon style={{ color: 'green' }} />
							<Typography style={{ color: 'green', fontWeight: 'bold' }}>1 Resolved</Typography>
						</Box>
					</Stack>
				</Stack>

				<CustomizedDataGrid
					title="Allocation"
					gridRef={gridRef}
					tableColumns={getColumns()}
					tableData={selectedRows}
					defaultColumnNames={defaultColumnNames}
					autoSetDraggedColumns={false}
					isRenderPagination={false}
					isShowMoreButton={false}
					style={{ maxHeight: '250px' }}
					rowHeight={(row: any) => {
						return row.row.isNew ? 110 : 40;
					}}
				/>
				<Stack>
					<Button
						variant="text"
						sx={{ width: '8rem', fontSize: '15px', color: 'text.focus' }}
						onClick={handleAddNewRow}
						disabled={!isNewAllocRowExists}
					>
						+ Add Row
					</Button>
				</Stack>
				<Stack direction="row" justifyContent="space-between">
					<Alert severity="info" sx={{ color: 'red' }}>
						<Typography sx={{ color: 'text.subtle', fontSize: '14px' }}>
							To make sure the resource is fully utilized and saved, please allocate it 100%. This will help us optimize
							our resources and avoid any potential issues.
						</Typography>
					</Alert>
					<Stack direction="row" gap={2} sx={{ alignSelf: 'flex-end' }}>
						<Button variant="pepwiseOutline" sx={{ width: '8rem' }} onClick={handleCancel}>
							SKIP & NEXT
						</Button>
						<Button variant="pepwiseSecondary" sx={{ width: '8rem' }} onClick={handleConfirm}>
							SAVE & NEXT
						</Button>
					</Stack>
				</Stack>
			</Stack>
		</>
	) : (
		<AddResourceSuccessModal
			bulkSelectedRows={bulkSelectedRows}
			onTitleChange={onTitleChange}
			onWidthChange={onWidthChange}
			modalTitle={modalTitle}
		/>
	);
};

export default ResolveAllocationConflictsModal;
