import {
	Box,
	Button,
	debounce,
	Dialog,
	Grid,
	InputAdornment,
	Snackbar,
	Stack,
	TextField,
	Typography
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { DataPipelineModal } from './modal-component';
import { ReactComponent as EditIcon } from '../../assets/edit-img.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete-img.svg';
import '../DataPipeline/dataPipeline.scss';
import {
	getProgramById,
	getProgramSectorList,
	getProjectListByProgram
} from '../../service/service';
import { CustomizedDataGrid } from '../customized_data_grid';
import moment from 'moment';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { usePepwiseAuthController } from 'Context/contexts/AuthContext';
import DataInfoSecondary from 'components/Widgets/DataInfoSecondary';
import PageHeader from 'components/Header/Page';
import DashboardIcon from '../../assets/material-symbols_dashboard.svg';
import ShowInfoWidget from 'components/Widgets/ShowInfoWidget';
import RowTypography from 'components/Table/RowTypography';
import StatusChip from 'components/Table/StatusChip';
import eventEmitter from 'SnackBarEventEmitter';
import DataInfoColorWidget from 'components/Widgets/DataInfoColorWidget';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { ReactComponent as DatePickerIcon } from '../../assets/calendar-outline_white.svg';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import SectorFilter from 'components/Dropdown/SectorFilter';
import CustomTextField from 'components/Inputs/CustomTextField';
import { Search } from '@mui/icons-material';
import { checkRole } from 'utils/roleUtils';

const ProgramDetails = () => {
	const [controller] = usePepwiseAuthController();
	const { role } = controller;
	const isAllowed = checkRole(role, ['Data Operations Admin', 'PepWise System User']);
	interface Row {
		id: number;
		Job_Name: string;
		Platform_Id: number;
		Start_Time: string;
		Normal_Completion_Time: number;
		slaStatus: number;
		jobStatus: string;
		estimatedTime: string;
		Days: number;
		actualStartTime: string;
		actualEndTime: string;
		scheduleStartTime: number;
		Date: string;
		successor: number;
	}
	const [currentFilterType, setCurrentFilterType] = useState<string>('');
	const [selectedRows, setSelectedRows] = useState<any>([]);
	const [isOrderChanged, setIsOrderChanged] = useState(false);
	const [accordionActiveId, setAccordionActiveId] = useState<any>(null);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [anchorElDetails, setAnchorElDetails] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorElDetails);
	const [sectorMasterList, setSectorMasterList] = useState<any[]>([]);
	const [sectorList, setSectorList] = useState<any[]>([]);
	const [sectorName, setSectorName] = useState('all');
	
	const handleThreeDotsClose = (event: any) => {
		event.preventDefault();
		event.stopPropagation();
		setAnchorEl(null);
		setAccordionActiveId(null);
	};

	const { id, name } = useParams();

	const handleDetailsThreeDotsClick = (event: React.MouseEvent<HTMLElement>) => {
		event.preventDefault();
		event.stopPropagation();
		setAnchorElDetails(event.currentTarget);
	};
	const handleDetailsThreeDotsClose = (event: any) => {
		event.preventDefault();
		event.stopPropagation();
		setAnchorElDetails(null);
	};

	const [startDate, setStartDate] = useState(moment().tz("America/Chicago").startOf('day').format('YYYY-MM-DD'));
	const [endDate, setEndDate] = useState(moment().tz("America/Chicago").endOf('day').format('YYYY-MM-DD'));
	const [projectList, setProjectList] = useState<any>([]);
	const [openModal, setOpenModal] = useState<any>({ isOpen: false, message: '' });
	const [programDetail, setProgramDetail] = useState<any>({});
	const [loading, setLoading] = useState(false);
	const [projectId, setProjectId] = useState('');
	const [tableLoading, setTableLoading] = useState(false);
	const [jobSchedulesList, setjobSchedulesList] = useState([]);
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	const defaultColumnNames = ['Sectors', 'Project', 'Delayed Start', 'Long Running', 'Failed', 'Success', 'SLA Status', 'SLA Time (CST)', 'Action'];
	const [filterData, setFilterData] = useState<any[]>([]);
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [searchDetails, setFilterSearchInfo] = useState([]);
	const [filterInfo, setFilterInfo] = useState([]);
	const [expanded, setExpanded] = useState<any>(false);
	const { prId, prName } = useParams();
	const [nameState, setNameState] = useState(prName);
	const [searchProject, setSearchProject] = useState('');
	const [toast, setToast] = useState<any>({
		open: false,
		message: ''
	});

	const navigate = useNavigate();

	const getColumns = () => {
		return [
			{
				key: 'Sectors',
				name: 'Sectors',
				formatter(props: any) {
					const sectorList = props.row.Sectors ? props.row.Sectors.split(', ') : [];
					if (sectorList && sectorList.length) {
						return sectorList.map((sector: string, idx: number) => <RowTypography sx={{ backgroundColor: 'rgba(41, 155, 255, 0.15)', color: '#fff', padding: '4px 10px', marginRight: '5px', borderRadius: '20px' }} key={idx} title={sector}>{sector}</RowTypography>);
					} else {
						return '--'
					}
				},
				minWidth: 150,
				maxWidth: 450
			},
			{
				key: 'Project_Name',
				name: 'Project',
				formatter(props: any) {
					return <Link to={`/data-pipeline/projects/${props.row.Program_ID}/${props.row.Project_Id}/${encodeURIComponent(programDetail?.program_Name)}/${encodeURIComponent(props.row.Project_Name)}/${startDate}`} state={props.row} title={props.row.Friendly_Name}>{props.row.Project_Name}</Link>
				},
				minWidth: 200,
				maxWidth: 500
			},
			{
				key: 'IP_Delayed_Start',
				name: 'Delayed Start',
				formatter(props: any) {
					if (props.row.IP_Delayed_Start && props.row.IP_Delayed_Start != 'NA') {
						return <StatusChip displayNumber={true} status='IP_Delayed_Start' value={props.row.IP_Delayed_Start ? props.row.IP_Delayed_Start : '--'} />
						// <Link to={`/data-pipeline/projects/${props.row.Program_ID}/${props.row.Project_Id}/${encodeURIComponent(programDetail?.program_Name)}/${encodeURIComponent(props.row.Project_Name)}/${startDate}?jobStatus=Delay Start&startingDate=${startDate}&endingDate=${endDate}`} state={props.row}>
							
						{/* </Link> */}
					} else {
						return <div style={{textAlign: 'center'}}>--</div>
					}
				},
				minWidth: 100
			},
			{
				key: 'IP_Long_Running',
				name: 'Long Running',
				formatter(props: any) {
					if (props.row.IP_Long_Running && props.row.IP_Long_Running != 'NA') {
						return <StatusChip displayNumber={true} status='IP_Long_Running' value={props.row.IP_Long_Running ? props.row.IP_Long_Running : '--'} />
						{/* </Link> */}
						//<Link to={`/data-pipeline/projects/${props.row.Program_ID}/${props.row.Project_Id}/${encodeURIComponent(programDetail?.program_Name)}/${encodeURIComponent(props.row.Project_Name)}/${startDate}?jobStatus=Long Running&startingDate=${startDate}&endingDate=${endDate}`} state={props.row}>
							
					} else {
						return <div style={{textAlign: 'center'}}>--</div>
					}
				},
				minWidth: 100
			},
			{
				key: 'Failed',
				name: 'Failed',
				formatter(props: any) {
					if (props.row.Failed && props.row.Failed != 'NA') {
						return <StatusChip displayNumber={true} status='JobsFailed' value={props.row.Failed ? props.row.Failed : '--'} />
						//<Link to={`/data-pipeline/projects/${props.row.Program_ID}/${props.row.Project_Id}/${encodeURIComponent(programDetail?.program_Name)}/${encodeURIComponent(props.row.Project_Name)}/${startDate}?jobStatus=Failure&startingDate=${startDate}&endingDate=${endDate}`} state={props.row}>
							
						{/* </Link> */}
					} else {
						return <div style={{textAlign: 'center'}}>--</div>
					}
				},
				minWidth: 100
			},
			{
				key: 'Success',
				name: 'Success',
				formatter(props: any) {
					if (props.row.Success && props.row.Success != 'NA') {
						return <StatusChip displayNumber={true} status='JobsSuccess' value={props.row.Success ? props.row.Success : '--'} />
						//<Link to={`/data-pipeline/projects/${props.row.Program_ID}/${props.row.Project_Id}/${encodeURIComponent(programDetail?.program_Name)}/${encodeURIComponent(props.row.Project_Name)}/${startDate}?jobStatus=Successful&startingDate=${startDate}&endingDate=${endDate}`} state={props.row}>
							
						// </Link>
					} else {
						return <div style={{textAlign: 'center'}}>--</div>
					}
				},
				minWidth: 100
			},
			{
				key: 'SLA_Status',
				name: 'SLA Status',
				formatter(props: any) {					
					if (props.row.SLA_Status == 'NA' || props.row.SLA_Status == '--') {
						return <div style={{textAlign: 'center'}}>{props.row.SLA_Status}</div>
					} else if (props.row.SLA_Status == '' || props.row.SLA_Status == null) {
						return ''
					}
					return <StatusChip displayNumber={true} status='ProjectSLAStatus' value={props.row.SLA_Status} />
				},
				minWidth: 200
			},
			{
				key: 'SLA_Time',
				name: 'SLA Time (CST)',
				formatter(props: any) {
					return (
						<RowTypography textTransform="capitalize">
							{
								props.row.SLA_Date != null && props.row.SLA_Date > 0 ? moment(props.row.Schedule_Date ? props.row.Schedule_Date.split('T')[0] : '').add(props.row.SLA_Date, 'days').format('MM-DD-YY')
								: moment(props.row.Schedule_Date ? props.row.Schedule_Date.split('T')[0] : '').format('MM-DD-YY')
							} {' '}
							{props.row.SLA_Time
								? props.row.SLA_Time < 10
									? `000${props.row.SLA_Time}`.replace(/(.{2})$/, ':$1')
									: props.row.SLA_Time < 100
										? `00${props.row.SLA_Time}`.replace(/(.{2})$/, ':$1')
										: props.row.SLA_Time.toString().replace(/(.{2})$/, ':$1')
								: '--'}
						</RowTypography>
					);
				},
				minWidth: 150
			},
			{
				key: 'action',
				name: 'Action',
				resizable: false,
				minWidth: 100,
				formatter: (props: any) => {
					return (
						<Stack direction="row" alignItems="center" height="100%">
							{isAllowed && (
								<><EditIcon style={{cursor: 'pointer'}} title={'click to edit'} onClick={(e) => handleAction('Project', 'edit', props.row, e)} /> 
								<DeleteIcon style={{cursor: 'pointer'}} title={'click to delete'} onClick={(e) => handleAction('Project', 'delete', props.row, e)} /></>
							)}
						</Stack>
					);
				}
			}
		];
	};

	const parseFilter = (filterInfo: any, name?: string) => {
		// Call only filterable columns and reset the filtering in case of wrong/empty column
		const parsedFilter: any = {};
		Object.keys(filterInfo).forEach((key: any) => {
			if (key !== name && filterInfo[key] && filterInfo[key].length) {
				parsedFilter[key] = filterInfo[key];
			}
		});
		return parsedFilter;
	};

	const handleFilter = (filterInfo: any) => {
		console.log('FILTER', filterInfo);
		setFilterInfo(filterInfo);
		getProjectsByProgram(parseFilter(filterInfo), currentFilterType);
	};

	const handleSearch = (e: any) => {
		console.log('FILTER', e);
	};

	const handleSorting = (sortingInfo: any) => {
		
		getProjectsByProgram(parseFilter(filterInfo), currentFilterType, page, pageSize, startDate, endDate, sortingInfo, getSectorId());
		// projectMappedJobScheduleFunc(page, pageSize, startDate, endDate, parseFilter(filterInfo), sortingInfo);
		setSortingInfo(sortingInfo)
	};

	const onClickWidget = (type: string) => {
		setCurrentFilterType(type);
		// filterInfo?: any, currentFilterType?: string, page?: number, pageSize?: number, startDate?: string, endDate?: string, sortingInfo?: any, sectorId?: number, projName?: string
		if (type) {
			getProjectsByProgram(parseFilter(filterInfo), type, page, pageSize, startDate, endDate, sortingInfo, getSectorId());
		} else {
			getProjectsByProgram({}, type, page, pageSize, startDate, endDate, sortingInfo, getSectorId());
		}
	}

	eventEmitter.on('showSnackbar', (message: string) => {
		setLoading(false);
		setTableLoading(false)
	});

	useEffect(() => {
		const activeId = document.getElementById('root');
		if (window.location.pathname.includes('/data-pipeline/programs')) {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
			}
		}
		if (localStorage.getItem('filterSectors') && localStorage.getItem('sectorsMasterData')) {
			setSectorList(JSON.parse(localStorage.getItem('filterSectors') ?? ''));
			setSectorMasterList(JSON.parse(localStorage.getItem('sectorsMasterData') ?? ''));
		} else {
			getSectorData();
		}
		getProgramDetails(getSectorId());
		getProjectsByProgram(parseFilter(filterInfo), currentFilterType, 1, 100, startDate, endDate, sortingInfo, getSectorId());
	}, []);	

	const handleSectorChange = (value: string) => {
		setSectorName(value);
		setSearchProject('');
		localStorage.setItem('selectedSector', value);
		let sectorId;
		if (value != 'all') {
			sectorId = sectorMasterList.filter((s: any) => s.Sector_Name == value)[0].Sector_Id
		}
		setProjectList([]);
		getProgramDetails(sectorId);
		getProjectsByProgram(parseFilter(filterInfo), currentFilterType, 1, 100, startDate, endDate, {sortBy: '', sortDir: ''}, sectorId);
	};

	const onProjectSearch = (value: string) => {
		setSearchProject(value)
		debouncedFetchOptions(value)
	}

	const debouncedFetchOptions = useMemo(
		() =>
			debounce(async (value) => {
				
				getProjectsByProgram(parseFilter(filterInfo), currentFilterType, 1, 100, startDate, endDate, {sortBy: '', sortDir: ''}, getSectorId(), searchProject || value);
				
			}, 500),
		[]
	);


	

	useEffect(() => {
		if (openModal && !openModal.isOpen && openModal.closeType && openModal.closeType == 'updated') {
			let sectorId;
			if (sectorName && sectorName != 'all') {
				sectorId = sectorMasterList.filter((s: any) => s.Sector_Name == sectorName)[0].Sector_Id
			}
			setSearchProject('');
			getProgramDetails(sectorId);
			getProjectsByProgram(parseFilter(filterInfo), currentFilterType, 1, 100, startDate, endDate, sortingInfo, sectorId);
		}
	}, [openModal]);
		
	const getSectorData = async () => {
		let sectors: any = await getProgramSectorList();
		const filterSectors = sectors.data.sectors.map((sec: any) => sec.Sector_Name);
		localStorage.setItem('filterSectors', JSON.stringify(filterSectors));
		localStorage.setItem('sectorsMasterData', JSON.stringify(sectors.data.sectors));
		setSectorList(filterSectors);
		setSectorMasterList(sectors.data.sectors);
	};

	const passInfoToParent = (data: any) => {
		getProjectsByProgram(parseFilter(filterInfo), currentFilterType, 1, 100);
	};

	const getProgramDetails = async (sectorId?: number, date?: string) => {
		setLoading(true);
		let result = await getProgramById(id, sectorId, date || startDate);
		setLoading(false);
		setProgramDetail(result.data?.programData ? result.data.programData[0] : '');
		if (!result.data.programData.length) {
			navigate(`/data-pipeline/programs`)
		}
	};

	const getProjectsByProgram = async (filterInfo?: any, currentFilterType?: string, page?: number, pageSize?: number, stDate?: string, edDate?: string, sortingInfo?: any, sectorId?: number, projName?: string) => {
		setTableLoading(true);
		console.log(sortingInfo)
		stDate = stDate || startDate
		edDate = edDate || endDate
		sectorId = sectorId || getSectorId()
		let result = await getProjectListByProgram(id, page, pageSize, currentFilterType, filterInfo, stDate, edDate, sortingInfo, sectorId, projName || '');
		setTimeout(() => {
			let table = document.getElementById(`project_list${id}`);
			if (table) {
				const rdgElement = table.querySelector('.rdg') as HTMLElement | null;
				if (rdgElement) {
					let jobsLength = result.data.projectList.length;
					let height = 80;
					if (jobsLength === 1) height = jobsLength * 80;
					else if (jobsLength > 1 && jobsLength <= 3) height = jobsLength * 65;
					else if (jobsLength > 3 && jobsLength <= 5) height = jobsLength * 55;
					else if (jobsLength > 5 && jobsLength <= 10) height = jobsLength * 50;
					else if (jobsLength > 10 && jobsLength <= 15) height = jobsLength * 40;
					else height = jobsLength * 30;
					rdgElement.style.height = `${height}px`;
				}
			}
		}, 100);
		setPaginationData(result.data.page);
		setTableLoading(false);
		const projData = result.data.projectList.map((list: any) => {
			list['activeRow'] = 0;
			const selectedSectors = list.Sectors && list.Sectors.split(', ');
			list['Sectors'] = selectedSectors && selectedSectors.length >= 10 ? ['All 10 Sectors'] : list.Sectors;
			return list;
		});
		const filtersData: any = {
			Sectors: [], Project_Name: [], SLA_Status: []
		}
		if(result.data.filterOptions){
			Object.entries(result.data.filterOptions).filter((filter: any) => {
				if (filter[0] == 'sector' || filter[0] == 'Sector_Name') {
					filtersData['Sectors'] = filter[1]
				} 
				else if (filter[0] == 'Project_Name' || filter[0] == 'projectName') {
					filtersData['Project_Name'] = filter[1]
				} else if (filter[0] == 'SLA_Status' || filter[0] == 'projectSlaStatus') {
					filtersData['SLA_Status'] = filter[1].filter((status: string) => status)
				}
			})
		}
		setFilterData(filtersData);
		setFilterInfo(filterInfo)
		setProjectList(projData);
	};

	const handlePageChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
		getProjectsByProgram(parseFilter(filterInfo), currentFilterType, newPage, pageSize, startDate, endDate, sortingInfo, getSectorId());
	};
	const handlepageSizeChange = (event: any) => {
		const newPageSize = event.target.value;
		setPageSize(newPageSize);
		getProjectsByProgram(parseFilter(filterInfo), currentFilterType, page, newPageSize, startDate, endDate, sortingInfo, getSectorId());
	};

	const handleEditClose = (closeType: any) => {
		setOpenModal({
			...openModal,
			isOpen: false,
			closeType: closeType
		});
		setSelectedRows([]);
	};

	const onCreateProgramClick = (event: any) => {
		setOpenModal({
			...openModal,
			isOpen: true,
			type: 'Project',
			action: 'CREATE'
		});
	};

	const handleAction = (module: string, type: string, data: any, e: any) => {
		e.preventDefault();
		e.stopPropagation();
		handleThreeDotsClose(e);
		handleDetailsThreeDotsClose(e);
		data = {
			...data,
			ProgramName: programDetail?.program_Name,
			ProgramID: data.Program_ID
		};

		switch (type) {
			case 'edit':
				setOpenModal({
					...openModal,
					isOpen: true,
					type: module,
					action: 'EDIT',
					data: module === 'Program' ? data : data
				});
				break;
			case 'delete':
				setOpenModal({
					...openModal,
					isOpen: true,
					type: module,
					action: 'DELETE',
					data: module === 'Program' ? data : data
				});
				break;
			case 'view':
				window.location.href = `/data-pipeline/projects/${data.Program_ID}/${data.Project_Id}/${encodeURIComponent(
					data.ProgramName
				)}/${encodeURIComponent(data.Project_Name)}`;
				break;
			default:
				break;
		}
	};

	const handleClose = () => {
		setToast({
			...toast,
			open: false
		});
	};

	const getToastData = (data: any) => {
		setToast({
			...toast,
			message: data.data.message
		});
	};

	const getSectorId = () => {
		let sectorId;
		let selectedSctr = localStorage.getItem('selectedSector')
		if (selectedSctr && selectedSctr != 'all') {
			setSectorName(selectedSctr)
			const sectorList = JSON.parse(localStorage.getItem('sectorsMasterData') ?? '')
			if (sectorList) {
				sectorId = sectorList.filter((s: any) => s.Sector_Name == selectedSctr)[0].Sector_Id
			}
		}
		return sectorId;
	}

	const handleDateSelect = (stDate: any) => {
		setSearchProject('');
		const sd = moment(stDate).startOf('day').format('YYYY-MM-DD');
		const ed = moment(stDate).startOf('day').format('YYYY-MM-DD');
		// let cstDate = moment(stDate).tz("America/Chicago").format('YYYY-MM-DD');
		setStartDate(sd)
		setEndDate(ed)
		getProgramDetails(getSectorId(), sd)
		getProjectsByProgram(parseFilter(filterInfo), currentFilterType, 1, 100, sd, ed, sortingInfo, getSectorId());
	}

	return (
		<Box mt="45px" bgcolor="blue.darker">
			<PageHeader
				icon={DashboardIcon}
				title={[
					{
						text: 'Programs',
						link: '/data-pipeline/programs'
					},
					{
						text: decodeURIComponent(window.location.pathname.split('/')[4].replace(/%20/g, ' '))
					}
				]}
			>
			<SectorFilter 
				sectorName={sectorName}
				setSectorName={setSectorName}
				sectorOptionsList={sectorList}
				handleChange={handleSectorChange} 
				boxProps={{
					sx: {minWidth: '200px', position: 'static'},
				}}/>
			{<LocalizationProvider className="outerDateContainer" dateAdapter={AdapterMoment}>
						<DatePicker
							showToolbar={false}
							components={{
								OpenPickerIcon: DatePickerIcon
							}}
							PopperProps={{ placement: 'auto' }}
							minDate={moment(new Date('2024-10-01T06:00:00Z'))}
							disableFuture={true}
							className="dateFilterDataPipeline"
							value={moment(startDate)}
							onChange={(dateTime) => {
								handleDateSelect(dateTime);
							}}
							renderInput={(params: any) => <TextField name="startDateTime" {...params} />}
						/>
					</LocalizationProvider>
						}
				{/* <ReactDateRangeFilter isDisabled={true} setStartDate={setStartDate} setEndDate={setEndDate} handleDateSelect={handleDateSelect} /> */}

				{isAllowed && (
					<Button variant="pepwiseSecondary" onClick={onCreateProgramClick} sx={{ height: '43px'}}>
						ADD PROJECT
					</Button>
				)}
			</PageHeader>

			<Dialog open={openModal.isOpen} onClose={handleEditClose} fullWidth={true} maxWidth="md">
				<DataPipelineModal
					handleEditClose={handleEditClose}
					programData={openModal}
					getToastData={getToastData}
					passInfoToParent={passInfoToParent}
				/>
			</Dialog>
			<Box p={3} pr={2} mt={3}>
				<Grid container spacing={2}>
					<Grid item xs={12} lg={5}>
						<DataInfoSecondary
							infoCards={[
								{
									label: 'Program Name',
									value: programDetail?.program_Name,
									isLoading: !programDetail?.program_Name,
									valueProps: {
										lineHeight: '24px'
									}
								}
							]}
							infoTooltip={{
								dateValues: [
									{ label: 'Date Created', value: programDetail?.Created_Dt ?? 'NA' },
									{ label: 'Last Updated', value: programDetail?.Updated_Dt ?? 'NA' }
								]
							}}
							infoOptionMenu={{
								onClick: handleDetailsThreeDotsClick,
								onClose: handleDetailsThreeDotsClose,
								menuItems: [
									{
										key: 'edit',
										label: 'Edit',
										icon: <EditIcon />,
										onMenuClick: (e) => handleAction('Program', 'edit', programDetail, e)
									},
									{
										key: 'delete',
										label: 'Delete',
										icon: <DeleteIcon />,
										onMenuClick: (e) => handleAction('Program', 'delete', programDetail, e)
									}
								]
							}}
							isAdmin = {isAllowed ? true : false}
						/>
					</Grid>


					<Grid item container xs={12} lg={7} spacing={1}>
						{[
							{
								title:sectorName != 'all' ? sectorName + ' Projects' : 'Total Projects',
								value: programDetail?.ProjectCount || 0,
								isLoading: loading,
								color: ''
							},
							{
								title: 'Completed Jobs',
								value: (programDetail?.SLA_Met_Count || 0) + (programDetail?.SLA_Missed_Count || 0),
								isLoading: loading,
								color: 'solidBlue'
							},
							{
								title: 'SLA Met',
								value: programDetail?.SLA_Met_Count || 0,
								isLoading: loading,
								color: 'pgmGreen'
							},
							{
								title: 'SLA Missed',
								value: programDetail?.SLA_Missed_Count || 0,
								isLoading: loading,
								color: 'pgmPink'
							},
							{
								title: 'In Progress',
								value: programDetail?.In_Progress_Count || 0,
								isLoading: loading,
								color: 'pgmYellow'
							},
							{
								title: 'Scheduled',
								value: programDetail?.Scheduled_Count || 0,
								isLoading: loading,
								color: 'paleGray'
							}
						].map(({ title, value, color, isLoading }) => (
							<Grid key={title} item xs={4} sm={2} display="flex">
								<DataInfoColorWidget title={title} style={{ cursor: value ? 'pointer' : 'default' }} onClick={(e) => value ? !title.includes('Projects') ? onClickWidget(title) : onClickWidget('') : false} value={value} boxColor={color} isLoading={isLoading} />
							</Grid>
						))}
					</Grid>


				</Grid>
				<Box sx={{position: 'relative'}}>
				<Typography mt={3} variant="overlayWidgetTitle" style={{position: 'absolute'}} component="span"> Projects</Typography>
					{ programDetail?.ProjectCount > 0 && <CustomTextField
						placeholder="Search Projects"
						value={searchProject}
						onChange={(e) => onProjectSearch(e.target.value)}
						style={{width: '22rem', position: 'absolute', top: '11px', right: '70px', backgroundColor: 'rgba(0, 35, 73, 1)', borderRadius: '5px', zIndex: '99'}}
						sx={{
							backgroundColor: 'rgba(0, 35, 73, 1)'
						}}
						InputLabelProps={{
							sx: {
								backgroundColor: 'rgba(0, 35, 73, 1)'
							}
						}}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Search sx={{ color: 'white.main' }} />
								</InputAdornment>
							),
							sx: {
								'& .MuiInputBase-input': {
									padding: '14px',
									paddingLeft: 0
								},
								'& .MuiOutlinedInput-notchedOutline': {
									borderColor: 'dark.dark',
									borderWidth: '0px',
									borderStyle: 'solid'
								},
								'&:hover .MuiOutlinedInput-notchedOutline': {
									borderColor: 'dark.dark',
									borderWidth: '0px'
								},
								'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
									borderColor: 'dark.dark',
									borderWidth: '0px'
								}
							}
						}}
					/>
					}
				</Box>
				{programDetail?.ProjectCount > 0 && !tableLoading ? (
					<Box sx={{position: 'relative'}}>
					

					
				
					{projectList.length > 0 && (
						<Box>
							<CustomizedDataGrid
								tableColumns={getColumns()}
								tableData={projectList}
								tableType="program-list"
								defaultColumnNames={defaultColumnNames}
								onSorting={handleSorting}
								currSorting={sortingInfo}
								filterDataOptions={filterData}
								searchDataValues={searchDetails}
								onFilterCheck={handleFilter}
								currFilter={filterInfo}
								onFilterSearch={handleSearch}
								page={page}
								pageSize={pageSize}
								paginationData={paginationData}
								listLength={projectList.length}
								handlepageSizeChange={handlepageSizeChange}
								handlePageChange={handlePageChange}
								isFetching={tableLoading}
								showExport={false}
								isDateRangeFilter={false}
								isDisabled={false}
								isShowMoreButton
							/>
						</Box>
					)}
					{ !projectList.length && <Stack justifyContent="center" alignItems="center" height="60vh">
						<Typography variant="pageTitle" color="dark.main">
							No data for the selected filters
						</Typography>
					</Stack>}
					</Box>
				) : tableLoading ? (
					<Stack justifyContent="center" alignItems="center" height="60vh">
						<Typography variant="pageTitle" color="dark.main">
							Loading...
						</Typography>
					</Stack>
				) : (
					<ShowInfoWidget 
							title="No Projects has been added yet" 
							height="50vh" 
							button="+ ADD New Project" 
							onClick= {onCreateProgramClick}
							  />
				)}
			</Box>
			<Snackbar
				open={ toast && openModal.message ? true : false }
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				autoHideDuration={6000}
				onClick={handleClose}
				message={openModal.message}
			/>
		</Box>
	);
};

export default ProgramDetails;
