import { Box, Stack, Typography, TypographyProps } from '@mui/material';
import DateInfoTooltip, { DateInfoTooltipProps } from 'components/Tooltip/DateInfoTooltip';
import { ReactComponent as InfoGreyIcon } from '../../assets/icon-grey-info.svg';
import MoreOptionMenu, { MoreOptionMenuProps } from 'components/Dropdown/MoreOptionSecondary';
import { ReactComponent as MoreIcon } from '../../assets/more-vertical.svg';
import LoaderComponent from 'components/Loader';

type DataInfoSecondaryProps = {
	infoCards: {
		label: string | number;
		value: string | number;
		isLoading?: boolean;
		labelProps?: TypographyProps;
		valueProps?: TypographyProps;
		width?:string;
		height?:string;
		minWidth?: string;
	}[];
	bgColor?: string;
	infoTooltip?: Omit<DateInfoTooltipProps, 'children'>;
	infoOptionMenu?: MoreOptionMenuProps;	
	isAdmin?: boolean;
};

const DataInfoSecondary = ({ infoCards, infoTooltip, bgColor = 'primary.main', infoOptionMenu, isAdmin }: DataInfoSecondaryProps) => {
	return (
		<Box display="flex" height="100%">
			<Box py={1} px={2} display="flex" flexGrow={1} alignItems="center" bgcolor={bgColor} borderRadius="5px">
				{infoCards.map(({ label, value, labelProps, valueProps, isLoading, minWidth, width, height }) => (
					<Box
						key={`${label}-${value}`}
						display="flex"
						flexGrow={1}
						sx={{
							width: width ? width : undefined,
							minWidth: width ? undefined : minWidth || 'auto',
							height: height ? height : undefined
						}}
					>
						<Stack gap={0.5}>
							<Typography fontWeight={500} fontSize="16px" lineHeight="normal" color="text.subtle" {...labelProps}>
								{label}
							</Typography>
							{isLoading ? (
								<LoaderComponent size={24}/>
							) : (
								<Typography fontWeight={600} fontSize="16px" lineHeight="normal" {...valueProps}>
									{value}
								</Typography>
							)}
						</Stack>
					</Box>
				))}
				<Box display="flex" alignItems="center" gap={1}>
					{!!infoTooltip && (
						<DateInfoTooltip {...infoTooltip}>
							<InfoGreyIcon />
						</DateInfoTooltip>
					)}
					{!!infoOptionMenu && isAdmin && (
						<MoreOptionMenu {...infoOptionMenu}>
							<MoreIcon />
						</MoreOptionMenu>
					)}
				</Box>
			</Box>
		</Box>
	);
};

export default DataInfoSecondary;
