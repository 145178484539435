import { Chip, ChipProps } from '@mui/material';

const StatusChip: React.FC<{ status: string, value?: number | string, displayNumber?: boolean, columnName?: string } & ChipProps> = ({ status, value, displayNumber, columnName, ...props }) => {
	let bgColor = '#299BFF26';
	let color = '#fff';
    status = status ? status : '--'
	switch (status?.toUpperCase()) {
		case 'SUCCESS':
		case 'SUCCESSFUL':
		case 'YES':
		case 'COMPLETED':
		case 'ONLINE':	
		case 'CLOSED':
		case 'SUCCEEDED':
			bgColor = '#17372e';
			color = '#01c008';
			break;

		
		case 'OPEN':
			bgColor = '#299bff26';
			color = '#299bff';
			break;

		case 'FAILURE':
		case 'TERMINATED':
		case 'SUSPENDED':
		case 'NO':
		case 'FAILED':
			bgColor = columnName == 'jobStatus' ? 'rgba(216, 43, 81, 0.3)' : '#3d2033';
			color = columnName == 'jobStatus' ? 'rgba(216, 43, 81, 1)' : '#cd2429';
			break;

		
		case 'IN PROGRESS':
			bgColor = 'rgba(255, 162, 0, 0.3)';
			color = 'rgba(255, 162, 0, 1)';
			break;

		case 'SLA MISSED':
			bgColor = 'rgba(253, 120, 59, 0.3)';
			color = 'rgba(253, 120, 59, 1)';
			break;

		case 'Not Met':
		case 'NOT MET':
		case 'Sla Missed':
		case 'IN PROGRESS':
			bgColor = 'rgba(255, 162, 0, 0.2)';
			color = 'rgba(255, 162, 0, 1)';
			break;

		case 'NOT STARTED':
			bgColor = '#1B1E35CC';
			color = '#71758F';
			break;

		case 'N/A':
		case 'NA':
		case 'Scheduled':
		case 'SCHEDULED':
			bgColor = 'rgba(169, 173, 203, 0.2)';
			color = 'rgba(169, 173, 203, 1)';
			break;

		// case 'SCHEDULED':
		// 	bgColor = '#ffa20033';
		// 	color = '#ffa200';
		// 	break;

		case 'RUNNING':
		case 'ACTIVATED':
		case 'IN PROGRESS':
		case 'HOLD':
		case 'FROZEN':
			bgColor = '#299BFF26';
			color = '#299BFF';
			break;
		
		case 'SLA MET':
		case 'MET':
		case 'Sla Met':
		case 'HEALTHY':
		case 'SUCCESSFUL':
			bgColor = 'rgba(1, 192, 8, 0.2)';
			color = 'rgba(1, 192, 8, 1)';
			break;
		case 'OFFLINE':	
		case 'THROTTLED':
			bgColor = '#FE262626';
			color = '#FE2626';
			break;
		case 'DELAYEDSTART':
			bgColor = value == '--' ? 'transparent' : 'rgba(255, 56, 176, 0.2)';
			color = value == '--' ? '#F5F5F5' : 'rgba(255, 56, 176, 1)';
			break;
		case 'FAILED TO START':
				bgColor = value == '--' ? 'transparent' : 'rgba(160, 129, 255, 0.2)';
				color = value == '--' ? '#F5F5F5' : 'rgba(160, 129, 255, 1)';
				break;
		case 'LONGRUNNING':
			bgColor = value == '--' ? 'transparent' : 'rgba(253, 149, 50, 0.2)';
			color = value == '--' ? '#F5F5F5' : 'rgba(253, 149, 50, 1)';
			break;
		case 'JOBSFAILED':
			bgColor = value == '--' ? 'transparent' : 'rgba(254, 38, 38, 0.15)';
			color = value == '--' ? '#F5F5F5' : 'rgba(254, 38, 38, 1)';
			break;
		case 'JOBSSUCCESS':
			bgColor = value == '--' ? 'transparent' : 'rgba(41, 155, 255, 0.2)';
			color = value == '--' ? '#F5F5F5' : 'rgba(41, 155, 255, 1)';
			break;
		case 'PROJECTSLASTATUS':
			bgColor = value == '--' ? 'transparent' : value == 'SLA Met' ? 'rgba(1, 192, 8, 1)' : value == 'SLA Missed' ? 'rgba(216, 43, 81, 1)' : value == 'Scheduled' ? 'rgba(169, 173, 203, 0.2)' : value == 'In Progress' ? 'rgba(236, 185, 20, 1)' :  '#2a4444';
			color = value == '--' ? '#F5F5F5' : value == 'Scheduled' ? 'rgba(169, 173, 203, 1)' : 'rgba(255, 255, 255, 1)';
			break;
		case '--':
			bgColor = 'transparent';
			color = '#fff';
			break;
		default:
			bgColor = '#299BFF26';
			color = '#fff';
			break;
		}

	const chipProps: ChipProps = {
		label: displayNumber ? value : status,
		sx: {
			borderRadius: status == 'ProjectSLAStatus' || columnName == 'jobStatus' || columnName == 'totalExecutions' ? '50px' : '5px',
			'&.MuiChip-filled': {
				height: displayNumber ? '30px' : '20px',
				maxWidth: '100%',
				width: displayNumber ? '100%' : 'fit-content'
			},
			'&.MuiChip-filled, & .MuiChip-label': {
				margin: 0,
				fontFamily: 'Montserrat',
				fontWeight: value == '--' ? 300: 600,
				fontSize: displayNumber ? value == '--' ? '12px' : '14px': '10px',
				textTransform: 'uppercase'
			},
			// !important is given, becoz mui chip style is being overridden by other css files, remove !important once css files are removed
			bgcolor: `${bgColor} !important`,
			color: `${color} !important`
		},
		...props
	};

	return <Chip {...chipProps} />;
};

export default StatusChip;
