import React, { useEffect, useState, useRef } from 'react';
import {
    Box,
	Button,
	ButtonGroup,
	Checkbox,
	FormControl,
	FormControlLabel,
	Grid,
	MenuItem,
	Popover,
	Select,
	Stack,
	Typography
} from '@mui/material';
import { powerBICapacityMetricsData } from '../../service/service';
import { powerBICapacityDetailData } from '../../service/service';
import '../PowerBIObservability/powerBIObs.scss';
import { CustomizedDataGrid } from '../customized_data_grid';
import { Column } from 'react-data-grid';
import { useSearchParams } from 'react-router-dom';
import { powerBICapacitySearch } from '../../service/service';
import ReactECharts from 'echarts-for-react';
import Loader from 'components/Loader';
import NumberWidget from 'components/Widgets/NumberWidget';
import LoaderComponent from 'components/Loader';
import RowTypography from 'components/Table/RowTypography';
import StatusChip from 'components/Table/StatusChip';
import BarChartWidget from 'components/Widgets/BarChartWidget';
import SectorCheckFilter from 'components/Dropdown/SectorCheckFilter';
import PageHeader from 'components/Header/Page';
import AdoptionMetricsLogo from 'assets/dataset_obs.svg';
import CustomDropdown from 'components/Dropdown';
import DateFilterDropdown from 'components/Dropdown/DateFilter';

const AdoptionMetrics = () => {
	interface Row {
		sector: string;
		capacity: string;
		capacitySKU: string;
		workspace: string;
		reportName: string;
		userName: string;
		groupUserAccess: string;
		workspaceOwner: string;
		reportOwner: string;
		lastLogin: string;
		capacityOwner: string; 
	}

	function getColumns(): Array<Column<Row>> {
		return [
			{
				key: 'sector',
				name: 'Sector',
				width: 190,
				minWidth: 190,
			},
			{
				key: 'capacity',
				name: 'Capacity',
				width: 150,
				minWidth: 150,
			},
			{
				key: 'capacitySKU',
				name: 'Capcity SKU',
				width: 230,
				minWidth: 230,
			},
			{
				key: 'workspace',
				name: 'Workspace',
				width: 180,
				minWidth: 180,
			},
			{
				key: 'reportName',
				name: 'Report Name',
				width: 180,
				minWidth: 180,
			},
			{
				key: 'userName',
				name: 'User Name',
				width: 180,
				minWidth: 180,
			},
			{
				key: 'groupUserAccess',
				name: 'Group User Access',
				width: 200,
				minWidth: 200,
			},
			{
				key: 'workspaceOwner',
				name: 'Workspace Owner',
				width: 180,
				minWidth: 180,
			},
			{
				key: 'reportOwner',
				name: 'Report Owner',
				width: 200,
				minWidth: 200,
			},
			{
				key: 'lastLogin',
				name: 'Last Login',
				width: 200,
				minWidth: 200,
			},
			{
				key: 'capacityOwner',
				name: 'Capacity Owner',
				width: 230,
				minWidth: 230,
			},
		];
	}

	const [loading, setLoading] = useState(false);
	const [tableLoading, setTableLoading] = useState(false);
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	const [drillDownStatusfilterInfo, setDrilldownStatusFilterInfo] = useState([]);
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [filterData, setFilterData] = useState<Row[]>([]);
	const [filterInfo, setFilterInfo] = useState([]);
	const [searchDetails, setFilterSearchInfo] = useState([]);
	const [searchParams, setSearchParams] = useSearchParams();
	const [currSectorFilter, setCurrSectorFilter] = useState<any[]>([]);
	const dataFetchedRef = useRef(false);
	const hasDrillDown = searchParams.has('isDrillDowned');
	const [chartLoadingCapacity, setChartLoadingCapacity] = useState(false);
	const [chartLoadingGateway, setChartLoadingGateway] = useState(false);
	const [chartLoadingReports, setChartLoadingReports] = useState(false);
	const [chartLoadingUsers, setChartLoadingUsers] = useState(false);
	const [chartOptionCapacity, setChartOptionCapacity] = useState<any>();
	const [chartOptionGateway, setChartOptionGateway] = useState<any>();
	const [chartOptionReports, setChartOptionReports] = useState<any>();
	const [chartOptionUsers, setChartOptionUsers] = useState<any>();
	const [timePeriodCapacity, setTimePeriodCapacity] = useState('Monthly');
	const [timePeriodGateway, setTimePeriodGateway] = useState('Monthly');
	const [timePeriodReports, setTimePeriodReports] = useState('Monthly');
	const [timePeriodUsers, setTimePeriodUsers] = useState('Monthly');
	const [adoptionDetails, setAdoptionDetails] = useState<Row[]>([]);
	const checkedListRef = useRef<any>([]);
	const [customMonth, setCustomMonth] = useState('');
	const [filterMonth, setFilterMonth] = useState('0Y');

	const defaultColumnNames = [
		'Sector',
		'Capacity',
		'Capacity SKU',
		'Workspace',
		'Report Name',
		'Username',
		'Group User Access',
		'Workspace Owner',
		'Report Owner',
		'Last Login',
		'Capacity Owner',
		'Workspace Owner',
		'Capacity Owner'
	];


	const handleOkClick = () => {
		const updatedCheckedList = [...checkedListRef.current]; // Just copying the checkedList
		if (updatedCheckedList.length > 0) {
			setCurrSectorFilter(updatedCheckedList);
		} else {
			setCurrSectorFilter([]);
		}
		// updateSharedValue({ sectorFilter: updatedCheckedList });
		// fetchData();
		setFilterInfo([]);
		sortingInfo.sortBy = '';
		sortingInfo.sortDir = '';
		adoptionDetailsFunc(page, pageSize, sortingInfo, parseFilter(filterInfo));
		// fetchChartData();
	};

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		adoptionDetailsFunc(value, pageSize, sortingInfo, parseFilter(filterInfo));
	};

	const handlepageSizeChange = (event: any) => {
		adoptionDetailsFunc('1', event.target.value, sortingInfo, parseFilter(filterInfo));
	};
	const handleSorting = (sortingInfo: any) => {
		adoptionDetailsFunc(page, pageSize, sortingInfo, parseFilter(filterInfo));
	};

	const handleFilter = (filterInfo: any) => {
		setFilterInfo(filterInfo);
		console.log(filterInfo, 'filterinfo');
		adoptionDetailsFunc(1, pageSize, sortingInfo, parseFilter(filterInfo));
		
	};
	const handleSearch = (searchDetails: any, columnKey: any) => {
		// incidentSearchFunc(searchDetails, parseFilter(filterInfo, columnKey), columnKey);
	};

	const parseFilter = (filterInfo: any, name?: string) => {
		// Call only filterable columns and reset the filtering in case of wrong/empty column
		const parsedFilter: any = {};
		Object.keys(filterInfo).forEach((key: any) => {
			if (key !== name && filterInfo[key] && filterInfo[key].length) {
				parsedFilter[key] = filterInfo[key];
			}
		});
		return parsedFilter;
	};


	async function incidentSearchFunc(searchDetails: any, jobFilterInfo: any, columnKey: any) {
		// const res = await jobSearch(searchDetails, jobFilterInfo, columnKey);
		// if (res.success) {
		// 	setFilterSearchInfo(res.data);
		// }
	}

	async function adoptionDetailsFunc(pageNumber: any, size: any, sortingInfo: any, jobFilterInfo: any) {
		console.log(jobFilterInfo, 'jobFilterInfo');
		// Reset page number if page size has changed
		if (pageSize !== size) {
			pageNumber = 1;
		}

		const dummyAdoptionDetailsData = [
            {
				sector: 'PBNA',
                capacity: 'Capacity 1',
                capacitySKU: 'F1',
				workspace: 'Workspace 1',
				reportName: 'Report Name 1',
				userName: 'User Name 1',
				groupUserAccess: 'Group User Access 1',
				workspaceOwner: 'Workspace Owner 1',
				reportOwner: 'Report Owner 1',
				lastLogin: 'Last Login 1',
				capacityOwner: 'Capacity Owner 1',
            },
			{
				sector: 'PBNA',
                capacity: 'Capacity 2',
                capacitySKU: 'F2',
				workspace: 'Workspace 2',
				reportName: 'Report Name 2',
				userName: 'User Name 2',
				groupUserAccess: 'Group User Access 2',
				workspaceOwner: 'Workspace Owner 2',
				reportOwner: 'Report Owner 2',
				lastLogin: 'Last Login 2',
				capacityOwner: 'Capacity Owner 2',
            },
			{
				sector: 'PBNA',
                capacity: 'Capacity 3',
                capacitySKU: 'F3',
				workspace: 'Workspace 3',
				reportName: 'Report Name 3',
				userName: 'User Name 3',
				groupUserAccess: 'Group User Access 3',
				workspaceOwner: 'Workspace Owner 3',
				reportOwner: 'Report Owner 3',
				lastLogin: 'Last Login 3',
				capacityOwner: 'Capacity Owner 3',
            },
			{
				sector: 'PBNA',
                capacity: 'Capacity 4',
                capacitySKU: 'F4',
				workspace: 'Workspace 4',
				reportName: 'Report Name 4',
				userName: 'User Name 4',
				groupUserAccess: 'Group User Access 4',
				workspaceOwner: 'Workspace Owner 4',
				reportOwner: 'Report Owner 4',
				lastLogin: 'Last Login 4',
				capacityOwner: 'Capacity Owner 4',
            },
			{
				sector: 'PBNA',
                capacity: 'Capacity 5',
                capacitySKU: 'F5',
				workspace: 'Workspace 5',
				reportName: 'Report Name 5',
				userName: 'User Name 5',
				groupUserAccess: 'Group User Access 5',
				workspaceOwner: 'Workspace Owner 5',
				reportOwner: 'Report Owner 5',
				lastLogin: 'Last Login 5',
				capacityOwner: 'Capacity Owner 5',
            },
			{
				sector: 'PBNA',
                capacity: 'Capacity 6',
                capacitySKU: 'F6',
				workspace: 'Workspace 6',
				reportName: 'Report Name 6',
				userName: 'User Name 6',
				groupUserAccess: 'Group User Access 6',
				workspaceOwner: 'Workspace Owner 6',
				reportOwner: 'Report Owner 6',
				lastLogin: 'Last Login 6',
				capacityOwner: 'Capacity Owner 6',
            },
			{
				sector: 'PBNA',
                capacity: 'Capacity 7',
                capacitySKU: 'F7',
				workspace: 'Workspace 7',
				reportName: 'Report Name 7',
				userName: 'User Name 7',
				groupUserAccess: 'Group User Access 7',
				workspaceOwner: 'Workspace Owner 7',
				reportOwner: 'Report Owner 7',
				lastLogin: 'Last Login 7',
				capacityOwner: 'Capacity Owner 7',
            },
			{
				sector: 'PBNA',
                capacity: 'Capacity 8',
                capacitySKU: 'F8',
				workspace: 'Workspace 8',
				reportName: 'Report Name 8',
				userName: 'User Name 8',
				groupUserAccess: 'Group User Access 8',
				workspaceOwner: 'Workspace Owner 8',
				reportOwner: 'Report Owner 8',
				lastLogin: 'Last Login 8',
				capacityOwner: 'Capacity Owner 8',
            },
			{
				sector: 'PBNA',
                capacity: 'Capacity 9',
                capacitySKU: 'F9',
				workspace: 'Workspace 9',
				reportName: 'Report Name 9',
				userName: 'User Name 9',
				groupUserAccess: 'Group User Access 9',
				workspaceOwner: 'Workspace Owner 9',
				reportOwner: 'Report Owner 9',
				lastLogin: 'Last Login 9',
				capacityOwner: 'Capacity Owner 9',
            },
			{
				sector: 'PBNA',
                capacity: 'Capacity 10',
                capacitySKU: 'F10',
				workspace: 'Workspace 10',
				reportName: 'Report Name 10',
				userName: 'User Name 10',
				groupUserAccess: 'Group User Access 10',
				workspaceOwner: 'Workspace Owner 10',
				reportOwner: 'Report Owner 10',
				lastLogin: 'Last Login 10',
				capacityOwner: 'Capacity Owner 10',
            },
        ];
        setAdoptionDetails(dummyAdoptionDetailsData);
        paginationData.totalElements = 1;
            paginationData.totalPages = 1;
            setPage(pageNumber);
            setPageSize(size);
            setSortingInfo(sortingInfo);
            setLoading(false);
	}

	useEffect(() => {
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		localStorage.removeItem('ml-summary-sector-filter');
		// let storedSectorFilter = localStorage.getItem('ml-summary-sector-filter')
		// 	? JSON.parse(localStorage.getItem('ml-summary-sector-filter') ?? '')
		// 	: null;
		// 	console.log("Fetched Data1:", storedSectorFilter );
		// if (storedSectorFilter) {
		// 	setCheckedList(storedSectorFilter);
		// 	setCurrSectorFilter(storedSectorFilter);
		// }
       
		const activeId = document.getElementById('root');
		if (window.location.pathname === '/powerBI/adoptionMetrics/dashboard') {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
				document.body.classList.add('filter-bg');
				activeId.classList.remove('root');
			}
		}
		adoptionDetailsFunc(page, pageSize, sortingInfo, parseFilter(filterInfo));
		fetchChartData();
	}, []);

	const infoCardData = [
		{
			title: 'Total Power BI Users',
			// handleEvent: () => handleCapacityClick('RISK'),
			number: '20k',
            totalNumber: ''
		},
		{
			title: 'Total Power BI Reports',
			// handleEvent: () => handleCapacityClick('RISK'),
			number: '30k',
            totalNumber: ''
		},
        {
			title: 'Power BI Active Users',
			// handleEvent: () => handleCapacityClick('RISK'),
			number: '12k',
            totalNumber: ''
		},
	];

	const seriesData = [
		{ name: 'PBNA', dataKey: 'PBNA', color: '#3498db' },
		{ name: 'PFNA', dataKey: 'PFNA', color: '#1abc9c' },
		{ name: 'AMESA/APAC', dataKey: 'AMESA/APAC', color: '#f1c40f' },
		{ name: 'CGF', dataKey: 'CGF', color: '#e67e22' },
		{ name: 'LATAM', dataKey: 'LATAM', color: '#e74c3c' }
	];

	// Delete below dummyData once API is set
	const dummyData = {
		months: ['Jan 24', 'Feb 24', 'Mar 24', 'Apr 24', 'May 24', 'Jun 24', 'Jul 24', 'Aug 24', 'Sep 24', 'This Month'],
		usageLevels: {
			'PBNA': [2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
			'PFNA': [3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
			'AMESA/APAC': [4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
			'CGF': [5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
			'LATAM': [6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
		}
	};		

	const fetchDummyData: () => Promise<typeof dummyData> = async () => {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve(dummyData);
			}, 500);
		});
	};

	const fetchChartData = async () => {
		try {
			// Simulate fetching chart data
			//   const fetchedData = await fetchDataFromAPI(); // Replace with actual API call
			const fetchedData = await fetchDummyData();

			if (fetchedData) {
				const reportData = fetchedData.months.map((month, idx) => ({
					[month]: {
						'PBNA': fetchedData.usageLevels['PBNA'][idx],
						'PFNA': fetchedData.usageLevels['PFNA'][idx],
						'AMESA/APAC': fetchedData.usageLevels['AMESA/APAC'][idx],
						'CGF': fetchedData.usageLevels['CGF'][idx],
						'LATAM': fetchedData.usageLevels['LATAM'][idx]
					}
				}));

				setChartOptionCapacity(reportData);
				setChartOptionGateway(reportData);
				setChartOptionReports(reportData);
				setChartOptionUsers(reportData);
			}
		} catch (error) {
			console.error('Error fetching chart data:', error);
		}
	};

	
	const handleTimePeriodChange = (timePeriod: string) =>
		(event: { target: { value: React.SetStateAction<string> } }) => {
		if(timePeriod === 'Capacity')
		    setTimePeriodCapacity(event.target.value);
	    else if(timePeriod === 'Gateway')
		    setTimePeriodGateway(event.target.value);
		else if(timePeriod === 'Reports')
			setTimePeriodReports(event.target.value);
		else if(timePeriod === 'Users')
			setTimePeriodUsers(event.target.value);
	};

	const handleDateChange = (selectedDate: string) => {
		//incidentsDetailFunc(1, pageSize, sortingInfo, parseFilter(filterInfo),  selectedDate, chartType, dimension);
		//getMajorIncidentsFunc(selectedDate);
		//setFilterDefaultMonth(selectedDate)
	};

	return (
		<Stack mt="80px" width="100%">
			<PageHeader title="Adoption Metrics" icon={AdoptionMetricsLogo} lastSync={''} iconSize={30}>
			<DateFilterDropdown
					customMonth={customMonth}
					filterMonth={filterMonth}
					setCustomMonth={setCustomMonth}
					setFilterMonth={setFilterMonth}
					handleChange={handleDateChange}
					boxProps={{ sx: { backgroundColor: 'primary.main',
						minHeight: '50px', 
					 } }}
				/>
				<SectorCheckFilter
					sectorStorageName="ml-summary-sector-filter"
					handleOkClick={handleOkClick}
					checkedListRef={checkedListRef}
				/>
			</PageHeader>
			<Stack p={2} spacing={2}>
				<Grid container spacing={1}>
					<Grid item container xs={12} spacing={1}>
						{infoCardData.map((info, idx) => (
							<Grid key={info.title} item xs={12} md={4}>
                                <NumberWidget
									isLoading={loading}
									title={info.title}
									tooltipLabel=""
									// handleEvent={info.handleEvent}
									ratioNumber={{
										number: info.number,
										totalNumber: info.totalNumber,
									}}
									containerProps={{
										minHeight: '50px',
									}}
                                    unitIcon={info.title == 'Total Power BI Users' || info.title == 'Total Power BI Reports' ? 'upArrow' : 'downArrow' }
                                    unitIconColor={info.title == 'Total Power BI Users' || info.title == 'Total Power BI Reports' ? 'green' : 'red' }
									refreshersRate={'10%'}
								/>
							</Grid>
						))}
					</Grid>
				</Grid>
				<Grid container spacing={1}>
				<Grid item container xs={12} md={6}>
					<BarChartWidget
							title="Capacity Growth"
							isLoading={chartLoadingCapacity}
							seriesData={seriesData}
							data={chartOptionCapacity}
							yAxisOptions={{ name: 'Capacities', nameLocation: 'middle', nameGap: 40, splitLine: { show: false } }}
							heightDifferencePercent={-40}
							containerProps={{
								pt: 0.5
							}}
						>
							<CustomDropdown
								handleChange={handleTimePeriodChange('Capacity')}
								value={timePeriodCapacity}
								options={[
									{label: 'Monthly', value: 'Monthly'},
									{label: '15 Minutes', value: '15 Minutes'},
									{label: '30 Minutes', value: '30 Minutes'},
									{label: 'Hourly', value: 'Hourly'},
									{label: 'Daily', value: 'Daily'},
									{label: 'Weekly', value: 'Weekly'},
								]}
							/>
					</BarChartWidget>
				</Grid>
				<Grid item container xs={12} md={6}>
				<BarChartWidget
							title="Gateway Growth"
							isLoading={chartLoadingGateway}
							seriesData={seriesData}
							data={chartOptionGateway}
							yAxisOptions={{ name: 'Gateways', nameLocation: 'middle', nameGap: 40, splitLine: { show: false } }}
							heightDifferencePercent={-40}
							containerProps={{
								pt: 0.5
							}}
						>
							<CustomDropdown
								handleChange={handleTimePeriodChange('Gateway')}
								value={timePeriodGateway}
								options={[
									{label: 'Monthly', value: 'Monthly'},
									{label: '15 Minutes', value: '15 Minutes'},
									{label: '30 Minutes', value: '30 Minutes'},
									{label: 'Hourly', value: 'Hourly'},
									{label: 'Daily', value: 'Daily'},
									{label: 'Weekly', value: 'Weekly'},
								]}
							/>
				</BarChartWidget>
				</Grid>
				</Grid>
				<Grid container spacing={1}>
				<Grid item container xs={12} md={6}>
					<BarChartWidget
							title="Reports Growth"
							isLoading={chartLoadingReports}
							seriesData={seriesData}
							data={chartOptionReports}
							yAxisOptions={{ name: 'Reports', nameLocation: 'middle', nameGap: 40, splitLine: { show: false } }}
							heightDifferencePercent={-40}
							containerProps={{
								pt: 0.5
							}}
						>
							<CustomDropdown
								handleChange={handleTimePeriodChange('Reports')}
								value={timePeriodReports}
								options={[
									{label: 'Monthly', value: 'Monthly'},
									{label: '15 Minutes', value: '15 Minutes'},
									{label: '30 Minutes', value: '30 Minutes'},
									{label: 'Hourly', value: 'Hourly'},
									{label: 'Daily', value: 'Daily'},
									{label: 'Weekly', value: 'Weekly'},
								]}
							/>
					</BarChartWidget>
				</Grid>
				<Grid item container xs={12} md={6}>
				<BarChartWidget
							title="Users Growth"
							isLoading={chartLoadingUsers}
							seriesData={seriesData}
							data={chartOptionUsers}
							yAxisOptions={{ name: 'Users', nameLocation: 'middle', nameGap: 40, splitLine: { show: false } }}
							heightDifferencePercent={-40}
							containerProps={{
								pt: 0.5
							}}
						>
							<CustomDropdown
								handleChange={handleTimePeriodChange('Users')}
								value={timePeriodUsers}
								options={[
									{label: 'Monthly', value: 'Monthly'},
									{label: '15 Minutes', value: '15 Minutes'},
									{label: '30 Minutes', value: '30 Minutes'},
									{label: 'Hourly', value: 'Hourly'},
									{label: 'Daily', value: 'Daily'},
									{label: 'Weekly', value: 'Weekly'},
								]}
							/>
				</BarChartWidget>
				</Grid>
				</Grid>
				{!tableLoading ? (
					<CustomizedDataGrid
						title="Details"
						tableColumns={getColumns()}
						tableData={adoptionDetails}
						tableType="adoption-details-ver"
						defaultColumnNames={defaultColumnNames}
						onSorting={handleSorting}
						currSorting={sortingInfo}
						filterDataOptions={filterData}
						onFilterCheck={handleFilter}
						currFilter={filterInfo}
						searchDataValues={searchDetails}
						onFilterSearch={handleSearch}
						page={page}
						pageSize={pageSize}
						paginationData={paginationData}
						listLength={adoptionDetails.length}
						handlepageSizeChange={handlepageSizeChange}
						handlePageChange={handlePageChange}
						isDateRangeFilter={false}
						isDisabled={false}
						isFetching={tableLoading}
					/>
				) : (
					<Stack justifyContent="center" alignItems="center" minHeight="100px">
						<LoaderComponent />
					</Stack>
				)}
			</Stack>
		</Stack>
	);
};

export default AdoptionMetrics;
