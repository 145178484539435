import { Box, Stack, Typography } from '@mui/material';
import NoPrgramFound from '../../assets/No Data.svg';

const ShowInfoWidget = ({ title, height = '50vh', button, onClick }: { title: string; height: string; button?: string ; onClick?: (event?: any) => void;}) => {
	return (
		<Stack justifyContent="center" alignItems="center" height={height}>
			<Box component="img" src={NoPrgramFound} alt="" />
			<Typography variant="pageTitle" color="dark.main">
				{title}
			</Typography>
			{button && (
                <Typography variant="button" color="#009BD8"onClick={onClick} style={{ cursor: onClick ? 'pointer' : 'default' }}>
                    {button}
                </Typography>
            )}
		</Stack>
	);
};

export default ShowInfoWidget;
