export type Severity = 'error' | 'success' | 'warning' | 'info';
type Callback = (message: string,severity: Severity) => void;

class EventEmitter {
    private events: { [key: string]: Callback[] } = {};

    on(event: string, callback: Callback) {
        if (!this.events[event]) {
            this.events[event] = [];
        }
        this.events[event].push(callback);
    }

    emit(event: string, message: string,severity:Severity = 'error') {
        if (this.events[event]) {
            this.events[event].forEach(callback => callback(message,severity));
        }
    }
}

const eventEmitter = new EventEmitter();
export default eventEmitter;
