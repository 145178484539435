import { IconButton, Stack, Typography } from '@mui/material';
import colors from 'assets/theme/base/colors';
import RowTypography from 'components/Table/RowTypography';
import React, { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { ReactComponent as ClockIcon } from '../../assets/time-history.svg';
import CloseIcon from '@mui/icons-material/Close';
import { CustomizedDataGrid } from 'components/customized_data_grid';
import LoaderComponent from 'components/Loader';
import { getCyclicExecutions } from 'service/service';
import moment from 'moment';
import { CurrencyRubleTwoTone } from '@mui/icons-material';
import StatusChip from 'components/Table/StatusChip';


interface CyclicExecutionsProps {
	curRow: any;
    setIsCyclicExeData: any;
}

interface CyclicExecution {
	execId: number;
	actualStartTime: any;
	actualEndTime: any;
	jobStatus: string;
	avgRunTime: string;
}

const CyclicExecutions: React.FC<CyclicExecutionsProps> = ({ curRow, setIsCyclicExeData }: any) => {
	const defaultColumnNames = [
		'Exec Id',
		'Act. Start Time',
		'Act. End Time',
		'Avg Job Exec. Time',
		'Status'
	];

	const columnNames = [
		{ name: 'execId', label: 'Exec Id' },
		{ name: 'actualStartTime', label: 'Act. Start Time' },
		{ name: 'actualEndTime', label: 'Act. End Time' },
		{ name: 'jobStatus', label:  'Status' },
		{ name: 'avgRunTime', label: 'Avg Job Exec. Time'}
		
	];

	const [updateCyclicExec, setUpdateCyclicExec] = useState<any[]>([]);
	const [loading, setLoading] = useState<boolean>(false);


	async function cyclicExecFunc() {
		setLoading(true);
		const res = await getCyclicExecutions(curRow.isCyclic, curRow.jobId, curRow.orderDate );
		if (res.success) {
			setLoading(false);
			setUpdateCyclicExec(res.data.cyclicExeData);
		}
	}

	//fetching data
	useEffect(() => {
		cyclicExecFunc();
		// setLoading(true);
		// if (curRow) {
			
		// 	setTimeout(() => {
		// 		cyclicExecFunc();
		// 	}, 2000);
		// }
        // setLoading(false)

	}, [curRow]);

	function getColumns(): Array<any> {
		return columnNames.map((col: any, idx: number) => ({
			key: col.name,
			name: col.label,
			formatter({ row, column }: any) {
				return (
					<Stack direction="row" justifyContent="space-between" alignItems="center">
						<RowTypography isSuccessor={!row[column.key]}>
							{(column.key === 'actualStartTime') || (column.key === 'actualEndTime') ? (
								row[column.key] ? moment(row[column.key]).format('YYYY-MM-DD HH:MM:SS') : ''
							) : column.key === 'jobStatus' ? (
								<StatusChip status={row[column.key]} value={row[column.key] ? row[column.key] : '--'} />
							) : (
								row[column.key]
							)}
						</RowTypography>
					</Stack>
				);
			},
			 width: 300
		}));
	}

	return (
		<Stack direction="column" p={4} position='relative'>
			<IconButton
				onClick={() => setIsCyclicExeData(false)} // Close drawer
				sx={{
					position: 'absolute',
					top: 8,
					right: 8,
					zIndex: 1
				}}
			>
				<CloseIcon />
			</IconButton>
			<Stack
				direction="row"
				spacing={12}
				pt={1}
				pb={4}
				marginBottom={1}
				borderBottom={`0.5px solid ${colors.dark.dark}`}
			>
				<Stack>
					<Typography sx={{ color: 'text.subtle' }}>
						Job Name&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						<Typography component="span" sx={{ fontWeight: 'bold' }}>
							{curRow.jobName}
						</Typography>
					</Typography>
				</Stack>

				<Stack>
					<Typography sx={{ color: 'text.subtle' }}>
						Platform&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						<Typography component="span" sx={{ fontWeight: 'bold' }}>
							{curRow.platformId == 1 ?  'Teradata' : ''}
						</Typography>
					</Typography>
				</Stack>
                <Stack>
					<Typography sx={{ color: 'text.subtle' }}>
						Cyclic&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						<Typography component="span" sx={{ fontWeight: 'bold' }}>
							{curRow.isCyclic}
						</Typography>
					</Typography>
				</Stack>
			</Stack>
			{loading ? (
				<LoaderComponent />
			) : (
				<CustomizedDataGrid
					title={
						<Stack direction="row" alignItems="center" spacing={1} mt="1rem">
							<ClockIcon />
							<Typography fontSize="16px">Recent Executions</Typography>
						</Stack>
					}
					tableColumns={getColumns()}
					tableData={updateCyclicExec}
					tableType="history-list"
					defaultColumnNames={defaultColumnNames}
					autoSetDraggedColumns={false}
					listLength={updateCyclicExec.length}
					showExport={false}
					isDateRangeFilter={false}
					isDisabled={false}
					isRenderPagination={false}
					isShowMoreButton={false}
					style={{ maxHeight: '250px' }}
					rowHeight={40}
				/>
			)}
		</Stack>
	);
};

export default CyclicExecutions;
