import React, { useEffect, useState } from 'react';
import { Stack, Popover, MenuItem, FormControlLabel, Checkbox, Button, Typography } from '@mui/material';
import { ReactComponent as FilterIcon } from '../../assets/ml_summary_filter.svg';
import { fetchSectors, getDecodedToken } from '../../service/service';

// Common styles
const commonMenuItemStyles = {
	margin: 0,
	padding: 0,
	'& .MuiFormControlLabel-root': {
		margin: 0,
		width: '100%'
	},
	'& .MuiCheckbox-root': {
		padding: 0
	}
};

type SectorCheckFilterProps = {
	sectorStorageName: string;
	handleOkClick: () => void;
	checkedListRef: React.MutableRefObject<any>;
};

const SectorCheckFilter = ({ sectorStorageName, handleOkClick, checkedListRef }: SectorCheckFilterProps) => {
	const userSector = getDecodedToken()?.sector?.name?.toLowerCase();
	const [filterMenu, setFilterMenu] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);
	const [checkedList, setCheckedList] = useState<any[]>([]);
	const [itemsChecked, setItemsChecked] = useState(false);
	const [sectorOptionsList, setSectorOptionsList] = useState<any[]>([]);

	async function fetchSectorOptionsData() {
		const sectorRes = await fetchSectors();
		if (sectorRes.success) {
			setSectorOptionsList(sectorRes.data.data);
		}
	}

	useEffect(() => {
		if (userSector === 'all') {
			fetchSectorOptionsData();
		}
	}, []);

	const filterClick = (event: any) => {
		event.preventDefault();
		setFilterMenu(filterMenu === null ? { mouseX: event.clientX - 20, mouseY: event.clientY - 6 } : null);
	};

	const handleClose = () => {
		setFilterMenu(null);
		let storedSectorFilter =
			!!sectorStorageName && localStorage.getItem(sectorStorageName)
				? JSON.parse(localStorage.getItem(sectorStorageName) ?? '')
				: null;
		if (storedSectorFilter) {
			setCheckedList(storedSectorFilter);
			checkedListRef.current = storedSectorFilter;
		} else {
			setCheckedList([]);
			checkedListRef.current = [];
		}
		if (storedSectorFilter && storedSectorFilter.length == sectorOptionsList.length) setItemsChecked(true);
		else setItemsChecked(false);
	};

	const selectAllCheck = (e: any) => {
		const { checked } = e.target;
		if (checked) {
			setCheckedList(sectorOptionsList);
			checkedListRef.current = sectorOptionsList;
		} else {
			setCheckedList([]);
			checkedListRef.current = [];
		}
		setItemsChecked(checked);
	};

	const handleOkChange = (event: any) => {
		if (sectorStorageName) {
			localStorage.setItem(sectorStorageName, JSON.stringify(checkedList));
		}
		handleOkClick();
		setFilterMenu(null);
	};

	const handleChange = (e: any) => {
		const { value, checked } = e.target;
		const newlyChecked = [];
		if (checked && e.target.value) {
			newlyChecked.push(e.target.value);
		}
		if (checked) {
			setCheckedList([...checkedList, ...newlyChecked]);
			checkedListRef.current = [...checkedList, ...newlyChecked];
		} else {
			const checkedValue = checkedList.filter((item) => item !== value);
			setCheckedList(checkedValue);
			checkedListRef.current = checkedValue;
			setItemsChecked(checked);
		}
		if (sectorOptionsList.length === checkedList.length + 1) {
			setItemsChecked(true);
		}
	};

	return (
		<>
			<Stack
				direction="row"
				alignItems="center"
				sx={{ backgroundColor: 'primary.main', width: 'auto', padding: 0, borderRadius: '5px' }}
			>
				{userSector === 'all' ? (
					<>
						<Typography onClick={filterClick} style={{ cursor: 'pointer' }} sx={{ lineHeight: '30px', px: 2 }}>All Sectors</Typography>
						<FilterIcon onClick={filterClick} style={{ cursor: 'pointer' }} />
					</>
				) : (
					<Typography sx={{ lineHeight: '30px', px: 2 }}>Sector: {getDecodedToken()?.sector?.name}</Typography>
				)}
			</Stack>
			<Popover
				open={filterMenu !== null}
				onClose={handleClose}
				anchorReference="anchorPosition"
				anchorPosition={filterMenu ? { top: filterMenu.mouseY, left: filterMenu.mouseX } : undefined}
			>
				<Stack spacing={1} sx={{ backgroundColor: 'primary.main', width: '230px' }}>
					<Stack
						sx={{
							height: '140px',
							overflowY: 'auto',
							'&::-webkit-scrollbar': { width: '8px' },
							'&::-webkit-scrollbar-thumb': { borderRadius: '10px' }
						}}
					>
						<MenuItem
							disableGutters
							sx={{
								...commonMenuItemStyles,
								backgroundColor: 'dark.dark',
								borderRadius: '5px',
								border: '1px solid #009BD8',
								width: '100%'
							}}
						>
							<FormControlLabel
								control={
									<Checkbox
										color="success"
										value="all"
										checked={itemsChecked}
										onChange={selectAllCheck}
										sx={{ color: 'white.main' }}
									/>
								}
								label={<Typography variant="subtitle1">All</Typography>}
							/>
						</MenuItem>

						{sectorOptionsList && 
							sectorOptionsList
								.sort((a, b) => {
								// Check if 'a' or 'b' is checked (checked items should come first)
								const isCheckedA = checkedList.includes(a);
								const isCheckedB = checkedList.includes(b);
								if (isCheckedA && !isCheckedB) return -1; // 'a' should come before 'b'
								if (!isCheckedA && isCheckedB) return 1;  // 'b' should come before 'a'
								return 0; // If both are checked or both unchecked, no change in order
								})
								.map((filter: any) => (
								<MenuItem key={filter} disableGutters sx={commonMenuItemStyles}>
									<FormControlLabel
										control={
											<Checkbox
												color="success"
												value={filter}
												checked={checkedList.includes(filter)}
												onChange={handleChange}
												sx={{ color: 'white.main', backgroundColor: 'primary.main' }}
											/>
										}
										label={
											<Typography variant="subtitle1">
												{filter === true ? 'Yes' : filter === false ? 'No' : filter}
											</Typography>
										}
									/>
								</MenuItem>
							))}
					</Stack>

					<Stack direction="row" justifyContent="flex-end" spacing={1}>
						<Button variant="pepwiseSecondaryOutline" onClick={handleOkChange}>
							OK
						</Button>
						<Button variant="pepwiseSecondaryOutline" onClick={handleClose}>
							Cancel
						</Button>
					</Stack>
				</Stack>
			</Popover>
		</>
	);
};

export default SectorCheckFilter;
