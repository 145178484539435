import { Box, Grid, Stack, GridProps, Typography } from '@mui/material';

import NumberWidget from 'components/Widgets/NumberWidget';
import ChartWidget from 'components/Widgets/ChartWidget';

import '../DataEstate/data-estate-dashboard.scss';
import { useEffect, useRef, useState } from 'react';
import {
	fetchAnnualSpendDataSourceIncidents,
	fetchDataProcessingTrends,
	fetchDatabricksClusters,
	fetchDataprocessingSummary,
	fetchPerformanceData,
	getDecodedToken,
	getSector,
	getDBDatabricks,
	dataProcessingTable,
	fetchDatabricksClustersCount,
	fetchDataprocessingSynapse
} from '../../service/service';
import moment from 'moment';
import useStyles from '../jobs/jobs_styles';

import { getPercentageOfTwoNumbers, formatNumberWithCommas } from '../../utils/common-util';
import { ReactComponent as Downarrow } from '../../assets/down-arrow.svg';
import '../DataEstate/data-estate.scss';
import { CustomizedDataGrid } from '../customized_data_grid';
import { Row, type Column } from 'react-data-grid';
import { getSectorSql } from '../../service/service';
import { isEnvTypePROD } from '../../service/config';
import NoPrgramFound from '../../assets/no-program-found.svg';
import ToolTipIcon from '../../assets/info-icon.svg';
import PageHeader from 'components/Header/Page';
import { useCacheDataController } from 'Context/contexts/CacheDataContext';
import DateFilterDropdown from 'components/Dropdown/DateFilter';
import DataProcIcon from '../../assets/data-processing.svg';
import WidgetCardContainer from 'components/Widgets/WidgetCardContainer';
import GuageWidget from 'components/Widgets/GaugeWidget';
import UnderDevelopmentWidget from 'components/Widgets/UnderDevlopmentWidget';

export default function dataProcessingSummary() {
	const [ADFPipeline, SetADFPipeline] = useState(0);
	const [azureSqlUptime, SetAzureSqlUptime] = useState('-');
	const [synapseUptime, SetSynapseUptime] = useState('-');
	const [teradataJobs, SetTeradataJobs] = useState(0);
	const [teradataJobsLastExecution, SetTeradataJobsLastDec] = useState(0);
	const [teradataJobsCurrent, SetTeradataJobsCurrent] = useState(0);

	const [databricksClusters, SetDatabricksClusters] = useState(0);
	const [databricksClustersLastExecution, SetDatabricksClustersLastExecution] = useState(0);
	const [databricksClustersCurrent, SetDatabricksClustersCurrent] = useState(0);
	const [databricksJobExecutionTimeLoader, SetDatabricksJobExecutionTimeLoader] = useState(true);
	const [databricksJobExecutionTimeChartOptions, SetDatabricksJobExecutionTimeChartOptions] = useState({});
	const [teradataJobExecutionTimeLoader, SetTeradataJobExecutionTimeLoader] = useState(true);
	const [azureUptimeLoader, SetAzureUptimeLoader] = useState(true);
	const [synapseUptimeLoader, SetSynapseUptimeLoader] = useState(true);
	const [teradataJobExecutionTimeChartOptions, SetSetTeradataJobExecutionTimeChartOptions] = useState({});

	const [pipelineGrowthLoading, setPipelineGrowthLoading] = useState(true);
	const [pipelineGrowthOptions, setPipelineGrowthOptions] = useState<any>();
	const [clusterGrowthLoading, setClusterGrowthLoading] = useState(true);
	const [clusterGrowthOptions, setClusterGrowthOptions] = useState<any>();
	const [teradataJobsLoading, setTeradataJobsLoading] = useState(true);
	const [teradataJobsOptions, setTeradataJobsOptions] = useState<any>();
	
	const [lastUpdatedDatePipeline, setLastUpdatedDatePipeline] = useState('')
	const [lastUpdatedDateCluster, setLastUpdatedDateCluster] = useState('')
	const [lastUpdatedDateTeradata, setLastUpdatedDateTeradata] = useState('')
	
	// sector filter
	const userSector = getDecodedToken()?.sector?.name?.toLowerCase();
	const [filterMenu, setFilterMenu] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);

	const [dateFilterMenu, setDateFilterMenu] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);

	const classes = useStyles();
	const [itemsChecked, setItemsChecked] = useState(false);
	const [sectorOptionsList, setSectorOptionsList] = useState<any[]>([]);
	const [checkedList, setCheckedList] = useState<any[]>([]);
	const [currSectorFilter, setCurrSectorFilter] = useState<any[]>([]);
	const [sector, setCurrSector] = useState<any[]>([]);
	const [filterMonth, setFilterMonth] = useState('0M');
	const [filterDefaultMonth, setFilterDefaultMonth] = useState('0M');
	const [customYear, setCustomYear] = useState(moment().format('YYYY'));
	const [isDateOpen, setIsDateOpen] = useState(false);
	const isCustomMonth = useRef(false);
	const dataFetchedRef = useRef(false);
	const [loading, setLoading] = useState(false);
	// server filter
	const [dateFilter, setDateFilter] = useState<any>('Apr 2024');

	const [adfpipelineBubbleValue, SetAdfpipelineBubbleValue] = useState(0);
	const [teradataBubbleValue, SetTeradataBubbleValue] = useState(0);
	const [databricksBubbleValue, SetDatabricksBubbleValue] = useState(0);

	const [adfpipelineLastDec, SetAdfpipelineLastDec] = useState(0);
	const [adfpipelineCurrent, SetAdfpipelineCurrent] = useState(0);
	const [adfpipelineStartMonth, setAdfpipelineStartMonth] = useState('');
	const [isNoAnnualAdfpipeline, setIsNoAnnualAdfpipeline] = useState(false);

	const [teradataJobStartMonth, setTeradataJobStartMonth] = useState('');
	const [isNoAnnualTeradataJob, setIsNoAnnualTeradataJob] = useState(false);

	let dimension: any = '';
	const [customMonth, setCustomMonth] = useState('');
	const [isDetailVisible, setIsDetailVisible] = useState(false);

	function formatNumber(number: any) {
		if (number != null && !isNaN(number)) {
			return Number(number).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
		} else {
			return '0.00';
		}
	}

	const applyDateFilter = (selectedDate: any) => {
		getDataProcessingSummary(selectedDate, checkedList);
		getDataProcessingSynapseTime(selectedDate, checkedList);
		loadDatabricksClustersCount(selectedDate)
		loadDatabricksClustersCountExecution(selectedDate);
		setDateFilter(selectedDate);
	};

	async function fetchSectorOptionsData() {
		const sectorRes = await getSector('1', '100', '');
		if (sectorRes.success) {
			const filteredSectorOptions = sectorRes.data.sector.filter((x: any) => x.name !== 'S&T').map((x: any) => x.name);
			filteredSectorOptions.push('Blank');
			setSectorOptionsList(filteredSectorOptions);
		}
	}
	
	async function getDataProcessingSynapseTime(selectedDate?: string, sectors?: any) {
		setLoading(true);
		SetSynapseUptimeLoader(true);
		try {
			const res = await fetchDataprocessingSynapse(selectedDate, sectors);
			console.log(res);
	
			if (res?.data) {
				SetSynapseUptime(res.data.synapseUptimeCount);
			}
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
			SetSynapseUptimeLoader(false);
		}
	}
	

	async function getDataProcessingSummary(selectedDate?: string, sectors?: any) {
		let sector = sectors.length == sectorOptionsList.length ? [] : sectors;
		setLoading(true);
		SetDatabricksJobExecutionTimeLoader(true);
		SetTeradataJobExecutionTimeLoader(true);
		SetAzureUptimeLoader(true)
		const todaysDate = moment().utc().format('YYYY-MM-DD');
		fetchDataprocessingSummary(selectedDate, sector)
			.then((res) => {
				SetADFPipeline(res?.data?.dataPipelineCount);
				SetAzureSqlUptime(formatNumber(res?.data?.azureSqlUptimeCount[0]?.VALUE));				
				setLoading(false);
				SetAzureUptimeLoader(false);
			})
			.catch((error) => {
				console.error(error);
			});

		fetchAnnualSpendDataSourceIncidents('teradata-jobs', selectedDate, sector)
			.then((res) => {
				SetTeradataJobs(res?.data?.result[0]?.count);
			})
			.catch((error) => {
				console.error(error);
			});

		fetchPerformanceData('teradata', selectedDate, sector)
			.then((res) => {
				SetTeradataJobExecutionTimeLoader(false);
				if (res?.data?.result[0]?.avg_execution) {
					let teradataOption = {
						backgroundColor: '#003771',
						series: [
							{
								type: 'gauge',
								center: ['50%', '55%'],
								min: 0,
								max: Math.floor(res?.data?.result[0]?.avg_execution * 1.1),
								splitNumber: 5,
								itemStyle: {
									color: '#e5e5e5'
								},
								progress: {
									show: false
								},
								pointer: {
									show: false
								},
								axisTick: {
									distance: -55,
									splitNumber: 8,
									lineStyle: {
										width: 0,
										color: '#999'
									}
								},
								splitLine: {
									distance: -30,
									length: 14,
									lineStyle: {
										width: 0,
										color: '#999'
									}
								},
								axisLabel: {
									distance: -35,
									color: '#999',
									fontSize: 14,
									formatter: (value: any) => {
										return `${Math.floor(value).toLocaleString()}`;
									}
								},
								anchor: {
									show: false
								},
								title: {
									show: false
								},
								detail: {
									valueAnimation: true,
									width: '60%',
									lineHeight: 40,
									borderRadius: 8,
									offsetCenter: [0, 30],
									fontSize: 30,
									formatter: `${Math.floor(res?.data?.result[0]?.avg_execution).toLocaleString()}`,
									color: '#e5e5e5'
								},
								data: [
									{
										value: res?.data?.result[0]?.avg_execution
									}
								]
							},
							{
								type: 'gauge',
								center: ['50%', '55%'],
								min: 0,
								max: Math.floor(res?.data?.result[0]?.avg_execution * 1.1),
								itemStyle: {
									color: '#FD7347'
								},
								progress: {
									show: true,
									width: 10
								},
								pointer: {
									show: false
								},
								axisLine: {
									show: false
								},
								axisTick: {
									show: false
								},
								splitLine: {
									show: false
								},
								axisLabel: {
									show: false
								},
								detail: {
									valueAnimation: true,
									width: '60%',
									lineHeight: 40,
									borderRadius: 8,
									offsetCenter: [0, '-30%'],
									fontSize: 14,
									fontWeight: 'normal',
									formatter: 'Avg Execution',
									color: '#e5e5e5'
								},
								data: [
									{
										value: res?.data?.result[0]?.avg_execution
									}
								]
							},
							{
								type: 'gauge',
								center: ['50%', '55%'],
								min: 0,
								max: Math.floor(res?.data?.result[0]?.avg_execution * 1.1),
								splitNumber: 5,
								itemStyle: {
									color: '#e5e5e5'
								},
								progress: {
									show: false
								},
								pointer: {
									show: false
								},
								axisTick: {
									distance: -55,
									splitNumber: 8,
									lineStyle: {
										width: 0,
										color: '#999'
									}
								},
								splitLine: {
									distance: -30,
									length: 14,
									lineStyle: {
										width: 0,
										color: '#999'
									}
								},
								axisLabel: {
									distance: -35,
									color: '#999',
									fontSize: 14,
									formatter: (value: any) => {
										return `${Math.floor(value).toLocaleString()}`;
									}
								},
								anchor: {
									show: false
								},
								title: {
									show: false
								},
								detail: {
									valueAnimation: true,
									width: '60%',
									lineHeight: 40,
									borderRadius: 8,
									offsetCenter: [0, 65],
									fontSize: 14,
									fontWeight: 'normal',
									formatter: 'seconds',
									color: '#e5e5e5'
								},
								data: [
									{
										value: res?.data?.result[0]?.avg_execution
									}
								]
							},
						]
					};
					SetSetTeradataJobExecutionTimeChartOptions(teradataOption);
				}
			})
			.catch((error) => {
				console.error(error);
			});

		fetchDataProcessingTrends('adfpipelinegrowth', selectedDate, sector)
			.then((res) => {
				if (res.data) {
					setPipelineGrowthOptions(res.data.data);
					setPipelineGrowthLoading(false);
					setLastUpdatedDatePipeline(res?.data.lastUpdatedDate ? res.data.lastUpdatedDate : todaysDate);
				}
			})
			.catch((error) => {
				console.error(error);
			});

		fetchDataProcessingTrends('teradataJobsgrowth', selectedDate, sector)
			.then((res) => {
				if (res.data) {
					setTeradataJobsOptions(res.data.data);
					setTeradataJobsLoading(false);
					setLastUpdatedDateTeradata(res?.data.lastUpdatedDate ? res.data.lastUpdatedDate : todaysDate);
				}
			})
			.catch((error) => {
				console.error(error);
			});

		// const monthList = ["2024-01-30", "2024-02-28", "2024-03-31", "2024-04-30", "2024-05-30", "2024-06-09"];

		// Databricks Cluster
		fetchDataProcessingTrends('clustergrowth', selectedDate, sector)
			.then((res) => {
				if (res.data) {
					setClusterGrowthOptions(res.data.data);
					setClusterGrowthLoading(false);
					setLastUpdatedDateCluster(res?.data.lastUpdatedDate ? res.data.lastUpdatedDate : todaysDate);
				}
			})
			.catch((error) => {
				console.error(error);
			});
	}

	// fetchPerformanceData('databricks', selectedDate, sector).then(res => {
	async function getDatabricksExecutionData(exeCount: number) {
		SetDatabricksJobExecutionTimeLoader(false);
		let databricksOption = {
			backgroundColor: '#003771',
			series: [
				{
					type: 'gauge',
					center: ['50%', '55%'],
					min: 0,
					max: Math.floor(exeCount * 1.1),
					splitNumber: 5,
					itemStyle: {
						color: '#e5e5e5'
					},
					progress: {
						show: false
					},
					pointer: {
						show: false
					},
					axisTick: {
						distance: -55,
						splitNumber: 8,
						lineStyle: {
							width: 0,
							color: '#999'
						}
					},
					splitLine: {
						distance: -30,
						length: 14,
						lineStyle: {
							width: 0,
							color: '#999'
						}
					},
					axisLabel: {
						distance: -35,
						color: '#999',
						fontSize: 14,
						formatter: (value: any) => {
							return Math.floor(value).toLocaleString();
						}
					},
					anchor: {
						show: false
					},
					title: {
						show: false
					},
					detail: {
						valueAnimation: true,
						width: '60%',
						lineHeight: 40,
						borderRadius: 8,
						offsetCenter: [0, 30],
						fontSize: 30,
						formatter: Math.floor(exeCount).toLocaleString(),
						color: '#e5e5e5'
					},
					data: [
						{
							value: Math.floor(exeCount)
						}
					]
				},
				{
					type: 'gauge',
					center: ['50%', '55%'],
					min: 0,
					max: Math.floor(exeCount * 1.1),
					itemStyle: {
						color: '#FD7347'
					},
					progress: {
						show: true,
						width: 10
					},
					pointer: {
						show: false
					},
					axisLine: {
						show: false
					},
					axisTick: {
						show: false
					},
					splitLine: {
						show: false
					},
					axisLabel: {
						show: false
					},
					detail: {
						valueAnimation: true,
						width: '60%',
						lineHeight: 40,
						borderRadius: 8,
						offsetCenter: [0, '-30%'],
						fontSize: 14,
						fontWeight: 'normal',
						formatter: 'Avg Execution',
						color: '#e5e5e5'
					},
					data: [
						{
							value: exeCount
						}
					]
				}
			]
		};
		SetDatabricksJobExecutionTimeChartOptions(databricksOption);
		// }).catch(error => {
		// 	console.error(error);
		// });
	}

	async function loadDatabricksAnnualIndicator() {
		let startDate = moment().subtract(1, 'months').startOf('month').format('yyyy-MM-DD');
		let endDate = moment().subtract(1, 'months').endOf('month').format('yyyy-MM-DD');

		try {
			const res = await fetchDatabricksClustersCount(startDate, endDate, true);
			if (res.success) {
				if (res.data.databricksClustersCount.length) {
					let currentYearCount = res.data.databricksClustersCount.reduce((count: any, item: any) => count + item.count, 0);
					loadDatabricksLastYearData(currentYearCount);
					SetDatabricksClustersCurrent(currentYearCount);
				}
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	}

	async function loadDatabricksLastYearData(currentYearCount: number) {
		let lastyearStartDate = moment().subtract(1, 'year').subtract(1, 'months').startOf('month').format('yyyy-MM-DD');
		let lastYearEndDate = moment().subtract(1, 'year').subtract(1, 'months').format('yyyy-MM-DD');

		// let lastyearStartDate = moment().subtract(1, 'year').startOf('year').format('yyyy-MM-DD');
		// let lastYearEndDate = moment().subtract(1, 'year').endOf('year').format('yyyy-MM-DD');

		try {
			const res = await fetchDatabricksClustersCount(lastyearStartDate, lastYearEndDate, true);
			if (res.success) {
				if (res.data.databricksClustersCount.length) {
					let lastYearCount = res.data.databricksClustersCount.reduce((count: any, item: any) => count + item.count, 0);
					SetDatabricksClustersLastExecution(lastYearCount);
					SetDatabricksBubbleValue(getPercentageOfTwoNumbers(lastYearCount, currentYearCount));
				}
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	}

	async function loadDatabricksClustersCount(selectedDateFilter?: string) {
		let startDate: any;
		let endDate: any;
		if (selectedDateFilter === '0M') {
			startDate = moment().startOf('month').format('yyyy-MM-DD');
			endDate = moment().format('yyyy-MM-DD');
		}
		if (selectedDateFilter === '1M') {
			startDate = moment().subtract(1, 'months').startOf('month').format('yyyy-MM-DD');
			endDate = moment().subtract(1, 'months').endOf('month').format('yyyy-MM-DD');
		}
		if (selectedDateFilter === '3M') {
			startDate = moment().subtract(2, 'months').startOf('month').format('yyyy-MM-DD');
			endDate = moment().endOf('month').format('yyyy-MM-DD');
		}
		if (selectedDateFilter === '6M') {
			startDate = moment().subtract(5, 'months').startOf('month').format('yyyy-MM-DD');
			endDate = moment().endOf('month').format('yyyy-MM-DD');
		}
		if (selectedDateFilter === '9M') {
			startDate = moment().subtract(8, 'months').startOf('month').format('yyyy-MM-DD');
			endDate = moment().endOf('month').format('yyyy-MM-DD');
		}
		if (selectedDateFilter === '0Y') {
			startDate = moment().subtract(0, 'year').startOf('year').format('yyyy-MM-DD');
			endDate = moment().endOf('year').format('yyyy-MM-DD');
		}
		if (selectedDateFilter === '1Y') {
			startDate = moment().subtract(1, 'year').startOf('year').format('yyyy-MM-DD');
			endDate = moment().subtract(1, 'year').endOf('year').format('yyyy-MM-DD');
		}
		if (selectedDateFilter === '2Y') {
			startDate = moment().subtract(1, 'year').startOf('year').format('yyyy-MM-DD');
			endDate = moment().endOf('year').format('yyyy-MM-DD');
		}
		setLoading(true);
		try {
			const res = await fetchDatabricksClustersCount(startDate, endDate, true, 'databricksCluster');
			if (res.success) {
				if (res.data.databricksClustersCount.length) {
					let count = res.data.databricksClustersCount.reduce((count: any, item: any) => count + item.count, 0);
					SetDatabricksClusters(count);
				} else {
					SetDatabricksClusters(0);
				}
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	}

	async function loadDatabricksClustersCountExecution(selectedDateFilter?: string) {
		let startDate: any;
		let endDate: any;
		if (selectedDateFilter === '0M') {
			startDate = moment().startOf('month').format('yyyy-MM-DD');
			endDate = moment().format('yyyy-MM-DD');
		}
		if (selectedDateFilter === '1M') {
			startDate = moment().subtract(1, 'months').startOf('month').format('yyyy-MM-DD');
			endDate = moment().subtract(1, 'months').endOf('month').format('yyyy-MM-DD');
		}
		if (selectedDateFilter === '3M') {
			startDate = moment().subtract(2, 'months').startOf('month').format('yyyy-MM-DD');
			endDate = moment().endOf('month').format('yyyy-MM-DD');
		}
		if (selectedDateFilter === '6M') {
			startDate = moment().subtract(5, 'months').startOf('month').format('yyyy-MM-DD');
			endDate = moment().endOf('month').format('yyyy-MM-DD');
		}
		if (selectedDateFilter === '9M') {
			startDate = moment().subtract(8, 'months').startOf('month').format('yyyy-MM-DD');
			endDate = moment().endOf('month').format('yyyy-MM-DD');
		}
		if (selectedDateFilter === '0Y') {
			startDate = moment().subtract(0, 'year').startOf('year').format('yyyy-MM-DD');
			endDate = moment().endOf('year').format('yyyy-MM-DD');
		}
		if (selectedDateFilter === '1Y') {
			startDate = moment().subtract(1, 'year').startOf('year').format('yyyy-MM-DD');
			endDate = moment().subtract(1, 'year').endOf('year').format('yyyy-MM-DD');
		}
		if (selectedDateFilter === '2Y') {
			startDate = moment().subtract(1, 'year').startOf('year').format('yyyy-MM-DD');
			endDate = moment().endOf('year').format('yyyy-MM-DD');
		}
		setLoading(true);
		try {
			const res = await fetchDatabricksClustersCount(startDate, endDate);
			if (res.success) {
				if (res.data.databricksClustersCount.length) {
					let sumOfExeCount = res.data.databricksClustersCount.reduce((count: any, item: any) => count + item.jobSumOfRunTimeSec + item.sqlSumOfRunTimeSec + item.interactiveSumOfRunTimeSec, 0);
					let countOfExeCount = res.data.databricksClustersCount.reduce((count: any, item: any) => count + item.jobCountOfRunTimeSec + item.sqlCountOfRunTimeSec + item.interactiveCountOfRunTimeSec, 0);
					let exeCount = sumOfExeCount / countOfExeCount;
					getDatabricksExecutionData(exeCount)
				} else {
					getDatabricksExecutionData(0);
				}
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	}

	useEffect(() => {

		const activeId = document.getElementById('root');
		if (window.location.pathname === '/dataestate/data-processing') {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
				document.body.classList.add('custom-class');
				activeId.classList.add('jobs-list-table');
				activeId.classList.remove('jobs-list');
				activeId.classList.remove('root');
				document.body.classList.add('jobs-exe-datas');
				document.body.classList.remove('jobs-run-table');
				document.body.classList.add('jobs-list-datas');
				document.body.classList.add('datefilter-align-sector');
				document.body.classList.add('sector-label');
			}
		} else {
			if (activeId) {
				document.body.classList.remove('jobs-list-datas');
				activeId.classList.add('root');
				document.body.classList.add('dashboard-panel-dataestate');
				document.body.classList.add('datefilter-align-sector');
			}
		}
		if (userSector === 'all') fetchSectorOptionsData();
		getDataProcessingSummary(filterMonth, sector);
		loadDatabricksClustersCount();
		loadDatabricksClustersCountExecution();
		loadDatabricksAnnualIndicator();
		getDataProcessingSynapseTime(filterMonth);
	}, []);
	const [tableName, setTableName] = useState('');
	interface ADF {
		azureSubscription: string;
		resourceGroup: string;
		pipelineName: string;
		pipelineStatus: string;
		runStart: string;
		runEnd: string;
		durationInMs: string;
		lastUpdated: string;
	}
	interface teraDataJobs {
		jobName: string;
		boxName: string;
		sector: string;
		system: string;
		process: string;
		critical: string;
		lastSuccessfulRun: string;
		normalCompletionTime: string;
		observedSince: string;
	}
	interface dataBricks {
		workspace_name: string;
		cluster_name: string;
		cluster_category: string;
		total_dbus: string;
		project_name: string;
		job_name: string;
		timestamp_state_start: string;
		timestamp_state_end: string;
		sector_name: string;
	}
	interface Row {}
	function getColumns(): Array<Column<Row>> {
		if (typeName == 'ADF') {
			return [
				{
					key: 'azureSubscription',
					name: 'Azure Subscription',
					minWidth: 250
				},
				{
					key: 'resourceGroup',
					name: 'Resource Group',
					minWidth: 200
				},
				{
					key: 'pipelineName',
					name: 'Pipeline Name',
					minWidth: 200
				},
				{
					key: 'pipelineStatus',
					name: 'Pipeline Status',
					minWidth: 250
				},
				{
					key: 'runStart',
					name: 'Run Start',
					minWidth: 200
				},
				{
					key: 'runEnd',
					name: 'Run End',
					minWidth: 200
				},
				{
					key: 'durationInMs',
					name: 'Duration In MS',
					minWidth: 250
				},
				{
					key: 'lastUpdated',
					name: 'Last Updated',
					minWidth: 250
				}
			];
		} else if (typeName == 'TeraData') {
			return [
				{
					key: 'jobName',
					name: 'Job Name',
					minWidth: 180
				},
				{
					key: 'boxName',
					name: 'Box Name',
					minWidth: 180
				},
				{
					key: 'sector',
					name: 'Sector',
					minWidth: 170
				},
				{
					key: 'system',
					name: 'System',
					minWidth: 180
				},
				{
					key: 'process',
					name: 'Process',
					minWidth: 180
				},
				{
					key: 'critical',
					name: 'Critical',
					minWidth: 180
				},
				{
					key: 'lastSuccessfulRun',
					name: 'Last successful run',
					minWidth: 250
				},
				{
					key: 'normalCompletionTime',
					name: 'Normal completion Time',
					minWidth: 250
				},
				{
					key: 'observedSince',
					name: 'Observed since',
					minWidth: 250
				}
			];
		} else if (typeName == 'dataBricks') {
			return [
				{
					key: 'workspace_name',
					name: 'Project name',
					minWidth: 250
				},
				{
					key: 'cluster_name',
					name: 'Workspace',
					minWidth: 250
				},
				{
					key: 'cluster_category',
					name: 'Cluster name',
					minWidth: 250
				},
				{
					key: 'total_dbus',
					name: 'Job Name',
					minWidth: 250
				},
				{
					key: 'project_name',
					name: 'Job Run Time',
					minWidth: 250
				},
				{
					key: 'job_name',
					name: 'Job Status',
					minWidth: 250
				},
				{
					key: 'timestamp_state_start',
					name: 'Timestamp State Start',
					minWidth: 170
				},
				{
					key: 'timestamp_state_end',
					name: 'Timestamp State End',
					minWidth: 170
				},
				{
					key: 'sector_name',
					name: 'Start Time',
					minWidth: 170
				}
			];
		} else {
			return [];
		}
	}
	const ADFPipelinesColumns = [
		'Azure Subscription',
		'Resource Group',
		'Pipeline Name',
		'Pipeline Status',
		'Run Start',
		'Run End',
		'Duration In MS',
		'Last Updated'
	];
	const teraDataJobsColumns = [
		'Job Name',
		'Box Name',
		'Sector',
		'System',
		'Process',
		'Critical',
		'Last successful run',
		'Normal completion Time',
		'Observed since'
	];
	const dataBricksColumns = [
		'Project name',
		'Workspace',
		'Cluster name',
		'Job Name',
		'Job Run Time',
		'Job Status',
		'Timestamp State End',
		'Timestamp State End',
		'Start Time'
	];
	const [defaultColumnNames, setDynamicValue] = useState(['']);
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	const [isTableVisible, setIsTableVisible] = useState(false);

	const [tableData, setTableData] = useState<Row[]>([]);
	const [tableHeading, setTableHeading] = useState('');
	const [typeName, setTypeName] = useState('');

	async function processingListingFunc(pageNumber: any, size: any, sortingInfo: any) {
		let month = customMonth ? customMonth : filterMonth;
		const res = await dataProcessingTable('dataProcessing', dimension, sector, month);
		if (res.success) {
			setTableData(res.data.summary);
			if (res.data.page) {
				setPaginationData(res.data.page);
			}
			setPage(pageNumber);
			setPageSize(size);
			setSortingInfo(sortingInfo);
		}
	}

	async function getDBDatabricksData(pageNumber: any, size: any, sortingInfo: any) {
		let startDate: any;
		let endDate: any;
		if (filterMonth === '0M') {
			startDate = moment();
			endDate = moment();
		}
		if (filterMonth === '1M') {
			startDate = moment().subtract(1, 'months').startOf('month');
			endDate = moment().endOf('month');
		}
		if (filterMonth === '3M') {
			startDate = moment().subtract(3, 'months').startOf('month');
			endDate = moment().endOf('month');
		}
		if (filterMonth === '6M') {
			startDate = moment().subtract(6, 'months').startOf('month');
			endDate = moment().endOf('month');
		}
		if (filterMonth === '9M') {
			startDate = moment().subtract(9, 'months').startOf('month');
			endDate = moment().endOf('month');
		}
		if (filterMonth === '0Y') {
			startDate = moment().subtract(0, 'year').startOf('year');
			endDate = moment().endOf('year');
		}
		if (filterMonth === '1Y') {
			startDate = moment().subtract(1, 'year').startOf('year');
			endDate = moment().endOf('year');
		}
		if (filterMonth === '2Y') {
			startDate = moment().subtract(2, 'year').startOf('year');
			endDate = moment().endOf('year');
		}
		let sector: any = [];
		let filterInfo: any = [];

		const res = await getDBDatabricks(pageNumber, size, sortingInfo, filterInfo, startDate, endDate, sector);

		if (res.success) {
			setTableData(res.data.result);
			if (res.data.page) {
				setPaginationData(res.data.page);
			}

			setPage(pageNumber);
			setPageSize(size);
			setSortingInfo(sortingInfo);
		}
	}

	const handleSorting = (sortingInfo: any) => {
		processingListingFunc(page, pageSize, sortingInfo);
	};
	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		processingListingFunc(value, pageSize, sortingInfo);
	};
	const handlepageSizeChange = (event: any) => {
		processingListingFunc(page, event.target.value, sortingInfo);
	};
	const handleClick = (typeName: any) => {
		setDynamicValue([]);
		setTableData([]);
		setIsTableVisible(true);
		if (typeName == 'ADF') {
			console.log('default before', defaultColumnNames);
			setDynamicValue(ADFPipelinesColumns);
			setTableHeading('ADF Pipelines');
			type Row = keyof ADF;
			setTypeName('ADF');
			dimension = 'adfpipeline';
			console.log('default', defaultColumnNames);
			processingListingFunc(page, pageSize, sortingInfo);
		}
		if (typeName === 'Teradata') {
			console.log('default before', defaultColumnNames);
			setDynamicValue(teraDataJobsColumns);
			setTableHeading('TeraData Jobs');
			type Row = keyof teraDataJobs;
			setTypeName('TeraData');
			dimension = 'teradataJobs';
			console.log('default', defaultColumnNames);
			processingListingFunc(page, pageSize, sortingInfo);
		}
		if (typeName === 'dataBricks') {
			console.log('default before', defaultColumnNames);
			setDynamicValue(dataBricksColumns);
			setTableHeading('Databricks clusters');
			type Row = keyof teraDataJobs;
			setTypeName('dataBricks');
			console.log('default', defaultColumnNames);
			getDBDatabricksData(page, pageSize, sortingInfo);
		}
	};

	const clearMetricsSummary = () => {
		// setDynamicValue(['']);
		setTableData([]);
		setIsDetailVisible(false);
	};

	return (
		<Box mt="45px">
			<PageHeader title="Data Processing Summary" icon={DataProcIcon} lastSync={''}>
				<DateFilterDropdown
					customMonth={customMonth}
					filterMonth={filterMonth}
					setCustomMonth={setCustomMonth}
					setFilterMonth={setFilterMonth}
					handleChange={applyDateFilter}
				/>
			</PageHeader>
			<Grid container spacing={1} p={2} mt={2}>
				<Grid item xs={12} lg={8}>
					<WidgetCardContainer title="Data Processing">
						<Grid item xs={12} md={6}>
							<NumberWidget
								title="ADF Pipelines"
								tooltipLabel={''}
								hasTooltip={false}
								number={ADFPipeline ? formatNumber((ADFPipeline / 1000).toFixed(2).toString()) : '-'}
								units={ADFPipeline ? 'k' : ''}
								isLoading={ADFPipeline ? ADFPipeline.toString() === '-' : false}
								handleEvent={!isEnvTypePROD ? () => handleClick('ADF') : undefined}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							<NumberWidget
								title="Teradata Jobs"
								tooltipLabel={''}
								hasTooltip={false}
								number={teradataJobs ? formatNumber((teradataJobs / 1000).toFixed(2).toString()) : ''}
								units={teradataJobs ? 'k' : ''}
								isLoading={teradataJobs?.toString() === '-'}
								handleEvent={!isEnvTypePROD ? () => handleClick('Teradata') : undefined}
							/>
						</Grid>
						{/* </Grid> */}

						{/* <Grid container spacing={2}> */}
						<Grid item xs={12} md={6}>
							<ChartWidget
								title="Azure Pipeline Trend"
								data={pipelineGrowthOptions}
								isLoading={pipelineGrowthLoading}
								lastUpdatedDate={lastUpdatedDatePipeline}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							<ChartWidget
								title="Teradata Job Trend"
								data={teradataJobsOptions}
								isLoading={teradataJobsLoading}
								lastUpdatedDate={lastUpdatedDateTeradata}
							/>
						</Grid>
						{/* </Grid> */}
					</WidgetCardContainer>
				</Grid>
				<Grid item xs={12} lg={4}>
					<WidgetCardContainer title="Compute Clusters">
						<Grid item xs={12}>
							<NumberWidget
								title="Databricks"
								tooltipLabel={''}
								hasTooltip={false}
								number={databricksClusters ? formatNumber((databricksClusters / 1000000).toFixed(2).toString()) : 0}
								units={databricksClusters ? 'MM' : ''}
								isLoading={databricksClusters?.toString() === '-'}
								handleEvent={!isEnvTypePROD ? () => handleClick('dataBricks') : undefined}
							/>
						</Grid>

						<Grid item xs={12} className="">
							<ChartWidget
								title="Databricks Trend"
								data={clusterGrowthOptions}
								isLoading={clusterGrowthLoading}
								lastUpdatedDate={lastUpdatedDateCluster}
								restrictToMillions={true}
								isDatabrick={true}
							/>
						</Grid>
					</WidgetCardContainer>
				</Grid>
				<Grid item xs={12} lg={7}>
					<WidgetCardContainer title="Performance">
						<Grid item xs={12} md={6}>
							{/* <GuageWidget
								title="Databricks Job Execution Time"
								isLoading={databricksJobExecutionTimeLoader}
								data={databricksJobExecutionTimeChartOptions}
							/> */}
							<UnderDevelopmentWidget title="Databricks Job Execution Time" />
						</Grid>
						<Grid item xs={12} md={6}>
							<GuageWidget
								title="Teradata Job Execution Time"
								isLoading={teradataJobExecutionTimeLoader}
								data={teradataJobExecutionTimeChartOptions}
							/>
						</Grid>
					</WidgetCardContainer>
				</Grid>
				<Grid item xs={12} lg={5}>
					<WidgetCardContainer title="Reliability">
						<Grid item xs={12} md={6}>
							<NumberWidget
								title="Azure SQL Uptime"
								tooltipLabel={''}
								hasTooltip={false}
								number={azureSqlUptime ? azureSqlUptime : '0'}
								units={'%'}
								isLoading={azureUptimeLoader}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<NumberWidget
								title="Synapse Uptime"
								tooltipLabel={''}
								hasTooltip={false}
								number={synapseUptime ? `${formatNumber((synapseUptime))}` : '0'}
								units={'%'}
								isLoading={synapseUptimeLoader}
							/>
						</Grid>
					</WidgetCardContainer>
				</Grid>
				{isTableVisible && (
					<Grid item xs={12}>
						<WidgetCardContainer title={tableHeading}>
							<Grid>
								{tableData && tableData.length > 0 ? (
									<CustomizedDataGrid
										tableColumns={getColumns()}
										tableData={tableData}
										tableType="sector"
										defaultColumnNames={defaultColumnNames}
										onSorting={handleSorting}
										currSorting={sortingInfo}
										page={page}
										pageSize={pageSize}
										paginationData={paginationData}
										listLength={tableData.length}
										handlepageSizeChange={handlepageSizeChange}
										handlePageChange={handlePageChange}
										isDateRangeFilter={false}
										isDisabled={false}
										isFetching={true}
									/>
								) : (
									<span>No data found </span>
								)}
							</Grid>
						</WidgetCardContainer>
					</Grid>
				)}
			</Grid>
		</Box>
	);
}
