import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Tooltip, Stack, StackProps } from '@mui/material';
import ToolTipIcon from '../../assets/info-icon.svg';
import PageNextIcon from '../../assets/page_next.svg';
import NoPrgramFound from '../../assets/no-program-found.svg';
import LoaderComponent from 'components/Loader';
import SplitNumber from './SplitNumber';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';

type NumberWidgetProps = {
	color?: string;
	bgColor?: string;
	isLoading: boolean;
	isInProgress?: boolean;
	title: string;
	titleFontSize?: string; 
	tooltipLabel: string;
	footer?: {
		footerLink: string;
		footerLabel: string;
	};
	number?: number | string | string[] | number[] | null;
	units?: null | string | string[];
	unitIcon?: null | string;
	unitIconColor?: null | string;
	refreshersRate?: null | string;
	isCost?: boolean;
	minTitleHeight?: `${number}px`;
	hasTooltip?: boolean;
	handleEvent?: () => void;
	ratioNumber?: {
		number: number | string;
		totalNumber: number | string;
	};
	totalRefreshers?: number;
	containerProps?: StackProps;
};

const NumberWidget = ({
	color = 'text.main',
	bgColor = 'primary.main',
	isLoading = false,
	isInProgress = false,
	title = '',
	titleFontSize = '',
	tooltipLabel = '',
	number = '',
	units = null,
	unitIcon = null,
	unitIconColor = null,
	refreshersRate = null,
	footer,
	minTitleHeight = '38px',
	isCost = false,
	hasTooltip = true,
	handleEvent,
	ratioNumber,
	totalRefreshers,
	containerProps,
}: Readonly<NumberWidgetProps>) => {
	return (
		<Box
			display="flex"
			flexGrow={1}
			height="100%"
			position="relative"
			{...(handleEvent && { onClick: handleEvent, sx: { cursor: 'pointer' } })}
		>
			<Stack
				minHeight="100px"
				p={2}
				bgcolor={bgColor}
				fontFamily="Montserrat"
				borderRadius="5px"
				flexGrow={1}
				{...containerProps}
				sx={{
					position: 'relative',
					'&:hover .hover-content': {
						opacity: 1
					},
					...containerProps?.sx,
				}}
			>
				<Stack direction="row" gap={2} alignItems="start" minHeight={minTitleHeight}>
					<Typography variant="widgetTitle" sx={{ fontSize: (titleFontSize ? titleFontSize : '') }}>{title}</Typography>
					{hasTooltip && !!tooltipLabel && (
						<Box
							className="hover-content"
							sx={{
								opacity: 0,
								transition: 'opacity 0.3s ease-in-out'
							}}
						>
							<Tooltip title={tooltipLabel} arrow>
								<img src={ToolTipIcon} />
							</Tooltip>
						</Box>
					)}
				</Stack>
				<Stack minHeight="43px" flexGrow={1} justifyContent="center">
					{!isLoading ? (
						<Stack color={color} direction="row" alignItems="baseline">
							{Array.isArray(number) ? (
								number.map((item, index) =>
									item !== '' && item !== null ? (
										<Stack direction="row" alignItems="baseline" key={`${item}-${index}`} ml={index !== 0 ? '10px' : 0}>
											<SplitNumber number={item} isCost={isCost} />
											{!!units && units[index] ? (
												<Typography variant="widgetUnit" {...(units[index] === '%' && { ml: 0 })}>
													{units[index]}
												</Typography>
											) : (
												''
											)}{' '}
										</Stack>
									) : (
										<React.Fragment key={index} />
									)
								)
							) : number !== '' && number !== null ? (
								<>
									<SplitNumber number={number} isCost={isCost} />
									{units && !Array.isArray(units) && (
										<Typography variant="widgetUnit" {...(units === '%' && { ml: 0 })}>
											{units}
										</Typography>
									)}
								</>
							) : number == null && units == 'hrs' ? (
								<>
									<Typography variant="widgetUnit">Insufficient Data</Typography>
								</>
							) : (
								<></>
							)}
							{!!ratioNumber && (
								<Box display='flex' gap={0} alignItems='baseline'>
									<Typography variant="widgetValue">{ratioNumber.number}</Typography>
									{ratioNumber.totalNumber && ratioNumber.totalNumber !== "" && 
									(<Typography variant="widgetRatioValue" ml={0} color='light.diff'>/{ratioNumber.totalNumber}</Typography>)
									} 
								</Box>
							)}
							{totalRefreshers && (
								<Box display='flex' gap={0} alignItems='baseline'>
									<Typography variant="breadcrumbs">{totalRefreshers}</Typography>
								</Box>
							)}
							{unitIcon &&
								(unitIcon === 'upArrow' ? (
									<Box display='flex' gap={0} alignItems='baseline' sx={{ ml: '10px' }}>
										{refreshersRate && (<Typography variant="widgetUnit" sx={{ color: unitIconColor }}>{refreshersRate}</Typography>)}
										<ArrowUpward sx={{ ml: '2px', color: unitIconColor }} />
									</Box>
								) : unitIcon === 'downArrow' ? (
									<Box display='flex' gap={0} alignItems='baseline' sx={{ ml: '10px' }}>
										{refreshersRate && (<Typography variant="widgetUnit" sx={{ color: unitIconColor }}>{refreshersRate}</Typography>)}
										<ArrowDownward fontSize="large" sx={{ ml: '2px', color: unitIconColor, }} />
									</Box>
								) : (
									<img
										src={unitIcon}
										style={{
											height: '24px',
											width: '24px',
											marginLeft: '10px'
										}}
									/>
								))}
						</Stack>
					) : (
						<LoaderComponent />
					)}
				</Stack>
				{isInProgress && (
					<Box position="absolute" right={15} bottom={10}>
						<Tooltip title="In Progress/Validation">
							<img
								src={NoPrgramFound}
								style={{
									height: '20px',
									width: '20px'
								}}
							/>
						</Tooltip>
					</Box>
				)}
			</Stack>
		</Box>
	);
};

export default NumberWidget;
