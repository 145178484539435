import {
	Button,
	Stack,
	TextField,
	InputAdornment,
	Typography,
	FormControl,
	FormHelperText,
	Box,
	Chip,
	InputLabel,
	Select,
	Menu,
	MenuItem,
	SelectChangeEvent,
	Alert
} from '@mui/material';
import { CustomizedDataGrid } from 'components/customized_data_grid';
import CustomTextField from 'components/Inputs/CustomTextField';
import CustomDateTimePicker from 'components/Inputs/CustomDateTimePicker';
import { ReactComponent as EditIcon } from '../../../assets/material-symbols_edit-rounded.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/delete-img.svg';
import { ReactComponent as CloseIcon } from '../../../assets/eva_close-outline_red.svg';
import { ReactComponent as SaveIcon } from '../../../assets/mingcute_check-fill.svg';
import RowTypography from 'components/Table/RowTypography';
import React, { useEffect, useRef, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { InsertEmoticon, Padding } from '@mui/icons-material';
import AsyncAutoComplete from 'components/Inputs/ResourceMapping/AsyncAutocomplete';
import colors from 'assets/theme/base/colors';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { DatePicker, LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {ReactComponent as WarningImage} from '../../../assets/warning.svg';
import ResourceAllocationSuccessModal from './ResourceAllocationSuccessModal';
import {
	getBulkEditFinopsinputProgramProjectOption,
	getBulkEditFinopsinputSecEnvOption,
	postResourceAllocationFinops,
	getResourceAllocationFinops
} from 'service/service';
import RowChip from 'components/Table/RowChip';
import LoaderComponent from 'components/Loader';

type NewAllocationRecords = {
	[key: number]: {};
};

interface Option {
	value: string;
	label: string;
}

interface ProjectOwner {
	name: string;
	email: string;
}

export interface TableRow {
	id: number;
	isNew: boolean;
	isEditing: boolean;
	PepwiseEnvironment: string;
	PepwiseProject: string;
	PepwiseProgram: string;
	PepwiseSector: string;
	AllocPercent: string;
	startDate: Dayjs;
	endDate: Dayjs | 'current';
}

interface Allocation {
	pepwiseSector: string;
	pepwiseProject: string;
	pepwiseProgram: string;
	allocation: number;
	projectId: number;
	programId: number;
  }

interface RowData {
	startDate: string;
	endDate: string;
	pepwiseEnvironment: string;
	allocations: Allocation[];
  }

type ModalWidth = "sm" | "md" | "lg" | "xl";
interface ResourceAllocationModalProps {
	currentRow: any;
	allocationType: {
		key: string;
		label: string;
	};
	onCancel: () => void;
	onConfirm: () => void;
	modalTitle: string;
	onTitleChange: (newTitle: string) => void;
    onWidthChange: (newWidth: ModalWidth) => void;
}

const ResourceAllocationModal: React.FC<ResourceAllocationModalProps> = ({ currentRow, onCancel, onConfirm, onTitleChange, onWidthChange, modalTitle, allocationType }) => {
	
	const validationInitialState = {
		environment: false,
		project: false,
		programs: false,
		sectors: false,
		allocationPercentage: false,
		allocationStartTime: false,
		allocationEndTime: false
	};

	const editErrorMessages = {
		allocationPercentage: 'Allocation percentage must be between 0 and 100.',
		allocationStartTime: 'Allocation Start date is required.',
		allocationEndTime: 'Allocation End date is required and must be after start date, and cannot be a future date.'
	};
	
	const [validationErrors, setValidationErrors] = useState(validationInitialState);
	const [editValidationErrors, setEditValidationErrors] = useState(validationInitialState);
	const [selectedRows, setSelectedRows] = useState<TableRow[]>([]);
	const [originalRows, setOriginalRows] = useState<TableRow[]>([]);
	const [newAllocationRecords, setNewAllocationRecords] = useState<NewAllocationRecords>({});
	const [isSuccesModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);
	// const [selectedOption, setSelectedOption] = useState<'current' | 'custom'>('current');
	const [remainingPercentage, setRemainingPercentage] = useState<number>(0);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [response, setResponse] = useState<any>(null);

	const [groupId, setGroupId] = useState<string>('');
	const [selectedProgramId, setSelectedProgramId] = useState<Record<string,number>>({});
	const [selectedProjectId, setSelectedProjectId] = useState<Record<string,number>>({});
	const [programOptns, setProgramOptns] = useState<Option[]>([]);
	const [projectOptions, setProjectOptions] = useState<Option[]>([]);
	const [sectorOptions, setSectorOptions] = useState<Option[]>([]);
	const [environmentOptions, setEnvironmentOptions] = useState<Option[]>([]);
	const [selectedProgram, setSelectedProgram] = useState<number | null>(null);
	const [projectsByProgram, setProjectsByProgram] = useState<Record<string, Option[]>>({});
	const [isProjectDisabled, setIsProjectDisabled] = useState(true);
	const [payload, setPayload] = useState<TableRow[]>([]);
	const [allocationResponse, setAllocationResponse] = useState<any>()
	const [resource, setResource] = useState<string>('');

	const isNewAllocRowExists = selectedRows.some((row) => row.isNew || row.isEditing === true);

	// initial table listing of selected resource allocation
	// useEffect(() => {
	// 	if (currentRow) {
	// 		const allocations = currentRow.AllocPercent.split(',')
	// 		const environments = currentRow.PepwiseEnvironment.split(',')
	// 		const programs = currentRow.PepwiseProgram.split(',')
	// 		const projects = currentRow.PepwiseProject.split(',')
	// 		const sectors = currentRow.PepwiseSector.split(',')
	// 		const rows: TableRow[] = [];
	// 		for (let i = 0; i < currentRow.AllocationCount; i++) {
	// 			rows.push({
	// 				id: i === 0 ? currentRow.id : currentRow.values[i - 1].id,
	// 				isNew: false,
	// 				isEditing: false,
	// 				environment: environments[i],
	// 				project: projects[i],
	// 				programs: programs[i],
	// 				sectors: sectors[i],
	// 				allocationPercentage: allocations[i],
	// 				allocationStartTime: dayjs('2024-08-02T19:30:00'),
	// 				allocationEndTime: 'current',
	// 				actions: 'Save/Delete'
	// 			})
	// 		}
	// 		setSelectedRows(rows);			
	// 	}
		
	// }, [currentRow])

	const defaultColumnNames = [
		'Projects',
		'Programs',
		'Sectors',
		'Allocation%',
		'Allocation Start Date',
		'Allocation End Date',
		'Actions'
	];

	const columnNames = [
		{ name: 'PepwiseProgram', label: 'Programs', isEditable: false },
		{ name: 'PepwiseProject', label: 'Projects', isEditable: false },
		{ name: 'PepwiseSector', label: 'Sectors', isEditable: false },
		{ name: 'AllocPercent', label: 'Allocation %', isEditable: true },
		{ name: 'startDate', label: 'Allocation Start Date', isEditable: true },
		{ name: 'endDate', label: 'Allocation End Date', isEditable: true },
		{ name: 'actions', label: 'Actions', isEditable: false }
	];

	const [newAllocation, setNewAllocation] = useState<TableRow>({
		id: 0,
		isNew: true,
		PepwiseEnvironment: '',
		PepwiseProject: '',
		PepwiseProgram: '',
		PepwiseSector: '',
		AllocPercent: '',
		startDate: dayjs(),
		endDate: 'current',
		isEditing: false
	});

	const [environment, setEnvironment] = useState('');
	const [originalEnvironment, setOriginalEnvironment] = useState('');

	useEffect(() => {
		const groupId = currentRow?.groupId;
		setGroupId(groupId);
		const resource = currentRow?.[allocationType.key];
		setResource(resource);
	}, [currentRow, allocationType.key]);

	// update payload function
	const updatePayload = () => {
		const updatedRows: {[key: string]: RowData} = {};
		selectedRows.forEach((r: any) => {
			const { startDate, endDate, PepwiseProject, PepwiseProgram, PepwiseSector, AllocPercent } = r;
			const formatedStartDate = dayjs(startDate)?.format('YYYY-MM-DD');
			const formatedEndDate = endDate === 'current' ? "9999-12-31" : dayjs(endDate)?.format('YYYY-MM-DD');
			if (!r.isNew) {
				const key = `${formatedStartDate}-${formatedEndDate}`;
				
				if (updatedRows[key]) {
					// If the key already exists, add to the allocations array
					updatedRows[key].allocations.push({
					  pepwiseSector: PepwiseSector,
					  pepwiseProject: PepwiseProject,
					  pepwiseProgram: PepwiseProgram,
					  allocation: Number(AllocPercent),
					  projectId: selectedProjectId[PepwiseProject] ? selectedProjectId[PepwiseProject] : Number(r.projectId),
					  programId: selectedProgramId[PepwiseProgram] ? selectedProgramId[PepwiseProgram] : Number(r.programId)
					});
				  } else {
					// If the key doesn't exist, create a new object with allocations array
					updatedRows[key] = {
					  startDate: formatedStartDate,
					  endDate: formatedEndDate,
					  pepwiseEnvironment: environment,
					  allocations: [
						{
						  pepwiseSector: PepwiseSector,
						  pepwiseProject: PepwiseProject,
						  pepwiseProgram: PepwiseProgram,
						  allocation: Number(AllocPercent),
						  projectId: selectedProjectId[PepwiseProject] ? selectedProjectId[PepwiseProject] : Number(r.projectId),
					      programId: selectedProgramId[PepwiseProgram] ? selectedProgramId[PepwiseProgram] : Number(r.programId)
						}
					  ]
					};
				  }
			}
		});
		return updatedRows;
	};

	// Fetch program and project options
	const getInputProgramProjectOptions = async (input: string) => {
		try {
			const fieldsRes = await getBulkEditFinopsinputProgramProjectOption({ searchText: input });
			const programs = fieldsRes.data.programs;

			const programOptions: Option[] = programs.map((program: any) => ({
				value: program.programId,
				label: program.programName
			}));

			// Map `projects` for each program into a nested structure
			const projects: Record<string, Option[]> = {};
			programs.forEach((program: any) => {
				projects[program.programName] = program.projects.map((project: any) => ({
					value: project.projectId,
					label: project.projectName
				}));
			});
			
			return { programOptions, projects };
		} catch (error) {
			console.error('Error fetching program/project options:', error);
			return { programOptions: [], projects: {} };
		}
	};

	const getProgramProjectOptions = async (
		input: string | undefined,
		type: 'project' | 'program'
	): Promise<Option[]> => {
		const { programOptions, projects } = await getInputProgramProjectOptions(input ?? '');
		programOptions && setProgramOptns(programOptions);
		projects && setProjectsByProgram(projects);
		if (type === 'program') return programOptions;
		return projects[`${newAllocation.PepwiseProgram}`] ?? [];
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				setIsLoading(true);
				if (groupId) {
					const response = await getResourceAllocationFinops({ groupId: groupId });
					if (response.success && response.data) {
						const transformedData = response.data.map((item: any) => ({
							id: item.allocationId,
							isNew: false,
							isEditing: false,
							PepwiseEnvironment: item.pepwiseEnvironment,
							PepwiseProject: item.pepwiseProject,
							PepwiseProgram: item.pepwiseProgram,
							PepwiseSector: item.pepwiseSector,
							AllocPercent: item.allocation,
							startDate: item.startDate,
							endDate: item.endDate,
							programId: item.programId,
							projectId: item.projectId,
							updatedBy: item.updatedBy,
							updatedDate: item.updatedDate
						}));
						setEnvironment(response.data[0].pepwiseEnvironment);
						setOriginalEnvironment(response.data[0].pepwiseEnvironment);
						setSelectedRows(transformedData);
						setOriginalRows(transformedData);
					}
				}
			} catch (err) {
				console.error('Error fetching allocation:', err);
				return [];
			} finally {
				setIsLoading(false);
			}
		};

		fetchData();
	}, [groupId]);

	// Helper function to check if data has changed
	const hasChanges = () => {
		if (!originalRows || originalRows.length !== selectedRows.length || environment !== originalEnvironment) {
			return true;
		}
		return selectedRows.some((row, index) => {
			const originalRow = originalRows[index];
			return (
				row.PepwiseProject !== originalRow.PepwiseProject ||
				row.PepwiseProgram !== originalRow.PepwiseProgram ||
				row.PepwiseSector !== originalRow.PepwiseSector ||
				row.AllocPercent !== originalRow.AllocPercent ||
				row.startDate !== originalRow.startDate ||
				row.endDate !== originalRow.endDate
			);
		});
	};

	useEffect(() => {
		const fetchOptions = async () => {
			const { programOptions, projects } = await getInputProgramProjectOptions('');
			setProgramOptns(programOptions);
			setProjectsByProgram(projects);
		};
		fetchOptions();
		getInputSecEnvOptions();
		handleAddNewRow();
	}, []);

	// Update project options when a program is selected
	useEffect(() => {
		if (!!newAllocation.PepwiseProgram) {
			setProjectOptions(projectsByProgram[`${newAllocation.PepwiseProgram}`] || []);
			setIsProjectDisabled(false);
		} else {
			setProjectOptions([]);
			setIsProjectDisabled(true);
		}
	}, [newAllocation]);

	// fetch sector and environment options
	const getInputSecEnvOptions = async () => {
		try {
			const fieldsRes = await getBulkEditFinopsinputSecEnvOption();

			const sectorsOptions = fieldsRes.data.sectors.filter(Boolean).map((item: string) => ({
				value: item,
				label: item
			}));

			const environmentsOptions = fieldsRes.data.environments.filter(Boolean).map((item: string) => ({
				value: item,
				label: item
			}));
			setSectorOptions(sectorsOptions);
			setEnvironmentOptions(environmentsOptions);
		} catch (error) {
			console.error('Error fetching options:', error);
			return [];
		}
	};

	const handleChange = (field: string, value: any) => {
		setNewAllocation((prev) => {
			// If `PepwiseProgram` is being updated, reset `PepwiseProject`
			if (field === 'PepwiseProgram') {
				return {
				  ...prev,
				  [field]: value, // Update `PepwiseProgram`
				  PepwiseProject: '', // Reset `PepwiseProject`
				};
			  }
			  // Otherwise, just update the specified field
			  return {
				...prev,
				[field]: value,
			  };
		});

		// validation based on the field
		switch (field) {
			case 'environment':
				setValidationErrors((prev) => ({
					...prev,
					environment: !value
				}));
				break;
			case 'project':
				setValidationErrors((prev) => ({
					...prev,
					project: !value
				}));
				break;
			case 'programs':
				setValidationErrors((prev) => ({
					...prev,
					programs: !value
				}));
				break;
			case 'sectors':
				setValidationErrors((prev) => ({
					...prev,
					sectors: !value
				}));
				break;
			case 'allocationPercentage':
				setValidationErrors((prev) => ({
					...prev,
					allocationPercentage: !value || value < 0 || value > 100 || isNaN(value)
				}));
				break;
			case 'allocationStartTime':
				setValidationErrors((prev) => ({
					...prev,
					allocationStartTime: !value,
					allocationEndTime: prev.allocationEndTime && value && dayjs(value).isAfter(newAllocation.endDate)
				}));
				break;
			case 'allocationEndTime':
				setValidationErrors((prev) => ({
					...prev,
					allocationEndTime:
						// value !== 'current' ? (newAllocation.startDate && !dayjs(value).isAfter(newAllocation.startDate)) : false
						value !== 'current' &&
						(dayjs(value).isAfter(dayjs(), 'day') ||
							(newAllocation.startDate && !dayjs(value).isAfter(dayjs(newAllocation.startDate))))
				}));
				break;
			default:
				break;
		}
	};

	const handleEditRow = (row: TableRow) => {
		setSelectedRows((prevRows) =>
			prevRows.map((r) => (r.id === row.id ? { ...r, isEditing: true } : { ...r, isEditing: false }))
		);
	};

	// const handleSaveEdit = (rowId: number, updatedFields: { [key: string]: any }) => {
	// 		setSelectedRows((prevRows) =>
	// 			prevRows.map((row) => (row.id === rowId ? { ...row, ...updatedFields, isEditing: false } : row))
	// 		);
	// 		setEditValidationErrors(validationInitialState); // Clear validation errors
	// };

	const handleSaveEdit = (rowId: number, updatedFields: { [key: string]: any }) => {
		setSelectedRows((prevRows) => 
		  prevRows.map((row) => {
			if (row.id === rowId) {
			  // const { isValid, remainingPercentage } = validateCurrentAllocation(
				// selectedRows, 
				// { ...row, ...updatedFields }
			  // );
			  
			  // setRemainingPercentage(remainingPercentage);
			  
			  // if (isValid) {
				// 	setEditValidationErrors(validationInitialState);
				// }
				return { ...row, ...updatedFields, isEditing: false };
			}
			return row;
		  })
		);
	};

	const handleAllocationChange = (id: number, field: string, value: any) => {
		if (field === 'allocationEndTime' && value === '') {
			value = 'current';
		}
		setSelectedRows(selectedRows.map((row) => (row.id === id ? { ...row, [field]: value } : row)));
	};

	// validate current allocation
	// const validateCurrentAllocation = (
	// 	rows: TableRow[],
	// 	Row: TableRow
	// ): { isValid: boolean; remainingPercentage: number } => {
	// 	let currentAllocations = rows.filter((row) => row.endDate === '' && row.id !== Row.id);

	// 	if (Row.endDate === '') {
	// 		currentAllocations = [...currentAllocations, Row];
	// 	}

	// 	const totalPercentage = currentAllocations.reduce((sum, row) => sum + Number(row.AllocPercent), 0);

	// 	const remainingPercentage = 100 - totalPercentage;
	// 	console.log("remainingPercentage: " + remainingPercentage)
	// 	return { remainingPercentage, isValid: totalPercentage === 100 };
	// };

	const handleAddAllocation = () => {
		const generateRandomId = () => Math.floor(Math.random() * 1000000);
		// Remove the existing row with isNew: true, if any
		const rowsWithoutNew = selectedRows.filter((row) => !row.isNew);
		const newId = generateRandomId();
		// const { isValid, remainingPercentage } = validateCurrentAllocation(selectedRows, newAllocation);
		// setRemainingPercentage(remainingPercentage);
		const errors = {
			environment: !environment,
			project: !newAllocation.PepwiseProject, // Project is required
			programs: !newAllocation.PepwiseProgram,
			sectors: !newAllocation.PepwiseSector,
			allocationPercentage: !newAllocation.AllocPercent,
			// ||
			// !isValid ||
			// Number(newAllocation.AllocPercent) < 0 ||
			// Number(newAllocation.AllocPercent) > 100 ||
			// isNaN(Number(newAllocation.AllocPercent)), // Percentage must be between 0 and 100
			allocationStartTime: !newAllocation.startDate, // Start time is required
			allocationEndTime:
			!newAllocation.endDate || 
			(newAllocation.endDate !== 'current' &&
				(
					dayjs(newAllocation.endDate).isAfter(dayjs()) || 
					(newAllocation.startDate && 
						!dayjs(newAllocation.endDate).isAfter(newAllocation.startDate))
				)
			)
		};
		setValidationErrors(errors);

		// Add the new allocation to the updated list
		if (!Object.values(errors).some((error) => error)) {
			setSelectedRows([...rowsWithoutNew, { ...newAllocation, id: newId, isNew: false, isEditing: false }]);
			setNewAllocation({
				id: 0,
				isNew: false,
				isEditing: false,
				PepwiseEnvironment: '',
				PepwiseProject: '',
				PepwiseProgram: '',
				PepwiseSector: '',
				AllocPercent: '',
				startDate: dayjs(),
				endDate: 'current'
			});
			setValidationErrors(validationInitialState);
			setNewAllocationRecords({
				...newAllocationRecords,
				[newId]: { ...newAllocation, id: newId, isNew: false, isEditing: false }
			});
		}
	};

	// useEffect(() => {
	// 	console.log('new allocation records', newAllocationRecords);
	// 	console.log('new allocation', newAllocation);
	// 	console.log('selected rows', selectedRows);
	// 	console.log('group Id', groupId);
	// 	console.log('currentRow', currentRow);
	// 	console.log('payload', payload);
	// 	console.log('response state', response);
	// }, [newAllocationRecords, newAllocation, selectedRows, groupId, payload, response]);

	const handleDeleteNewRow = (row:any) => {
		setSelectedRows(selectedRows.filter((row) => !row.isNew ))
	};

	const handleAddNewRow = () => {
		setValidationErrors(validationInitialState);
		
		// check there's only one new row at a time
		if (isNewAllocRowExists) return;
		
		const totalAllocation = selectedRows.reduce((sum, row) => {
			if (
				row.startDate === selectedRows[selectedRows.length - 1]?.startDate &&
				row.endDate === selectedRows[selectedRows.length - 1]?.endDate
			) {
				return sum + Number(row.AllocPercent);
			}
			return sum;
		}, 0);

		if (totalAllocation !== 0 && totalAllocation < 100) {
			const lastRow = selectedRows[selectedRows.length - 1];
			setNewAllocation(
				{
					id: 0,
					isNew: true,
					isEditing: false,
					PepwiseEnvironment: '',
					PepwiseProject: '',
					PepwiseProgram: '',
					PepwiseSector: '',
					AllocPercent: '',
					startDate: lastRow?.startDate,
					endDate: lastRow?.endDate
				}
			);
		} else if(totalAllocation === 100) {
			const lastRow = selectedRows[selectedRows.length - 1];
			setNewAllocation(
				{
					id: 0,
					isNew: true,
					isEditing: false,
					PepwiseEnvironment: '',
					PepwiseProject: '',
					PepwiseProgram: '',
					PepwiseSector: '',
					AllocPercent: '',
					startDate: dayjs(lastRow.endDate).add(1, 'day'),
					endDate: 'current'
				}
			);
		}else{
			setNewAllocation(
				{
					id: 0,
					isNew: true,
					isEditing: false,
					PepwiseEnvironment: '',
					PepwiseProject: '',
					PepwiseProgram: '',
					PepwiseSector: '',
					AllocPercent: '',
					startDate: currentRow.AllocationCount === 0 ? dayjs(currentRow.RescCreatedDate) : dayjs(),
					endDate: 'current'
				}
			);
		}

		setSelectedRows([
			...selectedRows,
			{
				id: 0,
				isNew: true,
				isEditing: false,
				PepwiseEnvironment: '',
				PepwiseProject: '',
				PepwiseProgram: '',
				PepwiseSector: '',
				AllocPercent: '',
				startDate: currentRow.AllocationCount === 0 ? dayjs(currentRow.RescCreatedDate) : dayjs(),
				endDate: 'current'
			}
		]);
	};

	const createAllocation = async (groupId: string, resource: string, payload: RowData[]) => {
		try {
			setIsLoading(true);
			const res = (await postResourceAllocationFinops({
				groupId: groupId,
				resource: resource,
				data: payload
			})) as { response: any; data?: any; success?: boolean };

			// // Check if `BulkEditRes` exists (success case)
			if (res.success) {
				// const { success } = res;
				if (res.success) {
					onTitleChange('Allocation Modified');
					onWidthChange('md');
					setResponse(res.data.result);
					setIsSuccessModalOpen(true);
					return true;
				} else {
					// Handle failure when success flag is false
					onTitleChange('Create/Modify Allocation');
					onWidthChange('lg');
					setResponse(res.data);
					setIsSuccessModalOpen(false);
					return false;
				}
			} else if (res.response.data) {
				const { success } = res.response.data;
				const { message } = res.response.data;

				if (success) {
					onTitleChange('Allocation Modified');
					onWidthChange('md');
					setResponse(res.response.data);
					setIsSuccessModalOpen(true);
					return true;
				} else {
					// Handle failure when success flag is false
					onTitleChange('Create/Modify Allocation');
					onWidthChange('lg');
					setResponse(res.response.data);
					setIsSuccessModalOpen(false);
					return false;
				}
			}
		} catch (error) {
			console.log('Error in create allocation:', error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleConfirm = async () => {
		if (Object.values(validationErrors).includes(true) || remainingPercentage !== 0) {
			return;
		}
		const updatedPayload = updatePayload();
		await createAllocation(groupId, resource, Object.values(updatedPayload));
	};

	const handleCancel = () => {
		const newRecordIds = Object.keys(newAllocationRecords).map(Number);

		setSelectedRows((prevRows) => prevRows.filter((row) => !newRecordIds.includes(row.id)));
		setNewAllocationRecords({});
		onCancel();
	};

	const handleDeleteRow = (id: number) => {
		setSelectedRows(selectedRows.filter((row) => row.id !== id));
		setNewAllocationRecords((prevRecords) => {
			const updatedRecords = { ...prevRecords };
			delete updatedRecords[id];
			return updatedRecords;
		});
		// validateCurrentAllocation()
	};

	function getColumns(): Array<any> {
		return columnNames.map((col: any, idx: number) => ({
			key: col.name,
			name: col.label,
			editable: () => col.isEditable,
			formatter({ row, column }: any) {
				if (row.isEditing && !row.originalData) row.originalData = { ...row };
				// Add new row of input fields
				if (row.isNew) {
					if (col.name === 'PepwiseProgram') {
						return (
							<FormControl fullWidth error={validationErrors.project}>
								<AsyncAutoComplete
									textFieldLabel="Programs"
									inputValue={newAllocation.PepwiseProgram}
									onChange={(event, value) => {
										const selectedProgram = programOptns.find((option) => option.label === value);
										const programId = selectedProgram && Number(selectedProgram.value);
										
										programId && setSelectedProgramId((prev) => ({...prev, [value]: programId}));
										handleChange('PepwiseProgram', value);
									}}
									mappingType=""
									columnName={'PepwiseProgram'}
									options={programOptns}
									fieldValueOptionsCallback={async (input) => await getProgramProjectOptions(input, 'program')}
									style={{ width: '9.5rem' }}
									sx={{
										'& .MuiOutlinedInput-notchedOutline': {
											borderColor: `${colors.text.focus} !important`
										},
										'&:hover .MuiOutlinedInput-notchedOutline': {
											borderColor: `${colors.text.focus} !important`
										},
										'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
											borderColor: `${colors.text.focus} !important`
										},
										'& .MuiInputBase-root': {
											minWidth: '12rem',
											padding: '6px'
										}
									}}
								/>
								{validationErrors.programs && <FormHelperText>Programs is required.</FormHelperText>}
							</FormControl>
						);
					}

					if (col.name === 'PepwiseProject') {
						return (
							<FormControl fullWidth error={validationErrors.project}>
								<AsyncAutoComplete
									textFieldLabel="Project"
									inputValue={newAllocation.PepwiseProject}
									value={newAllocation.PepwiseProject}
									onChange={(event, value) => {
										const selectedProject = projectOptions.find((option) => option.label === value);
										const projectId = selectedProject && Number(selectedProject.value);

										projectId && setSelectedProjectId((prev) => ({...prev, [value]: projectId}));
										handleChange('PepwiseProject', value);
									}}
									mappingType=""
									columnName={'PepwiseProject'}
									options={projectOptions}
									disabled={isProjectDisabled}
									// fieldValueOptionsCallback={async (input) => await getProgramProjectOptions(input, 'project')}
									style={{ width: '9.5rem' }}
									sx={{
										'& .MuiOutlinedInput-notchedOutline': {
											borderColor: `${colors.text.focus} !important`
										},
										'&:hover .MuiOutlinedInput-notchedOutline': {
											borderColor: `${colors.text.focus} !important`
										},
										'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
											borderColor: `${colors.text.focus} !important`
										},
										'& .MuiInputBase-root': {
											minWidth: '12rem',
											padding: '6px'
										}
									}}
								/>
								{validationErrors.project && <FormHelperText>Project is required.</FormHelperText>}
							</FormControl>
						);
					}

					if (col.name === 'PepwiseSector') {
						return (
							<FormControl fullWidth error={validationErrors.project}>
								<AsyncAutoComplete
									textFieldLabel="Sectors"
									inputValue={newAllocation.PepwiseSector}
									onChange={(event, value) => handleChange('PepwiseSector', value)}
									mappingType="finopsResourceMapping"
									columnName={'PepwiseSector'}
									options={sectorOptions}
									// fieldValueOptionsCallback={async () => getInputSecEnvOptions('sectors')}
									style={{ width: '9.5rem' }}
									sx={{
										'& .MuiOutlinedInput-notchedOutline': {
											borderColor: `${colors.text.focus} !important`
										},
										'&:hover .MuiOutlinedInput-notchedOutline': {
											borderColor: `${colors.text.focus} !important`
										},
										'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
											borderColor: `${colors.text.focus} !important`
										},
										'& .MuiInputBase-root': {
											minWidth: '12rem',
											padding: '6px'
										}
									}}
								/>
								{validationErrors.sectors && <FormHelperText>Sectors is required.</FormHelperText>}
							</FormControl>
						);
					}

					if (col.isEditable && col.name === 'AllocPercent') {
						return (
							<FormControl fullWidth error={validationErrors.allocationPercentage}>
								<TextField
									type="text"
									variant="outlined"
									label="Allocation %"
									value={newAllocation.AllocPercent}
									sx={{
										'& .MuiOutlinedInput-root': {
											'& fieldset': {
												// borderColor: validationErrors.allocationPercentage ? 'boxColor.orange.color' : 'text.focus'
												borderColor: 'text.focus'
											},
											'&:hover fieldset': {
												// borderColor: validationErrors.allocationPercentage ? 'boxColor.orange.color' : 'text.focus'
												borderColor: 'text.focus'
											},
											'&.Mui-focused fieldset': {
												// borderColor: validationErrors.allocationPercentage ? 'boxColor.orange.color' : 'text.focus'
												borderColor: 'text.focus'
											}
										},
										'& .MuiOutlinedInput-input': {
											fontSize: '12px',
											width: '9.5rem'
										}
									}}
									onChange={(e) => handleChange(col.name, e.target.value)}
									// InputProps={{
									// 	endAdornment: validationErrors.allocationPercentage && (
									// 		<WarningAmberIcon
									// 			style={{
									// 				color: 'orange',
									// 				marginRight: '8px',
									// 				fontSize: '20px'
									// 			}}
									// 		/>
									// 	)
									// }}
								/>
								{validationErrors.allocationPercentage && (
									<FormHelperText sx={{ whiteSpace: 'normal', wordBreak: 'break-word', lineHeight: 1.5 }}>
										Allocation percentage must be between 0 and 100.
									</FormHelperText>
								)}
							</FormControl>
						);
					}
					if (col.name === 'startDate') {
						return (
							<FormControl fullWidth error={validationErrors.allocationStartTime}>
								{/* <CustomDateTimePicker
									value={newAllocation.startDate}
									style={{
										'& .MuiOutlinedInput-input': {
											fontSize: '12px'
										},
										'& .MuiOutlinedInput-notchedOutline': {
											borderColor: `${colors.text.focus} !important`
										},
										'&:hover .MuiOutlinedInput-notchedOutline': {
											borderColor: `${colors.text.focus} !important`
										},
										'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
											borderColor: `${colors.text.focus} !important`
										}
									}}
									onChange={(newValue) => handleChange(col.name, dayjs(newValue))}
								/> */}
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DatePicker
										value={newAllocation.startDate}
										onChange={(newValue) => {handleChange(col.name, dayjs(newValue).format('YYYY-MM-DD'))
										}}
										disabled={currentRow.AllocationCount === 0}
										renderInput={(params) => (
											<TextField
												{...params}
												sx={{
													'& .MuiOutlinedInput-input': {
														fontSize: '12px'
													},
													'& .MuiOutlinedInput-notchedOutline': {
														borderColor: `${colors.text.focus} !important`
													},
													'&:hover .MuiOutlinedInput-notchedOutline': {
														borderColor: `${colors.text.focus} !important`
													},
													'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
														borderColor: `${colors.text.focus} !important`
													}
												}}
											/>
										)}
									/>
								</LocalizationProvider>
								{validationErrors.allocationStartTime && (
									<FormHelperText sx={{ whiteSpace: 'normal', wordBreak: 'break-word', lineHeight: 1.5 }}>
										Allocation Start date is required.
									</FormHelperText>
								)}
							</FormControl>
						);
					}

					if (col.name === 'endDate') {
						return (
							<FormControl fullWidth error={validationErrors.allocationEndTime}>
								{/* <CustomDateTimePicker
									value={newAllocation.endDate}
									style={{
										'& .MuiOutlinedInput-input': {
											fontSize: '12px'
										},
										'& .MuiOutlinedInput-notchedOutline': {
											borderColor: `${colors.text.focus} !important`
										},
										'&:hover .MuiOutlinedInput-notchedOutline': {
											borderColor: `${colors.text.focus} !important`
										},
										'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
											borderColor: `${colors.text.focus} !important`
										}
									}}
									onChange={(newValue) =>
										!newValue ? handleChange(col.name, 'current') : handleChange(col.name, dayjs(newValue))
									}
								/> */}
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DatePicker
										value={newAllocation.endDate}
										maxDate={dayjs()}
										onChange={(newValue) =>
											!newValue
												? handleChange(col.name, 'current')
												: handleChange(col.name, dayjs(newValue).format('YYYY-MM-DD'))
										}
										renderInput={(params) => {
											
											return <TextField
												{...params}
												sx={{
													'& .MuiOutlinedInput-input': {
														fontSize: '12px'
													},
													'& .MuiOutlinedInput-notchedOutline': {
														borderColor: `${colors.text.focus} !important`
													},
													'&:hover .MuiOutlinedInput-notchedOutline': {
														borderColor: `${colors.text.focus} !important`
													},
													'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
														borderColor: `${colors.text.focus} !important`
													}
												}}
											/>
										}}
									/>
								</LocalizationProvider>
								{validationErrors.allocationEndTime && (
									<FormHelperText
										sx={{
											whiteSpace: 'normal',
											wordBreak: 'break-word',
											lineHeight: 1.5
										}}
									>
										End date is required and must be after start date, cannot be a future date.
									</FormHelperText>
								)}
							</FormControl>
						);
					}
					// if (col.name === 'actions') {
					// 	return (
					// 		<Stack>
					// 			<Button
					// 				variant="pepwiseOutline"
					// 				sx={{
					// 					color: 'text.focus',
					// 					width: '1.3rem',
					// 					height: '1rem',
					// 					padding: ' 25px 25px'
					// 				}}
					// 				onClick={handleAddAllocation}
					// 			>
					// 				CONFIRM
					// 			</Button>
					// 		</Stack>
					// 	);
					// }
					if (col.name === 'actions') {
						return (
							<Stack
								direction="row"
								alignItems="center"
								spacing="1rem"
								justifyContent="center"
								sx={{
									height: '50%',
									width: '100%'
								}}
							>
								<Alert
									severity="success"
									sx={{
										width: '8px',
										cursor: 'pointer',
										'& .MuiAlert-icon': {
											color: 'info.main'
										}
									}}
									onClick={handleAddAllocation}
								></Alert>
								<DeleteIcon
									title="Delete Row"
									style={{ width: '30px', height: '30px', cursor: 'pointer' }}
									onClick={() => handleDeleteNewRow(row)}
								/>
							</Stack>
						);
					}
				}

				// Cell editing field render
				if (col.isEditable && row.isEditing && col.name === 'AllocPercent') {
					return (
						<FormControl fullWidth error={!!editValidationErrors.allocationPercentage}>
							<TextField
								sx={{
									'& .MuiOutlinedInput-root': {
										'&.Mui-error': {
											'& .MuiOutlinedInput-notchedOutline': {
												borderColor: 'red'
											},
											'&:hover .MuiOutlinedInput-notchedOutline': {
												borderColor: 'red'
											},
											'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
												borderColor: 'red'
											}
										}
									},
									'& .MuiOutlinedInput-notchedOutline': {
										borderColor: `${colors.text.focus} !important`
									},
									'&:hover .MuiOutlinedInput-notchedOutline': {
										borderColor: `${colors.text.focus} !important`
									},
									'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
										borderColor: `${colors.text.focus} !important`
									},
									'& .MuiOutlinedInput-input': {
										fontSize: '12px',
										padding: '8px'
									}
								}}
								error={!!editValidationErrors.allocationPercentage}
								value={row[column.key]}
								onChange={(e) => {
									const value = e.target.value;
									handleAllocationChange(row.id, column.key, value);
									// Validate allocationPercentage
									if (!value || Number(value) < 0 || Number(value) > 100 || isNaN(Number(value))) {
										setEditValidationErrors((prev) => ({
											...prev,
											allocationPercentage: true
										}));
									} else {
										setEditValidationErrors((prev) => ({
											...prev,
											allocationPercentage: false
										}));
									}
								}}
							/>
							{/* {editValidationErrors.allocationPercentage && (
								<FormHelperText sx={{ whiteSpace: 'normal', wordBreak: 'break-word', lineHeight: 1.5 }}>
									Allocation percentage must be between 0 and 100.
								</FormHelperText>
							)} */}
						</FormControl>
					);
				}
				if (col.isEditable && row.isEditing && col.name === 'startDate') {
					return (
						<FormControl fullWidth error={!!editValidationErrors.allocationStartTime}>
							{/* <CustomDateTimePicker
								style={{
									'& .MuiOutlinedInput-root': {
										'&.Mui-error': {
											'& .MuiOutlinedInput-notchedOutline': {
												borderColor: 'red'
											},
											'&:hover .MuiOutlinedInput-notchedOutline': {
												borderColor: 'red'
											},
											'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
												borderColor: 'red'
											}
										}
									},
									'& .MuiOutlinedInput-input': {
										fontSize: '12px',
										padding: '8px'
									}
								}}
								value={row[column.key]}
								error={!!editValidationErrors.allocationStartTime}
								onChange={(newValue) => {
									handleAllocationChange(row.id, column.key, newValue);
									// Validate allocationStartTime
									if (!newValue) {
										setEditValidationErrors((prev) => ({
											...prev,
											allocationStartTime: true
										}));
									} else {
										setEditValidationErrors((prev) => ({
											...prev,
											allocationStartTime: false
										}));
									}
								}}
							/> */}
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
									value={row[column.key]}
									onChange={(newValue) => {
										const formattedValue = newValue && dayjs(newValue).format('YYYY-MM-DD');

										handleAllocationChange(row.id, column.key, formattedValue);
										// Validate allocationStartTime
										if (!formattedValue) {
											setEditValidationErrors((prev) => ({
												...prev,
												allocationStartTime: true
											}));
										} else {
											setEditValidationErrors((prev) => ({
												...prev,
												allocationStartTime: false
											}));
										}
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											error={!!editValidationErrors.allocationStartTime}
											sx={{
												'& .MuiOutlinedInput-root': {
													'&.Mui-error': {
														'& .MuiOutlinedInput-notchedOutline': {
															borderColor: 'red'
														},
														'&:hover .MuiOutlinedInput-notchedOutline': {
															borderColor: 'red'
														},
														'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
															borderColor: 'red'
														}
													}
												},
												'& .MuiOutlinedInput-notchedOutline': {
													borderColor: `${colors.text.focus} !important`
												},
												'&:hover .MuiOutlinedInput-notchedOutline': {
													borderColor: `${colors.text.focus} !important`
												},
												'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
													borderColor: `${colors.text.focus} !important`
												},
												'& .MuiOutlinedInput-input': {
													fontSize: '12px',
													padding: '8px'
												}
											}}
										/>
									)}
								/>
							</LocalizationProvider>
							{/* {editValidationErrors.allocationStartTime && (
								<FormHelperText sx={{ whiteSpace: 'normal', wordBreak: 'break-word', lineHeight: 1.5 }}>
									Allocation Start date is required.
								</FormHelperText>
							)} */}
						</FormControl>
					);
				}
				if (col.isEditable && row.isEditing && col.name === 'endDate') {
					return (
						<FormControl fullWidth error={!!editValidationErrors.allocationEndTime}>
							{/* <CustomDateTimePicker
								style={{
									'& .MuiOutlinedInput-root': {
										'&.Mui-error': {
											'& .MuiOutlinedInput-notchedOutline': {
												borderColor: 'red'
											},
											'&:hover .MuiOutlinedInput-notchedOutline': {
												borderColor: 'red'
											},
											'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
												borderColor: 'red'
											}
										}
									},
									'& .MuiOutlinedInput-input': {
										fontSize: '12px',
										padding: '8px'
									}
								}}
								value={row[column.key]}
								error={!!editValidationErrors.allocationEndTime}
								onChange={(newValue) => {
									handleAllocationChange(row.id, column.key, newValue);
									// Validate allocationEndTime
									if (newValue && row.allocationStartTime && newValue <= row.allocationStartTime) {
										setEditValidationErrors((prev) => ({
											...prev,
											allocationEndTime: true
										}));
									} else {
										setEditValidationErrors((prev) => ({
											...prev,
											allocationEndTime: false
										}));
									}
								}}
							/> */}
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
									value={row[column.key]}
									maxDate={dayjs()}
									onChange={(newValue) => {
										const formattedValue = newValue ? dayjs(newValue).format('YYYY-MM-DD') : 'current';
										handleAllocationChange(row.id, column.key, formattedValue);

										if (
											formattedValue !== 'current' &&
											row.allocationStartTime &&
											dayjs(formattedValue).isBefore(dayjs(row.allocationStartTime), 'day')
										) {
											// End date is before start date
											setEditValidationErrors((prev) => ({
												...prev,
												allocationEndTime: true
											}));
										} else if (formattedValue !== 'current' && dayjs(formattedValue).isAfter(dayjs(), 'day')) {
											// End date is in the future
											setEditValidationErrors((prev) => ({
												...prev,
												allocationEndTime: true
											}));
										} else {
											// Valid end date
											setEditValidationErrors((prev) => ({
												...prev,
												allocationEndTime: false
											}));
										}
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											error={!!editValidationErrors.allocationEndTime}
											sx={{
												'& .MuiOutlinedInput-root': {
													'&.Mui-error': {
														'& .MuiOutlinedInput-notchedOutline': {
															borderColor: 'red'
														},
														'&:hover .MuiOutlinedInput-notchedOutline': {
															borderColor: 'red'
														},
														'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
															borderColor: 'red'
														}
													}
												},
												'& .MuiOutlinedInput-input': {
													fontSize: '12px',
													padding: '8px'
												},
												'& .MuiOutlinedInput-notchedOutline': {
													borderColor: `${colors.text.focus} !important`
												},
												'&:hover .MuiOutlinedInput-notchedOutline': {
													borderColor: `${colors.text.focus} !important`
												},
												'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
													borderColor: `${colors.text.focus} !important`
												}
											}}
										/>
									)}
								/>
							</LocalizationProvider>
							{/* {editValidationErrors.allocationEndTime && (
								<FormHelperText
									sx={{
										whiteSpace: 'normal',
										wordBreak: 'break-word',
										lineHeight: 1.5
									}}
								>
									Allocation End date is required and must be after start date.
								</FormHelperText>
							)} */}
						</FormControl>
					);
				}
				// Delete icon
				if (col.name === 'actions') {
					return row.isEditing ? (
						<Stack direction="row" alignItems="center" justifyContent="space-evenly" height='100%'>
							<CloseIcon className='rm-icons' title="Cancel" 
								onClick={() => {
									handleSaveEdit(row.id, row.originalData);
									delete row.originalData;
								}} 
							/>
							<SaveIcon title="Save" className='rm-icons'
								onClick={() => {
									if (
										!editValidationErrors.allocationPercentage &&
										!editValidationErrors.allocationStartTime &&
										!editValidationErrors.allocationEndTime
									) {
										handleSaveEdit(row.id, {
											allocationPercentage: row.allocationPercentage,
											allocationStartTime: row.allocationStartTime,
											allocationEndTime: row.allocationEndTime
										});
										delete row.originalData;
									}
								}}
							/>
						</Stack>
						// <Stack
						// 	direction="column"
						// 	alignItems="center"
						// 	justifyContent="center"
						// 	sx={{
						// 		height: '100%',
						// 		width: '100%'
						// 	}}
						// >
						// 	<Button
						// 		variant="pepwiseOutline"
						// 		sx={{
						// 			color: 'text.focus',
						// 			width: '1.3rem',
						// 			height: '1rem'
						// 		}}
						// 		onClick={() => {
						// 			if (
						// 				!editValidationErrors.allocationPercentage &&
						// 				!editValidationErrors.allocationStartTime &&
						// 				!editValidationErrors.allocationEndTime
						// 			) {
						// 				handleSaveEdit(row.id, {
						// 					allocationPercentage: row.allocationPercentage,
						// 					allocationStartTime: row.allocationStartTime,
						// 					allocationEndTime: row.allocationEndTime
						// 				});
						// 			}
						// 		}}
						// 	>
						// 		SAVE
						// 	</Button>
						// </Stack>
					) : (
						<Stack direction="row" alignItems="center" justifyContent="space-evenly">
							<EditIcon className="edit-icon" style={{ cursor: 'pointer' }} title="Click to Edit" onClick={() => handleEditRow(row)} />
							<DeleteIcon
								title="Delete Row"
								style={{ width: '30px', height: '30px', cursor: 'pointer' }}
								onClick={() => handleDeleteRow(row.id)}
							/>
						</Stack>
					);
				}
				return (
					<Stack direction="row" justifyContent="space-between" alignItems="center">
						<RowTypography isSuccessor={!row[column.key]}>
							{column.key === 'PepwiseSector' && row[column.key] ? (
								<Stack direction="row" spacing={1}>
									<RowChip value={row[column.key]} rowId={row.id} />
								</Stack>
							) : dayjs.isDayjs(row[column.key]) ? (
								row[column.key].format('YYYY-MM-DD')
							) : Array.isArray(row[column.key]) ? (
								<>
									{row[column.key].map((item: string, index: number) => (
										<Typography key={index}>{item}</Typography>
									))}
								</>
							) : column.key === 'AllocPercent' ? (
								`${row[column.key]}%`
							) : row[column.key] === 'current' ? (
								'Current'
							) : (
								row[column.key]
							)}
						</RowTypography>
					</Stack>
				);
			},
			width: col.name === 'actions' ? 50 : 212
		}));
	}

	// scroll to table input field once add row clicked
	const gridRef = useRef<any>();
	useEffect(() => {
		if (isNewAllocRowExists) {
			if (gridRef.current) {
				gridRef.current.scrollToRow(selectedRows.length - 1);
			}
		}
	}, [selectedRows]);

	if (isLoading) {
		return <LoaderComponent p={2} />;
	}

	return !isSuccesModalOpen ? (
		<>
		{response?.message && (
			<Stack
				direction="row"
				py="0.5rem"
				px="1.5rem"
				sx={{ backgroundColor: 'boxColor.orange.bgColor', display: 'flex', alignItems: 'center' }}
			>
				<WarningImage className="warning-image" />
				<Typography variant="tableColumnFont" sx={{ color: 'boxColor.orange.color', ml: '0.5rem' }}>
					{response?.message && (
						<>
							{response?.message}{' '}
						</>
					)} 
					{response?.data?.startDate && (
						<>
							( Start Date: {new Date(response.data.startDate).toLocaleDateString()}, End Date: {new Date(response.data.endDate).toLocaleDateString()} )
						</>
					)}
				</Typography>
				{response?.data?.overlappingDates &&
					response.data.overlappingDates.length > 0 &&
					response.data.overlappingDates.map((overlap: any, index: any) => (
						<Typography key={index} variant="tableColumnFont" sx={{ color: 'boxColor.orange.color', ml: '0.5rem' }}>
							<br />
							Start Date: {new Date(overlap?.startDate).toLocaleDateString()}
							<br />
							End Date:{' '}
							{new Date(overlap?.endDate).toLocaleDateString()}
							<br />
							Overlapping With:
							<br />
							Start Date: {new Date(overlap?.overlappingWith?.startDate).toLocaleDateString()}
							<br />
							End Date:{' '}
							{new Date(overlap?.overlappingWith?.endDate).toLocaleDateString()}
						</Typography>
					))}
			{response?.data?.missedDates &&
					response.data.missedDates.length > 0 &&
					response.data.missedDates.map((dates: any, index: any) => (
						<Typography key={`missed-${index}`}variant="tableColumnFont" sx={{ color: 'boxColor.orange.color', ml: '0.5rem' }}>
							<br />
							Prev. End Date: {new Date(dates?.prevEndDate).toLocaleDateString()}
							<br />
							Current Start Date:{' '}
							{new Date(dates?.currentStartDate).toLocaleDateString()}
							<br />
							 No. of Days:{' '}{dates?.NoofDays}
							<br />
						</Typography>
					))}
			{response?.data?.futureDates &&
					response.data.futureDates.length > 0 &&
					response.data.futureDates.map((dates: any, index: any) => (
						
						
						<Typography key={`missed-${index}`}variant="tableColumnFont" sx={{ color: 'boxColor.orange.color', ml: '0.5rem' }}>
							<br />
							Start Date: {new Date(dates?.startDate)?.toLocaleDateString()}
							<br />
							End Date:{' '}
							{new Date(dates?.endDate)?.toLocaleDateString()}
							<br />
							future Date Type:{' '}{dates?.futureDateType}
							<br />
						</Typography>
					))}
			</Stack>
		)}
			<Stack
				sx={{
					flexDirection: 'column',
					pl: '20px',
					pr: '20px',
					pb: '20px',
					gap: 2,
					textAlign: 'left',
					'& .rdg-cell': {
						'&:hover': {
							backgroundColor: 'inherit !important'
						}
					}
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						color: 'error.main',
						textAlign: 'center',
						width: '100%',
						fontSize: '12px'
					}}
				>
					{editValidationErrors.allocationPercentage && (
						<Typography>{editErrorMessages.allocationPercentage}</Typography>
					)}
					{editValidationErrors.allocationStartTime && <Typography>{editErrorMessages.allocationStartTime}</Typography>}
					{editValidationErrors.allocationEndTime && <Typography>{editErrorMessages.allocationEndTime}</Typography>}
				</Box>
				<Stack
					direction="row"
					justifyContent="space-between"
					spacing={12}
					pt={1}
					pb={4}
					marginBottom={1}
					borderBottom={`0.5px solid ${colors.dark.dark}`}
				>
					<Box display="flex" gap={3}>
						<Stack>
							<Typography sx={{ color: 'text.subtle' }}>{allocationType.label}</Typography>
							<Typography>{currentRow[allocationType.key]}</Typography>
						</Stack>
						<Stack>
							<Typography sx={{ color: 'text.subtle' }}>Total</Typography>
							<Typography>{currentRow.AllocationCount}</Typography>
						</Stack>
					</Box>

					<Stack>
						<FormControl
							fullWidth
							variant="outlined"
							sx={{
								minWidth: 400,
								'& .MuiOutlinedInput-root': {
									'& fieldset': {
										borderColor: 'light.diff'
									},
									'&:hover fieldset': {
										borderColor: 'white.main'
									},
									'&.Mui-focused fieldset': {
										borderColor: 'light.diff'
									}
								}
							}}
						>
							<InputLabel id="environment-label">Environment</InputLabel>
							<Select
								labelId="environment-label"
								id="environment-select"
								// value={newAllocation.environment}
								value={environment}
								error={validationErrors.environment}
								sx={{
									fontSize: '12px',
									padding: '6px'
								}}
								onChange={(e) => {
									setEnvironment(e.target.value);
									handleChange('PepwiseEnvironment', e.target.value);
								}}
								// onChange={(e) => handleChange('environment', e.target.value)}
								label="Environment"
							>
								{environmentOptions.map((env) => (
									<MenuItem key={env.value} value={env.value}>
										{env.label}
									</MenuItem>
								))}
							</Select>
							{validationErrors.environment && (
								<FormHelperText sx={{ whiteSpace: 'normal', wordBreak: 'break-word', lineHeight: 1.5, color: 'red' }}>
									Environment is required
								</FormHelperText>
							)}
						</FormControl>
					</Stack>
				</Stack>

				<CustomizedDataGrid
					title="Allocation"
					gridRef={gridRef}
					tableColumns={getColumns()}
					tableData={selectedRows}
					defaultColumnNames={defaultColumnNames}
					autoSetDraggedColumns={false}
					isRenderPagination={false}
					isShowMoreButton={false}
					style={{ maxHeight: '250px' }}
					rowHeight={(row: any) => {
						return row.row.isNew ? 110 : 40;
					}}
				/>
				<Stack>
					<Button
						variant="text"
						sx={{ width: '8rem', fontSize: '15px', color: 'text.focus' }}
						onClick={handleAddNewRow}
						disabled={isNewAllocRowExists}
					>
						+ Add Row
					</Button>
				</Stack>
				<Stack direction="row" justifyContent="space-between" alignItems="center">
						<Alert severity="info" sx={{ color: 'red' }}>
							<Typography sx={{ color: 'text.subtle', fontSize: '14px' }}>
								To make sure the resource is fully utilized and saved, please allocate it 100%. This will help us
							</Typography>
							<Typography sx={{ color: 'text.subtle', fontSize: '14px' }}>
								optimize our resources and avoid any potential issues.
							</Typography>
						</Alert>
					<Stack direction="row" gap={2} sx={{ alignSelf: 'flex-end' }} ml='auto'>
						<Button variant="pepwiseOutline" sx={{ width: '8rem' }} onClick={handleCancel}>
							CANCEL
						</Button>
						<Button variant="pepwiseSecondary" sx={{ width: '8rem' }} onClick={handleConfirm} disabled={ isNewAllocRowExists || !hasChanges()}>
							ALLOCATE
						</Button>
					</Stack>
				</Stack>
			</Stack>
		</>
	) : (
	<>
		<ResourceAllocationSuccessModal
		onTitleChange={onTitleChange}
		onWidthChange={onWidthChange}
		onCancel={() => {
			onConfirm();
			onCancel();
		}}
		setIsSuccessModalOpen={setIsSuccessModalOpen}
		modalTitle={modalTitle}
		allocationResponse={response}
		allocationType={allocationType}
	/>
  </>
	)
};

export default ResourceAllocationModal;
