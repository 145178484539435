import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { dashboardUrl } from '../../service/config';
import { Stack } from '@mui/material';
import useStyles from '../landingScreen/headerStyles';
import CustomDashboardIcon from '../../assets/Custom_Dashboard.svg';
import AnalyticsVisualization from 'components/framework/analyticsVisualization';
import '../landingScreen/header.scss';

export default function CustomDashboardDetails() {
	const { dashboardName } = useParams<{ dashboardName: string }>();
	const classes = useStyles();
	const storedProgramName = localStorage.getItem('programName') || '';

	const renderBreadcrumbs = () => (
		<div className="breadcrumb-custom">
			<div style={{ position: 'absolute', marginTop: '-33px' }}>
				<span
					className="job-menu"
					onClick={() => (window.location.href = `/data-pipeline/programOps/list/${storedProgramName}`)}
					style={{ cursor: 'pointer' }}
				>
					<img
						src={CustomDashboardIcon}
						className={classes.jobIcon}
						style={{ marginTop: '-3px' }}
						alt="Custom Dashboards Icon"
					/>
					<span className="titleLink">Program Ops Dashboards</span>
				</span>
				<span className="symbol">&gt;</span> <span className="page-title">{dashboardName}</span>
			</div>
		</div>
	);
	const getDashboardUrl = () => {
		if (dashboardName) {
			if (dashboardName.toLowerCase().includes('thailand mvs & gtm landing')) {
				return dashboardUrl.MDIPLandingPage;
			} else if (dashboardName.toLowerCase().includes('thailand mvs & gtm gold')) {
				return dashboardUrl.MDIPGoldPage;
			} else if (dashboardName.toLowerCase().includes('thailand mvs & gtm detailed')) {
				return dashboardUrl.MDIPDetailedPage;
			} else if (dashboardName.toLowerCase().includes('timeSeries')) {
				return dashboardUrl.IBPGDOTimeseries;
			} else if (dashboardName.toLowerCase().includes('turkey')) {
				return dashboardUrl.IBPTurkeyGDOTimeseries;
			} else if (dashboardName.toLowerCase().includes('vietnam')) {
				return dashboardUrl.MDIPVIETNAM;
			} else if (dashboardName.toLowerCase().includes('pmf')) {
				return dashboardUrl.MDIPPMF;
			} else if (dashboardName.toLowerCase().includes('activity details')) {
				return dashboardUrl.IBPGDOActivityDetail;
			} else if (dashboardName.toLowerCase().includes('error board')) {
				return dashboardUrl.IBPGDOErrorBoard;
			} else if (dashboardName.toLowerCase().includes('is&oe board')) {
				return dashboardUrl.IBPGDOIsoe;
			} else if (dashboardName.toLowerCase().includes('main page')) {
				return dashboardUrl.IBPGDOMainPage;
			} else if (dashboardName.toLowerCase().includes('gdo status')) {
				return dashboardUrl.IBPGDOStatus;
			} else if (dashboardName.toLowerCase().includes('home page')) {
				return dashboardUrl.IBPGDOHomePage;
			} else if (dashboardName.toLowerCase().includes('mosaic')) {
				return dashboardUrl.MOSAIC;
			}
		}
		return dashboardUrl.MDIPLandingPage; // Default URL
	};
	console.log(getDashboardUrl(), 'dashboard url');

	useEffect(() => {
		const activeId = document.getElementById('root');
		console.log(window.location.pathname);
		if (window.location.pathname.includes('/data-pipeline/programOps/details')) {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
			}
		}
	}, []);

	return (
		<div className="dashboard-panel-dataestate job-schedules-wrap">
			{renderBreadcrumbs()}
			<Stack>
				<AnalyticsVisualization
					params={{
						dashboardUrl: getDashboardUrl(),
						className: 'datapipelineCustomDashboardContainer',
						otherParams: '',
						seamless: true
					}}
				/>
			</Stack>
		</div>
	);
}
