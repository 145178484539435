import { useEffect, useRef, useState } from 'react';
import { Table, TableBody, TableCell, TableRow, Button, IconButton, TableHead, Box } from '@mui/material';
import { Add, FormatIndentIncrease } from '@mui/icons-material';
import QueryRules from './QueryRules';
import { useSearchParams } from 'react-router-dom';
import { add, update } from 'react-querybuilder';
import {
	canGroupSelectedRules,
	defaultRuleValue,
	getAllGroupPaths,
	groupSelectedRules,
	outerGroupSelectedRules,
	sortArrayOfArrays
} from './utilities';

const AdvancedBuilder = ({ fields, query, setQuery, handleFieldChange, fieldValueOptionsCallback, valueField }: any) => {
	const [selectedPaths, setSelectedPaths] = useState<any>([]);
	const groupPaths = useRef<number[][]>([]);
	const defaultRuleRef = useRef(false);

	const handleSetQuery = (newQuery: any) => {
		groupPaths.current = getAllGroupPaths(newQuery.rules).slice(1);
		setQuery(newQuery);
		setSelectedPaths([]);
	};

	const handleCheckboxChange = (path: any) => {
		setSelectedPaths((prevPaths: any) => {
			if (prevPaths.some((p: any) => JSON.stringify(p) === JSON.stringify(path))) {
				return prevPaths.filter((p: any) => JSON.stringify(p) !== JSON.stringify(path));
			} else {
				return [...prevPaths, path];
			}
		});
	};

	const handleGroupAction = () => {
		const canGroupRes = canGroupSelectedRules(query, selectedPaths);
		if (canGroupRes.canGroup) {
			const updatedQuery =
				canGroupRes.groupType === 'outer'
					? outerGroupSelectedRules(query, selectedPaths)
					: groupSelectedRules(query, selectedPaths);
			// console.log('query grouped', updatedQuery);
			handleSetQuery(updatedQuery);
		}
	};

	const [searchParams] = useSearchParams();
		  // Extract query parameters
		  const programId = searchParams.get('programId');
		  const projectId = searchParams.get('projectId');
		  const programName = decodeURIComponent(searchParams.get('programName') || '');
		  const projectName = decodeURIComponent(searchParams.get('projectName') || '');

	useEffect(() => {
		if (!defaultRuleRef.current && (!query.rules || query.rules.length === 0)) {
			if (programName && projectName && fields.Program && fields.ProjectName) {
				const newRules = [
					{
						field: fields.Program.name,
						operator: 'contains',
						value: programName
					},
					{
						field: fields.ProjectName.name,
						operator: 'contains',
						value: projectName
					}
				];

				const updatedQuery = newRules.reduce((acc, rule) => add(acc, rule, []), query);
				handleSetQuery(updatedQuery);
				defaultRuleRef.current = true;
			} else if (fields.Resource) {
				const newInitialValue = {
					field: fields.Resource.name || '',
					operator:
						fields.Resource.fieldOptions?.operators.find((operator: any) => operator.name === 'contains')?.value || '',
					value: ''
				};

				// initialValueRef.current = newInitialValue;
				handleSetQuery(add(query, newInitialValue, []));
				defaultRuleRef.current = true;
			}
		}
	}, [fields, valueField]);

	return (
		<Table sx={{ borderCollapse: 'separate', borderSpacing: 6 }} stickyHeader>
			{/* Headers */}
			<TableHead>
				<TableRow sx={{ color: 'dark.dark' }}>
					<TableCell width={50}>
						<IconButton
							disabled={!canGroupSelectedRules(query, selectedPaths).canGroup}
							sx={{ color: 'text.subtle' }}
							onClick={() => handleGroupAction()}
						>
							<FormatIndentIncrease />
						</IconButton>
					</TableCell>
					{/* group tree branch style */}
					{!!groupPaths.current.length && (
						<TableCell component="th" variant="head">
							<Box />
						</TableCell>
					)}
					<TableCell width={120} component="th" variant="head">
						And/Or
					</TableCell>
					<TableCell width={400} component="th" variant="head">
						Field
					</TableCell>
					<TableCell width={240} component="th" variant="head">
						Operator
					</TableCell>
					<TableCell component="th" variant="head">
						Value
					</TableCell>
					<TableCell width={100} align="center">
						<Button
							variant="text"
							color="secondary"
							sx={{ minWidth: 130 }}
							startIcon={<Add />}
							onClick={() => {
								handleSetQuery(add(query, defaultRuleValue, []));
							}}
						>
							Add Clause
						</Button>
					</TableCell>
				</TableRow>
			</TableHead>

			<TableBody>
				<QueryRules
					rules={query.rules}
					fields={fields}
					query={query}
					setQuery={handleSetQuery}
					onCheckboxChange={handleCheckboxChange}
					checkedRules={selectedPaths}
					groupPaths={groupPaths}
					handleFieldChange={handleFieldChange}
					fieldValueOptionsCallback={fieldValueOptionsCallback}
				/>
			</TableBody>
		</Table>
	);
};

export default AdvancedBuilder;
