import { useEffect } from 'react';
import { Grid } from '@mui/material';
import IBPIcon from '../../assets/IBP_small.svg';
import EDFIcon from '../../assets/EDF.svg';
import DataOpsIcon from '../../assets/DataOps_small.svg';
import './dataPipeline.scss';
import '../LandingPage/LandingPage.scss';

export default function programOpsDashboard() {
	useEffect(() => {
		const activeId = document.getElementById('root');
		console.log(window.location.pathname);
		if (window.location.pathname.includes('/data-pipeline/programOps')) {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
			}
		}
	}, []);

	const handleDataPipelineClick = (event: any, type: string, programName: string) => {
		event.preventDefault();
		const programEncoded = encodeURIComponent(programName);
		if (type === 'blank') {
			window.open(`/data-pipeline/programOps/list/${programEncoded}`, '_blank');
		} else {
			window.location.href = `/data-pipeline/programOps/list/${programEncoded}`;
		}
	};

	return (
		<div className="dashboard-panel-dataestate job-schedules-wrap">
			<div className="program-list-container">
				<Grid container spacing={1} style={{ marginTop: '0' }}>
					{/* <Grid
						item
						xs={4}
						style={{ cursor: 'pointer' }}
						onClick={(e) => handleDataPipelineClick(e, 'no-blank', 'IBP Turkey')}
						onContextMenu={(e) => {
							e.preventDefault();
							handleDataPipelineClick(e, 'blank', 'IBP Turkey');
						}}
					>
						<div className="programOpsScreens">
							<div className="title">
								{IBPIcon && (
									<img
										src={IBPIcon}
										alt="IBP Turkey logo"
										className="obs_logo"
										style={{ maxWidth: '100%', height: 'auto', marginBottom: '8px' }}
									/>
								)}
								<h3 className="obsTitle" style={{ margin: '0' }}>
									IBP Turkey
								</h3>
							</div>
						</div>
					</Grid> */}
					<Grid
						item
						xs={4}
						style={{ cursor: 'pointer' }}
						onClick={(e) => handleDataPipelineClick(e, 'no-blank', 'IBP Global')}
						onContextMenu={(e) => {
							e.preventDefault();
							handleDataPipelineClick(e, 'blank', 'IBP Global');
						}}
					>
						<div className="programOpsScreens">
							<div className="title">
								{IBPIcon && (
									<img
										src={IBPIcon}
										alt="IBP Global logo"
										className="obs_logo"
										style={{ maxWidth: '100%', height: 'auto', marginBottom: '8px' }}
									/>
								)}
								<h3 className="obsTitle" style={{ margin: '0' }}>
									IBP Global
								</h3>
							</div>
						</div>
					</Grid>
					<Grid
						item
						xs={4}
						style={{ cursor: 'pointer' }}
						onClick={(e) => handleDataPipelineClick(e, 'no-blank', 'MDIP Thailand')}
						onContextMenu={(e) => {
							e.preventDefault();
							handleDataPipelineClick(e, 'blank', 'MDIP Thailand');
						}}
					>
						<div className="programOpsScreens">
							<div className="title">
								{DataOpsIcon && (
									<img
										src={DataOpsIcon}
										alt="MDIP logo"
										className="obs_logo"
										style={{ maxWidth: '100%', height: 'auto', marginBottom: '8px' }}
									/>
								)}
								<h3 className="obsTitle" style={{ margin: '0' }}>
									MDIP Thailand
								</h3>
							</div>
						</div>
					</Grid>
					<Grid item xs={4}>
						<div className="programOpsScreens">
							<div className="title">
								{EDFIcon && (
									<img
										src={EDFIcon}
										alt="EDF logo"
										className="obs_logo"
										style={{ maxWidth: '100%', height: 'auto', marginBottom: '8px' }}
									/>
								)}
								<h3 className="obsTitle" style={{ margin: '0' }}>
									EDF
								</h3>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} style={{ margin: '16px 0' }} /> {/* Adjust the margin as needed */}
					<Grid
						item
						xs={4}
						style={{ cursor: 'pointer' }}
						onClick={(e) => handleDataPipelineClick(e, 'no-blank', 'MDIP Vietnam')}
						onContextMenu={(e) => {
							e.preventDefault();
							handleDataPipelineClick(e, 'blank', 'MDIP Vietnam');
						}}
					>
						<div className="programOpsScreens">
							<div className="title">
								{DataOpsIcon && (
									<img
										src={DataOpsIcon}
										alt="MDIP logo"
										className="obs_logo"
										style={{ maxWidth: '100%', height: 'auto', marginBottom: '8px' }}
									/>
								)}
								<h3 className="obsTitle" style={{ margin: '0' }}>
								    MDIP Vietnam
								</h3>
							</div>
						</div>
					</Grid>
					<Grid
						item
						xs={4}
						style={{ cursor: 'pointer' }}
						onClick={(e) => handleDataPipelineClick(e, 'no-blank', 'MDIP PMF Sales')}
						onContextMenu={(e) => {
							e.preventDefault();
							handleDataPipelineClick(e, 'blank', 'MDIP PMF Sales');
						}}
					>
						<div className="programOpsScreens">
							<div className="title">
								{DataOpsIcon && (
									<img
										src={DataOpsIcon}
										alt="MDIP logo"
										className="obs_logo"
										style={{ maxWidth: '100%', height: 'auto', marginBottom: '8px' }}
									/>
								)}
								<h3 className="obsTitle" style={{ margin: '0' }}>
								    MDIP PMF Sales
								</h3>
							</div>
						</div>
					</Grid>
					<Grid
						item
						xs={4}
						style={{ cursor: 'pointer' }}
						onClick={(e) => handleDataPipelineClick(e, 'no-blank', 'Mosaic')}
						onContextMenu={(e) => {
							e.preventDefault();
							handleDataPipelineClick(e, 'blank', 'Mosaic');
						}}
					>
						<div className="programOpsScreens">
							<div className="title">
								{DataOpsIcon && (
									<img
										src={DataOpsIcon}
										alt="MDIP logo"
										className="obs_logo"
										style={{ maxWidth: '100%', height: 'auto', marginBottom: '8px' }}
									/>
								)}
								<h3 className="obsTitle" style={{ margin: '0' }}>
								    Mosaic
								</h3>
							</div>
						</div>
					</Grid>
				</Grid>
			</div>
		</div>
	);
}
