import { Button, Stack, Typography, Box } from '@mui/material';
import { CustomizedDataGrid } from 'components/customized_data_grid';
import React, { useEffect, useState } from 'react';

import { ReactComponent as ResourceMapping } from '../../../../src/assets/resourceMappingNew.svg';
import styled from '@emotion/styled';

const StyledConfirmationIcon = styled(ResourceMapping)({
	width: '5rem',
	height: '5rem'
});

type ModalWidth = 'sm' | 'md' | 'lg' | 'xl';

interface CommonSuccessModalProps {
	onTitleChange: (newtitle: string) => void;
	onWidthChange: (newWidth: ModalWidth) => void;
	onCancel: () => void;
    onConfirm: () => void;
	getColumns: () => Array<any>;
	messageTitle: string;
    bulkEditResponse:any;
	defaultColumnNames:string[];
	buttonLabel:string;
    successMessage:string;
	allocationType: {
		key: string;
		label: string;
	};
}

const CommonSuccessModal: React.FC<CommonSuccessModalProps> = ({
	onTitleChange,
	onWidthChange,
	onCancel,
    onConfirm,
	getColumns,
	messageTitle,
    bulkEditResponse,
	defaultColumnNames,
	buttonLabel,
    successMessage,
	allocationType
}) => {
	const [resourceCount, setResourceCount] = useState<number>(0);
	const [updatedColumn, setUpdatedColumn] = useState([]);

	useEffect(() => {
		if (bulkEditResponse?.result) {
			const responseData = bulkEditResponse.result ?? [];
		
			const updatedColumns = responseData.map((item: any) => ({
				
				resource: item.resource,
				sector: item.sector, 
				program: item.program, 
				project: item.project, 
				environment: item.environment, 
				allocation: item.allocation, 
				groupId:item.groupId,
				startDate:item.startDate,
				endDate:item.endDate,
				
			}));
	
			setUpdatedColumn(updatedColumns);
			setResourceCount(responseData.length);
		}
	}, [bulkEditResponse, allocationType]);


	const handleCancel = () => {
		onCancel();
        onConfirm();
	};

	return (
		<>
			<Stack sx={{ padding: '15px' }}>
				<Stack sx={{ flexDirection: 'row', gap: 2 }}>
					<Box sx={{ alignItems: 'center', justifyContent: 'flex-start', width: '5rem' }}>
						<StyledConfirmationIcon />
					</Box>
					<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
						<Typography variant="h6" sx={{ fontWeight: 500 }}>
							{messageTitle}
						</Typography>
						<Typography sx={{ alignSelf: 'flex-start', color: 'light.diff' }}>
							{resourceCount}{' '}{successMessage}
						</Typography>
					</Box>
				</Stack>
				<Stack sx={{ flexDirection: 'column', gap: 3, textAlign: 'left' }}>
					<CustomizedDataGrid
						tableColumns={getColumns()}
						tableData={updatedColumn}
						defaultColumnNames={defaultColumnNames}
						autoSetDraggedColumns={false}
						isRenderPagination={false}
						isShowMoreButton={false}
						style={{ maxHeight: '250px' }}
					/>
					<Stack direction="row" gap={2} sx={{ alignSelf: 'flex-end' }}>
						<Button variant="pepwiseSecondary" onClick={handleCancel}>
							{buttonLabel}
						</Button>
					</Stack>
				</Stack>
			</Stack>
		</>
	);
};

export default CommonSuccessModal;
